import { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sessionVideos } from '../../data/sessionVideos';
import { mavenData } from '../../data/mavenData';
import './Course.scss';
import Sidebar from './Sidebar';
import BrowseContent from '../BrowseContent/BrowseContent';
import Slide from './Slide';
import SessionSummary from './SessionSummary';
import {
  getUserVideos,
  updateWatchingVideosList,
} from '@hooks/useWatchingVideo';
import Hls from 'hls.js';
import { isMobile } from 'react-device-detect';
import { getVideoStartTime } from './CourseHelpers';
import { AuthContext } from '../../context/AuthContext';
import { useSubscription } from 'src/context/SubscriptionContext';
import useHasMounted from '@hooks/useHasMounted';

function Course({
  searchParams,
  setVideoModal,
  setToggledSubscribeModal,
  setAlert,
  setBioModal,
}) {
  const params = useParams();
  const mainVideoRef = useRef();
  const navigate = useNavigate();
  const subscription = useSubscription();

  const { user } = useContext(AuthContext);
  const [sessions, setSessions] = useState(null);
  const [showNextVideoLoadScreen, setShowNextVideoeLoadScreen] =
    useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [filteredMavenData, setFilteredMavenData] = useState(null);
  const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(10);
  const [selectedMavenData, setSelectedMavenData] = useState(null);
  const [videosWatched, setVideosWatched] = useState(null);
  const [videosWatchedIds, setVideosWatchedIds] = useState([]);

  const handleReplay = () => {
    setShowNextVideoeLoadScreen(false);
    mainVideoRef?.current?.play();
  };

  const handleNextSession = () => {
    setShowNextVideoeLoadScreen(false);
    navigate(
      `/course/${params.mavenSlug}/video/${params.mavenSlug}-${
        selectedSession.sessionNumber + 1
      }`
    );
  };

  const handleVideoClick = (e) => {
    handlePostCurrentTime(selectedSession);
  };

  const getCourseData = () => {
    //get mavens sessions
    const mavenSessions = sessionVideos.filter(
      (s) => s.mavenSlug === params.mavenSlug
    );
    setSessions(mavenSessions);
    const sesh = mavenSessions.find((m) => m.slug === params.videoSlug);
    setSelectedSession(sesh);

    //filter mavenData to remove interactive only
    const mData = mavenData
      .map((d) => ({
        ...d,
        label: `${d.title} (${d.sessionCount} Sessions)`,
        id: d.slug,
      }))
      .filter((m) => m.interactiveOnly !== 'TRUE');
    setFilteredMavenData(mData);
    setSelectedMavenData(mData.find((m) => sesh?.mavenSlug === m?.slug));
  };

  const startTimer = (seconds) => {
    if (seconds === null) {
      setShowNextVideoeLoadScreen(false);
      setCurrentTimeInSeconds(10);
    } else {
      let timeLeft = seconds;

      const countdown = () => {
        timeLeft--;
        if (timeLeft > 0) {
          setTimeout(countdown, 1000);
          setCurrentTimeInSeconds(timeLeft);
        } else {
          setShowNextVideoeLoadScreen(false);
          setCurrentTimeInSeconds(10);
          //go to next video
          navigate(
            `/course/${params.mavenSlug}/video/${params.mavenSlug}-${
              selectedSession.sessionNumber + 1
            }`
          );
        }
      };

      setTimeout(countdown, 1000);
    }
  };

  const handlePostCurrentTime = (session) => {
    updateWatchingVideosList(
      session.slug,
      session.videoId,
      mainVideoRef?.current?.currentTime * 1000,
      session.firebaseMavenId
    );
  };

  const handleVideoOnEnd = (session) => {
    //save progress in firebase
    handlePostCurrentTime(session);
    if (session.sessionNumber < sessions.length) {
      //go to next video
      /* navigate(
        `/course/${params.mavenSlug}/video/${params.mavenSlug}-${
          session.sessionNumber + 1
        }`
      ); */
      setShowNextVideoeLoadScreen(true);
      startTimer(10);
    } else {
      //go to next maven
      /* const currentMavenIndex = mavenData.findIndex(
        (p) => p.slug == session.mavenSlug
      );
      if (currentMavenIndex < filteredMavenData?.length) {
        const nextMavenSlug = filteredMavenData[currentMavenIndex + 1].slug;
        navigate(`/course/${nextMavenSlug}/video/${nextMavenSlug}-1`);
      }
      console.log('currentMaveIndex', currentMavenIndex); */
    }
  };

  useEffect(() => {
    getCourseData();
    setShowNextVideoeLoadScreen(false);
    //stop navigation for non-premium and unauthed users
    /* if (!user || subscription.planName !== 'Premium') {
      navigate(`/course/${params.mavenSlug}`);
    } */
    /* if (!user || subscription.planName !== 'Premium') {
      if (!params.videoSlug.includes(1)) {
        navigate(`/course/${params.mavenSlug}/video/${params.mavenSlug}-1`);
      }
    } */

    /* if (!user) return; */
    getUserVideos(setVideosWatched, setVideosWatchedIds);
  }, [params.mavenSlug, params.videoSlug, user]);

  useEffect(() => {
    if (selectedSession) {
      const videoUrl = selectedSession?.m3u8Link || selectedSession?.video;
      const video = mainVideoRef?.current;

      function initializeHLS() {
        video.removeAttribute('src');
        if (Hls.isSupported()) {
          const hls = new Hls();
          try {
            hls.loadSource(videoUrl);
          } catch (err) {
            console.log('Error Loading Media!!! ' + err);
            return false;
          }
          hls.attachMedia(video);
          hls.on(Hls.Events.MEDIA_ATTACHED, function () {});
          const savedStartTime = getVideoStartTime(
            selectedSession,
            videosWatchedIds,
            videosWatched
          );
          //if video is near end, set it back to start or the user will be in an endless loop
          if (selectedSession?.durationInSeconds - 15 < savedStartTime) {
            video.currentTime = 0;
            video.play();
          } else {
            video.currentTime = savedStartTime;
            video.play();
          }
        } else {
          if (isMobile) {
            video.setAttribute('src', videoUrl);
          }
        }
      }
      initializeHLS();
    }
  }, [selectedSession, videosWatched, videosWatchedIds]);

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className="course fade-in">
      <div
        className={`video-player-container ${
          showNextVideoLoadScreen ? 'show-inbetween' : ''
        }`}
      >
        <video
          className={`main-video ${showNextVideoLoadScreen ? 'hide' : ''}`}
          poster={selectedSession?.image}
          //src={selectedSession?.video}
          ref={mainVideoRef}
          controls={true}
          controlsList="nodownload"
          autoPlay
          playsInline
          onEnded={() => handleVideoOnEnd(selectedSession)}
          onClick={(e) => handleVideoClick(e.target)}
          onPause={(e) => handleVideoClick(e.target)}
        />

        {showNextVideoLoadScreen && (
          <Slide
            data={sessions}
            selectedSession={selectedSession}
            handleReplay={handleReplay}
            currentTimeInSeconds={currentTimeInSeconds}
            handleNextSession={handleNextSession}
            selectedMavenData={selectedMavenData}
          />
        )}
        <Sidebar
          data={sessions}
          selectedSession={selectedSession}
          filteredMavenData={filteredMavenData}
          searchParams={searchParams}
          handlePostCurrentTime={handlePostCurrentTime}
          noAuth={user ? false : true}
          setToggledSubscribeModal={setToggledSubscribeModal}
          selectedMavenData={selectedMavenData}
          setBioModal={setBioModal}
        />
      </div>
      <SessionSummary
        selectedSession={selectedSession}
        selectedMavenData={selectedMavenData}
      />
      <BrowseContent
        coursesTitle="More Courses"
        conversationsTitle="More Conversations"
        searchParams={searchParams}
        setVideoModal={setVideoModal}
        setToggledSubscribeModal={setToggledSubscribeModal}
        setAlert={setAlert}
      />
    </div>
  );
}

export default Course;
