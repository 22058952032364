import Button from '@components/Button/Button';
import { isDesktop, isMobile } from 'react-device-detect';
import CourseSummaryTalkTo from '../CourseLanding/CourseSummaryTalkTo';
import { InlineShareButtons } from 'sharethis-reactjs';

function SessionSummary({ selectedSession, selectedMavenData }) {
  return (
    <div className="session-summary">
      <div className="left">
        <div className="title">
          <h3>{selectedSession?.title} </h3>
          {/* <Button className="secondary">
            <img src="/icons/share.svg" alt="share" />
            {isDesktop && 'Share'}
          </Button> */}
          {isMobile && (
            <InlineShareButtons
              config={{
                alignment: 'center', // alignment of buttons (left, center, right)
                color: 'social', // set the color of buttons (social, white)
                enabled: true, // show/hide buttons (true, false)
                font_size: 12, // font size for the buttons
                labels: null, // button labels (cta, counts, null)
                language: 'en', // which language to use (see LANGUAGES)
                networks: [
                  // which networks to include (see SHARING NETWORKS)
                  'sms',
                  'facebook',
                  'twitter',
                  'email',
                  'pinterest',
                ],
                padding: 12, // padding within buttons (INTEGER)
                radius: 8, // the corner radius on each button (INTEGER)
                show_total: false,
                size: 36, // the size of each button (INTEGER)

                // OPTIONAL PARAMETERS

                //min_count: 10, // (threshold for total share count to be displayed)
                url: /* window.location.href */ `https://versus.co/course/${selectedMavenData?.slug}/video/${selectedSession?.slug}`, // (defaults to current url)
                image: selectedSession?.image, // (defaults to og:image or twitter:image)
                description: 'custom text', // (defaults to og:description or twitter:description)
                title: `Video session by ${selectedMavenData?.title}: Learn Sports Training Skills & Techniques with Expert Advice from Versus | VS`, // (defaults to og:title or twitter:title)
                message: `Check out this expert advice on VS'`, // (only for email sharing)
                subject: `Check out this session by ${selectedMavenData?.title}
                  `, // (only for email sharing)
                username: 'vsapp_', // (only for twitter sharing)
              }}
            />
          )}
          {isDesktop && (
            <InlineShareButtons
              config={{
                alignment: 'center', // alignment of buttons (left, center, right)
                color: 'social', // set the color of buttons (social, white)
                enabled: true, // show/hide buttons (true, false)
                font_size: 12, // font size for the buttons
                labels: null, // button labels (cta, counts, null)
                language: 'en', // which language to use (see LANGUAGES)
                networks: [
                  // which networks to include (see SHARING NETWORKS)
                  'sms',
                  'linkedin',
                  'messenger',
                  'facebook',
                  'twitter',
                  'email',
                  'pinterest',
                ],
                padding: 12, // padding within buttons (INTEGER)
                radius: 8, // the corner radius on each button (INTEGER)
                show_total: false,
                size: 36, // the size of each button (INTEGER)

                // OPTIONAL PARAMETERS

                //min_count: 10, // (threshold for total share count to be displayed)
                url: /* window.location.href */ `https://versus.co/course/${selectedMavenData?.slug}/video/${selectedSession?.slug}`, // (defaults to current url)
                image: selectedSession?.image, // (defaults to og:image or twitter:image)
                description: 'custom text', // (defaults to og:description or twitter:description)
                title: `Video session by ${selectedMavenData?.title}: Learn Sports Training Skills & Techniques with Expert Advice from Versus | VS`, // (defaults to og:title or twitter:title)
                message: `Check out this expert advice on VS'`, // (only for email sharing)
                subject: `Check out this session by ${selectedMavenData?.title}
                  `, // (only for email sharing)
                username: 'vsapp_', // (only for twitter sharing)
              }}
            />
          )}
        </div>

        <p>
          {selectedSession?.longDescription ||
            selectedSession?.shortDescription}
        </p>
      </div>
      <div className="right">
        <CourseSummaryTalkTo
          athlete={selectedMavenData?.title}
          topicCount={selectedMavenData?.topicCount}
          questionCount={selectedMavenData?.questionCount}
          slug={selectedMavenData?.slug}
          image={selectedMavenData?.restingImage}
        />
      </div>
    </div>
  );
}

export default SessionSummary;
