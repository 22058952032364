import styled from 'styled-components';

export const InputStyles = styled.input`
  padding: 0 16px;
  width: 100%;
  height: 64px;
  border: none;
  outline: none;
  color: #000;
  border-radius: 8px;
  &:focus,
  &:hover,
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    color: #888;
  }
`;
