import styled from "styled-components";

export const ContainerStyles = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  @media (max-width: 1330px) {
    max-width: 94%;
  }
`;
