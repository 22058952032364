export const getWatchedStyle = (
  d,
  videosWatchedIds,
  videosWatched,
  isLimited
) => {
  if (isLimited) return { width: '0%' };
  if (videosWatchedIds.includes(d.videoId)) {
    const video = videosWatched.find((v) => v.slug === d.slug);
    if (video?.currentPosition > 0) {
      return {
        width: `${(video.currentPosition / 1000 / d.durationInSeconds) * 100}%`,
      };
    }
  } else {
    return { width: '0%' };
  }
};

export const getVideoStartTime = (d, videosWatchedIds, videosWatched) => {
  if (videosWatchedIds.includes(d.videoId)) {
    const video = videosWatched.find((v) => v.video === d.videoId);
    return video.currentPosition / 1000;
  } else {
    return 0;
  }
};
