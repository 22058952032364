import Button from '@components/Button/Button';
import { useNavigate } from 'react-router-dom';
import './CardGrid.scss';
import { isMobile } from 'react-device-detect';

function CardGrid({
  data,
  setVideoModal,
  setToggledSubscribeModal,
  searchParams,
  setAlert,
  title,
  type,
}) {
  const navigate = useNavigate();
  const handleShowVideo = (item) => {
    const actions = [
      {
        id: 0,
        title: 'Share',
        image: '/icons/share.svg',
        className: 'secondary',
        callback: () => {
          navigator.clipboard.writeText(
            `${window.location.origin}/course/${item.slug}`
          );
          setAlert({
            type: 'success',
            message: 'Copied link',
            icon: '/icons/checkmark.svg',
          });
          setTimeout(() => {
            setAlert(null);
          }, 3000);
        },
      },
      {
        id: 1,
        title: 'Go To Course',
        callback: () => {
          navigate(`/course/${item.slug}${searchParams}`);
          setVideoModal(null);
        },
        className: 'primary',
      },
    ];

    const videoForModal = {
      video: item.trailer,
      title: item.cardTitle,
      subtitle: item.sessionCount
        ? `${item.sessionCount} Sessions`
        : item.sport,
      actions,
    };
    setVideoModal(videoForModal);
  };

  const handleCardClick = (q) => {
    if (title === 'Conversations') {
      navigate(`/conversation/${q?.slug}${searchParams}`);
      return;
    }

    if (!q?.interactiveOnly && q?.type !== 'Interactive') {
      navigate(`/course/${q?.slug}${searchParams}`);
    } else {
      navigate(`/conversation/${q?.slug}${searchParams}`);
    }
  };

  const handleButton = (e, q) => {
    if (q.interactiveOnly || title === 'Conversations') {
      navigate(`/conversation/${q.slug}${searchParams}`);
    } else {
      navigate(`/course/${q.slug}${searchParams}`);
    }
    e.stopPropagation();
  };

  const getBackgroundImage = (q) => {
    if (title === 'Courses') return `url(${q?.courseCardImage})`;
    if (title === 'Conversations') return 'none';
    return `url(${q?.rosterCardImage})`;
  };
  return (
    <div className={`card-grid ${title ? title : ''}`}>
      <div className="card-collection">
        {data?.map((q) => (
          <div
            className="collection-container"
            onClick={() => isMobile && handleCardClick(q)}
            key={q.id}
          >
            <div
              itemId={q.id}
              itemName={q.title}
              className="card"
              style={{
                backgroundImage: getBackgroundImage(q),
              }}
            >
              <div className="clickable" onClick={() => handleCardClick(q)} />
              {title === 'Conversations' && (
                <>
                  <div className="overlay" />
                  <video
                    playsInline
                    autoPlay
                    muted
                    loop
                    preload="yes"
                    width="100%"
                    height="auto"
                    poster={q?.restingImage}
                    src={q?.restingVideo}
                  />
                </>
              )}
              <div className="card-content">
                <div>
                  <p className="title">
                    {q?.title?.substring(0, q?.title?.indexOf(' '))}
                  </p>
                  <p className="title">
                    {q?.title?.substring(q?.title?.indexOf(' ') + 1)}
                  </p>
                </div>
                <div className="meta">
                  {type === 'Conversation' && (
                    <>
                      <div>
                        <img src="/icons/list-plus.svg" alt="Topics" />{' '}
                        <b>{q.topicCount}</b> Topics
                      </div>
                      <div>
                        <img src="/icons/microphone.svg" alt="Interactions" />{' '}
                        <b>{q.questionCount}</b> Interactions
                      </div>
                    </>
                  )}
                  {type === 'Course' && (
                    <>
                      <div>
                        <img src="/icons/Square-Play.svg" alt="Sessions" />{' '}
                        {q.sessionCount} Sessions
                      </div>
                    </>
                  )}
                  {!type && (
                    <>
                      {(q?.type === 'Course' || type === 'Course') && (
                        <div>
                          <img src="/icons/Square-Play.svg" alt="Course" />{' '}
                          Course
                        </div>
                      )}
                      {(q?.type === 'Interactive' ||
                        type === 'Interactive') && (
                        <div>
                          <img src="/icons/AI.svg" alt="Interactive" />{' '}
                          <span>Conversation</span>
                        </div>
                      )}
                      {!q?.type && (
                        <>
                          {q?.course && (
                            <div>
                              <img src="/icons/Square-Play.svg" alt="Course" />{' '}
                              <span>Course</span>
                            </div>
                          )}
                          {q?.interactive && (
                            <div>
                              <img src="/icons/AI.svg" alt="Interactive" />{' '}
                              <span>Conversation</span>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="actions">
                  {q.interactiveOnly || title === 'Conversations' ? (
                    <Button
                      className="primary"
                      title="Card Grid Maven Card"
                      action="Ask Now"
                      description={q.title}
                      onClick={(e) => {
                        navigate(`/interactive/${q.slug}${searchParams}`);
                      }}
                    >
                      <img
                        className="microphone"
                        src="/icons/microphone.svg"
                        alt="Watch Video"
                      />
                      Ask Now
                    </Button>
                  ) : (
                    <Button
                      className="primary"
                      onClick={(e) => {
                        handleShowVideo(q);
                      }}
                      title="Card Grid Maven Card"
                      action="Watch Preview"
                      description={q.title}
                    >
                      <img src="/icons/play.svg" alt="Watch Video" />
                      Watch Preview
                    </Button>
                  )}

                  <Button
                    height="52px"
                    className="secondary button"
                    onClick={(e) => {
                      handleButton(e, q);
                    }}
                    title="Card Grid Maven Card"
                    action="Learn More"
                    description={q.title}
                  >
                    <img src="/icons/Info.svg" alt="Learn More" />
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardGrid;
