import React, { useState } from 'react';

function FAQsection({ data }) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div
      className={`section ${isExpanded ? 'active' : ''}`}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <img src="/icons/chevron-down.svg" alt="expand" />
      <h5>{data.title}</h5>
      {isExpanded && <div className="body">{data.body}</div>}
    </div>
  );
}

export default FAQsection;
