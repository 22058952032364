import { useRef, useEffect, useState } from 'react';
import Button from '@components/Button/Button';
import './CardStack.scss';
import { Link, useNavigate } from 'react-router-dom';

function CardStack({
  data,
  setVideoModal,
  setToggledSubscribeModal,
  scrollObject,
  searchParams,
  type,
  iconPath,
  setAlert,
  isAsk,
  showMoreCard,
  mavenCount,
}) {
  const [stackData, setStackData] = useState(data);
  const navigate = useNavigate();
  const scrollRef = useRef();
  const leftScrollAmount = 321;
  const handleShowVideo = (item) => {
    const actions = [
      {
        id: 0,
        title: 'Share',
        image: '/icons/share.svg',
        className: 'secondary',
        callback: () => {
          navigator.clipboard.writeText(
            `${window.location.origin}/course/${item.slug}`
          );
          setAlert({
            type: 'success',
            message: 'Copied link',
            icon: '/icons/checkmark.svg',
          });
          setTimeout(() => {
            setAlert(null);
          }, 3000);
        },
      },
      {
        id: 1,
        title: 'Go To Course',
        callback: () => {
          navigate(`/course/${item.slug}${searchParams}`);
          setVideoModal(null);
        },
        className: 'primary',
      },
    ];

    const videoForModal = {
      video: item.trailer,
      title: item.cardTitle,
      subtitle: item.sessionCount
        ? `${item.sessionCount} Sessions`
        : item.sport,
      actions,
    };
    setVideoModal(videoForModal);
  };

  useEffect(() => {
    if (showMoreCard) {
      const showMoreData = {
        id: 'show-more-card',
        mavenData: {
          title: 'Explore More',
          //avatar: '/icons/microphone.svg',
        },
        restingVideo:
          'https://cdn.bfldr.com/B1ZZKPB4/as/5sm9ntbpxsr8jhxnvh7hgp/Video_BG_Interactive',
      };
      setStackData([...data, showMoreData]);
    }
    setStackData(data);
  }, [data]);

  useEffect(() => {
    const offsetLeft = scrollRef?.current?.scrollLeft;
    if (scrollObject?.direction === 'left') {
      scrollRef.current.scroll({
        left: offsetLeft - leftScrollAmount,
        top: 0,
        behavior: 'smooth',
      });
    }
    if (scrollObject?.direction === 'right') {
      scrollRef.current.scroll({
        left: offsetLeft + leftScrollAmount,
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [scrollObject]);

  const getStyle = (q) => {
    if (type === 'Conversation') return;
    if (type === 'Course')
      return { backgroundImage: `url(${q?.courseCardImage})` };
    if (!type) return { backgroundImage: `url(${q?.rosterCardImage})` };
    if (q?.type !== 'Interactive') {
      if (type === 'Roster') {
        return { backgroundImage: `url(${q?.rosterCardImage})` };
      }
      return { backgroundImage: `url(${q?.courseCardImage})` };
    }
  };

  const handleCardClick = (q) => {
    if (type === 'Conversation') {
      if (isAsk) {
        if (q.id === 'show-more-card') {
          navigate(`/conversations${searchParams ? searchParams : ''}`);
          return;
        }
        navigate(`/interactive/${q?.id}${searchParams ? searchParams : ''}`);
        return;
      }
      navigate(`/conversation/${q?.slug}${searchParams ? searchParams : ''}`);
      return;
    }
    if (type === 'Course') {
      navigate(`/course/${q?.slug}${searchParams ? searchParams : ''}`);
      return;
    }
    if (q.interactiveOnly) {
      navigate(`/conversation/${q?.slug}${searchParams ? searchParams : ''}`);
      return;
    }
    if (q?.type !== 'Interactive') {
      navigate(`/course/${q?.slug}${searchParams ? searchParams : ''}`);
      return;
    } else {
      navigate(`/conversation/${q?.slug}${searchParams ? searchParams : ''}`);
    }
  };

  return (
    <div className={`card-stack ${type ? type : ''}`}>
      {stackData && stackData?.length > 0 && (
        <div className="card-collection" ref={scrollRef}>
          {stackData.map((q, index) => (
            <div key={`${q?.slug}-${index}`} className="collection-container">
              <div
                className={`card ${isAsk ? 'is-ask' : ''}`}
                style={getStyle(q)}
                onClick={() => handleCardClick(q)}
              >
                {type === 'Conversation' && (
                  <>
                    <div className="overlay" />
                    <video
                      playsInline
                      autoPlay
                      muted
                      loop
                      preload="metadata"
                      width="100%"
                      height="auto"
                      poster={q?.restingImage}
                      src={q?.restingVideo}
                    />
                  </>
                )}
                {isAsk && (
                  <div className="ask">
                    <div>
                      {q?.id === 'show-more-card' ? (
                        <>
                          <img
                            className="microphone"
                            src="/icons/microphone.svg"
                            alt="Conversations"
                          />
                          <h4 className="title">Explore All Conversations</h4>
                        </>
                      ) : (
                        <>
                          <img
                            src={q?.mavenData?.avatar}
                            alt={q?.mavenData?.title}
                          />
                          <h4 className="title">
                            {q?.mavenData?.title?.substring(
                              0,
                              q?.mavenData?.title?.indexOf(' ')
                            )}
                          </h4>
                          <h4 className="title">
                            {q?.mavenData?.title?.substring(
                              q?.mavenData?.title?.indexOf(' ') + 1
                            )}
                          </h4>
                        </>
                      )}
                    </div>
                    {q?.id === 'show-more-card' ? (
                      <div>
                        <h5 className="faded">{mavenCount}</h5>
                        <p className="faded">Mavens to Ask</p>
                      </div>
                    ) : (
                      <div>
                        <h5 className="faded">{q?.questions?.length}</h5>
                        <p className="faded">
                          Question{q?.questions?.length > 1 && "'s"} Asked
                        </p>
                      </div>
                    )}

                    <Button
                      className="primary"
                      title="Card"
                      action="Keep Asking"
                      description={q?.mavenData?.title || 'Go To Conversations'}
                    >
                      Keep Asking
                    </Button>
                  </div>
                )}
                <div className="card-content">
                  <div>
                    {iconPath && (
                      <img className="icon" src={iconPath} alt="Talk to" />
                    )}
                    <p className="title">
                      {q?.cardTitle?.substring(0, q?.cardTitle?.indexOf(' '))}
                    </p>
                    <p className="title">
                      {q?.cardTitle?.substring(q?.cardTitle?.indexOf(' ') + 1)}
                    </p>
                  </div>

                  <div className="meta">
                    {type === 'Conversation' && (
                      <>
                        <div>
                          <img src="/icons/list-plus.svg" alt="Topics" />{' '}
                          <b>{q.topicCount}</b> Topics
                        </div>
                        <div>
                          <img src="/icons/microphone.svg" alt="Interactions" />{' '}
                          <b>{q.questionCount}</b> Interactions
                        </div>
                      </>
                    )}
                    {type === 'Course' && (
                      <>
                        <div>
                          <img src="/icons/Square-Play.svg" alt="Sessions" />{' '}
                          {q.sessionCount} Sessions
                        </div>
                      </>
                    )}
                    {(!type || type === 'Roster') && (
                      <>
                        {(q?.type === 'Course' ||
                          type === 'Course' ||
                          !q?.interactiveOnly) && (
                          <div>
                            <img src="/icons/Square-Play.svg" alt="Course" />{' '}
                            <span>Course</span>
                          </div>
                        )}
                        {(q?.type === 'Interactive' ||
                          type === 'Interactive' ||
                          type === 'Roster') && (
                          <div>
                            <img src="/icons/AI.svg" alt="Interactive" />{' '}
                            <span>Conversation</span>
                          </div>
                        )}
                        {!q?.type && (
                          <>
                            {q?.course && (
                              <div>
                                <img
                                  src="/icons/Square-Play.svg"
                                  alt="Course"
                                />{' '}
                                Course
                              </div>
                            )}
                            {q?.interactive && (
                              <div>
                                <img src="/icons/AI.svg" alt="Interactive" />{' '}
                                Conversation
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="actions">
                    {(q?.interactiveOnly ||
                      q?.type === 'Interactive' ||
                      type === 'Conversation') && (
                      <>
                        <Button
                          className="primary"
                          title="Card"
                          action="Ask Now"
                          description={q?.mavenData?.title}
                          onClick={(e) => {
                            navigate(`/interactive/${q?.slug}${searchParams}`);
                            e.stopPropagation();
                          }}
                        >
                          <img
                            src="/icons/microphone.svg"
                            style={{ filter: 'invert(1)' }}
                            alt="Watch Video"
                          />
                          Ask Now
                        </Button>
                        <Button
                          height="52px"
                          className="secondary button"
                          title="Card"
                          action="View Conversation"
                          description={q?.mavenData?.title}
                          onClick={(e) => {
                            navigate(`/conversation/${q?.slug}${searchParams}`);
                            e.stopPropagation();
                          }}
                        >
                          <img src="/icons/Info.svg" alt="Watch Video" />
                          View Conversation
                        </Button>
                      </>
                    )}
                    {!q?.interactiveOnly && q?.type !== 'Conversation' && (
                      <>
                        <Button
                          className="primary"
                          title="Card"
                          action="Watch Preview"
                          description={q?.mavenData?.title}
                          onClick={(e) => {
                            handleShowVideo(q);
                            e.stopPropagation();
                          }}
                        >
                          <img src="/icons/play.svg" alt="Watch Video" />
                          Watch Preview
                        </Button>
                        <Button
                          height="52px"
                          className="secondary button"
                          title="Card"
                          action="View Course"
                          description={q?.mavenData?.title}
                          onClick={(e) => {
                            navigate(`/course/${q?.slug}${searchParams}`);
                            e.stopPropagation();
                          }}
                        >
                          <img src="/icons/Info.svg" alt="Watch Video" />
                          View Course
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CardStack;
