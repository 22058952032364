import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function InteractiveContent({ data, view, searchParams, slug, scrollObject }) {
  const scrollRef = useRef();
  const leftScrollAmount = 520;

  useEffect(() => {
    const offsetLeft = scrollRef?.current?.scrollLeft;
    if (scrollObject?.direction === 'left') {
      scrollRef?.current?.scroll({
        left: offsetLeft - leftScrollAmount,
        top: 0,
        behavior: 'smooth',
      });
    }
    if (scrollObject?.direction === 'right') {
      scrollRef?.current?.scroll({
        left: offsetLeft + leftScrollAmount,
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [scrollObject]);

  return (
    <div className={`interactive-content ${view ? view : ''}`} ref={scrollRef}>
      {data?.map((d, index) => (
        <Link
          key={`ic-${index}`}
          to={`/interactive/${slug}${searchParams}?topic=${d?.title}`}
        >
          <div className="card">
            {/* <img className="avatar" src={avatar} alt={`Avatar of ${athlete}`} /> */}
            <div className="title">
              <h3>{d?.title}</h3>
            </div>

            {/* <div>
              {d?.title.split(' ').map((b) => (
                <h3>{b}</h3>
              ))}
            </div> */}

            <div className="meta">
              <h4>{d?.questionCount}</h4>
              <h5>
                Questions
                <br />
                Available
              </h5>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default InteractiveContent;
