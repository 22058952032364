import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import EmailHeroForm from './EmailHeroForm';
import TeamsnapForm from '../Teamsnap/TeamsnapForm';
import Hls from 'hls.js';

function Hero({
  backgroundVideo,
  backgroundVideoMobile,
  heading,
  body,
  search,
  setToggledLoginType,
  showEmailSignup,
  emailPlaceholder,
  emailButton,
  emailType,
  emailPartner,
  emailSport,
  emailSlug,
  mailchimpTag,
}) {
  const [inputEmail, setInputEmail] = useState('');
  const [isMuted, setIsMuted] = useState(true);
  const desktopHeroVideoRef = useRef();
  const mobileHeroVideoRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (inputEmail.length > 0) {
      setToggledLoginType('signup');
    } else {
      setToggledLoginType(null);
    }
  }, [inputEmail]);

  useEffect(() => {
    let videoUrl;
    let video;
    if (isMobile) {
      videoUrl =
        backgroundVideo ||
        'https://stream.mux.com/enTHC9wm6Pz5W00SCzR9FLGElOI3QRBvQ.m3u8';
      video = mobileHeroVideoRef?.current;
    } else {
      videoUrl =
        backgroundVideoMobile ||
        backgroundVideo ||
        'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8';
      video = desktopHeroVideoRef?.current;
    }
    function initializeHLS() {
      video.removeAttribute('src');
      if (Hls.isSupported()) {
        const hls = new Hls();
        try {
          hls.loadSource(videoUrl);
        } catch (err) {
          console.log('Error Loading Media!!! ' + err);
          return false;
        }
        hls.attachMedia(video);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {});
      } else {
        if (isMobile) {
          video.setAttribute('src', videoUrl);
        }
      }
    }
    initializeHLS();
  }, [backgroundVideo]);

  return (
    <div className="hero">
      <div className="landingBg">
        <div className="overlay overlay-left" />
        <BrowserView>
          <video
            ref={desktopHeroVideoRef}
            playsInline
            autoPlay
            muted={isMuted}
            loop
            preload="metadata"
            width="100%"
            height="auto"
            id="bgvid"
          />
        </BrowserView>
        <MobileView>
          <video
            ref={mobileHeroVideoRef}
            playsInline
            autoPlay
            muted={isMuted}
            loop
            preload="metadata"
            width="100%"
            height="auto"
            id="bgvid"
          />
        </MobileView>

        <img
          className="audio-control"
          onClick={() => setIsMuted(!isMuted)}
          src={
            isMuted
              ? '/icons/coueseLandingMute.svg'
              : '/icons/courseLandingUnmute.svg'
          }
          alt="Mute"
        />

        <div className="landingBgInfo">
          <h3>Limited Time Offer</h3>
          <h1>
            {heading
              ? heading
              : 'Get FREE access to Versus. No credit card needed!'}
          </h1>
          <p>
            {body ? (
              <span dangerouslySetInnerHTML={{ __html: body }} />
            ) : (
              <span>
                Create an account today and unlock your FREE 1-year membership
                and start training with the best.
              </span>
            )}
          </p>
          {showEmailSignup ? (
            <>
              <TeamsnapForm
                emailPlaceholder={emailPlaceholder}
                emailButton={emailButton}
                emailSlug={emailSlug}
                emailType={emailType}
                emailPartner={emailPartner}
                emailSport={emailSport}
                mailchimpTag={mailchimpTag}
              />
              <div className="additionalInfo">
                By sharing your email with us, you're agreeing to our
                <Link to={`/terms${search ? search : ''}`}>
                  Terms & Conditions
                </Link>{' '}
                and
                <Link to={`/privacy${search ? search : ''}`}>
                  Privacy Policy
                </Link>
                .
              </div>
            </>
          ) : (
            <>
              <div className="line">
                <img src="/icons/microphone.svg" alt="Ask Questions" />
                Ask thousands of questions.
              </div>
              <div className="line">
                <img src="/icons/play_video.svg" alt="Ask Questions" /> Watch
                hours of content.
              </div>
              <div className="call-to-action">
                {/* <Input
              placeholder="Enter your email"
              value={inputEmail}
            /> */}
                <Button
                  //onClick={() => setToggledLoginType('signup')}
                  onClick={() => navigate('/pass/?id=vsunlimited')}
                  className="primary"
                  title="Create Account"
                  page="Landing"
                  /* onClick={() =>
                navigate(`/login/${search}&email=${inputEmail}`, {
                  replace: true,
                })
              } */
                >
                  Get FREE Access
                </Button>
              </div>
              <div className="additionalInfo">
                By sharing your email with us, you're agreeing to our
                <Link to={`/terms${search ? search : ''}`}>
                  Terms & Conditions
                </Link>{' '}
                and
                <Link to={`/privacy${search ? search : ''}`}>
                  Privacy Policy
                </Link>
                .
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Hero;
