import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePartner } from 'src/context/PartnerContext';
import TextHero from '../LandingPage/TextHero';
import Hero from '../LandingPage/Hero';
import Courses from '../LandingPage/Courses';
import { landing } from '../LandingPage/data.js';
import ScrollToTop from 'src/ScrollToTop';
import SEO from '@components/Seo/Seo';
import { partnersData } from '../../data/partnersData';
import SimpleHero from '../LandingPage/SimpleHero';
import '../LandingPage/LandingPage.scss';
import './LandingPartner.scss';
import useHasMounted from '@hooks/useHasMounted';
import Footer from 'src/layout/Footer/Footer';

const { tabData } = landing;

const LandingPartner = ({
  setVideoModal,
  setToggledSubscribeModal,
  setToggledLoginType,
  searchParams,
  setAlert,
}) => {
  const partnerData = usePartner();
  const [searchParamsObj] = useSearchParams();
  const params = useParams();
  const [background, setBackground] = useState('');
  const [backgroundVideo, setBackgroundVideo] = useState('');
  const [backgroundVideoMobile, setBackgroundVideoMobile] = useState('');
  const [partner, setPartner] = useState('');
  const [partnerLogo, setPartnerLogo] = useState('');
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [body, setBody] = useState('');
  const [textHero, setTextHero] = useState(null);
  const [textHeroHeading, setTextHeroHeading] = useState('');
  const [simpleHeading, setSimpleHeading] = useState('');
  const [simpleSubHeading, setSimpleSubHeading] = useState('');
  const [simpleButton, setSimpleButton] = useState('');
  const [simpleButtonLink, setSimpleButtonLink] = useState('');
  const [sport, setSport] = useState('All');
  const [emailPlaceholder, setEmailPlaceholder] = useState('');
  const [emailButton, setEmailButton] = useState('');
  const [emailType, setEmailType] = useState('');
  const [emailPartner, setEmailPartner] = useState('');
  const [emailSport, setEmailSport] = useState('');
  const [emailSlug, setEmailSlug] = useState('');
  const [mailchimpTag, setMailchimpTag] = useState('');
  const [appStoreLink, setAppStoreLink] = useState('');
  const [playStoreLink, setPlayStoreLink] = useState('');

  useEffect(() => {
    const sParams = Object.fromEntries([...searchParamsObj]);
    const partner = partnersData.find((p) => p.slug === params.slug);

    setBackground(
      partnerData?.background || partner?.heroBackgroundImage || ''
    );
    setBackgroundVideo(
      partnerData?.heroVideoBackground || partner?.heroVideoBackground || ''
    );
    setBackgroundVideoMobile(partnerData?.heroVideoBackground || '');
    setPartner(partnerData?.partner || '');
    setPartnerLogo(partnerData?.partnerLogo || '');
    setHeading(partnerData?.heading || partner?.heroHeader || '');
    setSubheading(partnerData?.subheading || partner?.heroSubHeader || '');
    setBody(partnerData?.body || partner?.heroBody || '');
    setTextHeroHeading(partner?.experienceHeader);
    setSimpleHeading(partner?.CTAHeader);
    setSimpleSubHeading(partner?.CTABody);
    setSimpleButton(partner?.CTAButton);
    setSimpleButtonLink(partner?.CTALink);
    setSport(partner?.sport);
    setEmailPlaceholder(partner?.heroEmailFormField);
    setEmailButton(partner?.heroEmailButton);
    setEmailType(partner?.type);
    setEmailPartner(partner?.partner);
    setEmailSport(partner?.sport);
    setEmailSlug(partner?.slug);
    setMailchimpTag(partner?.['Mailchimp Tag']);
    setAppStoreLink(partner?.appStoreLink);
    setPlayStoreLink(partner?.playStoreLink);

    const tHero = [
      {
        id: 0,
        title: partner?.experienceP1Header,
        subtitle: partner?.experienceP1SubHeader,
        body: partner?.experienceP1Body,
      },
      {
        id: 1,
        title: partner?.experienceP2Header,
        subtitle: partner?.experienceP2SubHeader,
        body: partner?.experienceP2Body,
      },
      {
        id: 2,
        title: partner?.experienceP3Header,
        subtitle: partner?.experienceP3SubHeader,
        body: partner?.experienceP3Body,
      },
    ];
    setTextHero(tHero);
  }, [searchParamsObj, partnerData, params]);

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className="landing-page landing-partner fade-in">
      <SEO
        title="Sports Performance Training Online Video Course | VS"
        description="Learn a new skill, get tips on how to train with purpose, and practice developing your mindset. Versus library of expert advice articles will help you reach your full potential."
        name="Welcome to Versus Landing Page"
        type="article"
        ogTitle="Versus helps you be your best, both on and off the field."
      />
      <ScrollToTop />
      <Hero
        backgroundVideoMobile={backgroundVideoMobile || backgroundVideo}
        backgroundVideo={backgroundVideo}
        heading={heading}
        subheading={subheading}
        body={body}
        searchParams={searchParams}
        setToggledLoginType={setToggledLoginType}
        showEmailSignup={true}
        emailPlaceholder={emailPlaceholder}
        emailButton={emailButton}
        emailType={emailType}
        emailPartner={emailPartner}
        emailSport={emailSport}
        emailSlug={emailSlug}
        mailchimpTag={mailchimpTag}
      />
      <TextHero data={textHero} heading={textHeroHeading} hideAppstore={true} />

      <Courses
        selectedTab={sport}
        title="Roster"
        type="Roster"
        tabData={tabData}
        setVideoModal={setVideoModal}
        setToggledSubscribeModal={setToggledSubscribeModal}
        searchParams={searchParams}
        setAlert={setAlert}
      />
      <SimpleHero
        heading={simpleHeading}
        subheading={simpleSubHeading}
        button={simpleButton}
        buttonLink={simpleButtonLink}
        searchParams={searchParams}
        setToggledLoginType={setToggledLoginType}
      />
      <Footer
        searchParams={searchParams}
        appStoreLink={appStoreLink}
        playStoreLink={playStoreLink}
      />
    </div>
  );
};

export default LandingPartner;
