import React, { ReactNode } from "react";
import { ContainerStyles } from "./Container.style";

interface Props {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Container = (props: Props) => {
  const { children, className = "", style } = props;

  return (
    <ContainerStyles style={style} className={className}>
      {children}
    </ContainerStyles>
  );
};

export default Container;
