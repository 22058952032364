import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import Button from '@components/Button/Button';
import Input from '@components/Input/Input';
import { auth, db } from '../../config/firebase';
import { updateDoc, doc } from 'firebase/firestore';
import Loader from '@components/Loader/Loader';
import './Survey.scss';
import { goToPayment } from 'src/features/Stripe/stripeHelpers';
import useHasMounted from '@hooks/useHasMounted';

const questionData = [
  {
    id: 0,
    question: 'Are you...',
    hint: 'Select all that apply.',
    answered: false,
    answers: [
      {
        id: 0,
        title: 'An athlete',
        checked: false,
      },
      {
        id: 1,
        title: 'A coach',
        checked: false,
      },
      {
        id: 2,
        title: 'A parent',
        checked: false,
      },
      {
        id: 3,
        title: 'A fan',
        checked: false,
      },
    ],
  },
  {
    id: 1,
    question: 'What sports are you interested in?',
    hint: 'Select all that apply.',
    answered: false,
    answers: [
      {
        id: 0,
        title: 'Baseball',
        checked: false,
      },
      {
        id: 1,
        title: 'Softball',
        checked: false,
      },
      {
        id: 2,
        title: 'Soccer',
        checked: false,
      },
      {
        id: 3,
        title: 'Other',
        checked: false,
        data: [],
      },
    ],
  },
];

const otherOption = {
  id: 0,
  placeholder: 'Add Sport...',
  value: '',
};

function Survey() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedQuestionId, setSelectedQuestionId] = useState(0);
  const [showOtherQuestions, setShowOtherQuestions] = useState(false);
  const [questions, setQuestions] = useState(questionData);
  const [otherOptions, setOtherOptions] = useState([{ ...otherOption }]);
  const [lastPage, setLastPage] = useState(false);

  const toggleActive = (answer) => {
    const newQ = [...questions];
    newQ[selectedQuestionId].answers.forEach((a) => {
      if (a.id === answer.id) {
        a.checked = !a.checked;
      }
    });
    setQuestions(newQ);
  };

  const questionHasAnswers = () => {
    const hasAnswers =
      questions[selectedQuestionId].answers.filter((q) => q.checked === true)
        .length > 0;
    if (hasAnswers) {
      return true;
    } else {
      return false;
    }
  };

  const goToNextSection = () => {
    if (selectedQuestionId === 0) {
      setShowOtherQuestions(false);
      setSelectedQuestionId(selectedQuestionId + 1);
      return;
    }
    if (questions[1].answers.find((a) => a.title === 'Other').checked) {
      if (lastPage) submitSurveyAnswersAndNavigate();
      setShowOtherQuestions(true);
      setLastPage(true);
      return;
    }
    if (selectedQuestionId < questions.length - 1) {
      setSelectedQuestionId(selectedQuestionId + 1);
    }

    if (selectedQuestionId === questions.length - 1) {
      submitSurveyAnswersAndNavigate();
    }
  };

  const handleGoToPayment = () => {
    goToPayment(searchParams, auth.currentUser, navigate, setIsLoading);
  };

  const submitSurveyAnswersAndNavigate = () => {
    const questionsArray = questions.map((q) =>
      q.answers
        .filter((a) => a.checked)
        .map((aa) => ({
          ...aa,
          label:
            aa.title?.split(' ')?.[1] === undefined
              ? aa.title?.toLowerCase()
              : aa.title?.split(' ')?.[1]?.toLowerCase(),
        }))
    );

    const meArray = questionsArray[0];
    const sportsArray = questionsArray[1].filter((s) => s.title !== 'Other');

    const meObj = meArray.reduce(
      (acc, cur) => ({ ...acc, [cur.label]: { label: cur.title } }),
      {}
    );

    const otherArray = otherOptions
      .filter((p) => p.value.length > 0)
      .map((o) => ({
        title: o.value,
        label: 'other',
      }));

    const sportsObj = [...sportsArray, ...otherArray].reduce(
      (acc, cur) => ({ ...acc, [cur.label]: { label: cur.title } }),
      {}
    );

    const aboutObj = {
      about: {
        me: meObj,
        sports: sportsObj,
      },
      userId: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
    };

    //call firestore and save surveyObject into survey collection
    updateDoc(doc(db, 'profiles', auth.currentUser.uid), aboutObj);
    goToPayment(searchParams, auth.currentUser, navigate, setIsLoading);
  };

  const handleChangeValue = (newValue, optionId) => {
    const newOptions = [...otherOptions];
    newOptions.forEach((n) => {
      if (n.id === optionId) n.value = newValue;
    });
    setOtherOptions(newOptions);
  };

  const handleBack = () => {
    if (showOtherQuestions) {
      setShowOtherQuestions(false);
      setLastPage(false);
      return;
    }
    setSelectedQuestionId(selectedQuestionId - 1);
    setLastPage(false);
  };

  const removeOption = (id) => {
    const newOptions = [...otherOptions].filter((o) => o.id !== id);
    setOtherOptions(newOptions);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading) {
    return (
      <div className="survey">
        {questions.map((q) => (
          <div
            className={`section ${q.id === selectedQuestionId ? '' : 'hidden'}`}
          >
            <div className="progress">
              Two quick questions before you get started. (
              {selectedQuestionId + 1}/{questions.length})
            </div>
            <div className="question">
              {!showOtherQuestions && (
                <>
                  <h3>{q.question}</h3>
                  <div className="hint">{q.hint}</div>
                </>
              )}
              {showOtherQuestions && (
                <>
                  <h3>You selected "Other". Please tell us more.</h3>
                  <div className="hint">
                    We want to know which sports you’d like ot see on Versus.
                  </div>
                </>
              )}
            </div>
            {!showOtherQuestions && (
              <div className="selections">
                {q.answers.map((a) => (
                  <div className="selection" onClick={() => toggleActive(a)}>
                    <img
                      src={
                        a.checked
                          ? '/icons/checked.svg'
                          : '/icons/unchecked.svg'
                      }
                      alt={a.title}
                      className={a.checked ? 'checked' : 'unchecked'}
                    />{' '}
                    {a.title}
                  </div>
                ))}
              </div>
            )}
            {showOtherQuestions && (
              <div className="selections other-selections">
                {otherOptions.map((a) => (
                  <div className="selection">
                    <Input
                      placeholder={a.placeholder}
                      value={a.value.length > 0 ? a.value : null}
                      onChange={(e) => handleChangeValue(e.target.value, a.id)}
                    />
                    {otherOptions.length > 1 && (
                      <img
                        className="close"
                        src="/icons/close.svg"
                        alt="close"
                        onClick={() => removeOption(a.id)}
                      />
                    )}
                  </div>
                ))}
                {otherOptions.length < 4 && (
                  <div
                    className="add-container"
                    onClick={() =>
                      setOtherOptions([
                        ...otherOptions,
                        { ...otherOption, id: otherOptions.length },
                      ])
                    }
                  >
                    <img src="/icons/add.svg" className="add" alt="add" />
                    Add Another
                  </div>
                )}
              </div>
            )}

            <div className="button-container">
              {selectedQuestionId > 0 && (
                <Button
                  title="Survey"
                  action="Back"
                  disabled={false}
                  onClick={() => handleBack()}
                  className="survey-button back"
                >
                  <img src="/icons/arrow-left.svg" />
                  Back
                </Button>
              )}
              <Button
                title="Survey"
                action="Next Section"
                disabled={!questionHasAnswers(q.id)}
                onClick={goToNextSection}
                className={`survey-button ${
                  questionHasAnswers(q.id) ? 'active' : ''
                }`}
              >
                <img src="/icons/arrow-right.svg" />
                Next
              </Button>
            </div>
            <div className="options" onClick={() => handleGoToPayment()}>
              Skip
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Survey;
