import { useEffect, useState, useRef } from 'react';
import Button from '@components/Button/Button';
import {
  herosBadges,
  herosBadgeInteractiveOnly,
} from '../../helpers/dataHelpers.js';
import {
  BrowserView,
  MobileView,
  isMobile,
  isDesktop,
} from 'react-device-detect';
import { Link } from 'react-router-dom';

function LandingHomeHero({
  athlete,
  setToggledSubscribeModal,
  shortBio,
  poster,
  mobilePoster,
  mobileVideo,
  video,
  params,
  subscription,
  interactiveOnly,
  slug,
  callback,
}) {
  const heroVideoRef = useRef();
  const [isMuted, setIsMuted] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [selectedMaven, setSelectedMaven] = useState();

  const onVideoTimeUpdate = () => {
    setCurrentTime(heroVideoRef?.current?.currentTime);
  };

  useEffect(() => {
    const t = setTimeout(() => {
      if (interactiveOnly && isDesktop) callback();
    }, 20000);

    if (!interactiveOnly) {
      setIsPaused(true);
      setIsMuted(true);
      if (isDesktop) {
        setTimeout(() => {
          setIsPaused(false);
          heroVideoRef?.current?.play();
        }, 3000);
      }

      if (heroVideoRef)
        heroVideoRef?.current?.addEventListener(
          'timeupdate',
          onVideoTimeUpdate
        );
    }

    return () => {
      clearTimeout(t);
      const v = heroVideoRef?.current;
      if (v) {
        v.load();
      }

      setCurrentTime(0);
      heroVideoRef?.current?.removeEventListener(
        'timeupdate',
        onVideoTimeUpdate
      );
    };
  }, [params?.slug]);

  const handlePlayPause = () => {
    const paused = isPaused;
    if (paused) {
      setIsMuted(false);
      heroVideoRef?.current?.play();
    } else {
      heroVideoRef?.current?.pause();
    }
    setIsPaused(!paused);
  };

  const isFlushed = () => {
    if (callback) return false;
    if (currentTime === 0) return true;
    return false;
  };

  return (
    <div className="hero">
      <div className="landingBg">
        <BrowserView>
          <div className="overlay" />
          <div
            className="overlay overlay-left"
            onClick={() => handlePlayPause()}
          />
          <video
            className="fade-in"
            ref={heroVideoRef}
            playsInline
            onEnded={() => {
              heroVideoRef?.current?.load();
              setCurrentTime(0);
              setIsPaused(true);
              if (callback) callback();
            }}
            muted={isMuted}
            preload="auto"
            width="100%"
            height="auto"
            poster={poster}
            src={video}
          />
        </BrowserView>
        <MobileView>
          <div
            className="overlay overlay-intense"
            onClick={() => handlePlayPause()}
          />
          <video
            className="fade-in"
            ref={heroVideoRef}
            playsInline
            onEnded={() => {
              heroVideoRef?.current?.load();
              setCurrentTime(0);
              setIsPaused(true);
              if (callback) callback();
            }}
            muted={false}
            preload="metadata"
            width="100%"
            height="auto"
            poster={mobilePoster}
            src={mobileVideo}
          ></video>
        </MobileView>

        <div className="heroBgInfo">
          <h1>
            {athlete}
            <span>
              <img
                onClick={() => setIsMuted(!isMuted)}
                src={
                  isMuted
                    ? '/icons/coueseLandingMute.svg'
                    : '/icons/courseLandingUnmute.svg'
                }
                alt="Mute"
              />
              <img
                onClick={() => handlePlayPause(isPaused)}
                src={
                  isPaused
                    ? '/icons/courseLandingPlay.svg'
                    : '/icons/courseLandingPause.svg'
                }
                alt="Mute"
              />
            </span>
          </h1>
          <div className={`secondary-filters ${isFlushed() ? 'hide' : ''}`}>
            {interactiveOnly
              ? herosBadgeInteractiveOnly.map((s) => (
                  <div key={s.id}>
                    <img src={s.icon} alt={s.title} /> {s.title}
                  </div>
                ))
              : herosBadges.map((s) => (
                  <div key={s.id}>
                    <img src={s.icon} alt={s.title} /> {s.title}
                  </div>
                ))}
          </div>
          <p className={isFlushed() ? 'hide' : ''}>{shortBio}</p>
          <div className="actions">
            {isMobile && (
              <>
                {interactiveOnly ? (
                  <Link className="button primary" to={`/conversation/${slug}`}>
                    Learn More
                  </Link>
                ) : (
                  <div className="button-group">
                    <Button
                      className="primary mobile"
                      onClick={() => handlePlayPause()}
                    >
                      {heroVideoRef?.current?.paused || isPaused ? (
                        <>
                          <img src="/icons/play.svg" alt="Play" /> Trailer
                        </>
                      ) : (
                        <img src="/icons/pause.svg" alt="Pause" />
                      )}
                    </Button>
                    <Link className="button secondary" to={`/course/${slug}`}>
                      Learn More
                    </Link>
                  </div>
                )}
              </>
            )}
            {isDesktop && (
              <>
                {interactiveOnly ? (
                  <Link className="button primary" to={`/conversation/${slug}`}>
                    Learn More
                  </Link>
                ) : (
                  <Link className="button primary" to={`/course/${slug}`}>
                    Learn More
                  </Link>
                )}
              </>
            )}
            {/* {subscription.planName === 'Premium' ? (
              <>
                {interactiveOnly ? (
                  <Link className="button primary" to={`/conversation/${slug}`}>
                    Learn More
                  </Link>
                ) : (
                  <Link className="button primary" to={`/course/${slug}`}>
                    Learn More
                  </Link>
                )}
              </>
            ) : (
              <>
                {interactiveOnly ? (
                  <Link className="button primary" to={`/conversation/${slug}`}>
                    Learn More
                  </Link>
                ) : (
                  <Link className="button primary" to={`/course/${slug}`}>
                    Learn More
                  </Link>
                )}
                <Button
                  className="primary"
                  onClick={() => setToggledSubscribeModal(true)}
                >
                  <img src="/icons/play.svg" alt="Watch Video" />
                  Start Course
                </Button>
                <Link
                  className="button primary"
                  to={`/course/${slug}/video/${slug}-1`}
                >
                  <img src="/icons/play.svg" alt="Watch Video" />
                  Start Course
                </Link>
              </>
            )} */}

            {!callback && (
              <Button height="52px" className="secondary">
                <img src="/icons/Info.svg" alt="Watch Video" />
                About {athlete?.split(' ')[0]}
              </Button>
            )}
          </div>
        </div>
      </div>
      {!interactiveOnly && (
        <div
          className={`video-controls ${
            currentTime === 0 && isPaused ? '' : 'flushed'
          }`}
        >
          {currentTime === 0 && !isMobile && (
            <Button
              className="outlined"
              onClick={() => {
                heroVideoRef?.current?.play();
                setIsPaused(false);
                setIsMuted(false);
              }}
            >
              Play Intro
            </Button>
          )}
          {currentTime !== 0 && (
            <BrowserView>
              <span>
                <img
                  onClick={() => setIsMuted(!isMuted)}
                  src={
                    isMuted
                      ? '/icons/coueseLandingMute.svg'
                      : '/icons/courseLandingUnmute.svg'
                  }
                  alt="Mute"
                />
                <img
                  onClick={() => handlePlayPause()}
                  src={
                    isPaused
                      ? '/icons/courseLandingPlay.svg'
                      : '/icons/courseLandingPause.svg'
                  }
                  alt="Mute"
                />
              </span>
            </BrowserView>
          )}
        </div>
      )}
    </div>
  );
}

export default LandingHomeHero;
