import { useState } from 'react';
import Container from '../../components/Container/Container';
import Account from './Account/Account';
import Subscription from './Subscription/Subscription';
import PaymentMethod from './PaymentMethod/PaymentMethod';
// import ChangeEmailModal from "../../components/Modals/ChangeEmailModal/ChangeEmailModal";
import ChangePasswordModal from '../../components/Modals/ChangePasswordModal/ChangePasswordModal';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import ChangeNameModal from '../../components/Modals/ChangeNameModal/ChangeNameModal';
import './SettingsPage.scss';
import useHasMounted from '@hooks/useHasMounted';

const SettingsPage = ({ setContactModal, currentUser, setIsLoading }) => {
  const [isChangeNameModalOpened, setIsChangeNameModalOpened] = useState(false);
  // const [isChangeEmailModalOpened, setIsChangeEmailModalOpened] =
  //   useState(false);

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState({
    msg: null,
    open: false,
  });
  const [openModalPassword, setOpenModalPassWord] = useState(false);

  const openChangeNameModal = () => {
    setIsChangeNameModalOpened(true);
  };

  const closeChangeNameModal = () => {
    setIsChangeNameModalOpened(false);
  };

  // const openChangeEmailModal = () => {
  //   setIsChangeEmailModalOpened(true);
  // };

  // const closeChangeEmailModal = () => {
  //   setIsChangeEmailModalOpened(false);
  // };

  const openChangePassWordModal = () => {
    setOpenModalPassWord(!openModalPassword);
  };

  const openConfirmModal = (message) => {
    setIsConfirmModalOpened({
      ...isConfirmModalOpened,
      msg: message,
      open: true,
    });
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpened({
      ...isConfirmModalOpened,
      open: false,
    });
  };

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className='settings'>
      {isChangeNameModalOpened && (
        <div className='modals'>
          <ChangeNameModal
            closeChangeNameModal={closeChangeNameModal}
            openConfirmModal={openConfirmModal}
          />
        </div>
      )}
      {/* {isChangeEmailModalOpened && (
        <ChangeEmailModal
          closeChangeEmailModal={closeChangeEmailModal}
          openConfirmModal={openConfirmModal}
        />
      )} */}
      {openModalPassword && (
        <div className='modals'>
          <ChangePasswordModal
            closeChangePasswordModal={openChangePassWordModal}
            openConfirmModal={openConfirmModal}
          />
        </div>
      )}
      {isConfirmModalOpened.open && (
        <div className='modals'>
          <ConfirmationModal
            modalText={isConfirmModalOpened?.msg || ''}
            onClose={closeConfirmModal}
          />
        </div>
      )}
      <Container className='settingsContainer'>
        <h2 className='settingsTitle'>Account Settings</h2>
        <Account
          openChangeNameModal={openChangeNameModal}
          // openChangeEmailModal={openChangeEmailModal}
          openChangePassWordModal={openChangePassWordModal}
        />
        <Subscription
          setContactModal={setContactModal}
          setIsLoading={setIsLoading}
          currentUser={currentUser}
        />
        <PaymentMethod />
      </Container>
    </div>
  );
};

export default SettingsPage;
