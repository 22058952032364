import styled from 'styled-components';

export const AccountStyles = styled.div`
  .settingsRow {
    padding: 90px 0 0 0;
    > h4 {
      margin-bottom: 36px;
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
    }
    .accountRow {
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:first-of-type) {
        margin: 18px 0 0 0;
      }
      .infoBox {
        padding: 0 16px;
        width: calc(100% - 84px);
        height: 100%;
        background: #222;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        span {
          font-size: 12px;
          font-weight: 500;
          color: #fff;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #fff;
        }
      }
      .editBox {
        width: 64px;
        height: 100%;
        background: #222;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        cursor: pointer;
        &.editEmailBox {
          cursor: not-allowed;
        }
        svg {
          width: 26px;
          fill: #fff;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .settingsRow {
      padding: 50px 0 0 0;
      .accountRow {
        .infoBox {
          width: calc(100% - 76px);
        }
      }
    }
  }
`;
