import FAQsection from './FAQsection';

function FAQ({ data, title }) {
  return (
    <div className="faq">
      <h3>{title}</h3>
      {data.map((d, index) => (
        <FAQsection key={`faq-${index}`} data={d} />
      ))}
    </div>
  );
}

export default FAQ;
