import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './Button.scss';

//ReactGA?.initialize('G-FJNT7Y8XYQ');

const Button = (props) => {
  const {
    page,
    title,
    children,
    route,
    external,
    className = '',
    onClick,
    disabled,
    action,
    description,
    ...rest
  } = props;
  let others = {};
  if (disabled) {
    others.disabled = true;
  }

  const _onClick = external
    ? () => {
        window.open(external, '_blank');
      }
    : onClick;

  const segmentClick = () => {
    /* ReactGA?.event({
      category: 'Button/Link Clicked',
      action: action ? `${action} : ${description || title}` : '',
      label: title ? title : '', // optional
      transport: 'xhr', // optional, beacon/xhr/image
    }); */

    window?.analytics?.track('Button/Link Clicked', {
      title,
      page: page ? page : '',
      action: action ? action : '',
      description: description ? description : '',
    });
    _onClick();
  };

  if (route) {
    return (
      <Link className={`cta primary ${className}`} to={route}>
        {children}
      </Link>
    );
  }

  return (
    <div
      onClick={segmentClick}
      className={`button ${className}`}
      {...others}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Button;
