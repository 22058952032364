import { useEffect, useState } from 'react';
import HeroSimple from '@components/HeroSimple/HeroSimple';
import BrowseContent from 'src/pages/BrowseContent/BrowseContent';
import ContactPage from 'src/pages/ContactPage/ContactPage';
import PricingPageNew from 'src/pages/PricingPageNew/PricingPageNew';
import SportPage from 'src/pages/Sport/Sport';
import { mavenData } from 'src/data/mavenData';
import './SimpleHeaderLayout.scss';
import { useParams } from 'react-router-dom';

const transformedData = mavenData.map((d, index) => ({
  ...d,
  id: index,
  cardTitle: d.title,
  link: '',
  image: d.rosterCardImage,
  course: d.course === 'TRUE' ? true : false,
  interactive: d.interactive === 'TRUE' ? true : false,
  interactiveOnly: d.course !== 'TRUE',
}));

const allData = {
  id: 3,
  title: 'Roster',
  backgroundImage:
    'https://cdn.bfldr.com/B1ZZKPB4/as/vtzt35pcmj44qnt67p3ss63/VS_BG_Main?auto=webp&format=png',
};

function SimpleHeaderLayout({
  title,
  subtitle,
  type,
  searchParams,
  setContactModal,
  currentUser,
  setToggledLoginType,
  setVideoModal,
  setToggledSubscribeModal,
  setAlert,
}) {
  let { slug } = useParams();
  const tDataBySlug = transformedData.filter(
    (t) => t.sport?.toLowerCase() === slug
  );
  const [sport, setSport] = useState(null);

  useEffect(() => {
    if (slug) {
      const sportData = sportPageData.find((s) => s.slug === slug);
      setSport(sportData);
    } else {
      setSport(allData);
    }
  }, [slug]);

  const sportPageData = [
    {
      id: 0,
      slug: 'baseball',
      title: 'Baseball',
      backgroundImage:
        'https://cdn.bfldr.com/B1ZZKPB4/as/vtzt35pcmj44qnt67p3ss63/VS_BG_Main?auto=webp&format=png',
      subtitle:
        'Learn from current MLB stars and Hall of Fame legends to gain an edge on the diamond.',
      meta: [
        {
          title: 'Experts',
          value: tDataBySlug.length,
        },
        {
          title: 'Sessions',
          value: tDataBySlug
            .map((m) => +m.sessionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
        {
          title: 'Interactions',
          value: tDataBySlug
            .map((m) => +m.questionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
      ],
    },
    {
      id: 1,
      slug: 'softball',
      title: 'Softball',
      backgroundImage:
        'https://cdn.bfldr.com/B1ZZKPB4/as/vtzt35pcmj44qnt67p3ss63/VS_BG_Main?auto=webp&format=png',
      subtitle:
        'Learn from current Softball stars and Hall of Fame legends to gain an edge on the diamond.',
      meta: [
        {
          title: 'Experts',
          value: tDataBySlug.length,
        },
        {
          title: 'Sessions',
          value: tDataBySlug
            .map((m) => +m.sessionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
        {
          title: 'Interactions',
          value: tDataBySlug
            .map((m) => +m.questionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
      ],
    },
    {
      id: 2,
      slug: 'soccer',
      title: 'Soccer',
      backgroundImage:
        'https://cdn.bfldr.com/B1ZZKPB4/as/vtzt35pcmj44qnt67p3ss63/VS_BG_Main?auto=webp&format=png',
      subtitle:
        'Learn from current Soccer stars and Hall of Fame legends to gain an edge on the field.',
      meta: [
        {
          title: 'Experts',
          value: tDataBySlug.length,
        },
        {
          title: 'Sessions',
          value: tDataBySlug
            .map((m) => +m.sessionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
        {
          title: 'Interactions',
          value: tDataBySlug
            .map((m) => +m.questionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
      ],
    },
  ];

  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <div className="simple-header-layout fade-in">
      <HeroSimple
        title={title || sport?.title}
        subtitle={subtitle}
        meta={sport?.meta}
        type={type}
      />
      {type === 'ContactPage' && <ContactPage searchParams={searchParams} />}
      {type === 'Pricing' && (
        <PricingPageNew
          searchParams={searchParams}
          setContactModal={setContactModal}
          currentUser={currentUser}
          setToggledLoginType={setToggledLoginType}
        />
      )}
      {type === 'Roster' && (
        <SportPage
          searchParams={searchParams}
          setVideoModal={setVideoModal}
          setToggledSubscribeModal={setToggledSubscribeModal}
          setAlert={setAlert}
          transformedData={transformedData}
          sport={sport}
        />
      )}
      {type === 'Courses' && (
        <SportPage
          searchParams={searchParams}
          setVideoModal={setVideoModal}
          setToggledSubscribeModal={setToggledSubscribeModal}
          setAlert={setAlert}
          title="Courses"
          type="Course"
          transformedData={transformedData}
          sport={sport}
        />
      )}
      {type === 'Conversation' && (
        <SportPage
          searchParams={searchParams}
          setVideoModal={setVideoModal}
          setToggledSubscribeModal={setToggledSubscribeModal}
          setAlert={setAlert}
          title="Conversations"
          type="Conversation"
          transformedData={transformedData}
          sport={sport}
        />
      )}

      {type === 'Browse' && (
        <BrowseContent
          type={type}
          searchParams={searchParams}
          setVideoModal={setVideoModal}
          setToggledSubscribeModal={setToggledSubscribeModal}
          setAlert={setAlert}
        />
      )}
      {type === 'Sport' && (
        <SportPage
          searchParams={searchParams}
          setVideoModal={setVideoModal}
          setToggledSubscribeModal={setToggledSubscribeModal}
          setAlert={setAlert}
          transformedData={transformedData}
          sport={sport}
        />
      )}
    </div>
  );
}

export default SimpleHeaderLayout;
