import './Alert.scss';

function Alert({ type, title, message, icon }) {
  return (
    <div className={`alert ${type ? type : ''}`}>
      {icon && <img src={icon} alt={type} />}
      <h5>{title}</h5>
      <p>{message}</p>
    </div>
  );
}

export default Alert;
