import Button from '@components/Button/Button';
import { getUserVideos } from '@hooks/useWatchingVideo';
import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSubscription } from 'src/context/SubscriptionContext';
import { timeFormatter } from '../../helpers/helpers';
import { current } from '@reduxjs/toolkit';

function CourseContent({
  data,
  setToggledSubscribeModal,
  setToggledDownloadModal,
  header,
  currentUser,
}) {
  const subscription = useSubscription();
  const navigate = useNavigate();
  const scrollRef = useRef();

  const leftScrollAmount = 440;

  const [scrollObject, setScrollObject] = useState(null);
  const [videosWatched, setVideosWatched] = useState(null);
  const [videosWatchedIds, setVideosWatchedIds] = useState([]);

  useEffect(() => {
    getUserVideos(setVideosWatched, setVideosWatchedIds);
  }, [data]);

  useEffect(() => {
    const offsetLeft = scrollRef?.current?.scrollLeft;
    if (scrollObject?.direction === 'left') {
      scrollRef.current.scroll({
        left: offsetLeft - leftScrollAmount,
        top: 0,
        behavior: 'smooth',
      });
    }
    if (scrollObject?.direction === 'right') {
      scrollRef.current.scroll({
        left: offsetLeft + leftScrollAmount,
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [scrollObject]);

  const getAction = (d) => {
    //if (!currentUser) setToggledSubscribeModal(true);
    if (!currentUser) setToggledSubscribeModal(true);
    //if (currentUser) setToggledDownloadModal(true);\
    if (currentUser) navigate(`/course/${d.mavenSlug}/video/${d.slug}`);
    /*  if (!subscription.planName) {
      setToggledSubscribeModal(true);
    } else {
      if (subscription.planName !== 'Premium') {
        setToggledSubscribeModal(true);
      } else {
        navigate(`/course/${d.mavenSlug}/video/${d.slug}`);
      } 
    }*/
  };

  const getWatchedStyle = (d) => {
    if (videosWatchedIds.includes(d.videoId)) {
      const video = videosWatched.find((v) => v.video === d.videoId);
      if (video?.currentPosition > 0) {
        return {
          width: `${
            (video.currentPosition / 1000 / d.durationInSeconds) * 100
          }%`,
        };
      } else {
        return { width: '0%' };
      }
    } else {
      return { width: '0%' };
    }
  };

  const isWatched = (d) => {
    if (videosWatchedIds.includes(d.videoId)) {
      const video = videosWatched.find((v) => v.video === d.videoId);
      if (video?.currentPosition / 1000 + 10 > d.durationInSeconds) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {header && (
        <div className="heading">
          <h4>{header}</h4>
          <div className="hArrows">
            <img
              onClick={() =>
                setScrollObject({ amount: Math.random(), direction: 'left' })
              }
              src="/icons/arrow-left.svg"
              alt="Scroll Left"
            />
            <img
              onClick={() =>
                setScrollObject({ amount: Math.random(), direction: 'right' })
              }
              src="/icons/arrow-right.svg"
              alt="Scroll Right"
            />
          </div>
        </div>
      )}
      <div className="session-content" ref={scrollRef}>
        {data.map((d) => (
          <div
            key={`${d.sessionNumber}-${d.slug ? d.slug : 'session'}`}
            className="card"
            /* onClick={() => {
              subscription.planName === 'Free'
                ? setToggledSubscribeModal(true)
                : setToggledDownloadModal(true);
            }} */
            onClick={() => getAction(d)}
            style={{
              backgroundImage: `url(${
                d.image || d.videoPoster || '/Placeholder.jpg'
              })`,
            }}
          >
            <div className="overlay">
              <div className="watched-indicator" style={getWatchedStyle(d)} />
            </div>
            <img
              className="play-button"
              src="/icons/playGlass.svg"
              alt="play"
            />
            <div className="header">
              {/* <img className="avatar" src={avatar} alt={`Avatar of ${athlete}`} /> */}
              <div>
                <div className="meta">
                  Session {d.sessionNumber} &bull;{' '}
                  {timeFormatter(d.durationInSeconds)}
                </div>
              </div>
            </div>
            <div className="body">
              <h3>
                {d.title}{' '}
                {isWatched(d) && <span className="badge">Watched</span>}
              </h3>
              <h6>{d.maven}</h6>
            </div>

            <div className="content">
              <h3>
                {d.title}{' '}
                {isWatched(d) && <span className="badge">Watched</span>}
              </h3>
              <p>{d.shortDescription}</p>
              {subscription.planName === 'Free' ? (
                <>
                  {/* <Button
                    className="secondary"
                    onClick={() => setToggledSubscribeModal(true)}
                  >
                    <img src="/icons/play.svg" alt="Watch Video" />
                    Watch Now
                  </Button> */}
                  <Link
                    className="button secondary"
                    to={`/course/${d.mavenSlug}/video/${d.slug}`}
                  >
                    Continue Watching
                  </Link>
                </>
              ) : (
                <>
                  {/* <Button
                    className="secondary"
                    onClick={() => setToggledDownloadModal(true)}
                  >
                    <img src="/icons/play.svg" alt="Watch Video" />
                    Continue Watching
                  </Button> */}
                  <Link
                    className="button secondary"
                    to={`/course/${d.mavenSlug}/video/${d.slug}`}
                  >
                    Continue Watching
                  </Link>
                </>
              )}
            </div>
          </div>
        ))}
        <div
          className="card browse-courses"
          onClick={() => navigate('/courses')}
        >
          <video
            playsInline
            autoPlay
            muted
            loop
            preload="metadata"
            width="100%"
            height="auto"
            src="https://cdn.bfldr.com/B1ZZKPB4/as/hvhh7hh4s35rnw8vmhwbtccw/Website_Intro"
          />
          <h3>Explore All Courses</h3>
        </div>
      </div>
    </>
  );
}

export default CourseContent;
