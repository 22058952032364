import Button from '@components/Button/Button';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Footer.scss';

const footerSocial = [
  {
    title: 'Twitter',
    icon: '/icons/icon-twitter.svg',
    link: 'https://twitter.com/vsapp_',
  },
  {
    title: 'Instagram',
    icon: '/icons/icon-instagram.svg',
    link: 'https://www.instagram.com/vsapp_/',
  },
  {
    title: 'Facebook',
    icon: '/icons/icon-facebook.svg',
    link: 'https://www.facebook.com/Versus-App-100403105917865',
  },
  {
    title: 'YouTube',
    icon: '/icons/icon-youtube.svg',
    link: 'https://www.youtube.com/channel/UCdPD6HR7rlGIDDiz7nwzOdQ/featured',
  },
  {
    title: 'Linkedin',
    icon: '/icons/icon-linkedin.svg',
    link: 'https://www.linkedin.com/company/vs-app/',
  },
];

const partnerFooterData = [
  {
    title: 'Contact Us',
    link: '/contact',
  },
  {
    title: 'Privacy Policy',
    link: '/privacy',
  },
  {
    title: 'Terms',
    link: '/terms',
  },
];

function Footer({ searchParams, appStoreLink, playStoreLink }) {
  const { pathname } = useLocation();
  const [footer, setFooter] = useState([]);
  const [appStore, setAppStore] = useState([]);

  useEffect(() => {
    const aStore = [
      {
        id: 0,
        image: '/landing/appstore.png',
        title: 'Apple AppStore',
        link: appStoreLink
          ? appStoreLink
          : 'https://apps.apple.com/us/app/versus-interactive/id1612364880',
        target: '_blank',
      },
      {
        id: 1,
        image: '/landing/googleplay.png',
        title: 'Google Play',
        link: playStoreLink
          ? playStoreLink
          : 'https://play.google.com/store/apps/details?id=com.mavenarena.versus',
        target: '_blank',
      },
    ];

    setAppStore(aStore);

    const footerData = [
      {
        title: 'Explore',
        data: [
          {
            id: 0,
            title: 'Roster',
            link: `/roster${searchParams}`,
          },
          {
            id: 1,
            title: 'Browse Content',
            link: `/browse-content${searchParams}`,
          },
          {
            id: 2,
            title: 'Expert Advice',
            link: `/learn${searchParams}`,
          },
        ],
      },
      {
        title: 'Resources',
        data: [
          {
            id: 0,
            title: 'Careers',
            link: 'https://versus.rippling-ats.com',
            target: '_blank',
          },
          {
            id: 1,
            title: 'Contact Us',
            link: `/contact${searchParams}`,
          },
          /* {
            id: 2,
            title: "FAQ's",
            link: `/faqs${searchParams}`,
          }, */
        ],
      },
      /* {
        title: 'Company',
        data: [
          {
            id: 0,
            title: 'Contact Us',
            link: `/contact${search}`,
          },
          {
            id: 1,
            title: 'Give Feedback',
            link: `/feedback${search}`,
          },
          {
            id: 2,
            title: 'Careers',
            link: `/careers${search}`,
          },
          {
            id: 3,
            title: 'About',
            link: `/about${search}`,
          },
        ],
      }, */
      {
        title: 'Legal',
        data: [
          {
            id: 0,
            title: 'Privacy Policy',
            link: `/privacy${searchParams}`,
          },
          {
            id: 1,
            title: 'Terms & Conditions',
            link: `/terms${searchParams}`,
          },
        ],
      },
      {
        title: 'Download our app for the ultimate experience',
        data: [
          {
            id: 0,
            image: '/landing/appstore.png',
            title: 'Apple AppStore',
            link: 'https://apps.apple.com/us/app/versus-interactive/id1612364880',
            target: '_blank',
          },
          {
            id: 1,
            image: '/landing/googleplay.png',
            title: 'Google Play',
            link: 'https://play.google.com/store/apps/details?id=com.mavenarena.versus',
            target: '_blank',
          },
        ],
      },
    ];

    if (pathname.includes('/partner/')) {
      setFooter(partnerFooterData);
    } else {
      setFooter(footerData);
    }
  }, [appStoreLink, pathname]);

  return (
    <>
      <div
        className={`app-footer ${
          pathname.includes('/partner/') ? 'partner-footer' : ''
        }`}
        style={{ backgroundImage: 'url(/pages/Footer_Bg_Logo.svg)' }}
      >
        {pathname.includes('/partner/') && (
          <div className="app-store">
            {appStore.map((ff) => (
              <a href={ff.link} target="_blank" rel="noopener noreferrer">
                {ff.image ? (
                  <>
                    <img src={ff.image} alt={ff.title} />
                  </>
                ) : (
                  <span>{ff.title}</span>
                )}
              </a>
            ))}
          </div>
        )}
        <div className="container">
          {footer?.map((f) => (
            <div key={f.title} className="column">
              <h6>
                <span>/</span> {f.title}
              </h6>
              <div className="items">
                {f.data?.map((ff, index) => (
                  <div className="item" key={`${f.title}-${index}`}>
                    {ff.target ? (
                      <a
                        href={ff.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {ff.image ? (
                          <img src={ff.image} alt={ff.title} />
                        ) : (
                          <span>{ff.title}</span>
                        )}
                      </a>
                    ) : ff.image ? (
                      <Button
                        external={ff.link}
                        title={ff.title}
                        action="Download App"
                        description="Footer"
                      >
                        <img src={ff.image} alt={ff.title} />
                      </Button>
                    ) : (
                      <Link to={ff.link}>
                        <span>{ff.title}</span>
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-footer">
        <div className="company">
          <img src="/logo.svg" className="logo" alt="logo" /> © 2023
        </div>
        <div className="social">
          {footerSocial.map((f) => (
            <Button
              key={f.title}
              external={f.link}
              title={f.title}
              action="Social"
            >
              <img src={f.icon} alt={f.title} />
            </Button>
          ))}
        </div>
      </div>
    </>
  );
}

export default Footer;
