import { loadStripe, Stripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "./app";


let stripePromise: Stripe | null;

export async function getStripe(): Promise<any> {
  if (!stripePromise)
  {
    stripePromise = await loadStripe(STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
}

export const STRIPE_API_URL = "https://api.stripe.com/v1";
