import { db } from "../../config/firebase"
import { collection, where, orderBy, query, onSnapshot, getDocs, doc } from "firebase/firestore";

export async function getPrices() {

  const all: any[] | PromiseLike<any[]> = [];

  const productsSnapshot = await getDocs(query(collection(db, `products`), orderBy('order'), where('active', '==', true)));


  for (const proRef of productsSnapshot.docs)
  {
    const pricesSnapshot = await getDocs(query(collection(db, `products/${proRef.ref.id}/prices`), where('active', '==', true)))
    const item = {
      product: { ...proRef.data(), uid: proRef.id },
      price: pricesSnapshot.docs[0].data(),
      priceId: pricesSnapshot.docs[0].id
    }
    all.push(item);
  }


  return all;

}