import React, { useContext } from "react";
import { PencilIcon } from "@heroicons/react/solid";
import { AccountStyles } from "./Account.style";
import { AuthContext } from "../../../context/AuthContext";

interface Props {
  openChangeNameModal: () => void;
  // openChangeEmailModal: () => void;
  openChangePassWordModal: () => void;
}

const Account = (props: Props) => {
  const { openChangeNameModal, openChangePassWordModal } = props;

  const { user } = useContext(AuthContext);

  return (
    <AccountStyles>
      <div className="settingsRow">
        <h4>Account</h4>
        <div className="accountRow">
          <div className="infoBox fullNameBox">
            <span>Full name</span>
            <p>{user?.displayName || ""}</p>
          </div>
          <div onClick={openChangeNameModal} className="editBox">
            <PencilIcon />
          </div>
        </div>
        <div className="accountRow">
          <div className="infoBox emailBox">
            <span>Email</span>
            <p>{user?.email}</p>
          </div>
          <div className="editBox editEmailBox">
            <PencilIcon />
          </div>
        </div>
        <div className="accountRow">
          <div className="infoBox passwordBox">
            <span>Password</span>
            <p>••••••••</p>
          </div>
          <div className="editBox" onClick={openChangePassWordModal}>
            <PencilIcon />
          </div>
        </div>
      </div>
    </AccountStyles>
  );
};

export default Account;
