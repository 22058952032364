import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function CollectionsSection({ data, searchParams }) {
  const scrollRef = useRef();
  const [scrollObject, setScrollObject] = useState(null);

  const leftScrollAmount = 662;

  useEffect(() => {
    const offsetLeft = scrollRef?.current?.scrollLeft;
    if (scrollObject?.direction === 'left') {
      scrollRef.current.scroll({
        left: offsetLeft - leftScrollAmount,
        top: 0,
        behavior: 'smooth',
      });
    }
    if (scrollObject?.direction === 'right') {
      scrollRef.current.scroll({
        left: offsetLeft + leftScrollAmount,
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [scrollObject]);

  return (
    <div className="collections-section">
      <div className="header">
        <h4>Collections</h4>
        <div className="hArrows">
          <img
            onClick={() =>
              setScrollObject({ amount: Math.random(), direction: 'left' })
            }
            src="/icons/arrow-left.svg"
            alt="Scroll Left"
          />
          <img
            onClick={() =>
              setScrollObject({ amount: Math.random(), direction: 'right' })
            }
            src="/icons/arrow-right.svg"
            alt="Scroll Right"
          />
        </div>
      </div>

      <div className="card-collection" ref={scrollRef}>
        {data.map((d) => (
          <Link
            key={d.slug}
            to={`/collection/${d.slug}${searchParams ? searchParams : ''}`}
          >
            <div
              className="card"
              style={{ backgroundImage: `url(${d.collectionCard})` }}
            >
              <div className="overlay" />
              <div className="content">
                {d.status === 'Active' && (
                  <>
                    {d.segmentsCount} Segments / {d.expertsCount} Experts
                  </>
                )}
                {d.status !== 'Active' && <>Coming Soon</>}

                <h5>{d.category}</h5>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <Link
        className="button secondary more-button"
        to={`/collections${searchParams}`}
      >
        Explore All
      </Link>
    </div>
  );
}

export default CollectionsSection;
