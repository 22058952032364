import { useEffect, useState } from 'react';
import Searchbar from '@components/Searchbar/Searchbar';
import { useSearchParams } from 'react-router-dom';
import Tabs from '@components/Tabs/Tabs';
import { landing } from '../LandingPage/data.js';
import { secondaryFilters } from '../../helpers/dataHelpers.js';
import './RosterPage.scss';
import CardGrid from 'src/layout/CardGrid/CardGrid';
import useHasMounted from '@hooks/useHasMounted.js';

const { tabData } = landing;

function RosterContent({
  sport,
  setVideoModal,
  setToggledSubscribeModal,
  searchParams,
  transformedData,
  setAlert,
  title,
  type,
}) {
  const [searchParam, setSearchParam] = useSearchParams();
  const [roster, setRoster] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCourseTabId, setSelectedCourseTabId] = useState(tabData[0].id);
  const [filteredCourses, setFilteredCourses] = useState(null);
  const [selectedSecondaryFilterId, setSelectedSecondaryFilterId] = useState(0);
  const handleCourseFilterId = (id) => {
    setSelectedCourseTabId(id);
    if (id === 0) {
      setFilteredCourses(roster);
    } else {
      setFilteredCourses(roster?.filter((c) => c.sport === tabData[id].title));
    }
  };

  const getRoster = async () => {
    setRoster(await transformedData);
    setFilteredCourses(await transformedData);
    return;
  };

  const handleSearchQuery = (query) => {
    setSearchQuery(query);
  };

  const getFilteredCoursesBySecondaryFilter = (fCourses) => {
    let newFCourses;
    if (title === 'Courses') {
      newFCourses = fCourses?.filter((f) => !f.interactiveOnly);
      return newFCourses;
    }
    if (fCourses) {
      const selectedSecondaryFilter =
        secondaryFilters[selectedSecondaryFilterId].value;

      if (selectedSecondaryFilter === 'conversations') {
        newFCourses = [...fCourses].filter((f) => !f.course);
      } else {
        newFCourses = [...fCourses];
      }
      return newFCourses;
    }
  };

  useEffect(() => {
    getRoster();
  }, []);

  useEffect(() => {
    if (sport) {
      const tId = tabData.find((t) => t.title === sport.title)?.id;
      if (tId) {
        handleCourseFilterId(tId);
      }
    }
  }, [sport]);

  /* useEffect(() => {
    const filter = searchParam.get('filter');
    if (filter) {
      if (filter === 'conversations') {
        setSelectedSecondaryFilterId(1);
      } else {
        setSelectedSecondaryFilterId(0);
      }
    }
  }, [searchParam]); */

  const handleSecondaryFilter = (id) => {
    setSelectedSecondaryFilterId(id);
    searchParam.set('filter', secondaryFilters[id].value);
    setSearchParam(searchParam);
  };

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className="roster-content">
      {/* <Searchbar
        placeholder="Search athletes, coaches, courses, and topics"
        value={searchQuery}
        callback={handleSearchQuery}
      /> */}
      {!title && (
        <>
          <div className="filters">
            <h4>Explore</h4>
            <Tabs
              data={tabData}
              selectedTabId={selectedCourseTabId}
              callback={handleCourseFilterId}
            />
          </div>
          <div className="secondary-filters">
            {secondaryFilters.map((s) => (
              <div
                key={s.id}
                onClick={() => handleSecondaryFilter(s.id)}
                className={selectedSecondaryFilterId === s.id ? 'active' : ''}
              >
                <img src={s.icon} alt={s.title} /> {s.title}
              </div>
            ))}
          </div>
        </>
      )}

      <CardGrid
        data={getFilteredCoursesBySecondaryFilter(filteredCourses)}
        setVideoModal={setVideoModal}
        setToggledSubscribeModal={setToggledSubscribeModal}
        searchParams={searchParams}
        setAlert={setAlert}
        title={title}
        type={type}
      />
    </div>
  );
}

export default RosterContent;
