import { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { collectionsDetail } from '../../data/collectionsDetail';
import './Segment.scss';
import '../Course/Course.scss';
import SegmentSidebar from './SegmentSidebar';
import BrowseContent from '../BrowseContent/BrowseContent';
import Slide from './Slide';
import SessionSummary from './SessionSummary';
import {
  getUserVideos,
  updateWatchingVideosList,
} from '@hooks/useWatchingVideo';
import Hls from 'hls.js';
import { isMobile } from 'react-device-detect';
import { getVideoStartTime } from '../Course/CourseHelpers';
import useHasMounted from '@hooks/useHasMounted';
import { AuthContext } from 'src/context/AuthContext';
import { useSubscription } from 'src/context/SubscriptionContext';

function Segment({
  searchParams,
  setVideoModal,
  setToggledSubscribeModal,
  setAlert,
}) {
  const { user } = useContext(AuthContext);
  const subscription = useSubscription();
  const params = useParams();
  const mainVideoRef = useRef();
  const navigate = useNavigate();
  const [sessions, setSessions] = useState(null);
  const [showNextVideoLoadScreen, setShowNextVideoeLoadScreen] =
    useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(10);
  const [groupedBySubcats, setGroupedBySubcats] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [videosWatched, setVideosWatched] = useState(null);
  const [videosWatchedIds, setVideosWatchedIds] = useState([]);

  const handleReplay = () => {
    setShowNextVideoeLoadScreen(false);
    mainVideoRef?.current?.play();
  };

  const handleNextSession = () => {
    setShowNextVideoeLoadScreen(false);
    navigate(
      `/course/${params.mavenSlug}/video/${params.mavenSlug}-${
        selectedSession.sessionNumber + 1
      }`
    );
  };

  const handleVideoClick = (e) => {
    if (!e.paused) handlePostCurrentTime(selectedSession);
  };

  const getCourseData = () => {
    //group by subcategory
    const gSubcats = collectionsDetail.map((c) => c.subcategory);

    const gBySubcats = [...new Set(gSubcats)].map((g) => {
      const d = collectionsDetail.filter((c) => c.subcategory === g);
      return {
        subcategory: g,
        category: d[0].category,
        slug: d[0].slug,
        categorySlug: d[0].categorySlug,
        data: d,
        mavenSlug: d[0].mavenSlug,
        thumbnail: d[0].thumbnail,
        subcategorySlug: d[0].subcategorySlug,
      };
    });
    setGroupedBySubcats(gBySubcats);

    //get collection subcategory videos
    const subcategorySessions = collectionsDetail.filter(
      (s) => s.subcategorySlug === params.subcategorySlug
    );
    setSessions(subcategorySessions);
    const sesh = subcategorySessions.find((m) => m.slug === params.videoSlug);
    setSelectedSession(sesh);
  };

  const startTimer = (seconds) => {
    if (seconds === null) {
      setShowNextVideoeLoadScreen(false);
      setCurrentTimeInSeconds(10);
    } else {
      let timeLeft = seconds;

      const countdown = () => {
        timeLeft--;
        if (timeLeft > 0) {
          setTimeout(countdown, 1000);
          setCurrentTimeInSeconds(timeLeft);
        } else {
          setShowNextVideoeLoadScreen(false);
          setCurrentTimeInSeconds(10);
          //go to next video
          navigate(
            `/course/${params.mavenSlug}/video/${params.mavenSlug}-${
              selectedSession.sessionNumber + 1
            }`
          );
        }
      };

      setTimeout(countdown, 1000);
    }
  };

  const handlePostCurrentTime = (session) => {
    updateWatchingVideosList(
      session.slug,
      session.videoId || session.slug,
      mainVideoRef?.current?.currentTime * 1000,
      session.firebaseCourseId || session.videoId || session.slug
    );
  };

  const handleVideoOnEnd = (session) => {
    handlePostCurrentTime(session);
    if (session.sessionNumber < sessions.length) {
      //go to next video
      setShowNextVideoeLoadScreen(true);
      startTimer(10);
    }
  };

  /* useEffect(() => {
    getCourseData();
    getUserVideos(setVideosWatched, setVideosWatchedIds);
  }, [params.mavenSlug, params.videoSlug]); */

  useEffect(() => {
    getCourseData();

    //stop navigation for non-premium and unauthed users
    if (!user || subscription.planName !== 'Premium') {
      if (!params.videoSlug.includes(1)) {
        navigate(
          `/segment/${params.subcategorySlug}/video/${
            params.videoSlug.split('-')[0]
          }-1`
        );
      }
    }

    if (!user) return;
    getUserVideos(setVideosWatched, setVideosWatchedIds);
  }, [params.mavenSlug, params.videoSlug, user]);

  useEffect(() => {
    if (selectedSession) {
      const linkIsHLS = selectedSession?.m3u8Link;
      const videoUrl = selectedSession?.m3u8Link;
      const video = mainVideoRef?.current;
      if (linkIsHLS) {
        function initializeHLS() {
          video.removeAttribute('src');
          if (Hls.isSupported()) {
            const hls = new Hls();
            try {
              hls.loadSource(videoUrl);
            } catch (err) {
              console.log('Error Loading Media!!! ' + err);
              return false;
            }
            hls.attachMedia(video);
            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
              //do something
            });
            const startTime = getVideoStartTime(
              selectedSession,
              videosWatchedIds,
              videosWatched
            );
            video.currentTime = startTime;
            video.play();
          } else {
            if (isMobile) {
              video.setAttribute('src', videoUrl);
            }
          }
        }
        initializeHLS();
      } else {
        video.src = selectedSession?.video;
        video.play();
      }
    }
  }, [selectedSession, videosWatched, videosWatchedIds]);

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className="segment course">
      <div
        className={`video-player-container ${
          showNextVideoLoadScreen ? 'show-inbetween' : ''
        }`}
      >
        <video
          className={`main-video ${showNextVideoLoadScreen ? 'hide' : ''}`}
          poster={selectedSession?.image}
          //src={selectedSession?.video}
          ref={mainVideoRef}
          controls={true}
          controlsList="nodownload"
          //autoPlay
          onEnded={() => handleVideoOnEnd(selectedSession)}
          onClick={(e) => handleVideoClick(e.target)}
        />

        {showNextVideoLoadScreen && (
          <Slide
            data={sessions}
            selectedSession={selectedSession}
            handleReplay={handleReplay}
            currentTimeInSeconds={currentTimeInSeconds}
            handleNextSession={handleNextSession}
          />
        )}
        <SegmentSidebar
          data={sessions}
          selectedSession={selectedSession}
          searchParams={searchParams}
          groupedBySubcats={groupedBySubcats}
          handlePostCurrentTime={handlePostCurrentTime}
          noAuth={user ? false : true}
          setToggledSubscribeModal={setToggledSubscribeModal}
        />
      </div>
      <SessionSummary selectedSession={selectedSession} />
      <BrowseContent
        coursesTitle="More Courses"
        conversationsTitle="More Conversations"
        searchParams={searchParams}
        setVideoModal={setVideoModal}
        setToggledSubscribeModal={setToggledSubscribeModal}
        setAlert={setAlert}
      />
    </div>
  );
}

export default Segment;
