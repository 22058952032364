import React, { useState, useEffect, createContext, useContext } from 'react';
import { db } from '../config/firebase';
import { query, collection, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { AuthContext } from './AuthContext';
import { useSearchParams } from 'react-router-dom';

export const PartnerContext = createContext();

export function usePartner() {
  return useContext(PartnerContext);
}

export const PartnerProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [background, setBackground] = useState('');
  const [backgroundVideo, setBackgroundVideo] = useState('');
  const [backgroundVideoMobile, setBackgroundVideoMobile] = useState('');
  const [partner, setPartner] = useState('');
  const [partnerLogo, setPartnerLogo] = useState('');
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [body, setBody] = useState('');
  const [rightImage, setRightImage] = useState('');
  const [type, setType] = useState('');
  const [passCount, setPassCount] = useState(0);
  const [partnerId, setPartnerId] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [ctaLink, setCtaLink] = useState('');
  const [appStoreLink, setAppStoreLink] = useState('');
  const [playStoreLink, setPlayStoreLink] = useState('');

  const { user } = useContext(AuthContext);

  // Listen for user changes
  const getPartner = async (id) => {
    const docRef = doc(db, 'partners', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const partnerDoc = docSnap.data();
      setBackground(partnerDoc?.background || '');
      setBackgroundVideo(partnerDoc?.backgroundVideo || '');
      setBackgroundVideoMobile(partnerDoc?.backgroundVideoMobile || '');
      setPartner(partnerDoc?.partner || '');
      setPartnerLogo(partnerDoc?.partnerLogo || '');
      setHeading(partnerDoc?.heading || '');
      setSubheading(partnerDoc?.subheading || '');
      setBody(partnerDoc?.body || '');
      setRightImage(partnerDoc?.rightImage || '');
      setType(partnerDoc?.type || '');
      setPassCount(partnerDoc?.passCount || 0);
      setPartnerId(id || null);
      setUserCount(partnerDoc?.emails?.length || 0);
      setCtaLink(partnerDoc?.ctaLink || '');
      setAppStoreLink(partnerDoc?.appStoreLink || '');
      setPlayStoreLink(partnerDoc?.playStoreLink || '');
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
    }
  };

  const values = {
    id: partnerId,
    background,
    backgroundVideo,
    backgroundVideoMobile,
    partner,
    partnerLogo,
    heading,
    body,
    rightImage,
    type,
    subheading,
    passCount,
    userCount,
    ctaLink,
    appStoreLink,
    playStoreLink,
  };

  useEffect(() => {
    const sParams = Object.fromEntries([...searchParams]);
    if (sParams.id) {
      getPartner(sParams.id);
    }
  }, [user]);

  return (
    <PartnerContext.Provider value={values}>{children}</PartnerContext.Provider>
  );
};
