import React, { ReactNode, useState } from 'react';
import { auth } from '../../../config/firebase';
import { updateProfile } from 'firebase/auth';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import { XIcon } from '@heroicons/react/outline';
import { ChangeNameModalOverlay } from './ChangeNameModal.style';

interface Props {
  closeChangeNameModal: () => void;
  openConfirmModal: (msg: ReactNode) => void;
}

const ChangeNameModal = (props: Props) => {
  const { closeChangeNameModal, openConfirmModal } = props;

  const [fullName, setFullName] = useState('');
  const [nameError, setNameError] = useState('');

  const updateNameInfo = () => {
    setNameError('');
    if (fullName.trim() === '') {
      setNameError("Name can't be empty");
      return;
    }
    updateProfile(auth.currentUser!, {
      displayName: fullName,
    })
      .then(() => {
        closeChangeNameModal();
        openConfirmModal(
          <p>
            Your name has been <br /> successfully updated
          </p>
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ChangeNameModalOverlay>
      <div className={`changeNameModal ${!!nameError ? 'hasError' : ''}`}>
        <XIcon onClick={closeChangeNameModal} className="closeModalBtn" />
        <h3>Change your name</h3>
        <div className="nameInputWrapper">
          <span className="nameLabel">New Name</span>
          <Input
            type="text"
            autoFocus
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          {!!nameError && <p className="errorMsg">{nameError}</p>}
        </div>
        <div className="nameBtnWrapper">
          <Button
            title="Change Name Modal"
            action="Cancel"
            onClick={closeChangeNameModal}
            className="cancelBtn"
          >
            Cancel
          </Button>
          <Button
            title="Change Name Modal"
            action="Done"
            disabled={fullName.trim() === ''}
            onClick={updateNameInfo}
            className={`doneBtn ${
              fullName.trim() === '' ? '' : 'validatedBtn'
            }`}
          >
            Done
          </Button>
        </div>
      </div>
    </ChangeNameModalOverlay>
  );
};

export default ChangeNameModal;
