import React, { ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import { confirmPasswordReset } from 'firebase/auth';
import ResetBg from '../../assets/images/login.png';
import ChangePasswordModal from '../../components/Modals/ChangePasswordModal/ChangePasswordModal';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import { ConfirmModalI } from 'src/interface/ConfirmModal.interface';
import { ResetPassword } from './ResetPasswordPage.style';
import useHasMounted from '@hooks/useHasMounted';

const ResetPasswordPage = () => {
  const [isChangePasswordModalOpened, setIsChangePasswordModalOpened] =
    useState(true);
  const [isConfirmModalOpened, setIsConfirmModalOpened] =
    useState<ConfirmModalI>({
      msg: null,
      open: false,
    });
  const [resetErrorMessage, setResetErrorMessage] = useState('');

  const closeChangePasswordModal = () => {
    setIsChangePasswordModalOpened(false);
  };

  const openConfirmModal = (message: ReactNode) => {
    setIsConfirmModalOpened({
      ...isConfirmModalOpened,
      msg: message,
      open: true,
    });
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpened({
      ...isConfirmModalOpened,
      open: false,
    });
    navigate('/');
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const navigate = useNavigate();
  const query = useQuery();
  const oobCode = query?.get('oobCode') || '';

  const resetPassword = (newPassword: string) => {
    setResetErrorMessage('');
    confirmPasswordReset(auth, oobCode, newPassword)
      .then(() => {
        closeChangePasswordModal();
        openConfirmModal(
          <p>
            Your password has been <br /> successfully updated
          </p>
        );
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/expired-action-code':
            setResetErrorMessage('Password reset code has expired');
            break;
          case 'auth/invalid-action-code':
            setResetErrorMessage('Password reset code is invalid');
            break;
          case 'auth/user-disabled':
            setResetErrorMessage(
              'User corresponding to the password reset code has been disabled'
            );
            break;
          case 'auth/user-not-found':
            setResetErrorMessage(
              'There is no user corresponding to the password reset code'
            );
            break;
          case 'auth/weak-password':
            setResetErrorMessage('Password is not strong enough');
            break;
        }
      });
  };

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <ResetPassword
      style={{ background: `url(${ResetBg}) no-repeat center/cover` }}
    >
      {isChangePasswordModalOpened && (
        <ChangePasswordModal
          closeChangePasswordModal={closeChangePasswordModal}
          openConfirmModal={openConfirmModal}
          resetPassword={resetPassword}
          hasResetPasswordLink
          resetErrorMessage={resetErrorMessage}
        />
      )}
      {isConfirmModalOpened.open && (
        <ConfirmationModal
          modalText={isConfirmModalOpened?.msg || ''}
          onClose={closeConfirmModal}
          buttonText='Sign In'
        />
      )}
    </ResetPassword>
  );
};

export default ResetPasswordPage;
