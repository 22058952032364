import ContactForm from 'src/pages/ContactPage/ContactForm';
import '../Modals.scss';
import '../LoginModal/LoginModal.scss';
import './ContactModal.scss';

function ContactModal({ title, type, setContactModal, user }) {
  return (
    <div className='contact-modal modals'>
      <img
        className='close-icon'
        src='/icons/close.svg'
        alt='Close Modal'
        onClick={() => setContactModal(false)}
      />
      <div className='content'>
        {title && <h3>{title}</h3>}
        <ContactForm
          type={type}
          user={user}
          setContactModal={setContactModal}
        />
      </div>
    </div>
  );
}

export default ContactModal;
