import React, { ReactNode, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../../config/firebase';
import { updatePassword } from 'firebase/auth';
import {
  XIcon,
  CheckIcon,
  EyeIcon,
  EyeOffIcon,
} from '@heroicons/react/outline';
import Button from '../../Button/Button';
import './ChangePasswordModal.scss';

interface Props {
  closeChangePasswordModal: () => void;
  openConfirmModal: (msg: ReactNode) => void;
  hasResetPasswordLink?: boolean;
  resetPassword?: (newPassword: string) => void;
  resetErrorMessage?: string;
}

export default function ChangePasswordModal(props: Props) {
  const {
    closeChangePasswordModal,
    openConfirmModal,
    hasResetPasswordLink = false,
    resetPassword,
    resetErrorMessage = '',
  } = props;

  const navigate = useNavigate();
  const { search } = useLocation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');

  const toggleNewPasswordShowing = () => {
    setShowNewPassword((prevNewPassword) => !prevNewPassword);
  };

  const toggleConfirmPasswordShowing = () => {
    setShowConfirmPassword((prevNewPassword) => !prevNewPassword);
  };

  const redirectToLoginPage = () => {
    navigate(`/${search}`);
  };

  const comparePasswords = () => {
    if (!!!newPassword || !!!confirmPassword) {
      return false;
    }
    if (newPassword === confirmPassword) {
      return true;
    }
  };

  const checkCharLimit = () => {
    if (newPassword.length > 5 && confirmPassword.length > 5) {
      return true;
    }
    return false;
  };

  const updatePasswordInfo = () => {
    updatePassword(auth?.currentUser!, newPassword)
      .then(() => {
        closeChangePasswordModal();
        openConfirmModal(
          <p>
            Your password has been <br /> successfully updated
          </p>
        );
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/weak-password':
            setNewPasswordError('Weak password');
            break;
          case 'auth/requires-recent-login':
            setNewPasswordError(
              'Recent login is required. You will need to login again before proceeding to update password.'
            );
            break;
        }
      });
  };

  return (
    <div className="reset-password">
      <div className="changePasswordModal ">
        <XIcon
          onClick={() => {
            if (hasResetPasswordLink) {
              redirectToLoginPage();
            } else {
              closeChangePasswordModal();
            }
          }}
          className="closeBtn"
        />
        <h3 className="title">Change your password</h3>
        <p className="subtitle">Enter in your new password</p>
        <div className="inputContainer">
          <p
            className={`labelInput ${
              newPassword.length > 0 ? 'show' : 'hidden'
            }`}
          >
            New password
          </p>
          <input
            style={{
              padding:
                newPassword.length > 0 ? '30px 16px 10px 16px' : '10px 16px',
            }}
            type={showNewPassword ? 'text' : 'password'}
            autoFocus
            value={newPassword}
            placeholder="New Password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {showNewPassword ? (
            <EyeIcon
              onClick={toggleNewPasswordShowing}
              className="showPasswordIcon"
            />
          ) : (
            <EyeOffIcon
              onClick={toggleNewPasswordShowing}
              className="showPasswordIcon"
            />
          )}
        </div>
        {!!newPasswordError && <p className="textError">{newPasswordError}</p>}
        <div
          style={{ margin: !!newPasswordError ? '0' : '26px 0 0 0' }}
          className="inputContainer"
        >
          <p
            className={`labelInput ${
              confirmPassword.length > 0 ? 'show' : 'hidden'
            }`}
          >
            Confirm password
          </p>
          <input
            style={{
              padding:
                confirmPassword.length > 0
                  ? '30px 16px 10px 16px'
                  : '10px 16px',
            }}
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {showConfirmPassword ? (
            <EyeIcon
              onClick={toggleConfirmPasswordShowing}
              className="showPasswordIcon"
            />
          ) : (
            <EyeOffIcon
              onClick={toggleConfirmPasswordShowing}
              className="showPasswordIcon"
            />
          )}
        </div>
        <div
          style={{
            padding: '30px 0 0 0',
          }}
          className="regexContainer"
        >
          <div className="regexRow">
            <div
              className={`regexStatus ${
                newPassword.length > 5 ? 'bg-active' : 'bg-inactive'
              }`}
            >
              <CheckIcon />
            </div>
            <span>At least 6 characters</span>
          </div>
          <div className="regexRow">
            <div
              className={`regexStatus ${
                comparePasswords() ? 'bg-active' : 'bg-inactive'
              }`}
            >
              <CheckIcon />
            </div>
            <span>Passwords matching</span>
          </div>
          {/* <div className="regexRow">
            <div
              className={`regexStatus ${
                ValidateUppercaseLowercase(newPassword)
                  ? "bg-active"
                  : "bg-inactive"
              }`}
            >
              <CheckIcon />
            </div>
            <span>Uppercase and lowercase letter (A,z)</span>
          </div> */}
          {/* <div className="regexRow">
            <div
              className={`regexStatus ${
                ValidateNumericCharacter(newPassword)
                  ? "bg-active"
                  : "bg-inactive"
              }`}
            >
              <CheckIcon />
            </div>
            <span>Numeric character (0-9)</span>
          </div> */}
          {/* <div className="regexRow">
            <div
              className={`regexStatus ${
                ValidateSpecialCharacter(newPassword)
                  ? "bg-active"
                  : "bg-inactive"
              }`}
            >
              <CheckIcon />
            </div>
            <span>Special character (!,%,#, etc)</span>
          </div> */}
          {!!resetErrorMessage && (
            <p className="textError">{resetErrorMessage}</p>
          )}
        </div>
        <div className="btnContainer">
          <Button
            className="btnCancel"
            title="Change Password Modal"
            action="Cancel"
            onClick={() => {
              if (hasResetPasswordLink) {
                redirectToLoginPage();
              } else {
                closeChangePasswordModal();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            title="Change Password Modal"
            action="Done"
            disabled={
              // !ValidateNumericCharacter(newPassword) ||
              // !ValidateUppercaseLowercase(newPassword) ||
              // !ValidateSpecialCharacter(newPassword)
              !comparePasswords() || !checkCharLimit()
            }
            onClick={() => {
              if (hasResetPasswordLink) {
                if (resetPassword) {
                  resetPassword(newPassword);
                }
              } else {
                updatePasswordInfo();
              }
            }}
            className={`btnDone button primary ${
              // ValidateNumericCharacter(newPassword) &&
              // ValidateUppercaseLowercase(newPassword) &&
              // ValidateSpecialCharacter(newPassword) &&
              // !!!confirmPasswordError ? "bg-active" : "bg-inactive"
              comparePasswords() && checkCharLimit()
                ? 'bg-active'
                : 'bg-inactive'
            }`}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
}
