import { useEffect, useState } from 'react';
import { mavenData } from '../../data/mavenData';
import { getConversationStarters } from '../../api/storyFileApi';
import { useParams } from 'react-router-dom';
import InteractiveContent from './InteractiveContent';
import Hero from './Hero';
import Courses from '../LandingPage/Courses';
import { landing } from '../LandingPage/data.js';
import './InteractiveLanding.scss';
import ScrollToTop from 'src/ScrollToTop';
import SEO from '@components/Seo/Seo';
import useHasMounted from '@hooks/useHasMounted';

const { tabData } = landing;

const activeSessionData = mavenData.filter((s) => s.status === 'Active');

const interactiveStack = activeSessionData
  .filter((s) => s.course === 'TRUE')
  .map((ss) => ({
    ...ss,
    count: ss.topicCount,
    cardTitle: ss.title,
    type: 'Interactive',
    image: ss.restingImage,
    restingVideo: ss.restingVideo,
    interactiveOnly: true,
  }));

function InteractiveLanding({
  setVideoModal,
  setBioModal,
  setToggledDownloadModal,
  searchParams,
  setAlert,
}) {
  const params = useParams();
  const [data, setData] = useState(null);
  const [conversationStarters, setConversationStarters] = useState(null);

  const getStoryFileData = async (id) => {
    const cStarters = await getConversationStarters(
      id,
      setConversationStarters
    );
  };

  const getCourseData = () => {
    const mData = mavenData.find((m) => m.slug === params.slug);
    setData(mData);
    getStoryFileData(mData?.storyFileId);
  };

  useEffect(() => {
    getCourseData();
  }, [params.slug]);

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className="interactive-landing fade-in">
      {data && (
        <>
          <SEO
            title={`${data.sport} Interactive Conversations with sports icon ${data.title}`}
            description={`Interact with ${data.title} about ${data.sport}: ${data.shortBio} | VS`}
            name={`${data.title} for VS`}
            type="article"
          />
          <ScrollToTop />
          <Hero
            athlete={data.title}
            topicsCount={data.topicCount}
            responsesCount={data.questionCount}
            restingImageLandscape={data.restingImageLandscape}
            image={data.restingImage}
            restingVideo={data.restingVideoLandscape}
            setBioModal={setBioModal}
            setToggledDownloadModal={setToggledDownloadModal}
            bio={data.bio}
            slug={data.slug}
            bioImage={data.avatar}
            restingVideoLandscape={data.restingVideoLandscape}
          />

          <div className="opposite-body">
            <div className="text-banner">
              <h1>{data.title?.split(' ')[0]}'s Topics</h1>
              {/* <p className="faded">
                Versus Interactive brings real-time mentorship from world-class
                athetes and coaches straight to your fingertips. Get to know
                {` ${data.title} `} like never before and recieve actionable
                advice on how to develp valuable life-skills and gain deeper
                insights on hown she executes her craft on the field.
              </p> */}
            </div>

            <InteractiveContent
              slug={params.slug}
              athlete={data.title}
              avatar={data.restingImage}
              data={conversationStarters}
              searchParams={searchParams}
            />
          </div>

          <Courses
            type="Conversation"
            title="More Conversations"
            tabData={tabData}
            cardStack={interactiveStack.filter((s) => s.slug !== params.slug)}
            setVideoModal={setVideoModal}
            searchParams={searchParams}
            setAlert={setAlert}
          />
        </>
      )}
    </div>
  );
}

export default InteractiveLanding;
