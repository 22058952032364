import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '@components/Loader/Loader';
import { getUserVideos } from '@hooks/useWatchingVideo';
import { getWatchedStyle } from './CourseHelpers';
import Button from '@components/Button/Button';
import { useSubscription } from 'src/context/SubscriptionContext';
import Tabs from '@components/Tabs/Tabs';
//import useOutsideAlerter from '../../hooks/useOutsideAlerter';

function Sidebar({
  data,
  selectedSession,
  filteredMavenData,
  handlePostCurrentTime,
  noAuth,
  setToggledSubscribeModal,
  selectedMavenData,
  searchParams,
  setBioModal,
}) {
  const [videosWatched, setVideosWatched] = useState(null);
  const [videosWatchedIds, setVideosWatchedIds] = useState([]);
  const [toggledHeader, setToggledHeader] = useState(false);
  const [tabData, setTabData] = useState(null);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const navigate = useNavigate();
  const subscription = useSubscription();

  const handleShowBio = () => {
    const bioForModal = {
      body: selectedMavenData?.bio,
      title: selectedMavenData?.title,
      image: selectedMavenData?.avatar,
    };
    setBioModal(bioForModal);
  };

  const handleChangeSession = (d) => {
    //save progress in firebase if user unmounts
    handlePostCurrentTime(selectedSession);
    navigate(`/course/${d.mavenSlug}/video/${d.slug}`);

    /* if (noAuth) {
      setToggledSubscribeModal(true);
      return;
    } else {
      if (subscription.planName !== 'Premium') {
        if (d?.sessionNumber > 1) setToggledSubscribeModal(true);
      } else {
        //save progress in firebase if user unmounts
        handlePostCurrentTime(selectedSession);
        navigate(`/course/${d.mavenSlug}/video/${d.slug}`);
      }
    } */
  };

  useEffect(() => {
    if (noAuth) return;
    getUserVideos(setVideosWatched, setVideosWatchedIds);
  }, [filteredMavenData]);

  const handleScrollTo = (u) => {
    const el = document.querySelector(`#${u}`);
    if (el)
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
  };

  useEffect(() => {
    if (selectedSession) {
      setTimeout(() => {
        handleScrollTo(selectedSession.slug);
      }, 500);
    }
    setSelectedTabId(0);
  }, [selectedSession]);

  useEffect(() => {
    setTabData([
      {
        id: 0,
        title: `Sessions (${data?.length})`,
      },
      {
        id: 1,
        title: 'More Courses',
      },
    ]);
  }, [data]);

  const isWatched = (d) => {
    if (videosWatchedIds.includes(d.videoId)) {
      const video = videosWatched.find((v) => v.video === d.videoId);
      if (video.currentPosition / 1000 + 10 > d.durationInSeconds) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div className="sidebar">
      {!data && <Loader />}
      {data && (
        <>
          <div className="sidebar-header">
            <div
              className="side-bar-header-card"
              style={{
                backgroundImage: `url(${selectedMavenData?.courseHeroImage})`,
              }}
            >
              <div className="overlay overlay-left" />
              <div className="prominent">
                <h4>You're watching</h4>
                <h3>{selectedMavenData?.title}</h3>
                <p className="faded">
                  {selectedMavenData?.sessionCount} Sessions{' '}
                  {/* ·{' '}
                  {selectedMavenData?.totalRunningMins} */}
                </p>
              </div>

              <Button
                className="secondary prominent"
                onClick={() => handleShowBio()}
              >
                About {selectedMavenData?.title.split(' ')[0]}
              </Button>
            </div>
            <div className="side-bar-header-tabs">
              {tabData && (
                <Tabs
                  data={tabData}
                  selectedTabId={selectedTabId}
                  callback={setSelectedTabId}
                />
              )}
            </div>
          </div>
          <div className="sidebar-body">
            {selectedTabId === 1 && (
              <div className="header-dropdown">
                {filteredMavenData
                  .filter((d) => d.interactiveOnly !== 'TRUE')
                  .map((m, index) => (
                    <div
                      key={`${m.slug}-${index}`}
                      className="header-dropdown-item"
                      onClick={() => {
                        setToggledHeader(!toggledHeader);
                        handlePostCurrentTime(selectedSession);
                        navigate(
                          `/course/${m.slug}/video/${m.slug}-1${
                            searchParams ? searchParams : ''
                          }`
                        );
                      }}
                    >
                      <img src={m.avatar} alt={m.title} />
                      <div>
                        <h5>{m.title}</h5>
                        <small className="faded">{m.sport}</small>
                        <small>({m.sessionCount})</small>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {selectedTabId === 0 &&
              data?.map((d, index) => (
                <div
                  key={`${d.slug}-${index}`}
                  className={`sidebar-item ${
                    selectedSession.slug === d.slug ? 'active' : ''
                  }`}
                  id={d.slug}
                  onClick={() => handleChangeSession(d)}
                >
                  <div
                    className="card-image"
                    style={{ backgroundImage: `url(${d.image})` }}
                  >
                    <div
                      className="watched-indicator"
                      style={getWatchedStyle(
                        d,
                        videosWatchedIds,
                        videosWatched,
                        noAuth
                      )}
                    />
                  </div>
                  <div className="sidebar-item-copy">
                    <h5>{d.title}</h5>
                    <div>
                      <span className="faded">
                        {d.maven} · {d.duration}{' '}
                      </span>
                      {isWatched(d) && <span className="badge">Watched</span>}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Sidebar;
