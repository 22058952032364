import './ContactPage.scss';
import HeroSimple from '../../components/HeroSimple/HeroSimple';
import ScrollToTop from 'src/ScrollToTop';
import ContactForm from './ContactForm';
import SEO from '@components/Seo/Seo';

function ContactPage() {
  return (
    <div className="contact-page">
      <SEO
        title="Contact Us | Versus"
        description="‍Contact Versus and let us know how we can help you get the most out of our platform."
        name="Contact Page for Versus"
        type="article"
      />
      <ScrollToTop />
      {/* <HeroSimple title="Contact Us" subtitle="Contact and support." /> */}
      <div className="contact-page-container">
        <div className="left">
          <h2>How Can We Help?</h2>
          <p className="faded">
            Please fill out our contact form and provide your reasoning for
            contacting us to better assist you.
          </p>
          <div className="meta">
            <h3>Contact Information</h3>
            <div className="meta-content">
              <a href="mailto:support@versus.co">
                <img src="/icons/email-contact.svg" alt="email us" />
                support@versus.co
              </a>
              {/* <a href="tel:+18003600273">
                <img src="/icons/phone-contact.svg" alt="call us" />
                +1 (800) 360-0273
              </a> */}
            </div>
          </div>
        </div>
        <div className="right">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
