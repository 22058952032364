import SEO from '@components/Seo/Seo';
import useHasMounted from '@hooks/useHasMounted';

function Terms() {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }
  return (
    <div className="policies">
      <SEO
        title="Terms of Service | Versus"
        description="‍If you have any questions or comments about this notice, the ways in which we collect and use your Personal Information described here, your choices and rights regarding such use, or wish to exercise your rights, please do not hesitate to contact us at: support@versus.co"
        name="Terms of Service for Versus"
        type="article"
      />
      <div class="page">
        <div class="div-block-85">
          <h1 class="heading-44">Terms and Conditions</h1>
          <div class="div-block-84">
            <div
              data-current="Tab 1"
              data-easing="ease"
              data-duration-in="300"
              data-duration-out="100"
              class="tabs w-tabs"
            >
              {/* <div class="tabs-menu w-tab-menu">
                <a
                  data-w-tab="Tab 1"
                  class="tab-link-tab-1 w-inline-block w-tab-link w--current"
                >
                  <div>Cookies</div>
                </a>
                <a data-w-tab="Tab 2" class="w-inline-block w-tab-link">
                  <div>License</div>
                </a>
                <a data-w-tab="Tab 3" class="w-inline-block w-tab-link">
                  <div>Hyperlinking to our Content</div>
                  <div class="div-block-87"></div>
                </a>
                <a data-w-tab="Tab 4" class="w-inline-block w-tab-link">
                  <div>iFrames</div>
                </a>
                <a data-w-tab="Tab 5" class="w-inline-block w-tab-link">
                  <div>Content Liability</div>
                </a>
                <a data-w-tab="Tab 6" class="w-inline-block w-tab-link">
                  <div>Your Privacy</div>
                </a>
                <a data-w-tab="Tab 7" class="w-inline-block w-tab-link">
                  <div>Reservation of Rights</div>
                </a>
                <a data-w-tab="Tab 8" class="w-inline-block w-tab-link">
                  <div>Removal of links from our website</div>
                </a>
                <a data-w-tab="Tab 9" class="w-inline-block w-tab-link">
                  <div>Disclaimer</div>
                </a>
              </div> */}
              <div class="tabs-content w-tab-content">
                <div
                  data-w-tab="Tab 1"
                  class="tab-pane-tab-1 w-tab-pane w--tab-active"
                >
                  <div class="div-block-87">
                    <p class="paragraph-28">
                      <span class="text-span-2">
                        <strong class="bold-text">‍</strong>
                      </span>
                      Effective Date: April 14, 2022 <br />
                      Last Revised: April 14, 2022
                      <br />
                      <br />
                      Maven Arena, Inc. d/b/a Versus (“<strong>Versus</strong>,”
                      “<strong>we</strong>,” “<strong>us</strong>,” or “
                      <strong>our</strong>”) provides its services (described
                      below) to you through its website located at
                      <a href="https://versus.co">https://versus.co</a> and
                      through its mobile application (“
                      <strong>App</strong>”) (collectively referred to as the
                      “Sites”) and related services (collectively, such
                      services, including any new features and applications, the
                      Mobile Services (as defined below), and the Site, the
                      “Service(s)”). These Terms &amp; Conditions (“
                      <strong>Terms &amp; Conditions</strong>”) govern your use
                      of the Sites, and your agreement is a condition precedent
                      to using the Sites. Please read these Terms &amp;
                      Conditions carefully before proceeding.
                      <br />‍<br />
                      <span>
                        <strong class="bold-text-2">Binding Effect</strong>
                      </span>
                      <br />
                      <br />
                      BY DOWNLOADING, INSTALLING, AND USING THE SITES, YOU
                      AGREE, WITHOUT LIMITATION OR QUALIFICATION, TO BE BOUND BY
                      THESE TERMS &amp; CONDITIONS, TO WHICH WE RESERVE THE
                      RIGHT TO MAKE CHANGES FROM TIME TO TIME, CONSISTENT WITH
                      APPLICABLE LAW. IF YOU DO NOT AGREE TO THESE TERMS &amp;
                      CONDITIONS, DO NOT DOWNLOAD, INSTALL, OR USE THE SITES AND
                      DELETE THE APP FROM YOUR DEVICE. PLEASE BE AWARE THAT
                      THESE TERMS &amp; CONDITIONS CONTAIN A BINDING ARBITRATION
                      AGREEMENT AND CLASS ACTION WAIVER THAT WAIVE YOUR RIGHT TO
                      A COURT HEARING AND JURY TRIAL.
                      <br />
                      <br />‍<br />
                      <span class="text-span-3">
                        <strong>
                          Modification to these Terms &amp; Conditions{' '}
                        </strong>
                        <br />
                      </span>
                      <br />
                      <br />
                      We reserve the right, at our sole discretion, to change or
                      modify portions of these Terms &amp; Conditions at any
                      time. If we do this, depending on the nature of the
                      change, we will post the changes on this page and indicate
                      at the top of this page the date these terms were last
                      revised and/or notify you, either through the Service’s
                      user interface, in an email notification or through other
                      reasonable means and as required by applicable law. Any
                      such changes addressing new functions of the Services or
                      changes made for legal reasons will be effective
                      immediately. Your continued use of the Services after the
                      date any such changes become effective constitutes your
                      acceptance of the new Terms &amp; Conditions. In addition,
                      when using certain Services, you will be subject to any
                      additional terms applicable to such Services that may be
                      posted on the App from time to time.
                      <br />‍<br />
                      <span class="text-span-4">
                        <strong>Privacy</strong>
                      </span>
                      <br />‍<br />
                      We respect your privacy and are committed to protecting
                      it. Our Privacy Policy governs the processing of all
                      personal data collected from you in connection with your
                      use of the Sites.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Minors</strong>
                      </span>
                      <br />‍<br />
                      If you are under 13 years of age, you are not authorized
                      to use the Service, with or without registering. For more
                      information, see our Privacy Policy.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Creating and Accessing Your Account</strong>
                      </span>
                      <br />‍<br />
                      You will be required to create an account in order to
                      access and use certain features of the Services. If you
                      agree to register for the Services, you agree to provide
                      and maintain true, accurate, current, and complete
                      information about yourself as prompted by the registration
                      form. Registration data and certain other information
                      about you, including but not limited to your username and
                      age, are governed by our Privacy Policy.You must treat
                      your account information (including, without limitation,
                      your password and any other piece of information required
                      as part of our security procedures) as confidential, and
                      you must not disclose the foregoing to any other person or
                      entity. You also acknowledge that your account is personal
                      to you and agree not to provide any other person with
                      access to the Sites or portions of them using your email
                      address, password or other security information. You agree
                      to notify us immediately of any unauthorized access to or
                      use of your email address, password or any other breach of
                      security information. You agree to be responsible for any
                      use of the Sites or portions of them (including, without
                      limitation, for all orders placed and for all activities
                      that occur) using your email address, password, or other
                      security information. You also agree to ensure that you
                      exit from your account at the end of each session. Versus
                      will not be liable for any loss or damage arising from
                      your failure to comply with this Section.
                      <br />‍<br />
                      <span class="text-span-4">
                        <strong>Mobile Services</strong>
                      </span>
                      <br />‍<br />
                      Certain services are available via a mobile device and,
                      subject to the terms of this Agreement Versus grants you a
                      limited, non-exclusive and nontransferable license to (i)
                      upload content to the Service via a single mobile device
                      owned or otherwise controlled by you (“Mobile Device”),
                      (ii) browse the Service and the Sites from a Mobile
                      Device, and (iii) access, stream, download and use certain
                      features through an application downloaded and installed
                      on a Mobile Device strictly in accordance with these Terms
                      &amp; Conditions (collectively, the “Mobile Services”). To
                      the extent you access the Service through a Mobile Device,
                      your wireless service carrier’s standard charges, data
                      rates and other fees may apply. In addition, downloading,
                      installing, or using certain Mobile Services may be
                      prohibited or restricted by your carrier, and not all
                      Mobile Services may work with all carriers or devices. In
                      using the Mobile Services, you may provide your telephone
                      number. By providing your telephone number, you consent to
                      receive calls and/or SMS, MMS, or text messages at that
                      number. We may share your phone numbers with our
                      affiliates or with our service providers (such as customer
                      support, billing or collections companies, and text
                      message service providers) who we have contracted with to
                      assist us in pursuing our rights or providing our Services
                      under these Terms &amp; Conditions, our policies,
                      applicable law, or any other agreement we may have with
                      you. You agree these parties may also contact you using
                      autodialed or prerecorded calls and text messages, as
                      authorized by us to carry out the purposes we have
                      identified above, and not for their own purposes. In the
                      event you change or deactivate your mobile telephone
                      number, you agree to promptly update your Versus account
                      information to ensure that your messages are not sent to
                      the person that acquires your old number.
                      <br />
                      <br />
                      You acknowledge that when you download, install, or use
                      the Mobile Services, Versus may use automatic means
                      (including, for example, cookies and web beacons) to
                      collect information about your Mobile Device and about
                      your use of the Mobile Services. You also may be required
                      to provide certain information about yourself as a
                      condition to downloading, installing, or using the Mobile
                      Services or certain of its features or functionality. All
                      information we collect through or in connection with the
                      Mobile Services is subject to our Privacy Policy. By
                      downloading, installing, using, and providing information
                      to or through the Mobile Services, you consent to all
                      actions taken by us with respect to your information in
                      compliance with the Privacy Policy.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Modifications to Services</strong>
                      </span>
                      <br />‍<br />
                      Versus reserves the right to modify or discontinue,
                      temporarily or permanently, the Service (or any part
                      thereof) with or without notice. You agree that Versus
                      will not be liable to you or to any third party for any
                      modification, suspension or discontinuance of the Service.
                      Additionally, we may, in our sole and absolute discretion,
                      disable, suspend or terminate your account at any time,
                      with or without notice, and for any reason, including, but
                      not limited to, if you breach these Terms &amp;
                      Conditions. Upon disabling your account, we may retain
                      your data in accordance with our Privacy Policy. Even if
                      your account is disabled, suspended or terminated, be
                      aware that you will continue to be bound by these Terms
                      &amp; Conditions.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Restrictions on Use</strong>
                      </span>
                      <br />‍<br />
                      You may use the Sites and all associated content solely
                      for your personal use and enjoyment. The Sites or any
                      portion of them may not be reproduced, duplicated, copied,
                      sold, resold, visited, or otherwise exploited for any
                      commercial purpose without our express written consent.{' '}
                      <br />
                      <br />
                      You agree not to use any device, software, or routine to
                      interfere with the proper functioning of the Sites. In
                      using the Sites, you may not: <br />
                      <br />- Transmit any unlawful, threatening, abusive,
                      libelous, defamatory, obscene, vulgar, pornographic,
                      profane, or indecent information of any kind, including
                      images and language; <br />- Transmit or solicit any
                      information, software, or other material that violates or
                      infringes upon the rights of others, including material
                      that is an invasion of privacy or publicity rights; is
                      protected by copyright, trademark, or other proprietary
                      rights; or is a derivative work with respect thereto,
                      without first obtaining permission from the owner or right
                      holder;
                      <br />- Delete or alter any copyright, trademark, or other
                      proprietary rights notices from copies of materials from
                      the Sites;
                      <br />- Modify copies of any materials from the Sites;{' '}
                      <br />- Use any illustrations, photographs, video or audio
                      sequences, or any graphics separately from the
                      accompanying text; or Sites; or <br />- Transmit any
                      information, software, or other material that contains a
                      virus or other harmful component. <br />
                      <br />
                      Moreover, you agree to not use the Service to:
                      <br />
                      <br />- Violate any applicable local, state, national or
                      international law, or any regulations having the force of
                      law;
                      <br />- Impersonate any person or entity, or falsely state
                      or otherwise misrepresent your affiliation with a person
                      or entity; <br />- Solicit personal information from
                      anyone under the age of 18;
                      <br />- Harvest or collect email addresses or other
                      contact information of other users from the Service by
                      electronic or other means for the purposes of sending
                      unsolicited emails or other unsolicited communications;
                      <br />- Advertise or offer to sell or buy any goods or
                      services for any business purpose that is not specifically
                      authorized;
                      <br />- Further or promote any criminal activity or
                      enterprise or provide instructional information about
                      illegal activities; or
                      <br />- Obtain or attempt to access or otherwise obtain
                      any materials or information through any means not
                      intentionally made available or provided for through the
                      Service.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>
                          Service Content, Software &amp; Trademarks
                        </strong>
                      </span>
                      <br />‍<br />
                      You acknowledge and agree that the Service may contain
                      content, features, and functionality (including but not
                      limited to all information, Software (as defined below),
                      text, displays, images, video, and audio, and the design,
                      selection, and arrangement thereof (“Service Content”)
                      that are owned by Versus, its licensors, or other
                      providers of such material and protected by copyright,
                      patent, trademark, trade secret or other proprietary
                      rights and laws. Except as expressly authorized by Versus,
                      you agree not to modify, copy, frame, scrape, rent, lease,
                      loan, sell, publish, transfer, distribute or create
                      derivative works based on the Service or the Service
                      Content, in whole or in part, including by making the
                      Sites available on a network where it is capable of being
                      accessed by more than one device at a time, except that
                      the foregoing does not apply to your own User Content (as
                      defined below) that you legally upload to the Service.
                      Also, your computer may temporarily store copies of such
                      materials in RAM incidental to your accessing and viewing
                      those materials. You may store files that are
                      automatically cached by your Web browser for display
                      enhancement purposes and print or download one copy of a
                      reasonable number of pages of the Sites for your own
                      personal, non-commercial use and not for further
                      reproduction, publication, or distribution. If we provide
                      social media features with certain content, you may take
                      such actions as are enabled by such features. <br />
                      <br />
                      In connection with your use of the Service, you will not
                      engage in or use any data mining, robots, scraping or
                      similar data gathering or extraction methods. If you are
                      blocked by Versus from accessing the Service (including
                      blocking your IP address), you agree not to implement any
                      measures to circumvent such blocking (e.g., by masking
                      your IP address or using a proxy IP address). Any use of
                      the Service or the Service Content other than as
                      specifically authorized herein is strictly prohibited. The
                      technology and software underlying the Service or
                      distributed in connection therewith are the property of
                      Versus, our affiliates and our partners (the “Software”).
                      You agree not to copy, modify, create a derivative work
                      of, reverse engineer, reverse assemble or otherwise
                      attempt to discover any source code, sell, assign,
                      sublicense, or otherwise transfer any right in the
                      Software. Any rights not expressly granted herein are
                      reserved by Versus.The Versus name and logos are
                      trademarks and service marks of Versus (collectively the
                      “Versus Trademarks”). Other products, service names and
                      logos used and displayed via the Service may be trademarks
                      or service marks of their respective owners who may or may
                      not endorse or be affiliated with or connected to Versus.{' '}
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Reservation of Rights</strong>
                      </span>
                      <br />‍<br />
                      Nothing in these Terms &amp; Conditions or the Service
                      should be construed as granting, by implication, estoppel,
                      or otherwise, any license or right to use any of Versus
                      Trademarks displayed on the Service, without our prior
                      written permission in each instance, or in the Sites,
                      Service, Service Content, or any other rights thereto
                      other than to use the Service and access to the Site in
                      accordance with the license granted and subject to all
                      terms, conditions, and restrictions under these Terms
                      &amp; Conditions. Versus and its licensors and service
                      providers reserve and shall retain their entire right,
                      title, and interest in and to the Sites and Services,
                      including all copyrights, trademarks, and other
                      intellectual property rights therein or relating thereto,
                      except as expressly granted to you in these Terms &amp;
                      Conditions. All goodwill generated from the use of Versus
                      Trademarks will inure to our exclusive benefit.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Third Party Material</strong>
                      </span>
                      <br />‍<br />
                      Under no circumstances will Versus be liable in any way
                      for any content or materials of any third parties
                      (including users), including, but not limited to, for any
                      errors or omissions in any content, or for any loss or
                      damage of any kind incurred as a result of the use of any
                      such content. You acknowledge that Versus does not
                      pre-screen content, but that Versus and its designees will
                      have the right (but not the obligation) in their sole
                      discretion to refuse or remove any content that is
                      available via the Service. Without limiting the foregoing,
                      Versus and its designees will have the right to remove any
                      content that violates these Terms &amp; Conditions or is
                      deemed by Versus, in its sole discretion, to be otherwise
                      objectionable. You agree that you must evaluate, and bear
                      all risks associated with, the use of any content,
                      including any reliance on the accuracy, completeness, or
                      usefulness of such content.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>User Content</strong>
                      </span>
                      <br />‍<br />
                      With respect to the content or other materials you upload
                      through the Service or share with other users or
                      recipients (collectively, “User Content”), you represent
                      and warrant that you own all right, title and interest in
                      and to such User Content, including, without limitation,
                      all copyrights and rights of publicity contained therein,
                      and that you have all required rights to post or transmit
                      such content or other materials without violation of any
                      third-party rights. By uploading any User Content you
                      hereby grant and will grant Versus, its affiliated
                      companies and partners (including but not limited to
                      Versus instructors, practitioners and other third parties
                      providing instructional information through the Services,
                      collectively “partners”) a nonexclusive, worldwide,
                      royalty free, fully paid up, transferable, sublicensable,
                      perpetual, irrevocable license to copy, display, upload,
                      adapt, perform, publish, distribute (through multiple
                      tiers of distribution and partnerships), store, modify and
                      otherwise use and fully exploit your User Content in any
                      and all media, form, medium, technology or distribution
                      methods now known or later developed and for any and all
                      purposes (commercial or otherwise).
                      <br />
                      <br />
                      You acknowledge and agree that any questions, comments,
                      suggestions, ideas, feedback or other information relevant
                      to the Service (“Submissions”), provided by you to Versus,
                      its affiliated companies or partners are non-confidential
                      and Versus, its affiliated companies and partners will be
                      entitled to the unrestricted use and dissemination of
                      these Submissions for any purpose, commercial or
                      otherwise, without acknowledgment or compensation to you.
                      <br />
                      <br />
                      You acknowledge and agree that Versus may preserve content
                      and may also disclose content if required to do so by law
                      or in the good faith belief that such preservation or
                      disclosure is reasonably necessary to: (a) comply with
                      legal process, applicable laws or government requests; (b)
                      enforce these Terms &amp; Conditions; (c) respond to
                      claims that any content violates the rights of third
                      parties; or (d) protect the rights, property, or personal
                      safety of Versus, its users and the public. You understand
                      that the technical processing and transmission of the
                      Service, including your content, may involve (a)
                      transmissions over various networks; and (b) changes to
                      conform and adapt to technical requirements of connecting
                      networks or devices.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Monitoring; Copyright Complaints </strong>
                      </span>
                      <br />‍<br />
                      You agree that we have the right, but not the obligation,
                      to monitor, suspend, terminate, edit, disclose, refuse to
                      post, or remove at any time, for any reason in our sole
                      discretion, any material, content, and/or activity
                      anywhere on the Sites, including, without limitation, by
                      suspending or terminating your access to or use of the
                      Sites. Notwithstanding this right, we do not and cannot
                      review all materials submitted to the Sites. If notified,
                      we will investigate an allegation that content transmitted
                      to us is in violation of these Terms &amp; Conditions and
                      determine whether to have the communication removed.
                      However, we assume no responsibility or liability arising
                      from or relating to any actions or content transmitted by
                      or between you or any third party within or outside of the
                      Sites, including, but not limited to, any error,
                      defamation, libel, slander, omission, falsehood,
                      obscenity, pornography, profanity, danger, or inaccuracy
                      contained therein.
                      <br />
                      <br />
                      <strong>
                        <em class="italic-text-9">
                          Digital Millennium Copyright Act <br />
                        </em>
                      </strong>
                      We may, in appropriate circumstances, terminate the access
                      of users who infringe or otherwise violate the rights of
                      others. While we reserve the right to terminate the access
                      of users who infringe or otherwise violate the rights of
                      others in our sole discretion, if you are a Repeat
                      Offender (as defined below), your access to the Sites will
                      be suspended or terminated. “
                      <strong>Repeat Offender</strong>” shall mean any user of
                      the Sites against whom we receive three (3) or more
                      Infringement Notifications (as defined below). <br />
                      <br />
                      If you believe that your work has been copied and is
                      accessible on the Sites in a way that constitutes
                      copyright infringement, you may notify us (each, an
                      “Infringement Notification”) by providing the following
                      information (as required by the Online Copyright
                      Infringement Liability Limitation Act of the Digital
                      Millennium Copyright Act (“DMCA”), 17 U.S.C. sec. 512):{' '}
                      <br />
                      <br />- A physical or electronic signature of the person
                      authorized to act on behalf of the owner of an exclusive
                      right that is allegedly infringed; <br />- Identification
                      of the copyrighted work claimed to have been infringed, or
                      if multiple copyrighted works at the Sites are covered by
                      a single notification, a representative list of such works
                      at the Sites; <br />- Identification of the material that
                      is claimed to be infringing or to be the subject of
                      infringing activity and that is to be removed or access to
                      which is to be disabled, and information reasonably
                      sufficient to permit us to locate the material; <br />-
                      Information reasonably sufficient to permit us to contact
                      you, such as an address, telephone number, and if
                      available, an e-mail address; <br />- A statement that you
                      have a good faith belief that use of the material in the
                      manner complained of is not authorized by you, your agent,
                      or the law; and <br />- A statement that the information
                      in the notification is accurate, and, under penalty of
                      perjury, that you are authorized to act on behalf of the
                      owner of an exclusive right that is allegedly infringed.{' '}
                      <br />
                      <br />
                      Please send the written communication to our copyright
                      agent by e-mail AND by U.S. Mail to: <br />
                      <br />‍
                      <strong>
                        Maven Arena Inc D.B.A. Versus
                        <br />
                        2183 Parkway Lake Drive Birmingham AL, 35244
                        <br />
                      </strong>
                      <a target="_blank" href="tel:+18003600273">
                        <span class="text-span-16">
                          <strong>+1 (800) 360-0273</strong>
                        </span>
                      </a>
                      <strong>
                        <br />
                        <a href="mailto:support@versus.co">support@versus.co</a>
                        <br />‍
                      </strong>
                      E-mail Subject: “DMCA Request”
                      <br />
                      <br />
                      If you fail to comply with all of the requirements of
                      Section 512(c)(3) of the DMCA, your Infringement Notice
                      may not be effective. Please be aware that if you
                      knowingly materially misrepresent that material or
                      activity on the Sites is infringing your copyright, you
                      may be held liable for damages (including costs and
                      attorneys&#x27; fees) under Section 512(f) of the DMCA.
                      <br />
                      <br />
                      <strong>
                        <em class="italic-text-10">
                          Counter Notification Procedures
                        </em>
                      </strong>
                      <br />
                      If you believe that material you posted on the Site was
                      removed or access to it was disabled by mistake or
                      misidentification, you may file a counter notification
                      with us (a &quot;Counter Notice&quot;) by submitting
                      written notification to our copyright agent designated
                      above/below. Pursuant to the DMCA, the Counter Notice must
                      include substantially the following:
                      <br />
                      <br />- Your physical or electronic signature.
                      <br />- An identification of the material that has been
                      removed or to which access has been disabled and the
                      location at which the material appeared before it was
                      removed or access disabled.
                      <br />- Adequate information by which we can contact you
                      (including your name, postal address, telephone number,
                      and, if available, email address).
                      <br />- A statement under penalty of perjury by you that
                      you have a good faith belief that the material identified
                      above was removed or disabled as a result of a mistake or
                      misidentification of the material to be removed or
                      disabled.
                      <br />- A statement that you will consent to the
                      jurisdiction of the Federal District Court for the
                      judicial district in which your address is located (or if
                      you reside outside the United States for any judicial
                      district in which the Website may be found) and that you
                      will accept service from the person (or an agent of that
                      person) who provided the Website with the complaint at
                      issue
                      <br />
                      <br />
                      The DMCA allows us to restore the removed content if the
                      party filing the original Infringement Notice does not
                      file a court action against you within ten business days
                      of receiving the copy of your Counter Notice. <br />
                      <br />
                      Please be aware that if you knowingly materially
                      misrepresent that material or activity on the Site was
                      removed or disabled by mistake or misidentification, you
                      may be held liable for damages (including costs and
                      attorneys&#x27; fees) under Section 512(f) of the DMCA.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Third Party Websites</strong>
                      </span>
                      <br />‍<br />
                      The Service may provide, or third parties may provide,
                      links or other access to other sites and resources on the
                      Internet. Versus has no control over such sites and
                      resources, and Versus is not responsible for and does not
                      endorse such sites and resources. You further acknowledge
                      and agree that Versus will not be responsible or liable,
                      directly or indirectly, for any damage or loss caused or
                      alleged to be caused by or in connection with use of or
                      reliance on any content, events, goods or services
                      available on or through any such third party site or
                      resource. Any dealings you have with third parties found
                      while using the Service are between you and the third
                      party, and you agree that Versus is not liable for any
                      loss or claim that you may have against any such third
                      party.
                      <br />
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Updates</strong>
                      </span>
                      <br />‍<br />
                      We will not be liable if, for any reason, all or part of
                      the Sites are ever unavailable. We reserve the right at
                      any time and from time to time to modify or discontinue,
                      temporarily or permanently, the Sites, including, without
                      limitation, the products and services made available on
                      the Sites, (or any part thereof) with or without notice.
                      We undertake no obligation to update, amend, or clarify
                      information on the Sites, including without limitation,
                      pricing information, except as required by law. No
                      specified update or refresh date applied on the Sites
                      should be taken to indicate that all information on the
                      Sites has been modified or updated. Please remember when
                      reviewing information on the Sites that such information
                      may not represent the complete information available on a
                      subject. In addition, subsequent events or changes in
                      circumstances may cause existing information on the Sites
                      to become inaccurate or incomplete. <br />
                      <br />
                      On occasion, information on the Sites may contain errors.
                      We reserve the right to, at any time without prior notice,
                      correct any errors, inaccuracies, or omissions, and to
                      change or update information or cancel orders if any
                      information on the Sites is inaccurate (including after
                      you have submitted your order). <br />
                      <br />
                      Based on your Mobile Device settings, when your Mobile
                      Device is connected to the internet either: the Mobile
                      Services will automatically download and install all
                      available updates; or you may receive notice of or be
                      prompted to download and install available updates.
                      <br />
                      <br />
                      You shall promptly download and install all updates and
                      acknowledge and agree that the Mobile Service or portions
                      thereof may not properly operate should you fail to do so.
                      You further agree that all updates will be deemed part of
                      the Mobile Service and be subject to all terms and
                      conditions of this Agreement.
                      <br />
                      <br />
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Disclaimer of Warranties</strong>
                      </span>
                      <br />‍<br />
                      YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE
                      SITES, AND ANY PRODUCTS OR SERVICES MADE AVAILABLE ON THE
                      SITES, IS AT YOUR SOLE RISK. THE SITES, INCLUDING THE
                      PRODUCTS AND SERVICES MADE AVAILABLE ON AND THROUGH THE
                      SITES, IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                      WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER
                      EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED
                      TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                      A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. <br />
                      <br />
                      VERSUS MAKES NO WARRANTY (I) THAT THE SITES, INCLUDING,
                      THE SERVICES MADE AVAILABLE ON AND THROUGH THE SITES, WILL
                      MEET YOUR REQUIREMENTS OR WILL BE UNINTERRUPTED, TIMELY,
                      UNFAILINGLY SECURE, OR ERROR-FREE; (II) THAT THE RESULTS
                      THAT MAY BE OBTAINED FROM THE USE OF THE SITES OR THE
                      SERVICES WILL BE ACCURATE OR RELIABLE; (III) THAT THE
                      QUALITY OF ANY INFORMATION OR MATERIALS PURCHASED OR
                      OBTAINED BY YOU THROUGH THE SITES OR SUCH SERVICES WILL
                      MEET YOUR EXPECTATIONS; AND (IV) THAT ANY ERRORS ON THE
                      SITES OR THE SERVICES WILL BE CORRECTED. YOU FURTHER
                      ACKNOWLEDGE AND AGREE THAT ANY MATERIAL OBTAINED THROUGH
                      THE USE OF THE SITES OR THE SERVICE IS DONE AT YOUR OWN
                      DISCRETION AND RISK AND THAT YOU WILL BE SOLELY
                      RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR LOSS OF DATA
                      THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Limitation of Liability</strong>
                      </span>
                      <br />‍<br />
                      TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE
                      LAW, IN NO EVENT SHALL WE AND OUR SUCCESSORS, ASSIGNS,
                      AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT
                      PROVIDERS, MEMBERS, EMPLOYEES, REPRESENTATIVES, AGENTS,
                      OFFICERS, DIRECTORS, AND MANAGERS BE LIABLE FOR ANY
                      INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL,
                      CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES,
                      INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
                      PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES
                      (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                      DAMAGES OR SUCH DAMAGES ARE REASONABLE FORESEEABLE),
                      RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE
                      SITES OR ANY SERVICES, SOFTWARE OR INFORMATION MADE
                      AVAILABLE ON THE SITES; (II) UNAUTHORIZED ACCESS TO OR
                      ALTERATION OF YOUR TRANSMISSIONS OR DATA; (III) THE
                      IMPROPER AUTHORIZATION FOR THE SERVICES OFFERED ON THE
                      SITES BY SOMEONE CLAIMING SUCH AUTHORITY; OR (IV)
                      STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SITES. IN
                      NO EVENT SHALL THE COLLECTIVE LIABILITY OF US AND OUR
                      SUCCESSORS, ASSIGNS, AFFILIATES, LICENSORS, SERVICE
                      PROVIDERS, CONTENT PROVIDERS, MEMBERS, EMPLOYEES,
                      REPRESENTATIVES, AGENTS, OFFICERS, DIRECTORS, AND MANAGERS
                      TO OR THROUGH YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF
                      ACTION WHETHER IN CONTRACT, TORT OR OTHERWISE, EXCEED THE
                      AMOUNT PAID BY YOU TO US. <br />
                      <br />
                      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
                      WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                      INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF
                      THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO
                      YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE
                      SERVICE OR WITH THESE TERMS &amp; CONDITIONS, YOUR SOLE
                      AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Indemnification</strong>
                      </span>
                      <br />‍<br />
                      You agree to indemnify, defend, and hold harmless us, our
                      subsidiaries and affiliates, and our and their members,
                      managers, officers, directors, employees, agents,
                      licensors, retailers, and suppliers from and against all
                      claims (whether or not such claims are merely alleged or
                      otherwise), losses, expenses, damages, and costs,
                      including reasonable attorneys’ fees, costs, and arising
                      out of or in connection with: (I) your violation of these
                      Terms &amp; Conditions or other documents incorporated
                      herein by reference; (II) your use of the Sites, and/or
                      any products or services made available on the Sites;
                      (III) your violation of another person’s rights; (IV) your
                      dispute with another user; (V) the unauthorized access to
                      any protected area of the Sites; (VI) your violation of
                      applicable law; or (VII) any claim related to your User
                      Content, including a claim that your User Content caused
                      damage to another person. This indemnification obligation
                      will continue after you stop using the Sites and/or our
                      products or services made available on the Sites. We
                      reserve the right, at our own expense, to assume the
                      exclusive defense and control of any matter otherwise
                      subject to indemnification by you at your expense, and you
                      shall not in any event settle or otherwise dispose of any
                      matter without our prior written consent. <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Accessibility </strong>
                      </span>
                      <br />‍<br />
                      If you have difficulty using or accessing any element of
                      the Sites or if you have any feedback regarding
                      accessibility of the Sites, please feel free to contact us
                      at support@versus.co.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Force Majeure</strong>
                      </span>
                      <br />‍<br />
                      We will not be liable or responsible to you, nor be deemed
                      to have defaulted or breached these Terms &amp;
                      Conditions, for any failure or delay in our performance
                      under these Terms &amp; Conditions when and to the extent
                      such failure or delay is caused by or results from acts or
                      circumstances beyond our reasonable control, including,
                      without limitation, acts of God, flood, fire, earthquake,
                      explosion, governmental actions, war, invasion or
                      hostilities (whether war is declared or not), terrorist
                      threats or acts, riot or other civil unrest, national
                      emergency, revolution, insurrection, pandemic, epidemic,
                      lockouts, strikes or other labor disputes (whether or not
                      relating to our workforce), restraints or delays affecting
                      carriers, inability to obtain or delay in obtaining
                      adequate or suitable supplies, breakdown of materials or
                      telecommunications, or power outage. <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Dispute Resolution</strong>
                      </span>
                      <br />‍<br />
                      Arbitration You and Versus agree that these Terms &amp;
                      Conditions affect interstate commerce and that the U.S.
                      Federal Arbitration Act governs the interpretation and
                      enforcement of these arbitration provisions. This Section
                      is intended to be interpreted broadly and governs any and
                      all disputes between us, including but not limited to
                      claims arising out of or relating to any aspect of the
                      relationship between us or the Terms &amp; Conditions or
                      the Service, whether based in contract, tort, statute,
                      fraud, misrepresentation or any other legal theory. <br />
                      ‍<br />
                      {/* <span class="text-span-4">
                        <strong>Force Majeure</strong>
                      </span>
                      <br />‍<br />
                      We will not be liable or responsible to you, nor be deemed
                      to have defaulted or breached these Terms &amp;
                      Conditions, for any failure or delay in our performance
                      under these Terms &amp; Conditions when and to the extent
                      such failure or delay is caused by or results from acts or
                      circumstances beyond our reasonable control, including,
                      without limitation, acts of God, flood, fire, earthquake,
                      explosion, governmental actions, war, invasion or
                      hostilities (whether war is declared or not), terrorist
                      threats or acts, riot or other civil unrest, national
                      emergency, revolution, insurrection, pandemic, epidemic,
                      lockouts, strikes or other labor disputes (whether or not
                      relating to our workforce), restraints or delays affecting
                      carriers, inability to obtain or delay in obtaining
                      adequate or suitable supplies, breakdown of materials or
                      telecommunications, or power outage. <br />
                      <br /> */}
                      The only disputes excluded from this broad prohibition are
                      the litigation of certain intellectual property and small
                      court claims. Notwithstanding the parties&#x27; decision
                      to resolve all disputes through arbitration, you or we may
                      bring enforcement actions, validity determinations or
                      claims arising from or relating to theft, piracy or
                      unauthorized use of intellectual property in state or
                      federal court with jurisdiction or in the U.S. Patent and
                      Trademark Office to protect intellectual property rights
                      (&quot;intellectual property rights&quot; means patents,
                      copyrights, moral rights, trademarks, and trade secrets,
                      but not privacy or publicity rights). You or we may also
                      seek relief in a small claims court for disputes or claims
                      within the scope of that court&#x27;s jurisdiction to the
                      extent such claims do not seek equitable relief.
                      <br />
                      <br />
                      Arbitration will be held in Jefferson County, Alabama. The
                      expenses of the arbitration charged by the arbitrator
                      shall be borne by the prevailing party or otherwise as
                      appropriately allocated between the parties to the
                      arbitration by the arbitrator in his or her discretion.
                      However, in every other regard, each party shall pay for
                      and bear its own costs and legal fees, costs, and
                      expenses. The arbitration shall be completed within one
                      hundred twenty (120) days of either giving notice or
                      filing a demand to arbitrate with the American Arbitration
                      Association (whichever shall first occur).
                      <br />
                      <br />
                      The arbitrator’s award shall be binding and may be entered
                      as a judgment in any court of competent jurisdiction. The
                      testimony, evidence, ruling, and all documentation
                      regarding any arbitration shall be considered confidential
                      information. Neither party may use, disclose, or divulge
                      any such information unless otherwise required by law.
                      <br />
                      <br />
                      <strong>
                        <em class="italic-text-11">
                          Class Action Waiver
                          <br />
                        </em>
                      </strong>
                      To the fullest extent permitted by applicable law, no
                      arbitration under these Terms &amp; Conditions shall be
                      joined to an arbitration involving any other party subject
                      to these Terms &amp; Conditions, whether through class
                      arbitration proceedings or otherwise. You agree to an
                      arbitration on an individual basis. In any dispute,
                      NEITHER YOU NOR WE WILL BE ENTITLED TO JOIN OR CONSOLIDATE
                      CLAIMS BY OR AGAINST OTHER USERS IN COURT OR IN
                      ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A
                      CLASS REPRESENTATIVE, CLASS MEMBER, OR IN A PRIVATE
                      ATTORNEY GENERAL CAPACITY. The arbitral tribunal may not
                      consolidate more than one (1) person’s claims and may not
                      otherwise preside over any form of a representative or
                      class proceeding. The arbitral tribunal has no power to
                      consider the enforceability of this class arbitration
                      waiver and any challenge to the class arbitration waiver
                      may only be raised in a court of competent jurisdiction.{' '}
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Termination</strong>
                      </span>
                      <br />‍<br />
                      You agree that Versus, in its sole discretion, may suspend
                      or terminate your account (or any part thereof) or use of
                      the Service and remove and discard any content within the
                      Service, for any reason, including, without limitation,
                      for lack of use or if Versus believes that you have
                      violated or acted inconsistently with the letter or spirit
                      of these Terms &amp; Conditions. Any suspected fraudulent,
                      abusive or illegal activity that may be grounds for
                      termination of your use of Service, may be referred to
                      appropriate law enforcement authorities. Versus may also
                      in its sole discretion and at any time discontinue
                      providing the Service, or any part thereof, with or
                      without notice. You agree that any termination of your
                      access to the Service under any provision of this Terms
                      &amp; Conditions may be affected without prior notice, and
                      acknowledge and agree that Versus may immediately
                      deactivate or delete your account and all related
                      information and files in your account and/or bar any
                      further access to such files or the Service. Further, you
                      agree that Versus will not be liable to you or any third
                      party for any termination of your access to the
                      Service.Upon termination, all rights granted to you under
                      these Terms &amp; Conditions will also terminate; and you
                      must cease all use of the Sites and delete all copies of
                      the Mobile Service from your Mobile Device and account.
                      Termination will not limit any of Versus’ rights or
                      remedies at law or in equity.
                      <br />
                      <br />
                      <span class="text-span-4">
                        <strong>Miscellaneous</strong>
                      </span>
                      <br />‍<br />
                      These Terms &amp; Conditions constitute the entire
                      agreement between you and Versus and govern your use of
                      the Service, superseding any prior agreements between you
                      and Versus with respect to the Service. You also may be
                      subject to additional terms and conditions that may apply
                      when you use affiliate or third-party services,
                      third-party content or third-party software. These Terms
                      &amp; Conditions will be governed by the laws of the State
                      of Alabama without regard to its conflict of law
                      provisions. With respect to any disputes or claims not
                      subject to arbitration, as set forth above, you and Versus
                      agree to submit to the personal and exclusive jurisdiction
                      of the state and federal courts located within Jefferson
                      County, Alabama. The failure of Versus to exercise or
                      enforce any right or provision of these Terms &amp;
                      Conditions will not constitute a waiver of such right or
                      provision. If any provision of these Terms &amp;
                      Conditions is found by a court of competent jurisdiction
                      to be invalid, the parties nevertheless agree that the
                      court should endeavor to give effect to the parties’
                      intentions as reflected in the provision, and the other
                      provisions of these Terms &amp; Conditions remain in full
                      force and effect. You agree that regardless of any statute
                      or law to the contrary, any claim or cause of action
                      arising out of or related to use of the Service or these
                      Terms &amp; Conditions must be filed within one (1) year
                      after such claim or cause of action arose or be forever
                      barred. A printed version of this agreement and of any
                      notice given in electronic form will be admissible in
                      judicial or administrative proceedings based upon or
                      relating to this agreement to the same extent and subject
                      to the same conditions as other business documents and
                      records originally generated and maintained in printed
                      form. You may not assign these Terms &amp; Conditions
                      without the prior written consent of Versus, but Versus
                      may assign or transfer this Terms &amp; Conditions, in
                      whole or in part, without restriction. The section titles
                      in these Terms &amp; Conditions are for convenience only
                      and have no legal or contractual effect. Notices to you
                      may be made via either email or regular mail. Under no
                      circumstances shall Versus be held liable for any delay or
                      failure in performance resulting directly or indirectly
                      from an event beyond its reasonable control. The Service
                      may also provide notices to you of changes to these Terms
                      &amp; Conditions or other matters by displaying notices or
                      links to notices generally on the Service. These Terms
                      &amp; Conditions, together with our Privacy Policy,
                      constitute the entire agreement between the parties
                      pertaining to the subject matter hereof and supersede in
                      their entirety any and all written or oral agreements
                      previously existing between the parties with respect to
                      such subject matter.
                      <br />
                      <br />
                      <br />
                      <strong>
                        Questions
                        <br />‍
                      </strong>
                      If you have any questions or comments regarding these
                      Terms &amp; Conditions, our Privacy Policy, or the Sites,
                      please feel free to contact us by e-mail at{' '}
                      <a href="mailto:support@versus.co">support@versus.co</a>.
                      ‍
                    </p>
                  </div>
                </div>
                <div data-w-tab="Tab 2" class="w-tab-pane">
                  <div class="div-block-87"></div>
                </div>
                <div data-w-tab="Tab 3" class="w-tab-pane">
                  <div class="div-block-87"></div>
                </div>
                <div data-w-tab="Tab 4" class="w-tab-pane"></div>
                <div data-w-tab="Tab 5" class="w-tab-pane"></div>
                <div data-w-tab="Tab 6" class="w-tab-pane"></div>
                <div data-w-tab="Tab 7" class="w-tab-pane"></div>
                <div data-w-tab="Tab 8" class="w-tab-pane"></div>
                <div data-w-tab="Tab 9" class="w-tab-pane"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
