import React, { useEffect, useState, useRef } from 'react';
import Papa from 'papaparse';
import { usePartner } from 'src/context/PartnerContext';
import { useNavigate } from 'react-router-dom';
import { downloadFile, csvFileToArray } from 'src/helpers/helpers';
import './PortalPage.scss';
import useHasMounted from '@hooks/useHasMounted';

const instructions = [
  {
    id: 1,
    image: 'Download.svg',
    label: 'Download the CSV Template.',
  },
  {
    id: 2,
    image: 'Fillout.svg',
    label: "Fill out your members' information.",
  },
  {
    id: 3,
    image: 'Upload.svg',
    label: 'Upload your completed CSV file.',
  },
  {
    id: 4,
    image: 'Email.svg',
    label: 'Users emailed their free premium account details.',
    //label: "We'll send them a link to create their free premium account.",
  },
];

function Portal() {
  const partnerData = usePartner();
  const navigate = useNavigate();
  const [submitActive, setSubmitActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileErrors, setFileErrors] = useState(null);
  const [fileErrorArray, setFileErrorArray] = useState(null);
  const [fileData, setFileData] = useState(null);
  const uploadRef = useRef();

  const buttons = [
    {
      id: 0,
      image: 'DownloadIcon.svg',
      label: 'Download CSV Template',
      callback: () => handleDownloadCSV(),
    },
    {
      id: 1,
      image: 'UploadIcon.svg',
      label: 'Upload CSV File',
      callback: () => handleUploadCSV(),
    },
  ];

  const handleDownloadCSV = () => {
    downloadFile(
      '/files/VS_Member_Import_CSV.csv',
      `VS_Member_Import${
        partnerData?.partner ? `_for_${partnerData?.partner}` : ''
      }`
    );
  };

  //File Upload
  const onFileChange = (event) => {
    setSelectedFile(null);
    setFileErrors(null);
    setFileErrorArray(null);
    setFileData(null);
    setSelectedFile({ selectedFile: event.target.files[0] });
    Papa.parse(event.target.files[0], {
      complete: function (results) {
        const data = [...results.data].filter((d) => d.length === 3);
        setFileData(data);
        const errors = [];
        const rData = data.filter((r) => r.length > 1);
        rData.forEach((r, index) => {
          if (r[2].length === 0) {
            errors.push([...r, `${index + 1}`]);
          }
        });
        if (errors.length > 0) setFileErrorArray(errors);

        const fErrors = [];
        //setSubmitActive(false);
        if (rData.length - 1 > partnerData?.passCount) {
          const error = `Your file contains ${rData.length} users but you only have ${partnerData.passCount} slots.`;
          fErrors.push(error);
        }
        if (errors.length > 0) {
          const error = `Missing email address on ${errors.length} ${
            errors.length > 1 ? 'users' : 'user'
          }.`;
          fErrors.push(error);
          setFileErrors(fErrors);
        }
        setSubmitActive(false);
        if (errors.length === 0 && rData.length - 1 <= partnerData?.passCount) {
          setSubmitActive(true);
        }
      },
    });
  };

  const onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();
    const date = new Date();

    // Update the formData object
    formData.append(
      'myFile',
      selectedFile,
      `${partnerData?.partner}-${date.toDateString()}`
    );
  };

  const handleUploadCSV = () => {
    uploadRef?.current?.click();
  };

  const submitFile = () => {
    if (!submitActive) return;
    alert('submit');
  };

  useEffect(() => {
    if (!partnerData || partnerData?.type !== 'admin') {
      navigate(`/`, { replace: true });
    }
  }, [partnerData]);

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className='portal'>
      <div className='overlay' />
      <video
        playsInline
        autoPlay
        muted
        loop
        preload='metadata'
        width='100%'
        height='auto'
        // poster={background}
        id='bgvid'
        src='https://cdn.bfldr.com/B1ZZKPB4/as/hvhh7hh4s35rnw8vmhwbtccw/Website_Intro'
      />
      <div className='portal-container'>
        <header>
          <h1>Welcome to Versus</h1>
          <hr />
          {partnerData?.partner ? partnerData?.partner : ''}
        </header>
        <div className='content'>
          <h3>Let's get you set up!</h3>
          {partnerData?.passCount && (
            <h4>{partnerData?.passcount} passes available</h4>
          )}
          <div className='instructions'>
            {instructions.map((i) => (
              <div className='item'>
                <img src={`/icons/${i.image}`} alt={i.label} />
                <div>
                  Step {i.id}:<h5>{i.label}</h5>
                </div>
              </div>
            ))}
          </div>
          <div className='button-group'>
            {buttons.map((b) => (
              <div className='button' onClick={b.callback}>
                <img src={`/icons/${b.image}`} alt={b.label} /> {b.label}
              </div>
            ))}
          </div>
          {selectedFile ? (
            fileErrors ? (
              <div className='meta error'>
                {fileErrors?.map((f) => (
                  <div>{f}</div>
                ))}
                {fileErrorArray?.map((ff) => (
                  <div className='errorList'>
                    CSV Line: {ff[3]}, First Name: {ff[0]}, Last Name: {ff[1]}
                  </div>
                ))}
              </div>
            ) : (
              <div className='meta'>
                <img
                  src='/icons/close.svg'
                  onClick={() => {
                    setSelectedFile(null);
                    setFileData(null);
                    setSubmitActive(false);
                    setFileErrors(null);
                    setFileErrorArray(null);
                    uploadRef.current.value = '';
                  }}
                  alt='Remove File'
                />
                {selectedFile?.selectedFile?.name}
              </div>
            )
          ) : (
            <br></br>
          )}

          <div className='submit-button'>
            <div
              className={`button ${submitActive ? 'active' : ''}`}
              title={submitActive ? '' : 'Upload a valid CSV above'}
              onClick={submitFile}
            >
              Submit
            </div>
          </div>
          <input
            className='hidden'
            ref={uploadRef}
            type='file'
            onChange={onFileChange}
          />
        </div>

        {fileData && (
          <div className='user-table'>
            <h6>
              {fileData.length - 1} users of {partnerData?.passCount} passes
            </h6>
            <table>
              <tbody>
                {fileData.map((f, index) => (
                  <tr>
                    <td>{index === 0 ? '' : index}</td>
                    {f.map((ff) => (
                      <td>{ff}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default Portal;
