import React from 'react';
import { mavenData } from 'src/data/mavenData';
import Courses from '../LandingPage/Courses';

function KeepAsking({ data }) {
  const mavens = [...new Set(data?.map((d) => d.mavenSlug))];

  const mavenAskingData = mavens?.map((m) => ({
    id: m,
    questions: data.filter((d) => d.mavenSlug === m),
    restingImage: '/HomeHero.jpg',
    mavenData: mavenData.find((d) => d.slug === m),
  }));
  return (
    <div className="keep-asking">
      <Courses
        title="Keep Asking"
        type="Conversation"
        tabData={[]}
        data={mavenAskingData}
        showMoreCard={true}
      />
    </div>
  );
}

export default KeepAsking;
