import React, { useState } from "react";
import {  XIcon } from "@heroicons/react/outline";
import { CreditCardIcon} from "@heroicons/react/solid";
 
import { PaymentMethodStyles } from "./PaymentMethod.style";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CountryDropdown } from "react-country-region-selector";
 

const PaymentMethod = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openCard1, setOpenCard1] = useState(false);
  const [openCard2, setOpenCard2] = useState(false);
  const [openCard3, setOpenCard3] = useState(false);
  const [country, selectCountry] = useState("");
  const [change, setChange] = useState(false);
  const onOpenModal = () => {
    setOpenModal(true);
    setOpenCard1(true);
  };
  const onChange = () => {
    setChange(true);
  };
  const onOpenEditCard = () => {
    setOpenCard1(false);
    setOpenCard2(true);
  };
  const onOpenConfrimCard = () => {
    setOpenCard2(false);
    setOpenCard3(true);
  };
  const onConfirmCard = () => {
    setOpenCard3(false);
    setOpenModal(false);
    setChange(false);
  };
  const onClose = () => {
    setOpenModal(false);
    setOpenCard1(false);
    setOpenCard2(false);
    setOpenCard3(false);
    setChange(false);
  };
  const stripePromise = loadStripe(
    "pk_live_51KnTnRGZM0o83MkVJwNpD3lbWm1418Vf3VbMIeoln9xpwV71OQv4oIMklNkUhzh4ScJVSVSriAWENwYTWm9RUv3i00JL15cizI"
  );
  return (
    <PaymentMethodStyles>
      <div className="settingsRow">
        <h4>Payment method</h4>
        <div className="paymentMethodRow">
          <div className="paymentMethodCardWrapper">
            <img src="/card_details.png" alt="payment card" className="hover:shadow-lg" />
          </div>
          <div
            className="changePaymentMethod hover:shadow-2xl hover:text-black rounded-lg card-preview"
            onClick={onOpenModal}
          >
            <CreditCardIcon className="opacity-40  " color="#000"/>
            <span>Change Payment Method</span>
          </div>
        </div>
        <p className="mt-6 text-sm">
          Your card was successfully charged on the 10/13/21
        </p>
        {openModal && (
          <div className="fixed  w-screen h-screen bg-modal z-50 top-0 left-0">
            <div className="w-full h-full relative">
              <div
                className={
                  "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[448px] rounded-md shadow  min-h-[500px]  " +
                  (openCard1 && "bg-[#f5f5f5] ") +
                  (openCard2 && " bg-[#f5f5f5] ") +
                  (openCard3 && " bg-modal-card  w-[640px]")
                  //"bg-modal-card" :
                }
              >
                <div className="relative px-14 py-12">
                  <button
                    onClick={onClose}
                    className=" absolute top-7 right-7 z-50 text-base font-bold"
                  >
                    <XIcon
                      color={openCard3 ? "#fff" : "#000"}
                      width={20}
                      height={20}
                    />
                  </button>
                  {openCard1 && (
                    <div>
                      <p className="font-bold text-2xl pb-2">
                        Change Payment Method
                      </p>
                      <p className="text-sm text-[#535353]">Change or update your payment details</p>
                      <button
                        onClick={onOpenEditCard}
                        className={
                          "bg-linder-btn font-semibold text-black text-base w-full py-4 text-center mt-16 "
                        }
                      >
                        Update Card Details
                      </button>
                    </div>
                  )}
                  {openCard2 && (
                    <div>
                      <p className="font-bold text-2xl mb-12">
                        Update card details
                      </p>
                      <p className="text-[#888888] font-bold mb-2">
                        Card information
                      </p>
                      <Elements stripe={stripePromise}>
                        <div className="shadow-lg rounded-lg mb-8">
                          <div className="grid grid-cols-[1fr,120px] gap-2 rounded-t-lg py-3 px-3 border bg-white">
                            <CardNumberElement
                              onChange={onChange}
                              className="text-base"
                            />
                            <img src="/Cards.png" alt="card" />
                          </div>
                          <div className="rounded-b-lg border-b border-l border-r grid grid-cols-2 px-3 bg-white">
                            <CardExpiryElement
                              onChange={onChange}
                              className="border-r py-3"
                            />
                            <CardCvcElement
                              onChange={onChange}
                              className="py-3 pl-3"
                            />
                          </div>
                        </div>

                        <p className="text-[#888888] font-bold mb-2">
                          Name on card
                        </p>
                        <input
                          onChange={onChange}
                          className="w-full rounded-lg shadow-lg mb-8 py-2 px-3 focus:outline-none"
                        />
                        <p className="text-[#888888] font-bold mb-2 ">
                          Country or region
                        </p>
                        <div className="shadow-lg rounded-lg overflow-hidden">
                          <CountryDropdown
                            value={country}
                            classes="w-full border-0 focus:outline-none"
                            onChange={(val) => selectCountry(val)}
                          />
                          <input
                            placeholder="ZIP"
                            onChange={onChange}
                            className="w-full border-t  py-2 px-3 focus:outline-none"
                          />
                        </div>
                      </Elements>
                      <button
                        onClick={onOpenConfrimCard}
                        className={
                          " font-semibold text-black text-base w-full py-4 text-center mt-16 " +
                          (change ? " bg-linder-btn" : " border rounded-lg")
                        }
                      >
                        Update Card Details
                      </button>
                    </div>
                  )}

                  {openCard3 && (
                    <div className="w-[365px] mx-auto">
                      <p className="text-white w-[306px] mb-8 font-bold text-xl text-center mx-auto">
                        Your card details have been successfully updated
                      </p>
                      <img src="/card_details.png" alt="card" />
                      <button
                        onClick={onConfirmCard}
                        className={
                          "bg-linder-btn font-semibold text-black text-base w-36 mx-auto block py-4 text-center mt-16 "
                        }
                      >
                        Done
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PaymentMethodStyles>
  );
};

export default PaymentMethod;