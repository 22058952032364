import '../Modals.scss';
import './VideoModal.scss';

function VideoModal({ video, title, subtitle, actions, setVideoModal }) {
  return (
    <div className="modals video-modal">
      <img
        className="close"
        src="/icons/close.svg"
        alt="Close Modal"
        onClick={() => setVideoModal(null)}
      />
      <div className="content">
        <div className="header">
          <div className="left">
            <h3>{title}</h3>
            {subtitle}
          </div>
          <div className="right">
            {actions.map((a) => (
              <div
                onClick={() => a.callback()}
                className={`action button ${a.className ? a.className : ''}`}
              >
                {a.image && <img src={a.image} alt={a.title} />}
                {a.title}
              </div>
            ))}
          </div>
        </div>

        <video
          playsInline
          autoPlay
          controls
          loop
          preload="metadata"
          width="100%"
          height="auto"
          id="bgvid"
          src={video}
        />
      </div>
    </div>
  );
}

export default VideoModal;
