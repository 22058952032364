export const REVENUECAT_STRIPE_APP_USER_ID = process.env
  .REACT_APP_REVENUECAT_STRIPE_APP_USER_ID as string;

export const REVENUE_CAT_API_KEY = process.env
  .REACT_APP_REVENUECAT_API_KEY as string;

export const REVENUECAT_SECRET_KEY = process.env
  .REACT_APP_REVENUECAT_SECRET_KEY as string;

export const WEBFLOW_API_KEY = process.env.WEBFLOW_API_KEY as string;
