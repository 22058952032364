import { auth, db } from '../config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';

export const getUserDataByUserID = async () => {
  const id = auth.currentUser?.uid;
  if (id) {
    const docRef = doc(db, 'profiles', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log('No such profile!');
    }
  }
};

export const getUserVideos = async (setVideosWatched, setVideosWatchedIds) => {
  const userData = await getUserDataByUserID();
  setVideosWatched(userData.videos);
  setVideosWatchedIds(userData.videos.map((v) => v.video).filter((vv) => vv));
};

export const updateWatchingVideosList = async (
  id,
  firebaseVideoId,
  currentPosition,
  firebaseMavenId
) => {
  if (!id) return;
  if (currentPosition < 5) return;

  const userData = await getUserDataByUserID();
  const userId = auth.currentUser?.uid;
  const videosData = userData?.videos || [];
  const selectedVideo = videosData.find((v) => v.video === firebaseVideoId);
  if (selectedVideo) {
    //do not update if currentPosition is less than saved position
    if (currentPosition < selectedVideo.currentPosition) return;

    const updatedVideos = videosData.map((v) =>
      v.video === firebaseVideoId
        ? {
            ...v,
            currentPosition,
            updatedTime: new Date(),
          }
        : v
    );

    await updateDoc(doc(db, 'profiles', userId), {
      ...userData,
      videos: updatedVideos,
    });
  } else {
    videosData.push({
      currentPosition: currentPosition,
      updatedTime: new Date(),
      video: firebaseVideoId,
      slug: id,
      scope: { start: 0, end: 0 },
      firebaseMavenId,
    });
    await updateDoc(doc(db, 'profiles', userId), {
      ...userData,
      videos: videosData,
    });
  }
};

export const useWatchingVideosList = (counts, slug) => {
  const [watchingVideos, setWatchingVideos] = useState({ videos: [] });
  const [watchingVideosData, setWatchingVideosData] = useState([]);
  //const currentUserData = auth.currentUser;
  const userId = auth.currentUser?.uid;

  const getWatchingList = async () => {
    const userData = await getUserDataByUserID();
    setWatchingVideos(userData.videos);
  };

  useEffect(() => {
    if (!userId) return;
    getWatchingList();
  }, [userId, slug]);

  useEffect(() => {
    if (watchingVideos?.videos?.length > 0) {
      getWatchingVideosData();
    }
  }, [watchingVideos]);

  const getWatchingVideosData = async () => {
    const videos = await Promise.all(
      watchingVideos?.videos
        ?.sort((a, b) => b?.updatedTime - a?.updatedTime)
        .slice(-counts)
        .map(async (wv) => {
          const videoData = await db.collection('videos').doc(wv.video).get();
          if (videoData.data()?.type === 'session') {
            const sessionData = await db
              .collection('sessions')
              .where(`videoID`, '==', wv.video)
              .get();
            const mentorID = Object.keys(
              sessionData.docs[0].data()?.mentors
            )[0];
            if (mentorID) {
              const mentorData = await db
                .collection('mentors')
                .doc(mentorID)
                .get();
              return {
                id: wv.video,
                ...wv,
                ...videoData.data(),
                session: {
                  id: sessionData.docs[0].id,
                  ...sessionData.docs[0].data(),
                },
                mentor: { id: mentorData.id, ...mentorData.data() },
              };
            }
            return {
              id: wv.video,
              ...wv,
              ...videoData.data(),
              session: {
                id: sessionData.docs[0].id,
                ...sessionData.docs[0].data(),
              },
            };
          } else {
            return {};
          }
        })
    );
    setWatchingVideosData(videos);
  };

  return watchingVideosData;
};

export const deleteWatchingVideo = async (id) => {
  if (!id) return;
  const userId = auth.currentUser?.uid;
  const userData = await getUserDataByUserID(id); //cn3JQEwDXiHwjYLFWX6P
  const videosData = userData?.videos || [];
  if (videosData.find((v) => v.video === id)) {
    const updatedVideos = videosData.filter((v) => v.video !== id);
    await db
      .collection('profiles')
      .doc(userId)
      .update({ ...userData, videos: updatedVideos }); //cn3JQEwDXiHwjYLFWX6P
  }
};

//Interactive Data
export const getAskedQuestions = async (setAskedQuestions) => {
  const userData = await getUserDataByUserID();
  setAskedQuestions(userData.asked);
};

export const updateAskedQuestions = async (storyFileMsg, data) => {
  const profile = await getUserDataByUserID();
  const asked = profile.asked;
  const userId = auth.currentUser?.uid;
  let newAsked;

  const questionObj = {
    SFId: storyFileMsg?.id || 'CustomMessage',
    SFMsg: storyFileMsg.text,
    mavenSlug: data?.slug,
  };

  if (asked) {
    const askedIds = asked.map((a) => a.SFId);
    const askedFromMaven = asked.filter(
      (a) => a.mavenSlug === questionObj.mavenSlug
    );
    const questionExists = askedFromMaven.find(
      (a) => a.SFMsg === questionObj.SFMsg
    );
    if (questionObj.SFId === 'CustomMessage') {
      if (questionExists) return;
      newAsked = [questionObj, ...asked];
    } else {
      if (!askedIds.includes(questionObj.SFId)) {
        newAsked = [questionObj, ...asked];
      } else {
        return;
      }
    }
  } else {
    newAsked = [questionObj];
  }

  await updateDoc(doc(db, 'profiles', userId), {
    ...profile,
    asked: newAsked,
  });
};
