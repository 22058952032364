import React from 'react';
import LatestPublications from '../LandingPage/LatestPublications';
import { useParams } from 'react-router-dom';
import './ArticleLanding.scss';
import SEO from '@components/Seo/Seo';
import useHasMounted from '@hooks/useHasMounted';

function ArticleLanding({ searchParams }) {
  const params = useParams();
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  const getTitle = () => {
    if (params?.slug === 'baseball') {
      return "Expert Baseball Training Advice from the World's Best | Versus";
    }
    if (params?.slug === 'softball') {
      return "Expert Softball Training Advice from the World's Best | Versus";
    }
    if (params?.slug === 'soccer') {
      return 'Expert Soccer Training Advice | Versus';
    }
    if (params?.slug === 'leadership') {
      return 'Develop Your Leadership Skills | Versus';
    }
    if (params?.slug === 'mindset') {
      return 'Develop a Winning Mindset On & Off the Field | Versus';
    }
    if (params?.slug === 'coaching') {
      return 'Improve Your Coaching Skills On & Off the Field | Versus';
    }
    if (params?.slug === 'routines') {
      return 'Develop Your Routine to Improve Your Skills | Versus';
    }
  };

  const getDescription = () => {
    if (params?.slug === 'baseball') {
      return 'Learn the fundamentals and training advice about Baseball in our library of articles and videos.';
    }
    if (params?.slug === 'softball') {
      return 'Learn the fundamentals and training advice about Softball in our library of articles and videos.';
    }
    if (params?.slug === 'soccer') {
      return 'Learn the fundamentals and training advice about Soccer in our library of articles and videos.';
    }
    if (params?.slug === 'leadership') {
      return 'Learn how to develop your leadership skills with expert advice in our library of articles and videos.';
    }
    if (params?.slug === 'mindset') {
      return 'Learn how to develop your mindset and mental toughness skills with expert advice in our library of articles and videos.';
    }
    if (params?.slug === 'coaching') {
      return 'Learn how to develop your coaching skills with expert advice in our library of articles and videos.';
    }
    if (params?.slug === 'routines') {
      return 'Learn how to develop training routines that help you develop your skills with expert advice in our library of articles and videos.';
    }
  };

  const getKeywords = () => {
    if (params?.slug === 'baseball') {
      return 'baseball training';
    }
    if (params?.slug === 'softball') {
      return 'softball training';
    }
    if (params?.slug === 'soccer') {
      return 'soccer training';
    }
    if (params?.slug === 'leadership') {
      return 'leadership skills';
    }
    if (params?.slug === 'mindset') {
      return 'mindset';
    }
    if (params?.slug === 'coaching') {
      return 'coaching';
    }
    if (params?.slug === 'routines') {
      return 'routines';
    }
  };

  return (
    <div className="article-landing fade-in">
      <SEO
        title={getTitle()}
        description={getDescription()}
        keywords={getKeywords()}
        type="article"
      />
      <LatestPublications
        title="Expert Advice"
        showFilters={true}
        selectedFilter={params.slug}
        searchParams={searchParams}
      />
    </div>
  );
}

export default ArticleLanding;
