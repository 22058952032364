import React, { useState } from 'react';
import '../Modals.scss';
import './BioModal.scss';

function BioModal({ image, body, title, setBioModal }) {
  const [fBody, setFBody] = useState('');

  const formattedBody = body.split('\\n');
  return (
    <div className='modals bio-modal'>
      <div className='header'>
        <div>About {title}</div>
        <img
          className='close'
          src='/icons/close.svg'
          alt='Close Modal'
          onClick={() => setBioModal(null)}
        />
      </div>
      <div className='content'>
        <div className='left'>
          <img src={image} alt='avatar' />
        </div>
        <div className='right'>
          <h3>{title}</h3>
          <div>
            {formattedBody.map((f) => (
              <p>{f}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BioModal;
