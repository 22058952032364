import { useEffect, useState } from 'react';
import Tabs from '@components/Tabs/Tabs';
import CardStack from 'src/layout/CardStack/CardStack';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { mavenData } from 'src/data/mavenData';
import ScrollToTop from 'src/ScrollToTop';
import Button from '../../components/Button/Button';

//const mavenData = mavenData.filter((m) => m.status === 'Active');

function Courses({
  title,
  tabData,
  setVideoModal,
  setToggledSubscribeModal,
  type,
  searchParams,
  setAlert,
  exploreMore,
  selectedTab,
  data,
  showMoreCard,
}) {
  const [selectedCourseTabId, setSelectedCourseTabId] = useState(
    tabData[0]?.id || 0
  );
  const navigate = useNavigate();

  const tData = mavenData.map((d, index) => ({
    ...d,
    id: index,
    cardTitle: d.title,
    link: '',
    image: d.rosterCardImage,
    course: d.course === 'TRUE' ? true : false,
    interactive: d.interactive === 'TRUE' ? true : false,
    interactiveOnly: d.interactiveOnly === 'TRUE' ? true : false,
    slug: decodeURI(d.slug),
    type: type,
  }));

  let transformedData = [...tData];
  if (type === 'Course')
    transformedData = tData.filter((t) => !t.interactiveOnly);

  const [filteredCourses, setFilteredCourses] = useState(transformedData);
  const [scrollObject, setScrollObject] = useState(null);

  const handleCourseFilterId = (id) => {
    setSelectedCourseTabId(id);
    if (id === 0) {
      setFilteredCourses(transformedData);
    } else {
      const filtered = transformedData.filter(
        (c) => c.sport === tabData[id]?.title
      );
      setFilteredCourses(filtered);
    }
  };

  useEffect(() => {
    const selected = tabData.find((t) => t.title === selectedTab);
    setSelectedCourseTabId(selected?.id || tabData[0]?.id || 0);
    if (selected?.id) handleCourseFilterId(selected?.id);
  }, [selectedTab]);

  return (
    <div className="courses-section">
      <ScrollToTop />
      <div className="title-with-tabs">
        <h4>{title ? title : 'Courses'}</h4>
        <Tabs
          data={tabData}
          selectedTabId={selectedCourseTabId}
          callback={handleCourseFilterId}
          noLink={true}
        />
        <div className="hArrows">
          <img
            onClick={() =>
              setScrollObject({ amount: Math.random(), direction: 'left' })
            }
            src="/icons/arrow-left.svg"
            alt="Scroll Left"
          />
          <img
            onClick={() =>
              setScrollObject({ amount: Math.random(), direction: 'right' })
            }
            src="/icons/arrow-right.svg"
            alt="Scroll Right"
          />
        </div>
      </div>

      <CardStack
        type={type}
        data={data || filteredCourses}
        isAsk={data ? true : false}
        setVideoModal={setVideoModal}
        setToggledSubscribeModal={setToggledSubscribeModal}
        scrollObject={scrollObject}
        searchParams={searchParams}
        setAlert={setAlert}
        showMoreCard={showMoreCard}
        mavenCount={mavenData?.length}
      />
      {exploreMore && (
        <Button
          className="secondary more-button"
          onClick={() => navigate(exploreMore)}
        >
          Explore All
        </Button>
      )}
    </div>
  );
}

export default Courses;
