import { useEffect, useState, useRef } from 'react';
import { mavenData } from 'src/data/mavenData';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getConfig,
  getConversationStarters,
  interact,
  startSession,
} from '../../api/storyFileApi';
import Topics from './Topics';
import './InteractivePage.scss';
import { useSubscription } from 'src/context/SubscriptionContext';
import ScrollToTop from 'src/ScrollToTop';
import SEO from '@components/Seo/Seo';
import { useSpeechRecognition } from 'react-speech-kit';
import { updateAskedQuestions } from '@hooks/useWatchingVideo';
import useHasMounted from '@hooks/useHasMounted';
import { isMobile, browserName } from 'react-device-detect';
import { Microphone, AudioRecording } from '@mozartec/capacitor-microphone';

const checkPermissions = async () => {
  try {
    const checkPermissionsResult = await Microphone.checkPermissions();
  } catch (error) {}
};

const requestPermissions = async () => {
  try {
    const requestPermissionsResult = await Microphone.requestPermissions();
  } catch (error) {}
};

function InteractivePage({
  setToggledSubscribeModal,
  setToggledDownloadModal,
  currentUser,
}) {
  //generate all storyFileConversationStarters for all mavens
  const params = useParams();
  const [searchParams] = useSearchParams();
  const showTopics = (boolean) => {
    setToggledTopics(boolean);
  };
  const [value, setValue] = useState(null);
  const [speakAnimated, setSpeakAnimated] = useState(false);
  const { listening, stop, listen } = useSpeechRecognition({
    onResult: (result) => {
      setValue(result);
    },
    onEnd: () => {
      if (!value) return;
      handleModal({ text: value });
    },
  });

  const videoRef = useRef();
  const [sfVideo, setSfVideo] = useState(null);
  const [message, setMessage] = useState('');
  const [config, setConfig] = useState(null);
  const [session, setSession] = useState(null);
  const [data, setData] = useState(null);
  const [toggledTopics, setToggledTopics] = useState(false);
  const [interaction, setInteraction] = useState(null);
  const [isTalk, setIsTalk] = useState(true);
  const [typeMessage, setTypeMessage] = useState('');
  const [isMuted, setIsMuted] = useState(true);

  const [conversationStarters, setConversationStarters] = useState(null);
  const subscription = useSubscription();

  const handleModal = async (msg) => {
    if (!currentUser) {
      setToggledSubscribeModal(true);
      setValue(null);
      return;
    }

    if (msg?.text === message) return;

    setSpeakAnimated(false);

    videoRef.current.style.opacity = 0;
    videoRef.current.pause();

    if (msg) {
      setMessage(msg);
      const interactRes = await interact(
        setInteraction,
        session.sessionId,
        msg.text
      );
      // videoRef.current.style.opacity = 1;
      // videoRef.current.play();
      // setIsMuted(true);
      setValue(null);
      setMessage(null);
      setTypeMessage('');

      setToggledTopics(false);

      //save msg to firebase profile
      updateAskedQuestions(msg, data);
      setValue(null);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleModal({ text: typeMessage });
    }
  };

  const actionsTabData = [
    {
      id: 0,
      title: 'Talk',
      callBack: () => {
        showTopics(false);
        //setIsMuted(false);
      },
    },
    {
      id: 1,
      title: 'Type',
      callBack: () => {
        showTopics(false);
        //setIsMuted(false);
      },
    },
    {
      id: 2,
      title: 'Topics',
      callBack: () => {
        showTopics(true);
        //setIsMuted(false);
      },
    },
  ];

  const [selectedTabId, setSelectedTabId] = useState(actionsTabData[0].id);

  const getStoryFileData = async (id) => {
    const configSF = await getConfig(id, setConfig);
    const sessionSF = await startSession(id, setSession);
    const cStarters = await getConversationStarters(
      id,
      setConversationStarters
    );
  };

  useEffect(() => {
    const paramsTopic = searchParams.get('topic');

    if (paramsTopic && conversationStarters) {
      const starter = conversationStarters.find((c) => c.title === paramsTopic);
      if (starter) {
        setToggledTopics(true);
      }
    }
  }, [conversationStarters]);

  useEffect(() => {
    setSfVideo(interaction?.video?.url);
    if (videoRef?.current) {
      videoRef.current.style.opacity = 1;
      videoRef.current.play();
    }
  }, [interaction]);

  const getCount = () =>
    conversationStarters
      ?.map((m) => m.questionCount)
      .reduce((a, b) => a + b, 0);

  useEffect(() => {
    const mData = mavenData.find((m) => m.slug === params.slug);
    setData(mData);
    getStoryFileData(mData.storyFileId);
    checkPermissions();
    requestPermissions();
  }, [params.slug]);

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div
      className="interactive-page fade-in"
      /* style={{ backgroundImage: `url(${data.conversationsBanner.image})` }} */
    >
      <ScrollToTop />

      {data && (
        <>
          <SEO
            title={`${data.sport} Interactive Conversations via Conversational AI with sports icon ${data.title}`}
            description={`Ask ${data.title} about ${data.sport}: ${data.shortBio} | VS`}
            name={`${data.title} for VS`}
            keywords="Conversational AI, Interactive Conversations"
            type="article"
          />

          <div className="video-container">
            <video
              playsInline
              autoPlay
              muted
              loop
              preload="yes"
              width="100%"
              height="auto"
              src={data?.restingVideoLandscape}
            />
            <video
              id="main-video"
              ref={videoRef}
              playsInline
              autoPlay
              muted={isMuted}
              preload="yes"
              width="100%"
              height="auto"
              src={sfVideo}
              onEnded={() => {
                videoRef.current.style.opacity = 0;
                setIsMuted(true);
              }}
            />
          </div>
          <div className="actions-container">
            <div className="overlay" />

            {isMobile && (
              <>
                {value && <div className="speech">{value}</div>}
                {selectedTabId === 0 && (
                  <div className="speak-button">
                    {speakAnimated && <div class="pulsating-circle" />}
                    <img
                      className="microphone-mobile"
                      src="/interactive/speak-button.svg"
                      onPointerDown={() => {
                        setSpeakAnimated(false);
                        listen();

                        setIsMuted(true);
                        setTimeout(() => {
                          setSpeakAnimated(true);
                          setIsMuted(false);
                        }, 500);
                        videoRef.current.pause();
                      }}
                      onPointerUp={() => {
                        setSpeakAnimated(false);
                        //videoRef.current.pause();
                        setIsMuted(false);
                        stop();
                        //handleModal();
                      }}
                      /* onTouchStart={() => {
                        listen();
                        setSpeakAnimated(true);
                        videoRef.current.pause();
                      }}
                      onTouchCancel={() => {
                        stop();
                        videoRef.current.pause();
                        setSpeakAnimated(false);
                      }}
                      onTouchEnd={() => {
                        setSpeakAnimated(false);
                        videoRef.current.pause();
                        stop();
                        handleModal();
                      }} */
                      alt="Ask"
                    />
                  </div>
                )}
                {selectedTabId === 1 && (
                  <div className="input-container">
                    <input
                      value={typeMessage}
                      placeholder="Ask a question..."
                      onChange={(e) => setTypeMessage(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <img
                      src="/icons/Send.svg"
                      alt="Send Question"
                      onClick={() => {
                        handleModal({ text: typeMessage });
                        setIsMuted(false);
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <div className={`actions-tab ${toggledTopics ? 'toggled' : ''}`}>
              {actionsTabData.map((a) => (
                <div
                  key={a.id}
                  className={`item tab-item ${
                    a.id === selectedTabId ? 'active' : ''
                  }`}
                >
                  {a.title === 'Topics' && toggledTopics && (
                    <Topics
                      data={conversationStarters}
                      getCount={getCount}
                      setToggledTopics={setToggledTopics}
                      handleModal={handleModal}
                    />
                  )}
                  <span
                    className="tab-title"
                    onClick={() => {
                      setSelectedTabId(a.id);
                      a.callBack();
                    }}
                  >
                    {a.title}
                  </span>
                </div>
              ))}
              <h5 className="hide-desktop">Powered by Storyfile</h5>
            </div>

            <div className="actions">
              <div className="left">
                {isTalk && (
                  <>
                    <img
                      onClick={() => setIsTalk(!isTalk)}
                      src="/interactive/type-question-button.svg"
                      alt="Type Question"
                    />
                    <span className="faded toggle">Type Question</span>
                  </>
                )}
                {!isTalk && (
                  <>
                    <img
                      onClick={() => setIsTalk(!isTalk)}
                      src="/interactive/ask-question-button-small.svg"
                      alt="Ask Question"
                    />
                    <span className="faded toggle">Ask Question</span>
                  </>
                )}
              </div>
              <div className="middle">
                {isTalk && (
                  <>
                    {value && <div className="speech">{value}</div>}
                    <div className="speak-button">
                      {speakAnimated && <div class="pulsating-circle"></div>}
                      {!isMobile && (
                        <>
                          {/* {browserName === 'Safari' && (
                            <img
                              src="/interactive/speak-button.svg"
                              onMouseDown={() => {
                                listen();
                                setSpeakAnimated(true);
                                videoRef.current.pause();
                              }}
                              onMouseUp={() => {
                                setSpeakAnimated(false);
                                videoRef.current.pause();
                                stop();
                                handleModal();
                              }}
                              alt="Ask"
                            />
                          )} */}
                          {/* {browserName !== 'Safari' && ( */}
                          <img
                            src="/interactive/speak-button.svg"
                            onMouseDown={() => {
                              setSpeakAnimated(false);
                              listen();
                              setIsMuted(true);
                              setTimeout(() => {
                                setSpeakAnimated(true);
                                setIsMuted(false);
                              }, 10);
                              videoRef.current.pause();
                            }}
                            onMouseUp={() => {
                              setSpeakAnimated(false);
                              videoRef.current.pause();
                              stop();
                              handleModal();
                            }}
                            alt="Ask"
                          />
                          {/* )} */}
                        </>
                      )}
                    </div>
                    <>
                      {speakAnimated ? (
                        <b>Ask Your Question</b>
                      ) : (
                        <span className="faded">
                          Press and hold to ask your question
                        </span>
                      )}
                    </>
                  </>
                )}
                {!isTalk && (
                  <div className="input-container">
                    <input
                      value={typeMessage}
                      placeholder="Ask a question..."
                      onChange={(e) => setTypeMessage(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <img
                      src="/icons/Send.svg"
                      alt="Send Question"
                      onClick={() => handleModal({ text: typeMessage })}
                    />
                    <span className="faded">Type your question</span>
                  </div>
                )}

                <h5>Powered by Storyfile</h5>
              </div>
              <div className="group">
                {toggledTopics && (
                  <Topics
                    data={conversationStarters}
                    getCount={getCount}
                    setToggledTopics={setToggledTopics}
                    handleModal={handleModal}
                  />
                )}
                <img
                  src="/interactive/explore-topics-button.svg"
                  alt="Show Topics"
                  onClick={() => setToggledTopics(!toggledTopics)}
                />
                {/* <img
                  onClick={() => handleModal()}
                  src="/interactive/closed-caption-button.svg"
                  alt="Show Closed Captions"
                /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default InteractivePage;
