import styled from 'styled-components';

export const PaymentMethodStyles = styled.div`
  .settingsRow {
    padding: 90px 0 0 0;
    > h4 {
      margin-bottom: 36px;
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      color: #000;
    }
    .paymentMethodRow {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      .paymentMethodCardWrapper {
        img {
          width: 100%;
        }
      }
      .changePaymentMethod {
        width: 100%;
        background: #fff;
        backdrop-filter: blur(100px);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          width: 50px;
          color: #c0c0c0;
        }
        span {
          margin: 15px 0 0 0;
          color: #c0c0c0;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .cardDetails {
      color: #535353;
      font-size: 14px;
      font-weight: 400;
    }
  }
  @media (max-width: 600px) {
    .settingsRow {
      padding: 50px 0 0 0;
      .paymentMethodRow {
        grid-gap: 14px;
        .changePaymentMethod {
          text-align: center;
          svg {
            width: 44px;
          }
          span {
            margin: 12px 0 0 0;
            font-size: 14px;
          }
        }
      }
    }
  }
`;
