import { useEffect, useState } from 'react';
import SEO from '@components/Seo/Seo';
import { mavenData } from '../../data/mavenData';
import { sessionVideos } from '../../data/sessionVideos';
import { useParams } from 'react-router-dom';
import CourseLandingHero from './CourseLandingHero';
import CourseContent from './CourseContent';
import ConversationsBanner from './ConversationsBanner';
import Courses from '../LandingPage/Courses';
import { landing } from '../LandingPage/data.js';
import InteractiveContent from '../InteractiveLanding/InteractiveContent';
import { getConversationStarters } from 'src/api/storyFileApi';
import { useSubscription } from 'src/context/SubscriptionContext';
import CourseSummary from './CourseSummary';
import './CourseLanding.scss';
import useHasMounted from '@hooks/useHasMounted';
import { current } from '@reduxjs/toolkit';

const { tabData } = landing;

function CourseLanding({
  setVideoModal,
  setBioModal,
  setToggledSubscribeModal,
  setToggledDownloadModal,
  searchParams,
  setAlert,
  slug,
  currentUser,
}) {
  const params = useParams();

  const [data, setData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [conversationStarters, setConversationStarters] = useState(null);
  const [trailer, setTrailer] = useState(null);
  const subscription = useSubscription();
  const [scrollObject, setScrollObject] = useState(null);

  const getStoryFileData = async (id) => {
    const cStarters = await getConversationStarters(
      id,
      setConversationStarters
    );
  };

  const getCourseData = () => {
    const paramsSlug = params.slug || slug;
    const mavenOnlySessionData = sessionVideos.filter(
      (f) => f.mavenSlug === paramsSlug
    );
    const mData = mavenData.find((s) => s.slug === paramsSlug);

    //course detail data
    setCourseData(mavenOnlySessionData);

    setData(mData);
    setTrailer(mavenOnlySessionData.find((m) => m.title === 'Trailer')?.video);

    //interactive detail data
    getStoryFileData(mData?.storyFileId);
  };

  useEffect(() => {
    getCourseData();
  }, [params.slug || slug]);

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className="course-landing fade-in">
      {data && (
        <>
          <SEO
            title={`${data.sport} Video Courses by icon ${data.title}`}
            description={`Learn more about ${data.sport} through ${data.title}: ${data.shortBio} | VS`}
            name={`${data.title} for Versus`}
            type="article"
          />
          <CourseLandingHero
            athlete={data.title}
            poster={data.courseHeroImage}
            mobilePoster={data.courseHeroImageMobile}
            setBioModal={setBioModal}
            bio={data.bio}
            shortBio={data.shortBio}
            slug={data.slug}
            bioImage={data.avatar}
            params={params}
            video={data.trailer}
            videoAppleFormat={data.m3u8Link}
            setToggledSubscribeModal={setToggledSubscribeModal}
            setToggledDownloadModal={setToggledDownloadModal}
            subscription={subscription}
            currentUser={currentUser}
          />
          <div className="course-summary-container">
            <h3>About {data.title?.split(' ')[0]}'s Content</h3>
            <CourseSummary
              image={data.restingImage}
              athlete={data.title}
              sessionCount={data.sessionCount}
              topicCount={data.topicCount}
              questionCount={data.questionCount}
              durationReadableFormat={data.durationReadableFormat}
              slug={data.slug}
            />
          </div>
          <div className="opposite-body">
            {/* <div className="summary-banner-container">
              <SummaryBanner
                avatar={data.avatar}
                athlete={data.title}
                sessionCount={data.sessionCount}
                topicCount={data.topicCount}
                questionCount={data.questionCount}
                totalRunningMins={data.totalRunningMins}
                slug={data.slug}
              />
            </div> */}
            <h1 className="sessions-title">
              {data.title?.split(' ')[0]}'s Sessions
            </h1>
            <CourseContent
              data={courseData}
              setToggledDownloadModal={setToggledDownloadModal}
              setToggledSubscribeModal={setToggledSubscribeModal}
              currentUser={currentUser}
            />

            <div className="text-banner">
              <h1>Learn by asking.</h1>
              <p className="faded">
                Versus Interactive brings real-time mentorship from world-class
                athletes and coaches straight to your devices. Get to know
                {` ${data.title} `} like never before and recieve actionable
                advice on how to develop valuable skills and grow yourself as a
                person and athlete.
              </p>
            </div>
            <div className="conversations-banner-container">
              <ConversationsBanner
                athlete={data.title}
                topicsCount={data.topicCount}
                responsesCount={data.questionCount}
                image={data.restingImage}
                imageLandscape={data.restingImageLandscape}
                restingVideo={data.restingVideo}
                restingVideoLandscape={data.restingVideoLandscape}
                slug={params.slug}
              />
            </div>
            <div className="interactive-section">
              <div className="title-with-tabs">
                <h2>
                  {data.title?.split(' ')[0]}'s Topics ({data.topicCount})
                </h2>
                <div className="hArrows">
                  <img
                    onClick={() =>
                      setScrollObject({
                        amount: Math.random(),
                        direction: 'left',
                      })
                    }
                    src="/icons/arrow-left.svg"
                    alt="Scroll Left"
                  />
                  <img
                    onClick={() =>
                      setScrollObject({
                        amount: Math.random(),
                        direction: 'right',
                      })
                    }
                    src="/icons/arrow-right.svg"
                    alt="Scroll Right"
                  />
                </div>
              </div>
              <InteractiveContent
                searchParams={searchParams}
                athlete={data.title}
                avatar={data.restingImage}
                data={conversationStarters}
                view="horizontal"
                slug={params.slug}
                scrollObject={scrollObject}
              />
            </div>
          </div>

          <Courses
            type="Course"
            title="More Courses"
            tabData={tabData}
            setVideoModal={setVideoModal}
            setAlert={setAlert}
            searchParams={searchParams}
          />
        </>
      )}
    </div>
  );
}

export default CourseLanding;
