import React, { useState } from 'react';
import Button from '../../components/Button/Button';

function HeroInteractive({ backgroundVideo }) {
  const [toggledInteractiveVideo, setToggledInteractiveVideo] = useState(false);

  const getToggledInteractiveVideoSrc = () => {
    if (!toggledInteractiveVideo) {
      return 'https://cdn.bfldr.com/B1ZZKPB4/as/hvhh7hh4s35rnw8vmhwbtccw/Website_Intro';
    } else {
      return 'https://cdn.bfldr.com/B1ZZKPB4/as/wn763ktr28j6fczqrht5b/VS_TEASE_FINAL_MIXED_COLOR_h264_720';
    }
  };

  return (
    <div className="hero interactive">
      <div
        className={
          toggledInteractiveVideo ? 'not-toggled-video' : 'toggled-video'
        }
      >
        <div className="overlay" />

        <video
          playsInline
          autoPlay
          muted={toggledInteractiveVideo ? false : true}
          preload="auto"
          width="100%"
          height="auto"
          id="bgvid"
          style={{ top: 0, height: '80vh' }}
          src={getToggledInteractiveVideoSrc()}
          onEnded={() => setToggledInteractiveVideo(false)}
        />
        {toggledInteractiveVideo ? (
          <div className="close-interactive-video">
            <Button
              onClick={() => setToggledInteractiveVideo(false)}
              className="secondary"
            >
              Close
            </Button>
          </div>
        ) : (
          <div className="landingBgInfo">
            <div className="top">
              <h4>OVERCOME YOUR FEAR</h4>
              <h4>
                <span>RENEW YOUR MIND</span>
              </h4>
              <Button
                className="outlined"
                onClick={() =>
                  setToggledInteractiveVideo(!toggledInteractiveVideo)
                }
              >
                <img src="/icons/play_small.svg" alt="watch intro" />
                Watch Intro
              </Button>
            </div>
            <h1 className="bigger">
              WITH VERSUS, <span>YOU’RE NEVER ALONE</span> ON<br></br> YOUR
              JOURNEY.
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeroInteractive;
