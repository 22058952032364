import CourseSummaryTalkTo from './CourseSummaryTalkTo';

function CourseSummary({
  image,
  athlete,
  sessionCount,
  topicCount,
  questionCount,
  durationReadableFormat,
  slug,
}) {
  return (
    <div className="course-summary">
      <section>
        <div className="block">
          <img src="/icons/play_video.svg" alt="sessions" />
          <h5>{sessionCount}</h5>
          <span className="faded">Sessions</span>
        </div>
        <div className="block">
          <img src="/icons/clock.svg" alt="minutes" />
          <h5>{durationReadableFormat}</h5>
          <span className="faded">Duration</span>
        </div>
        <div className="block">
          <img src="/icons/list-plus.svg" alt="sessions" />
          <h5>{topicCount}</h5>
          <span className="faded">Topics</span>
        </div>
        <div className="block">
          <img src="/icons/microphone.svg" alt="interactions" />
          <h5>{questionCount}</h5>
          <span className="faded">Interations</span>
        </div>
        <CourseSummaryTalkTo
          athlete={athlete}
          topicCount={topicCount}
          questionCount={questionCount}
          slug={slug}
          image={image}
        />
        {/* <div className="block talk-block">
          <div className="overlay overlay-left" />
          <div className="talk-block-content">
            Talk to {athlete?.split(' ')[0]}
            <div className="meta">
              {topicCount} Topics · {questionCount} Interactions
            </div>
            <Link className="primary button" to={`/interactive/${slug}`}>
              <img src="/icons/microphone.svg" alt="Microphone" /> Ask Now
            </Link>
          </div>
          <img className="resting-image" src={image} alt={`${athlete}`} />
        </div> */}
      </section>
    </div>
  );
}

export default CourseSummary;
