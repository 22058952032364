import { Link, useNavigate } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import Button from '@components/Button/Button';

function SimpleHero({
  body,
  heading,
  subheading,
  button,
  buttonLink,
  setToggledLoginType,
}) {
  const navigate = useNavigate();
  return (
    <div className="simple-hero">
      <h1>{heading ? heading : "Let's get started."}</h1>
      <BrowserView>
        {subheading ? (
          <div dangerouslySetInnerHTML={{ __html: subheading }} />
        ) : (
          'A Versus membership gets you unlimited access to a variety of soft and technical skill based video content, plus a library of over 4,700 real-time interactions with top athletes and coaches.'
        )}
      </BrowserView>
      <MobileView>
        <p>
          {subheading ? (
            <div dangerouslySetInnerHTML={{ __html: subheading }} />
          ) : (
            'A Versus membership gets you unlimited access to the entire Versus content library.'
          )}
        </p>
      </MobileView>
      <h5>{body}</h5>
      {buttonLink ? (
        <a
          href={buttonLink}
          rel="noreferrer"
          className="button primary"
          target="_blank"
        >
          {button}
        </a>
      ) : (
        <Button
          title="Hero"
          action="Sign Up"
          className="primary"
          onClick={() => navigate('/pass/?id=vsunlimited')}
          //onClick={() => setToggledLoginType('signup')}
        >
          {button ? button : "Let's Go!"}
        </Button>
      )}
    </div>
  );
}

export default SimpleHero;
