import React, { useEffect, useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';

function Logout() {
  const { setUser, user } = useContext(AuthContext);
  const auth = getAuth();
  const navigate = useNavigate();

  const signOutUser = async () => {
    signOut(auth)
      .then((r) => {
        navigate('/');
        setUser(null);
      })
      .catch();
  };

  useEffect(() => {
    signOutUser();
  }, []);

  return <div></div>;
}

export default Logout;
