const panels = [
  {
    image: '/landing/leadership-image.jpg',
    title: 'Leadership',
    body: (
      <div>
        <b>Learn to lead with character & integrity.</b> Get exclusive
        leadership insights from top leaders through video sessions & real-time
        advice by asking hundreds of leadership-based questions.
      </div>
    ),
  },
  {
    image: '/landing/mindset-image.jpg',
    title: 'Mindset',
    body: (
      <div>
        It's the 90% that matters most that almost 100% don't train. A strong
        mindset transcends sports and can impact every area of your life.{' '}
        <b>
          Learn how these champions developed a mindset that’s prepared for any
          moment, and how you can too.
        </b>
      </div>
    ),
  },
  {
    image: '/landing/routines-image.jpg',
    title: 'Routines',
    body: (
      <div>
        How will you respond when the circumstances and environment change?
        Drastic change can make you feel overwhelmed and isolated. Routines can
        help you feel grounded.{' '}
        <b>
          Learn to develop your own routines that combat change and keep you
          moving toward your goals.
        </b>
      </div>
    ),
  },
  {
    image: '/landing/inspiration-image.jpg',
    title: 'Ignition',
    body: (
      <div>
        Most people want to achieve something, but few are ignited toward
        greatness. It all starts with learning how to{' '}
        <b>
          take control of your own journey and realize that your success is 100%
          up to you.
        </b>
      </div>
    ),
  },
  {
    image: '/landing/personal-growth-image.jpg',
    title: 'Overcoming Adversity',
    body: (
      <div>
        Adversity is a necessary ingredient to success. But what makes all the
        difference is how you respond to it. See, first-hand, how these elite
        competitors <b>overcame adversity to achieve greatness.</b>
      </div>
    ),
  },
  {
    image: '/landing/personal-development-image.jpg',
    title: 'Personal Development',
    body: (
      <div>
        Who you are as a person is the foundation of who you are as a
        competitor. In order to become the competitor you want to be,{' '}
        <b>you have to first understand the person you want to be</b>.
      </div>
    ),
  },
  {
    image: '/landing/technical-skills-image.jpg',
    title: 'Technical Skills',
    body: (
      <div>
        It's not just about the technical skills within one’s sport, but the
        tactical skills as well. But how do you know what really matters?{' '}
        <b> Learn from proven methods from our experts</b> who show you exactly
        what they train and why it matters.
      </div>
    ),
  },
];

function Story() {
  return (
    <div className="story">
      <div className="header">
        <h1>What's holding you back?</h1>
        <h5>Here's how we can help</h5>
        <img src="/icons/arrow-down.svg" alt="Scroll Down" />
      </div>
      <div className="panel-container">
        {panels.map((p) => (
          <div
            key={p.title}
            className="panel"
            style={{ backgroundImage: `url(${p.image})` }}
          >
            <div className="overlay-left" />
            <h3>{p.title}</h3>
            <div className="paragraph">{p.body}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Story;
