import { Link } from 'react-router-dom';

function CourseSummaryTalkTo({
  athlete,
  topicCount,
  questionCount,
  slug,
  image,
  subtitle,
  title,
}) {
  return (
    <div className="block talk-block">
      <div className="overlay overlay-left" />
      <div className="talk-block-content">
        <h5>{subtitle}</h5>
        {title ? (
          <>
            {title}
            <h6>{athlete}</h6>
          </>
        ) : (
          <>Talk to {athlete?.split(' ')[0]}</>
        )}

        <div className="meta">
          {topicCount && (
            <>
              {topicCount} Topics · {questionCount} Interactions
            </>
          )}
        </div>
        {title ? (
          <Link
            style={{ width: '200px' }}
            className="secondary button"
            to={`/course/${slug}/video/${slug}-1`}
          >
            Watch Full Session
          </Link>
        ) : (
          <Link className="primary button" to={`/interactive/${slug}`}>
            <img src="/icons/microphone.svg" alt="Microphone" /> Ask Now
          </Link>
        )}
      </div>
      <img className="resting-image" src={image} alt={`${athlete}`} />
    </div>
  );
}

export default CourseSummaryTalkTo;
