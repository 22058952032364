import axios from 'axios';
import { isNull, mergeWith } from 'lodash';

const apiKey = process.env.REACT_APP_STORYFILE_APIKEY;
const url = process.env.REACT_APP_STORYFILE_URL;

/* 
  sessionId: DialogueSessionId
  message: string
  id: string
  name: string
  quality?: number
  language?: string
  clientID?: string
  storyfileID?: string
  img?: string
*/

const config = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': `${apiKey}`,
  },
};

const defaultInteractionConfig = {
  quality: 360,
  lang: 'en-US',
  clientId: 'Versus',
};

export const getConversationStarters = async (id, setConversationStarters) => {
  const path = `${url}integration/storyfile/${id}/conversationStarters`;
  axios
    .get(path, config)
    .then((res) => {
      const cStarters = res.data.conversationStarters;
      const topics = [...new Set([...cStarters.map((c) => c.topic.name)])];
      const topicsWithData = topics.map((t, index) => {
        const filteredTopics = cStarters.filter((c) => c.topic.name === t);
        return {
          title: t,
          data: filteredTopics,
          questionCount: filteredTopics.length,
        };
      });
      setConversationStarters(topicsWithData);
      return topicsWithData;
    })
    .catch((err) => console.log(err));
};

export const getConfig = async (id, setConfig) => {
  const path = `${url}integration/storyfile/${id}/config`;
  axios
    .get(path, config)
    .then((res) => {
      setConfig(res);
    })

    .catch((err) => console.log(err));
};

export const interact = async (
  setInteraction,
  sessionId,
  message,
  quality,
  language,
  clientId
) => {
  const path = `${url}integration/dialogue/interact`;
  /* "sessionId": "92b7c3bd-f48e-4339-960e-9f5198a6e2ed",
"userUtterance": "Hello.",
"lang": "en-US",
"clientId": "webapp",
"quality": 1080 */
  const res = await fetch(path, {
    method: 'POST',
    headers: config.headers,
    body: JSON.stringify(
      mergeWith(
        {},
        defaultInteractionConfig,
        {
          sessionId,
          userUtterance: message,
        },
        (o, s) => (isNull(s) ? o : s)
      )
    ),
  }).then((response) => {
    response.json().then((r) => {
      setInteraction(r);
      return r;
    });
  });
};

export const startSession = async (id, setSession) => {
  const path = `${url}integration/dialogue/start`;
  fetch(path, {
    method: 'POST',
    headers: config.headers,
    body: JSON.stringify({
      storyfileId: id,
    }),
  }).then((response) => {
    response.json().then((r) => {
      setSession(r);
      return r;
    });
  });
};
