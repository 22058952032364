import Button from '@components/Button/Button';
import React from 'react';

function ConversationsBanner({
  athlete,
  topicsCount,
  responsesCount,
  image,
  restingVideo,
  restingVideoLandscape,
  slug,
}) {
  return (
    <div className="conversations-banner">
      <div className="overlay" />
      <div className="overlay overlay-left" />
      <div className="left">
        <img src="/icons/AI.svg" alt="AI" className="hide-mobile" />
        <h3>Conversations with {athlete?.split(' ')[0]}</h3>
        <div className="meta">
          <div>
            <h4>{topicsCount}</h4>
            <p className="faded">Topics</p>
          </div>
          <div>
            <h4>{responsesCount}</h4>
            <p className="faded">Interactions</p>
          </div>
        </div>
        <Button className="button primary" route={`/interactive/${slug}`}>
          Talk to {athlete?.split(' ')[0]}
        </Button>
        <Button className="link" route={`/conversation/${slug}`}>
          <img src="/icons/Info.svg" alt="info" />
          Learn More
        </Button>
      </div>

      {restingVideo ? (
        <div className="right">
          {/* <div className="overlay" /> */}
          <video
            playsInline
            autoPlay
            muted
            loop
            preload="yes"
            width="100%"
            height="auto"
            //poster={image}
            src={restingVideoLandscape}
          />
        </div>
      ) : (
        <div
          className="right"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      )}
    </div>
  );
}

export default ConversationsBanner;
