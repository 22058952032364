import React from 'react';
import { InputStyles } from './Input.style';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <InputStyles
      {...props}
      ref={ref}
      className={props.className}
      type={props.type}
      autoFocus={props.autoFocus}
      autoComplete={props.autoComplete}
    />
  );
});

export default Input;
