import { useRef, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { XIcon, MenuIcon } from '@heroicons/react/outline';
import Button from '../Button/Button';
import Logo from '../../assets/images/logo.svg';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import { partnersData } from '../../data/partnersData';
import '../Navbar/Navbar.scss';
import './NavbarUnAuthed.scss';
import { debounce } from 'src/helpers/helpers';
import NavbarDrawer from '@components/Navbar/NavbarDrawer';

const NavbarUnAuthed = ({ setToggledLoginType, isNative }) => {
  const { search, pathname } = useLocation();
  const [ctaLink, setCtaLink] = useState('');
  const navigate = useNavigate();

  const navbarLinks = [
    {
      name: 'Home',
      href: `/${search}`,
      mobileOnly: true,
    },
    {
      name: 'Roster',
      href: `/roster${search}`,
    },
    {
      name: 'Browse Content',
      icon: '/icons/browse-content-icon.svg',
      href: `/browse-content${search}`,
    },
    {
      name: 'Courses',
      href: `/courses${search}`,
      mobileOnly: true,
    },
    {
      name: 'Conversations',
      href: `/conversations${search}`,
      mobileOnly: true,
    },
    {
      name: 'Expert Advice',
      href: `/learn${search}`,
      mobileOnly: true,
    },
    {
      name: 'Pricing',
      href: `/pricing${search}`,
    },
    {
      name: 'Contact',
      href: `/contact${search}`,
      type: 'small',
    },
    {
      name: 'Terms and Conditions',
      href: `/terms${search}`,
      mobileOnly: true,
      type: 'small',
    },
    {
      name: 'Privacy Policy',
      href: `/privacy${search}`,
      mobileOnly: true,
      type: 'small',
    },
  ];

  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [toggledDropdown, setToggledDropdown] = useState(false);
  const [showLoginLink, setShowLoginLink] = useState(true);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpened((prevOpened) => !prevOpened);
  };

  useEffect(() => {
    if (
      pathname.includes('login') ||
      pathname.includes('signup') ||
      pathname.includes('/partner/')
    ) {
      setShowLoginLink(false);
      if (pathname.includes('/partner/')) {
        const slug = pathname.split('/partner/')[1];
        const routeData = partnersData.find((p) => p.slug === slug);
        setCtaLink(routeData?.CTALink || '/');
      }
    } else {
      setShowLoginLink(true);
    }
  }, [pathname]);

  const dropdownRef = useRef();
  const navRef = useRef();

  useOutsideAlerter(dropdownRef, (isOutSide) => {
    if (isOutSide) {
      setToggledDropdown(false);
    }
  });

  let scrollPos = 0;
  //const nav = document.querySelector('.sticky-nav');
  function checkPosition() {
    let windowY = window.scrollY;
    if (scrollPos > 160) {
      navRef?.current?.classList.remove('hide-nav');
    } else {
      navRef?.current?.classList.add('hide-nav');
    }
    scrollPos = windowY;
  }

  useEffect(() => {
    window.addEventListener('scroll', debounce(checkPosition));
    navRef.current.classList.add('hide-nav');
    return () => {
      window.removeEventListener('scroll', checkPosition);
    };
  }, []);

  return (
    <>
      <nav className={`${pathname.includes('/partner/') ? 'partner-nav' : ''}`}>
        <div className="navMenuWrapper">
          <div className="navbarLeftMenu">
            {isNative && pathname !== '/' ? (
              <Button
                className="logo dflex"
                to={`/${search}`}
                onClick={() => {
                  setToggledDropdown(false);
                  navigate(-1);
                }}
              >
                <img
                  src="/icons/chevron-down.svg"
                  alt="Back"
                  className="back"
                />
                <img src={Logo} alt="VS" />
              </Button>
            ) : (
              <Link
                className="logo"
                to={`/${search}`}
                onClick={() => setToggledDropdown(false)}
              >
                <img src={Logo} alt="VS" />
              </Link>
            )}
            {!pathname.includes('/partner/') &&
              navbarLinks.map((item, index) => {
                if (item.children) {
                  //is a Dropdown
                  return (
                    <div
                      key={`children-${index}`}
                      className="navLink"
                      onClick={() => setToggledDropdown(!toggledDropdown)}
                    >
                      {item.name}{' '}
                      <img
                        className={toggledDropdown ? 'inverted' : ''}
                        src="/icons/chevron-down.svg"
                        alt="Dropdown"
                      />
                    </div>
                  );
                } else {
                  if (!item.mobileOnly)
                    return (
                      <Link
                        key={`children-${index}`}
                        onClick={() => setToggledDropdown(false)}
                        className="navLink"
                        to={`${item.href}`}
                      >
                        {item.name}
                        {item.icon && (
                          <img src={item.icon} alt="icon" className="icon" />
                        )}
                      </Link>
                    );
                }
              })}
          </div>
          <div className="navbarRightMenu">
            {!pathname.includes('/partner/') && showLoginLink && (
              <Button
                className="link sign-in"
                title="Navbar Unauthed"
                action="Sign Up"
                onClick={() => {
                  setToggledDropdown(false);
                  setToggledLoginType('signin');
                }}
              >
                Sign In
              </Button>
            )}
            {pathname.includes('/partner/') && (
              <a
                href={ctaLink}
                target="_blank"
                rel="noreferrer"
                className="primary button"
                /* onClick={() => {
                  setToggledDropdown(false);
                  setToggledLoginType('signin');
                }} */
              >
                Sign Up
              </a>
            )}

            <img
              src={'/icons/burger-menu-right.svg'}
              alt="menu"
              className="openMenuIconBurger openMenuIcon"
              onClick={() => {
                toggleMobileMenu();
                setToggledDropdown(false);
              }}
            />
          </div>
        </div>
        {/* Mobile Layout */}
        {/* <div
          className={`navMenuWrapper mobileWrapper ${
            isMobileMenuOpened ? 'openedMenu' : ''
          }`}
        >
          <XIcon onClick={toggleMobileMenu} className="closeMenuIcon" />
          <div className="navbarLeftMenu">
            <Link to={`/${search}`} className="logo" onClick={toggleMobileMenu}>
              <img src={Logo} alt="VS" />
            </Link>
            {navbarLinks.map((item, index) => (
              <Link
                to={`${item.href}`}
                key={`${item.name}-${index}`}
                onClick={toggleMobileMenu}
                className={`navLink ${item.type ? item.type : ''}`}
              >
                {item.name}{' '}
                {item.icon && (
                  <img src={item.icon} alt="icon" className="icon" />
                )}
              </Link>
            ))}
          </div>
        </div> */}
        {/* Mobile Layout */}
        <NavbarDrawer
          isMobileMenuOpened={isMobileMenuOpened}
          toggleMobileMenu={toggleMobileMenu}
          search={search}
        />
      </nav>
      {showLoginLink && (
        <div
          ref={navRef}
          className="sticky-nav"
          style={{ backgroundImage: 'url(/pages/JoinVS-banner.svg)' }}
        >
          Get FREE unlimited access. No credit card needed!
          {/* Ready to get better? It’s You vs You. */}{' '}
          <Button
            className="primary button"
            title="Navbar Unauthed"
            action="Sign Up"
            onClick={() => {
              setToggledDropdown(false);
              navigate('/pass/?id=vsunlimited');
              //setToggledLoginType('signup');
            }}
          >
            Join Now!
          </Button>
        </div>
      )}
    </>
  );
};

export default NavbarUnAuthed;
