import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Tabs.scss';

function Tabs({ data, selectedTabId, callback, noLink }) {
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleCallback = (d) => {
    callback(d.id);
    if (d.link && !noLink) {
      navigate(d.link + search);
    }
  };
  return (
    <div className="tabs">
      {data.map((d) => (
        <div
          key={d.id}
          className={`tab ${d.id === selectedTabId ? 'active' : ''}`}
          onClick={() => handleCallback(d)}
        >
          {d.title}
        </div>
      ))}
    </div>
  );
}

export default Tabs;
