import { XIcon, UserCircleIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import { mavenData } from 'src/data/mavenData';
import Button from '@components/Button/Button';

function NavbarDrawer({
  isMobileMenuOpened,
  toggleMobileMenu,
  search,
  currentUser,
}) {
  const navigate = useNavigate();
  const navbarLinks = [
    {
      name: 'Home',
      href: `/${search ? search : ''}`,
      mobileOnly: true,
    },
    {
      name: 'Roster',
      href: `/roster${search ? search : ''}`,
    },
    {
      name: 'Browse Content',
      icon: '/icons/browse-content-icon.svg',
      href: `/browse-content${search ? search : ''}`,
    },
    {
      name: 'Expert Advice',
      href: `/learn${search ? search : ''}`,
      mobileOnly: true,
    },
    {
      name: 'Courses',
      href: `/courses${search ? search : ''}`,
      mobileOnly: true,
      data: mavenData.filter((m) => m.interactiveOnly === 'FALSE'),
    },
    {
      name: 'Conversations',
      href: `/conversations${search ? search : ''}`,
      mobileOnly: true,
      data: mavenData,
    },
    {
      name: 'Contact',
      href: `/contact${search ? search : ''}`,
      type: 'small',
    },
    {
      name: 'Terms and Conditions',
      href: `/terms${search ? search : ''}`,
      mobileOnly: true,
      type: 'small',
    },
    {
      name: 'Privacy Policy',
      href: `/privacy${search ? search : ''}`,
      mobileOnly: true,
      type: 'small',
    },
    /* {
      name: 'Logout',
      href: `/logout`,
      mobileOnly: true,
      type: 'small danger',
    }, */
  ];
  return (
    <div
      className={`navMenuWrapper mobileWrapper ${
        isMobileMenuOpened ? 'openedMenu' : ''
      }`}
    >
      <XIcon onClick={toggleMobileMenu} className="closeMenuIcon" />
      <div className="navbarLeftMenu">
        <Link to={`/${search}`} className="logo" onClick={toggleMobileMenu}>
          <img src={Logo} alt="VS" />
        </Link>
        {navbarLinks.map((item, index) => (
          <>
            {item.data && (
              <>
                <Link
                  to={`${item.href}${search}`}
                  key={index}
                  className={`navLink ${item.type ? item.type : ''}`}
                  onClick={toggleMobileMenu}
                >
                  {item.name}
                </Link>
                <div className="scrollable">
                  {item.data.map((d, index) => (
                    <div className="item">
                      {item.name === 'Courses' ? (
                        <div
                          key={d.slug}
                          className="item-image"
                          onClick={() => {
                            toggleMobileMenu(false);
                            navigate(`/course/${d.slug}`);
                          }}
                          style={{
                            backgroundImage: `url(${d.courseCardImage})`,
                          }}
                        ></div>
                      ) : (
                        <div
                          key={d.slug}
                          className="item-image"
                          onClick={() => {
                            toggleMobileMenu(false);
                            navigate(`/conversation/${d.slug}`);
                          }}
                          style={{
                            backgroundImage: `url(${d.restingImage})`,
                          }}
                        ></div>
                      )}

                      {d.title}
                    </div>
                  ))}
                </div>
              </>
            )}
            {!item.data && (
              <Link
                to={`${item.href}${search}`}
                key={index}
                className={`navLink ${item.type ? item.type : ''}`}
                onClick={toggleMobileMenu}
              >
                {item.name}
              </Link>
            )}
          </>
        ))}
        {currentUser && (
          <Link
            to={`/settings${search ? search : ''}`}
            className={`navLink small`}
            onClick={toggleMobileMenu}
          >
            Account Settings{/*  <UserCircleIcon /> */}
          </Link>
        )}
      </div>
      {currentUser && (
        <Button
          className="secondary"
          onClick={() => {
            navigate('/logout');
            toggleMobileMenu();
          }}
        >
          Sign Out
        </Button>
      )}
    </div>
  );
}

export default NavbarDrawer;
