export const landing = {
  quickView: [
    {
      id: 0,
      title: 'Have confidence in your routines.',
      link: '',
      image: '/landing/QV1.png',
      time: '1:12',
    },
    {
      id: 1,
      title: 'Be intentional with soft toss.',
      link: '',
      image: '/landing/QV2.png',
      time: '0:56',
    },
    {
      id: 2,
      title: 'Advice for youth sports parents.',
      link: '',
      image: '/landing/QV3.png',
      time: '1:16',
    },
    {
      id: 3,
      title: 'How to throw a curveball.',
      link: '',
      image: '/landing/QV4.png',
      time: '1:23',
    },
    {
      id: 4,
      title: 'Becoming your best self.',
      link: '',
      image: '/landing/QV5.png',
      time: '1:11',
    },
    {
      id: 5,
      title: 'Good teammates are inclusive.',
      link: '',
      image: '/landing/QV6.png',
      time: '0:56',
    },
    {
      id: 6,
      title: 'Building a strong team culture.',
      link: '',
      image: '/landing/QV7.png',
      time: '0:56',
    },
    {
      id: 7,
      title: 'How to deal with tough losses.',
      link: '',
      image: '/landing/QV8.png',
      time: '0:56',
    },
    {
      id: 8,
      title: 'Creating space from your defender.',
      link: '',
      image: '/landing/QV1.png',
      time: '0:56',
    },
    {
      id: 9,
      title: 'Have confidence in your routines.',
      link: '',
      image: '/landing/QV1.png',
      time: '0:56',
    },
    {
      id: 10,
      title: 'Have confidence in your routines.',
      link: '',
      image: '/landing/QV1.png',
      time: '0:56',
    },
    {
      id: 11,
      title: 'Have confidence in your routines.',
      link: '',
      image: '/landing/QV1.png',
      time: '0:56',
    },
    {
      id: 12,
      title: 'Have confidence in your routines.',
      link: '',
      image: '/landing/QV1.png',
      time: '0:56',
    },
    {
      id: 13,
      title: 'Have confidence in your routines.',
      link: '',
      image: '/landing/QV1.png',
      time: '0:56',
    },
  ],
  newContent: {
    left: [
      {
        id: 1,
        title: 'Jessica Mendoza',
        link: '',
        image: '/landing/Jessica-Mendoza-New.png',
        course: true,
        type: 'Course',
        interactive: true,
      },
      /* {
        id: 1,
        title: 'David Copeland-Smith',
        link: '',
        image: '/landing/NC2.png',
        course: true,
        type: 'Course',
        interactive: false,
      },
      {
        id: 2,
        title: 'Fernando Tatis Jr',
        link: '',
        image: '/landing/NC3.png',
        course: false,
        type: 'Interactive',
        interactive: true,
      }, */
    ],
    right: [
      {
        id: 0,
        title: 'Sessions',
        count: '13',
        image: '/icons/play_video.svg',
      },
      {
        id: 1,
        title: 'Topics',
        count: '26',
        image: '/icons/list-plus.svg',
      },
      {
        id: 2,
        title: 'Interactions',
        count: '270',
        image: '/icons/microphone.svg',
      },
    ],
  },
  textHero: [
    {
      id: 0,
      title: '100+',
      subtitle: 'available sessions',
      body: 'Watch hours of video content from our experts, curated to make you better in life and your sport.',
    },
    {
      id: 0,
      title: '100+',
      subtitle: 'available topics',
      body: "Explore hundreds of interactive topics that provide key insights into our experts lives and professional journey's.",
    },
    {
      id: 0,
      title: '4700+',
      subtitle: 'expert-driven responses',
      body: 'Grow your knowledge by asking our experts  thousands of questions using industry exclusive Conversational AI technology.',
    },
  ],
  contentPreview: [
    {
      id: 0,
      title: 'Jennie Finch',
      body: 'Jennie teaches you how to develop your pitching skills and create quality competitive habits.',
      image: 'CP1.jpg',
    },
    {
      id: 1,
      title: 'Albert Pujols',
      body: 'Albert shows you his approach to training and how to develop a mind that can handle success and failure.',
      image: 'CP2.jpg',
    },
    {
      id: 0,
      title: "Kelly O'Hara",
      body: 'Kelley teaches you how to train to be adaptable on the pitch and become a valuable teammate & leader.',
      image: 'CP3.jpg',
    },
  ],
  tabData: [
    {
      id: 0,
      title: 'All',
      link: '/roster',
    },
    {
      id: 1,
      title: 'Baseball',
      link: '/sport/baseball',
    },
    {
      id: 2,
      title: 'Softball',
      link: '/sport/softball',
    },
    {
      id: 3,
      title: 'Soccer',
      link: '/sport/soccer',
    },
  ],
  latestPublications: [
    {
      id: 0,
      category: 'Subcategory',
      title: 'Two-minute mind exercise',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      date: 'July 26',
      length: '5 min read',
      image: 'https://asweatlife.com/wp-content/uploads/2018/04/MG_5692.jpg',
    },
    {
      id: 1,
      category: 'Subcategory',
      title: 'Run on your toes',
      body: 'Consectetur adipiscing elit, sed do eiusmod tempor.',
      date: 'July 26',
      length: '5 min read',
      image:
        'https://www.wellandgood.com/wp-content/uploads/2017/10/Stocksy-Girl-Running-Bonninstudio.jpg',
    },
    {
      id: 2,
      category: 'Subcategory',
      title: 'Get in the mindset',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      date: 'July 26',
      length: '5 min read',
      image:
        'https://media1.popsugar-assets.com/files/thumbor/7AeqQOPn_kGLZCOZ-RGaBaurkU8/fit-in/728xorig/filters:format_auto-!!-:strip_icc-!!-/2019/01/12/912/n/40891428/bc5bdc2e5c3a53bd40a105.41820647_/i/How-Start-Working-Out-Gym.jpg',
    },
    {
      id: 3,
      category: 'Subcategory',
      title: 'Lat Pulldowns',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      date: 'July 26',
      length: '5 min read',
      image:
        'https://www.viewbug.com/media/mediafiles/2019/03/04/83659692_large1300.jpg',
    },
  ],
};
