import { useEffect, useState, useRef } from 'react';
import Button from '@components/Button/Button';
import { herosBadges } from '../../helpers/dataHelpers.js';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';

function CourseLandingHero({
  setBioModal,
  setToggledSubscribeModal,
  setToggledDownloadModal,
  shortBio,
  bio,
  athlete,
  poster,
  mobilePoster,
  video,
  bioImage,
  params,
  subscription,
  slug,
  currentUser,
}) {
  const heroVideoRef = useRef();
  const [isMuted, setIsMuted] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);

  const handleShowBio = () => {
    const bioForModal = {
      body: bio,
      title: athlete,
      image: bioImage,
    };
    setBioModal(bioForModal);
  };

  /* useEffect(() => {
    if (isPaused) {
      heroVideoRef.current.pause();
    } else {
      heroVideoRef.current.play();
    }
  }, [isPaused]); */

  const onVideoTimeUpdate = () => {
    setCurrentTime(heroVideoRef?.current?.currentTime);
  };

  useEffect(() => {
    setIsPaused(false);
    setIsMuted(true);
    setTimeout(() => {
      setIsPaused(false);
      heroVideoRef?.current?.play();
    }, 3000);

    if (heroVideoRef)
      heroVideoRef?.current?.addEventListener('timeupdate', onVideoTimeUpdate);

    return () => {
      const v = heroVideoRef?.current;
      if (v) {
        v.load();
      }

      setCurrentTime(0);
      heroVideoRef?.current?.removeEventListener(
        'timeupdate',
        onVideoTimeUpdate
      );
    };
  }, [params?.slug]);

  const handlePlayPause = () => {
    const paused = isPaused;
    if (paused) {
      setIsMuted(false);
      heroVideoRef?.current?.play();
    } else {
      heroVideoRef?.current?.pause();
    }
    setIsPaused(!paused);
  };

  return (
    <div className="hero">
      <div className="landingBg">
        <div className="overlay" onClick={() => handlePlayPause()} />

        <BrowserView>
          <video
            ref={heroVideoRef}
            playsInline
            onEnded={() => {
              heroVideoRef?.current?.load();
              setCurrentTime(0);
              setIsPaused(true);
            }}
            muted={isMuted}
            preload="auto"
            width="100%"
            height="auto"
            poster={poster}
            src={video}
          />
        </BrowserView>
        <MobileView>
          <video
            ref={heroVideoRef}
            playsInline
            onEnded={() => {
              heroVideoRef?.current?.load();
              setCurrentTime(0);
              setIsPaused(true);
            }}
            muted={isMuted}
            preload="metadata"
            width="100%"
            height="auto"
            poster={mobilePoster}
            src={video}
          />
        </MobileView>

        <div className={`heroBgInfo ${currentTime === 0 ? '' : 'flushed'}`}>
          <h1>
            {athlete}
            <span>
              <img
                onClick={() => setIsMuted(!isMuted)}
                src={
                  isMuted
                    ? '/icons/coueseLandingMute.svg'
                    : '/icons/courseLandingUnmute.svg'
                }
                alt="Mute"
              />
              <img
                onClick={() => handlePlayPause(isPaused)}
                src={
                  isPaused
                    ? '/icons/courseLandingPlay.svg'
                    : '/icons/courseLandingPause.svg'
                }
                alt="Mute"
              />
            </span>
          </h1>
          <div
            className={`secondary-filters ${currentTime === 0 ? '' : 'hide'}`}
          >
            {herosBadges.map((s) => (
              <div key={s.id}>
                <img src={s.icon} alt={s.title} /> {s.title}
              </div>
            ))}
          </div>
          <p className={currentTime === 0 ? '' : 'hide'}>{shortBio}</p>
          <div className="actions">
            {/* {subscription.planName === 'Premium' ? (
              <>
                {currentUser ? (
                  <Link
                    title="Course Landing Hero"
                    action="Start Course"
                    description={`/course/${slug}/video/${slug}-1`}
                    className="button primary"
                    to={`/course/${slug}/video/${slug}-1`}
                  >
                    <img src="/icons/play.svg" alt="Watch Video" />
                    Start Course
                  </Link>
                ) : (
                  <Button
                    title="Course Landing Hero"
                    action="Start Course"
                    description={athlete}
                    className="primary"
                    onClick={() => setToggledDownloadModal(true)}
                  >
                    <img src="/icons/play.svg" alt="Watch Video" />
                    Start Course
                  </Button>
                )}
              </>
            ) : ( */}
            <>
              {currentUser ? (
                <>
                  <Link
                    title="Course Landing Hero"
                    action="Start Course"
                    description={`/course/${slug}/video/${slug}-1`}
                    className="button primary"
                    to={`/course/${slug}/video/${slug}-1`}
                  >
                    <img src="/icons/play.svg" alt="Watch Video" />
                    Start Course
                  </Link>
                  {/* <Button
                    className="primary"
                    onClick={() => setToggledDownloadModal(true)}
                    title="Course Landing Hero"
                    action="Start Course"
                    description={athlete}
                  >
                    <img src="/icons/play.svg" alt="Watch Video" />
                    Start Course
                  </Button> */}
                </>
              ) : (
                <Button
                  className="primary"
                  onClick={() => setToggledSubscribeModal(true)}
                  title="Course Landing Hero"
                  action="Start Course"
                  description={athlete}
                >
                  <img src="/icons/play.svg" alt="Watch Video" />
                  Start Course
                </Button>
              )}
            </>
            {/* )} */}

            <Button
              height="52px"
              className="secondary"
              title="Course Landing Hero"
              action="About Maven"
              description={athlete}
              onClick={() => handleShowBio(bio)}
            >
              <img src="/icons/Info.svg" alt="Watch Video" />
              About {athlete?.split(' ')[0]}
            </Button>
          </div>
        </div>
      </div>
      <div
        className={`video-controls ${
          currentTime === 0 && isPaused ? '' : 'flushed'
        }`}
      >
        {currentTime === 0 && (
          <Button
            className="outlined"
            onClick={() => {
              heroVideoRef?.current?.play();
              setIsPaused(false);
              setIsMuted(false);
            }}
          >
            Play Intro
          </Button>
        )}
        {currentTime !== 0 && (
          <BrowserView>
            <span>
              <img
                onClick={() => setIsMuted(!isMuted)}
                src={
                  isMuted
                    ? '/icons/coueseLandingMute.svg'
                    : '/icons/courseLandingUnmute.svg'
                }
                alt="Mute"
              />
              <img
                onClick={() => handlePlayPause()}
                src={
                  isPaused
                    ? '/icons/courseLandingPlay.svg'
                    : '/icons/courseLandingPause.svg'
                }
                alt="Mute"
              />
            </span>
          </BrowserView>
        )}
      </div>
    </div>
  );
}

export default CourseLandingHero;
