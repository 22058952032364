import Courses from '../LandingPage/Courses';
import './BrowseContent.scss';
import { mavenData } from 'src/data/mavenData';
import CollectionsSection from './CollectionsSection';
import { collectionData } from 'src/data/dataCollections';
import useHasMounted from '@hooks/useHasMounted';

const coursesStack = mavenData
  .filter((s) => s.interactiveOnly === 'FALSE')
  .map((ss) => ({
    ...ss,
    count: ss.questionCount,
    cardTitle: ss.title,
    type: 'Course',
  }));

const interactiveStack = mavenData
  .filter((s) => s.interactiveOnly === 'TRUE')
  .map((ss) => ({
    ...ss,
    count: ss.topicCount,
    cardTitle: ss.title,
    type: 'Interactive',
    image: ss.restingImage,
    restingVideo: ss.restingVideo,
  }));

function BrowseContent({
  coursesTitle,
  conversationsTitle,
  setVideoModal,
  setToggledSubscribeModal,
  searchParams,
  setAlert,
  showConversationsOnly,
}) {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }
  return (
    <div className="browse-content">
      <>
        {showConversationsOnly ? (
          <Courses
            title={conversationsTitle || 'Conversations'}
            tabData={[]}
            cardStack={interactiveStack}
            setToggledSubscribeModal={setToggledSubscribeModal}
            searchParams={searchParams}
            type="Conversation"
            setAlert={setAlert}
            exploreMore="/conversations"
          />
        ) : (
          <>
            <Courses
              tabData={[]}
              title={coursesTitle || 'Courses'}
              cardStack={coursesStack}
              setVideoModal={setVideoModal}
              searchParams={searchParams}
              type="Course"
              setAlert={setAlert}
              exploreMore="/courses"
            />

            <Courses
              title={conversationsTitle || 'Conversations'}
              tabData={[]}
              cardStack={interactiveStack}
              setToggledSubscribeModal={setToggledSubscribeModal}
              searchParams={searchParams}
              type="Conversation"
              setAlert={setAlert}
              exploreMore="/conversations"
            />
          </>
        )}

        {/* <CollectionsSection data={collectionData} searchParams={searchParams} /> */}
      </>
    </div>
  );
}

export default BrowseContent;
