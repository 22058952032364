import styled from "styled-components";

export const ConfirmationModalOverlay = styled.div`
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  .confirmModal {
    padding: 0 14px;
    width: 100%;
    max-width: 640px;
    min-height: 310px;
    background: linear-gradient(125.25deg, #535353 3.46%, #000000 100%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      line-height: 1.3;
    }
    button {
      margin: 50px 0 0 0;
      width: 133px;
      height: 52px;
      background: #fff200;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      color: #000;
      font-size: 16px;
      font-weight: 600;
    }
  }
  @media (max-width: 694px) {
    .confirmModal {
      max-width: 94%;
      min-height: 300px;
      p {
        font-size: 19px;
        br {
          display: none;
        }
      }
    }
  }
`;
