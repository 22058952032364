import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePartner } from 'src/context/PartnerContext';
import './LandingPage.scss';
import SimpleHero from './SimpleHero';
import TextHero from './TextHero';
import Hero from './Hero';
import HeroInteractive from './HeroInteractive';
import NewContent from './NewContent';
import EmailHero from './EmailHero';
import FAQ from './FAQ';
import Courses from './Courses';
import { landing } from './data.js';
import { generalFaqs, pricingAndMembershipFaqs } from 'src/data/faqs';
import ScrollToTop from 'src/ScrollToTop';
import Story from './Story';
import SEO from '@components/Seo/Seo';
import FeaturedOn from './FeaturedOn';
import useHasMounted from '@hooks/useHasMounted';
import LatestPublications from './LatestPublications';

const { newContent, textHero, tabData } = landing;

const Landing = ({
  setVideoModal,
  setToggledSubscribeModal,
  setToggledLoginType,
  searchParams,
  setAlert,
}) => {
  const partnerData = usePartner();
  const [searchParamsObj] = useSearchParams();
  const [background, setBackground] = useState('');
  const [backgroundVideo, setBackgroundVideo] = useState('');
  const [backgroundVideoMobile, setBackgroundVideoMobile] = useState('');
  const [partner, setPartner] = useState('');
  const [partnerLogo, setPartnerLogo] = useState('');
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [body, setBody] = useState('');

  useEffect(() => {
    const sParams = Object.fromEntries([...searchParamsObj]);
    if (sParams) {
      setBackground(partnerData?.background || '');
      setBackgroundVideo(partnerData?.backgroundVideo || '');
      setBackgroundVideoMobile(partnerData?.backgroundVideoMobile || '');
      setPartner(partnerData?.partner || '');
      setPartnerLogo(partnerData?.partnerLogo || '');
      setHeading(partnerData?.heading || '');
      setSubheading(partnerData?.subheading || '');
      setBody(partnerData?.body || '');
    }
  }, [searchParamsObj, partnerData]);

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className="landing-page fade-in">
      <SEO
        title="Sports Performance Training Online Video Course | VS"
        description="Learn a new skill, get tips on how to train with purpose, and practice developing your mindset. Versus library of expert advice articles will help you reach your full potential."
        name="Welcome to Versus Landing Page"
        type="article"
        ogTitle="Versus helps you be your best, both on and off the field."
      />
      <ScrollToTop />
      <Hero
        backgroundVideoMobile={backgroundVideoMobile || backgroundVideo}
        backgroundVideo={backgroundVideo}
        heading={heading}
        body={body}
        searchParams={searchParams}
        setToggledLoginType={setToggledLoginType}
      />
      <TextHero data={textHero} />
      <HeroInteractive backgroundVideo={backgroundVideo} />
      <FeaturedOn />
      <Courses
        title="Roster"
        type="Roster"
        tabData={tabData}
        setVideoModal={setVideoModal}
        setToggledSubscribeModal={setToggledSubscribeModal}
        searchParams={searchParams}
        setAlert={setAlert}
        exploreMore={{ pathname: '/roster' }}
      />
      <Story />
      <SimpleHero
        searchParams={searchParams}
        setToggledLoginType={setToggledLoginType}
        body="For a limited time, get a Versus membership for FREE ($199 value)"
      />
      {/* <NewContent data={newContent} /> */}

      <EmailHero
        title="Be the first to know!"
        mobileTitle="Be the first to know!"
        body="Join our newsletter for new content release updates and get early
          access to upcoming course trailers."
      />

      <br />

      <LatestPublications
        length={4}
        title="Expert Advice"
        searchParams={searchParams}
      />

      <div className="faq-landing">
        <h1>Frequently Asked Questions</h1>
        <FAQ data={generalFaqs} title="General" />
        <FAQ data={pricingAndMembershipFaqs} title="Pricing & Membership" />
      </div>

      <Courses
        type="Course"
        title="Courses"
        tabData={tabData}
        setVideoModal={setVideoModal}
        setToggledSubscribeModal={setToggledSubscribeModal}
        searchParams={searchParams}
        setAlert={setAlert}
        exploreMore={{ pathname: '/courses' }}
      />
    </div>
  );
};

export default Landing;
