import Button from '@components/Button/Button';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Slide({
  data,
  selectedSession,
  handleReplay,
  selectedMavenData,
  currentTimeInSeconds,
  handleNextSession,
}) {
  const navigate = useNavigate();
  return (
    <div className="slide">
      <div className="left">
        <div>
          <h3>Watch Again</h3>
          <div
            className="watch-again"
            onClick={() => handleReplay()}
            style={{ backgroundImage: `url(${selectedSession.image})` }}
          >
            <img
              src="/icons/inbetween-replay.svg"
              className="icon"
              alt="replay video"
            />
            <div className="overlay" />
            <h5>
              S{selectedSession?.sessionNumber} · {selectedSession?.duration}
            </h5>
            <h4>{selectedSession?.title}</h4>
          </div>
          <h3>Explore AI</h3>
          <div
            className="watch-again explore-ai"
            style={{
              backgroundImage: `url(${selectedMavenData.restingImageLandscape})`,
            }}
          >
            <div className="overlay" />
            <div className="copy">
              <h2>Got a question?</h2>
              <h4 className="faded">
                Ask {selectedMavenData?.title?.split(' ')[0]}
              </h4>
            </div>
            <Link
              to={`/interactive/${selectedMavenData?.slug}`}
              className="button primary"
            >
              Talk to {selectedMavenData?.title?.split(' ')[0]}
            </Link>
          </div>
        </div>
      </div>
      <div className="right">
        <div>
          <h3>Next Session</h3>
          <div
            onClick={() =>
              navigate(
                `/course/${selectedSession.mavenSlug}/video/${
                  selectedSession.mavenSlug
                }-${selectedSession.sessionNumber + 1}`
              )
            }
            className="next-up"
            style={{
              backgroundImage: `url(${
                data[selectedSession.sessionNumber]?.image
              })`,
            }}
          >
            <div className="overlay" />
            <img
              src="/icons/inbetween-play-video.svg"
              className="icon"
              alt="play next video"
            />
            <h5>
              S{data[selectedSession.sessionNumber]?.sessionNumber} ·{' '}
              {data[selectedSession.sessionNumber]?.duration}
            </h5>
            <h4>{data[selectedSession.sessionNumber]?.title}</h4>

            <Button className="primary" onClick={() => handleNextSession()}>
              Next Session in {currentTimeInSeconds}s
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slide;
