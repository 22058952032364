import RosterContent from '../Roster/RosterContent';
import './Sport.scss';
import SEO from '@components/Seo/Seo';
import ScrollToTop from 'src/ScrollToTop';

/* const activeMavenData = mavenData.filter((m) => m.status === 'Active');

const transformedData = activeMavenData.map((d, index) => ({
  ...d,
  id: index,
  cardTitle: d.title,
  link: '',
  image: d.rosterCardImage,
  course: d.course === 'TRUE' ? true : false,
  interactive: d.interactive === 'TRUE' ? true : false,
  interactiveOnly: d.course !== 'TRUE',
})); */

/* const allData = {
  id: 3,
  title: 'Roster',
  backgroundImage:
    'https://cdn.bfldr.com/B1ZZKPB4/as/vtzt35pcmj44qnt67p3ss63/VS_BG_Main?auto=webp&format=png',
}; */

function SportPage({
  setVideoModal,
  setToggledSubscribeModal,
  searchParams,
  setAlert,
  title,
  type,
  slug,
  allData,
  sportPageData,
  transformedData,
  sport,
}) {
  //let { slug } = useParams();
  //const [sport, setSport] = useState(null);

  /*const tDataBySlug = transformedData.filter(
    (t) => t.sport?.toLowerCase() === slug
  );

  const sportPageData = [
    {
      id: 0,
      slug: 'baseball',
      title: 'Baseball',
      backgroundImage:
        'https://cdn.bfldr.com/B1ZZKPB4/as/vtzt35pcmj44qnt67p3ss63/VS_BG_Main?auto=webp&format=png',
      subtitle:
        'Learn from current MLB stars and Hall of Fame legends to gain an edge on the diamond.',
      meta: [
        {
          title: 'Experts',
          value: tDataBySlug.length,
        },
        {
          title: 'Sessions',
          value: tDataBySlug
            .map((m) => +m.sessionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
        {
          title: 'Interactions',
          value: tDataBySlug
            .map((m) => +m.questionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
      ],
    },
    {
      id: 1,
      slug: 'softball',
      title: 'Softball',
      backgroundImage:
        'https://cdn.bfldr.com/B1ZZKPB4/as/vtzt35pcmj44qnt67p3ss63/VS_BG_Main?auto=webp&format=png',
      subtitle:
        'Learn from current Softball stars and Hall of Fame legends to gain an edge on the diamond.',
      meta: [
        {
          title: 'Experts',
          value: tDataBySlug.length,
        },
        {
          title: 'Sessions',
          value: tDataBySlug
            .map((m) => +m.sessionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
        {
          title: 'Interactions',
          value: tDataBySlug
            .map((m) => +m.questionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
      ],
    },
    {
      id: 2,
      slug: 'soccer',
      title: 'Soccer',
      backgroundImage:
        'https://cdn.bfldr.com/B1ZZKPB4/as/vtzt35pcmj44qnt67p3ss63/VS_BG_Main?auto=webp&format=png',
      subtitle:
        'Learn from current Soccer stars and Hall of Fame legends to gain an edge on the field.',
      meta: [
        {
          title: 'Experts',
          value: tDataBySlug.length,
        },
        {
          title: 'Sessions',
          value: tDataBySlug
            .map((m) => +m.sessionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
        {
          title: 'Interactions',
          value: tDataBySlug
            .map((m) => +m.questionCount)
            .reduce((partialSum, a) => partialSum + a, 0),
        },
      ],
    },
  ]; */

  /* useEffect(() => {
    if (slug) {
      const sportData = sportPageData.find((s) => s.slug === slug);
      setSport(sportData);
    } else {
      setSport(allData);
    }
  }, [slug]); */

  const getDescription = () => {
    if (title === 'Roster') {
      return 'Our Roster is a series of elite-level online courses featuring world-renowned athletes and coaches, who share their training insights. Improve your performance by learning directly from the best in their sport through conversational AI and online video courses.';
    }
    if (title === 'Courses') {
      return 'Versus offers online courses created for athletes, coaches, parents and anyone that loves learning. Our mentors are the best in the world in their craft. Learn from the best.';
    }
    if (title === 'Conversations') {
      return 'With VS Interactive Conversational AI, you can ask questions to world-class athletes and coaches about anything--training techniques, mindset, how to overcome adversity. Get your questions answered and learn from the best.';
    }
    if (sport?.title === 'Baseball') {
      return "Learn how to train for baseball from some of the world's best athletes and coaches. From mindset to technical training techniques VS provides exclusive courses to help you reach your full potential.";
    }
    if (sport.title === 'Softball') {
      return "Learn how to train for softball from some of the world's best athletes and coaches. From mindset to technical training techniques VS provides exclusive courses to help you reach your full potential.";
    }
    if (sport.title === 'Soccer') {
      return "Learn how to train for soccer from some of the world's best athletes and coaches. From mindset to technical training techniques VS provides exclusive courses to help you reach your full potential.";
    }
    return 'Versus is a new digital edtech platform that provides aspiring athletes access to elite mentorship and skills training with professional athletes through exclusive video content and conversational AI.';
  };

  const getTitle = () => {
    if (title === 'Roster') {
      return 'Explore Roster: Learn from the Best to Be Your Best | Versus';
    }
    if (title === 'Courses') {
      return 'Sports Performance Training Online Courses | Versus';
    }
    if (title === 'Conversations') {
      return 'Ask Athletes and Coaches Anything with VS Interactive Conversational AI | Versus';
    }
    if (sport?.title === 'Baseball') {
      return 'Baseball Drills & Training Online Courses | Versus';
    }
    if (sport.title === 'Softball') {
      return 'Softball Drills & Training Online Courses | Versus';
    }
    if (sport.title === 'Soccer') {
      return 'Soccer Drills & Training Online Courses | Versus';
    }
    return 'Versus is a new digital edtech platform that provides aspiring athletes access to elite mentorship and skills training with professional athletes through exclusive video content and conversational AI.';
  };

  return (
    <div className="sport-page roster">
      {sport && (
        <>
          <ScrollToTop />
          <SEO
            title={getTitle()}
            description={getDescription()}
            name={`${sport?.title} content at VS`}
            type="article"
            keywords={`sports performance training, online courses, ${
              sport?.title !== 'Roster' ? sport?.title : ''
            } training`}
          />

          <RosterContent
            sport={sport}
            setVideoModal={setVideoModal}
            setToggledSubscribeModal={setToggledSubscribeModal}
            searchParams={searchParams}
            transformedData={transformedData}
            setAlert={setAlert}
            title={title}
            type={type}
          />
          {/* <ExploreCollections
            bImage={`url(${sport?.backgroundImage})`}
            sport={sport.title === 'Roster' ? null : sport}
            searchParams={searchParams}
          /> */}
        </>
      )}
    </div>
  );
}

export default SportPage;
