import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { AuthState } from './context/AuthContext';
import reportWebVitals from './reportWebVitals';
import App from './App.js';
import './styles/index.css';
import { SubscriptionProvider } from './context/SubscriptionContext';
import { PartnerProvider } from './context/PartnerContext.js';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <AuthState>
          <SubscriptionProvider>
            <PartnerProvider>
              <App />
            </PartnerProvider>
          </SubscriptionProvider>
        </AuthState>
      </Router>
    </Provider>
  </React.StrictMode>
);

/* const container = document.getElementById('root');
console.log('container', container); */

/* if (container.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <AuthState>
            <SubscriptionProvider>
              <PartnerProvider>
                <App />
              </PartnerProvider>
            </SubscriptionProvider>
          </AuthState>
        </Router>
      </Provider>
    </React.StrictMode>,
    container
  );
} else { */
/* ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <AuthState>
          <SubscriptionProvider>
            <PartnerProvider>
              <App />
            </PartnerProvider>
          </SubscriptionProvider>
        </AuthState>
      </Router>
    </Provider>
  </React.StrictMode>,
  container
); */
/* } */
serviceWorkerRegistration.unregister();
reportWebVitals();
