import { useEffect, useState } from 'react';
import { articlesList } from '../../data/articles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './ArticlePage.scss';
import Button from '@components/Button/Button';
import EmailHero from '../LandingPage/EmailHero';
import CardStack from 'src/layout/CardStack/CardStack';
import { mavenData } from 'src/data/mavenData';
import EmailHeroForm from '../LandingPage/EmailHeroForm';
import SEO from '@components/Seo/Seo';
import { InlineShareButtons } from 'sharethis-reactjs';
import useHasMounted from '@hooks/useHasMounted';

const activeSessionData = mavenData.filter((s) => s.status === 'Active');

function ArticlePage({
  setVideoModal,
  setToggledSubscribeModal,
  searchParams,
  setAlert,
  setToggledLoginType,
  setContactModal,
  currentUser,
}) {
  const params = useParams();
  const navigate = useNavigate();

  const [article, setArticle] = useState(null);
  const [interactiveStack, setInteractiveStack] = useState(null);
  const [randomAthlete, setRandomAthlete] = useState(null);
  const [videoForModal, setVideoForModal] = useState(null);
  const [actions, setActions] = useState(null);

  useEffect(() => {
    const a = articlesList.find((a) => a.Slug === params.slug);

    setArticle(a);

    const rAthlete =
      activeSessionData[
        Math.round(Math.random() * (activeSessionData.length - 1))
      ];
    setRandomAthlete(rAthlete);

    const actions = [
      {
        id: 0,
        title: 'Share',
        image: '/icons/share.svg',
        className: 'secondary',
        callback: () => {
          navigator.clipboard.writeText(
            `${window.location.origin}/course/${rAthlete.slug}`
          );
          setAlert({
            type: 'success',
            message: 'Copied link',
            icon: '/icons/checkmark.svg',
          });
          setTimeout(() => {
            setAlert(null);
          }, 3000);
        },
      },
      {
        id: 1,
        title: 'Go To Course',
        callback: () => {
          navigate(`/course/${rAthlete.slug}${searchParams}`);
          setVideoModal(null);
        },
        className: 'primary',
      },
    ];

    const videoForModal = {
      video: rAthlete.trailer,
      title: rAthlete.title,
      subtitle: rAthlete.sessionCount
        ? `${rAthlete.sessionCount} Sessions (${rAthlete.sport})`
        : rAthlete.sport,
      actions,
    };

    setVideoForModal(videoForModal);

    const iStack = activeSessionData.map((ss) => ({
      ...ss,
      count: ss.topicCount,
      cardTitle: ss.title,
      type: 'Interactive',
      image: ss.restingImage,
      restingVideo: ss.restingVideo,
    }));

    const filteredIStack = iStack.find((s) => s.slug === a?.['Maven CTA']);
    const randomIStack =
      iStack[Math.round(Math.random() * (activeSessionData.length - 1))];

    if (filteredIStack) {
      setInteractiveStack(filteredIStack);
    } else {
      setInteractiveStack([randomIStack]);
    }
  }, [params.slug]);

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className="article-page fade-in">
      {article && (
        <>
          <SEO
            title={`${article.Name} | Versus`}
            description={`${article['Post Summary']}`}
            name={`${article.Name} for VS`}
            image={article['Thumbnail image']}
            type="article"
            keywords={`${article.Sport}, ${article.Topic}, ${article['Maven CTA']}`}
          />
          <div className="breadcrumbs">
            <Link to={`/learn${searchParams}`}>Expert Advice</Link>
            <span>/</span>
            <Link to={`/learn/${article.topic}${searchParams}`}>
              {article.topic.charAt(0).toUpperCase() +
                article.topic.slice(1, article.topic.length)}
            </Link>
            <span>/</span>
            {article.Name}
          </div>
          <div className="article-body">
            <h1>{article.Name}</h1>
            <div className="meta">
              <div className="meta-details">
                <span className="faded">Written by Versus</span>
                <span className="faded">
                  <label className="hide-mobile">Last Updated on </label>
                  {new Date(article['Updated On']).toDateString()}
                </span>
              </div>
              <div className="social">
                <InlineShareButtons
                  config={{
                    alignment: 'center', // alignment of buttons (left, center, right)
                    color: 'social', // set the color of buttons (social, white)
                    enabled: true, // show/hide buttons (true, false)
                    font_size: 12, // font size for the buttons
                    labels: null, // button labels (cta, counts, null)
                    language: 'en', // which language to use (see LANGUAGES)
                    networks: [
                      // which networks to include (see SHARING NETWORKS)
                      'sms',
                      'linkedin',
                      'messenger',
                      'facebook',
                      'twitter',
                      'email',
                      'pinterest',
                    ],
                    padding: 12, // padding within buttons (INTEGER)
                    radius: 8, // the corner radius on each button (INTEGER)
                    show_total: false,
                    size: 36, // the size of each button (INTEGER)

                    // OPTIONAL PARAMETERS

                    //min_count: 10, // (threshold for total share count to be displayed)
                    url: /* window.location.href */ `https://versus.co/learn/${article.Slug}`, // (defaults to current url)
                    image: article['Main Image'], // (defaults to og:image or twitter:image)
                    description: 'custom text', // (defaults to og:description or twitter:description)
                    title: `Advice Article: ${article.Name}: Learn Sports Training Skills & Techniques with Expert Advice from Versus | VS`, // (defaults to og:title or twitter:title)
                    message: 'Check out this expert advice on VS', // (only for email sharing)
                    subject: `Check out this Expert Advice article: ${article.Name}
                  `, // (only for email sharing)
                    username: 'vsapp_', // (only for twitter sharing)
                  }}
                />
              </div>
            </div>

            <img
              className="post-image"
              src={article['Main Image']}
              alt={article.Name}
            />

            {!currentUser && (
              <div
                className="ad-banner"
                style={{ backgroundImage: `url('/pages/ad-banner.jpg')` }}
              >
                <div className="overlay" />
                <h3>Get 3-days free when you create and account today!</h3>

                <Button
                  className="primary button"
                  title="Article"
                  action="Sign Up"
                  //onClick={() => setToggledLoginType('signup')}
                  onClick={() => navigate('/pass/?id=vsunlimited')}
                >
                  Create Free Account
                </Button>
              </div>
            )}

            <div className="content-container">
              <div className="htmlBody">
                <h2 className="post-summary">{article['Post Summary']}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: article['Post Body'] }}
                />
                <div className="athlete-banner">
                  <div className="left">
                    <h3>Versus featured {article.Sport} expert</h3>
                    <Button
                      className="primary"
                      title="Article"
                      action="Watch Intro"
                      description={randomAthlete?.title}
                      onClick={() => setVideoModal(videoForModal)}
                    >
                      Watch Intro
                    </Button>
                  </div>
                  <div className="right">
                    <div className="overlay" />
                    <img
                      src={randomAthlete?.courseHeroImage}
                      alt={randomAthlete?.title}
                    />
                    <h4>{randomAthlete.title}</h4>
                  </div>
                </div>
              </div>
              {!currentUser && (
                <div className="right">
                  <div className="signup">
                    <h3>Keep up with the Versus community.</h3>
                    <p>
                      Get notified of new content releases, new features, and
                      much more!
                    </p>
                    <EmailHeroForm />
                  </div>
                  <CardStack
                    type="Conversation"
                    data={interactiveStack}
                    setToggledSubscribeModal={setToggledSubscribeModal}
                    searchParams={searchParams}
                    iconPath="/interactive/speak-button.svg"
                  />
                </div>
              )}
            </div>
            <EmailHero
              title="Sign up to get new article notifications"
              body="Join our newsletter for new course release updates and get early access to upcoming course trailers."
            />
            <div className="membership-banner">
              <div className="overlay" />
              <img
                src="https://cdn.bfldr.com/B1ZZKPB4/as/tr2q9w3w5qcxvjjz7rtv9j8t/Group_29613525?auto=webp&format=png"
                alt="membership"
              />
              <div className="card-body">
                <h3>Level up your organization with a group membership.</h3>
                <p>
                  Want to get Versus for your group or organization? Experience
                  volume based discounts and give your team members access to
                  many of the greatest competiors.
                </p>
                <Button
                  className="primary button"
                  title="Group Membership"
                  action="Contact"
                  onClick={() =>
                    setContactModal({
                      type: 'Group Membership',
                      title: 'Group Membership Inquiry',
                    })
                  }
                >
                  Contact Sales
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ArticlePage;
