export const sessionVideos = [
  {
    slug: 'adam-wainwright-1',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Early Life & Childhood',
    sessionNumber: 1,
    firebaseId: 'sjBMLRaXYZaihcHEl7vA',
    videoId: 'VhgR6fGV8mMONUzWKhlW',
    episode: 1,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/8mw35xjbb8wb75r2khpb3ps/KelleyOHaraThumbnails_0008_Session_9.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/JzMsKItUj2ZHq7PZcX00rFsDYJpaNnbpw.m3u8',
    duration: '10:55',
    durationInSeconds: 655,
    durationInMiliSeconds: 655000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/88s5hgks35gg2pvcm74xpf/01_Adam_Wainwright_Early_Life_and_Childhood_FINAL_H264_20Mbps_Apr21_7am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/wrmz6m389g54q5n784w79vw/AdamWainwrightthumbnails2_0000_Session_1?auto=webp&format=png',
    shortDescription:
      'From humble beginnings in Georgia to the MLB, Adam Wainwright has earned his place as one of the greatest pitchers to ever play the game of baseball. Hear how Adam became one of the most consistent pitchers in the big leagues.',
    longDescription:
      "Adam Wainwright has achieved remarkable success, rising from modest beginnings in Georgia to the Major Leagues. His immense talent and hard work have made him one of baseball's greatest pitchers ever! Hear how this inspiring story of dedication came together for Adam on his journey to MLB notoriety!",
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-2',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'The Minors',
    sessionNumber: 2,
    firebaseId: 'FjDbhP68iWEpnIObZebc',
    videoId: 'X21MUWxltLcSw2RjENZI',
    episode: 2,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/8zpm472xw4v8njkf5gg52n/AdamWainwrightthumbnails2_0001_Session_2.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/ymdl02e6YhHfOLpsncjs00iunZy02RgnfC7.m3u8',
    duration: '12:00',
    durationInSeconds: 720,
    durationInMiliSeconds: 720000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bk2bbjthvcr4k9s36srnxprq/02_Adam_Wainwright_The_Minors_FINAL_H264_20Mbps_Apr21_7am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/vzvfc7qvwsjzxr4vhhcv66h9/AdamWainwrightthumbnails2_0001_Session_2?auto=webp&format=png',
    shortDescription:
      'What was is like dreaming and working to make it to the biggest stage? Adam talks life and baseball before the MLB. Hard work. Setbacks and advice to all dreamers working to make it to the next level.',
    longDescription:
      "Adam knows all about the journey to his MLB dream -the highs and lows, immense effort, setbacks, and more. He's here to tell us that no matter what we're striving for in life- it won't be easy but our hard work can pay off if we stay committed! Listen as Adam gives invaluable advice on how you too can reach new heights with your ambitions.",
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-3',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'The Majors',
    sessionNumber: 3,
    firebaseId: 'nlVTA5rJQVRUBRQ4z1jW',
    videoId: 'yzEMVRUszFg1DFBRLtk1',
    episode: 3,
    thumbnail:
      'https://videogif.bfldr.com/l7AzBC8YUEk7lzyDrVVl0201RgQYMkCbcv/animated.gif',
    m3u8Link: 'https://stream.mux.com/38j5eOK01f9NZYRKigpvTkZ4ul5ykFGQJ.m3u8',
    duration: '10:21',
    durationInSeconds: 621,
    durationInMiliSeconds: 621000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/mbgnjzprqfbkmfvr2nt59s2k/03_Adam_Wainwright_The_Majors_FINAL_H264_20_Mbps_Apr21_7am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hpwj475rsz4vq6swpxtmvfj/AdamWainwrightthumbnails2_0002_Session_3?auto=webp&format=png',
    shortDescription:
      'Learn how to navigate success and pressure. Adam discusses what he learned at the every level that will help young athletes. Whether you’re a in little league or a varsity starter, learn how a pro approaches the game we all love.',
    longDescription:
      'Equip yourself to succeed in the game you love! Adam can show young athletes how a professional athlete navigates success and pressure, no matter what their level of play. From little league players through varsity starters, learn tactics that will prepare them for any situation they may face on the field.',
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-4',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Great Teammates',
    sessionNumber: 4,
    firebaseId: 'ci96J9dzwrKY9g4G0p0Y',
    videoId: 'RRXdoGjaZcnz0tlDw371',
    episode: 4,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/ksx36w9swhzskfm9jgspkvw/AdamWainwrightthumbnails2_0003_Session_4.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/402rG0191qv8a4f47xnFQKZqn6cTRQF8mW.m3u8',
    duration: '7:59',
    durationInSeconds: 479,
    durationInMiliSeconds: 479000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/6cjx43hbvx7xtmzxbkjtsq/04_Adam_Wainwright_Great_Teammates_Carp_Yadi_FINAL_H264_20Mbps_Apr21_7am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/vz4kmbvzghp678949nvbh/AdamWainwrightthumbnails2_0003_Session_4?auto=webp&format=png',
    shortDescription:
      'What makes a great teammate? Adam talks about what makes a great teammate and how to develop your player that builds your team up.',
    longDescription:
      "To be a great teammate requires more than just skill, but also the ability to encourage and uplift your fellow players. Adam will share practical steps on how to develop yourself as an individual while strengthening your team's bond!",
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-5',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Mentality Is Everything',
    sessionNumber: 5,
    firebaseId: '2rtnH6JJiZZAfujj2tOs',
    videoId: 'k2gv40EmFcXb612RSLm2',
    episode: 5,
    thumbnail:
      'https://videogif.bfldr.com/gxnMCNsiPtZqHxmmBbKRilYXl79iDbku/animated.gif',
    m3u8Link: 'https://stream.mux.com/NgL75Zo7N3nL1mWDEDEV00yvPMC019m2w6.m3u8',
    duration: '11:55',
    durationInSeconds: 714,
    durationInMiliSeconds: 714000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/qjn6vg6pss32v2g8cpskrqv/05_Adam_Wainwright_Mentality_is_Everything_FINAL_H264_Apr21_7am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/pmm39hjtnt5tsf26pnx349mx/AdamWainwrightthumbnails2_0004_Session_5?auto=webp&format=png',
    shortDescription:
      'How does your mindset translate on the field? Adam talks about how to create conviction and commitment into every pitch you throw.',
    longDescription:
      'Every pitch you throw is an opportunity to demonstrate your commitment and conviction. Adam will show how a positive mindset can be the difference between success or failure on the field - no matter what situation you face!',
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-6',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Adversity and Opportunity',
    sessionNumber: 6,
    firebaseId: 'AzwOoTfsDTBCVFEfttRa',
    videoId: '03VcLgxkEQNu0SBAWGGX',
    episode: 6,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/9n36v3sx4h8ftmjf2mtrmt/AdamWainwrightthumbnails2_0005_Session_6.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/saZ9NmN700XiJJwRgWaGxUKJovgTP01l6z.m3u8',
    duration: '8:14',
    durationInSeconds: 494,
    durationInMiliSeconds: 494000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/n5vgw7c45m34ftqj6fwc4x3/06_Adam_Wainwright_Adversity_Opportunity_FINAL_H264_Apr21_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/wf8b3tggxngk5hb5v5c7v8fk/AdamWainwrightthumbnails2_0005_Session_6?auto=webp&format=png',
    shortDescription:
      'How do you turn adversity into opportunity? Adam talks about how to use doubt to fuel your game both mentally and physically.',
    longDescription:
      "How do you turn a challenge into an opportunity? Adam reveals how to use doubt as fuel for your mental and physical performance, so that it pushes you further and helps make all of life's obstacles seem surmountable. Discover the power within yourself to face adversity head on with confidence!",
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-7',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Stretching and Arm Care',
    sessionNumber: 7,
    firebaseId: 'NtDw2XctvnWyZrP2xB9g',
    videoId: 'H9iSFSVd9iuXrR5PzLp1',
    episode: 7,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/63ns2jb9h3pk5hkbnk6smzwz/AdamWainwrightthumbnails2_0006_Session_7.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/JKNQHUiyXpjTZ56NWJ1SYo9u85C6Ltt4.m3u8',
    duration: '5:42',
    durationInSeconds: 342,
    durationInMiliSeconds: 342000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/8g726hhpsgv7kjjpn8sc8m/07_Adam_Wainwright_Stretching_Arm_Care_FINAL_H264_20_Mbps_Apr21_7am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/3v9v56nshztg8qwjpgmp9xc9/AdamWainwrightthumbnails2_0006_Session_7?auto=webp&format=png',
    shortDescription:
      'How do you get ready to pitch in between starts? Regardless of the day or the stress levels, the one thing you get to do every day is arm care and play catch. Learn how to build a routine that keeps you ready for every start.',
    longDescription:
      "Every day is a new opportunity to be ready and prepared for success. Arm care and playing catch are essential habits in your routine that will help you hone your skills so when it's time, you can hit the pitching mound with confidence! Take advantage of each day by creating a structured plan designed specifically to get yourself ready between starts.",
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-8',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Playing Catch with a Purpose',
    sessionNumber: 8,
    firebaseId: 'vIHbEn9zGyHxfjLCXLf8',
    videoId: 'RazMlGCbcRa03bAnJASI',
    episode: 8,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/gr4ghx898tf3398sh36x/AdamWainwrightthumbnails2_0007_Session_8.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/dwjGqvGCMEFIAaoDXUrkOyZRrsbg018lQ.m3u8',
    duration: '4:31',
    durationInSeconds: 271,
    durationInMiliSeconds: 271000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bm7m5mq5cnrck96prm7kkhc8/08_Adam_Wainwright_Playing_Catch_With_A_Purpose_FINAL_H264_20Mbps_Apr21_7am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/5nffj3sbmwhvjkqkgws75/AdamWainwrightthumbnails2_0007_Session_8?auto=webp&format=png',
    shortDescription:
      'Catch play is your opportunity to get better every single day, and experiment with your pitches and grips. Adam talks about the importance of a laser focused mindset and how you can use catch play to develop skills that will translate into the game.',
    longDescription:
      'Embrace catch play as your opportunity to become a more dynamic pitcher every single day! Adam experienced the immense value of having laser focus and using it during catch play. He realized that this mindset, combined with experimentation in grips and pitches development his skillset - enabling him to take things he learned directly into game-time performance.',
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-9',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'My Bullpen Routine',
    sessionNumber: 9,
    firebaseId: 'lj31igLyMFShAanWeU1M',
    videoId: 'DZ3nrvIMNA84omzSAYfY',
    episode: 9,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/335vx76vr5mtrvfp6m74kwkm/AdamWainwrightthumbnails2_0008_Session_9.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/C02KXA1CDULbblHOLnGl3oHvR1uJ962gm.m3u8',
    duration: '7:32',
    durationInSeconds: 452,
    durationInMiliSeconds: 452000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/93h6rzgwthphnn98nw35tb6t/09_Adam_Wainwright_My_Bullpen_And_Pitch_Grips_FINAL_H264_20Mbps_Apr21_7am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/tsx34x69xn6526bb5sxkhghq/AdamWainwrightthumbnails2_0008_Session_9?auto=webp&format=png',
    shortDescription:
      'How do you get the most out of your bullpens? Adam takes you behind the scenes into his bullpen practice and routine. He walks you through each phase, pitch groups, and teaches you how to work with intentionality to get the most out of your bullpen.',
    longDescription:
      "Adam has the tools to show you how to reach success in your bullpen! He will guide you on a journey of discovery, giving insight into his practice and routine. Learn from Adam's unique approach as he explains each phase and pitch group, showing that working with intentionality is key for elevating performance levels. Don't be afraid to take control – unleash your potential today!",
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-10',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Pitcher Fielding Practice',
    sessionNumber: 10,
    firebaseId: 'xDEdWFvnb4UYSUXBe470',
    videoId: 'LfMy4X0RC1c1YtCqd1ui',
    episode: 10,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/w3bpk2pjxggmt9hfzsrcbqs/AdamWainwrightthumbnails2_0009_Session_10.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/bZhK801mlZO7Ujw79t9Ofw6d7L02z2kEsV.m3u8',
    duration: '9:31',
    durationInSeconds: 571,
    durationInMiliSeconds: 571000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/wzx4m4t8n8mzrf7g5q7nrbhr/10_Adam_Wainwright_Pitcher_Fielding_Practice_FINAL_H264_20Mbps_Apr21_7am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hv8zt68kmhxsk94c3wbfsgx/AdamWainwrightthumbnails2_0009_Session_10?auto=webp&format=png',
    shortDescription:
      "Adam talks about key lessons learned and how he trained to become one of the best fielding pitchers in the game today. You'll learn how you can create a competitive mindset and how you can do the little things right that make a big impact in your performance.",
    longDescription:
      'Adam is a testament to what you can achieve when hard work and dedication come together. He will share the secrets of how he harnessed his competitive spirit and mastered the details that resulted in becoming one of the top fielding pitchers out there! Learn from Adam today on how to tap into your potential, develop winning strategies, and ultimately rise above any challenge.',
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-11',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Being a Complete Pitcher',
    sessionNumber: 11,
    firebaseId: 'XLbWYB7XhDgeQsuuZ0Wm',
    videoId: 'SrRm2luqzkO6E76nd4JA',
    episode: 11,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/msg95vcbm3cwjwwxt4wz37x7/AdamWainwrightthumbnails2_0010_Session_11.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/QXZXtEyZFu4MajMLHSaseiXK6E9LTuG6.m3u8',
    duration: '8:13',
    durationInSeconds: 493,
    durationInMiliSeconds: 493000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/fmf4rzx9xcgmkx74p9n4t9rf/11_Adam_Wainwright_Complete_Pitcher_FINAL_H264_Apr21_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/wfc59pmtr3673t28v4svcr2t/AdamWainwrightthumbnails2_0010_Session_11?auto=webp&format=png',
    shortDescription:
      'Adam sets the stage for what it means to be a complete pitcher. Learn key elements all good pitchers possess--Commanding the strike zone, fielding your position, covering/backing up bases, holding runner on, repeatable mechanics, reading hitters, and situational awareness.',
    longDescription:
      'Adam shows us that to be an ace pitcher, you have got to go the extra mile. You need confidence in commanding the strike zone, smart fielding skills, and without fail back up those bases when needed! Keep your mechanics repeatable, read hitters like, and develop your situational awareness for success on any mound.',
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-12',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Working Hitters',
    sessionNumber: 12,
    firebaseId: 'kJWj3xOIA9wXLM3VDvwr',
    videoId: 'gx7dV2yIRUb1QtGkbROD',
    episode: 12,
    thumbnail:
      'https://videogif.bfldr.com/muaIbfV2BpjTHI2eESIiL1HBOLiW01coB/animated.gif',
    m3u8Link: 'https://stream.mux.com/6kDvqClA2p9tzJEDkUkRSGKWGm01ArXl4.m3u8',
    duration: '9:50',
    durationInSeconds: 590,
    durationInMiliSeconds: 590000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/7mcjq9s6pct6bz6qpcchwhxq/12_Adam_Wainwright_Working_Hitters_FINAL_H264_Apr21_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/kkhm9qrtpxprv6f52hp79k2p/AdamWainwrightthumbnails2_0011_Session_12?auto=webp&format=png',
    shortDescription:
      'Learn how to stay ahead of hitters and keep leverage on your side as a pitcher. Adam talks about what you should read with hitters and how to use their aggression against them.',
    longDescription:
      'As a pitcher, you have the power to gain an advantage over your opponents. Adam shares his insights for reading hitters’ cues and using their aggression against them so that you can remain one step ahead! Arm yourself with knowledge - it could be the difference between victory and defeat!',
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-13',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Routines',
    sessionNumber: 13,
    firebaseId: 'nEniEmP2LBja2ugkGtb3',
    videoId: 'zHqtVJKM4mlTZoYt2QSi',
    episode: 13,
    thumbnail:
      'https://videogif.bfldr.com/1Vh3InQalYDBKf8i3ZnARPOScs37KeMA/animated.gif',
    m3u8Link:
      'https://stream.mux.com/L02OgCxdossWxrSzQgQvWiQ00wwR01wqY00M.m3u8',
    duration: '8:10',
    durationInSeconds: 489,
    durationInMiliSeconds: 489000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/xkm6pvq8mn9w6h5j9zrt7bgb/13_Adam_Wainwright_Routines_FINAL_H264_20Mbps_Apr21_7am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/435f38vcxvm95fvks3rf7nv/AdamWainwrightthumbnails2_0012_Session_13?auto=webp&format=png',
    shortDescription:
      "Adam talks you through his approach on game day from the minute he wakes up. He'll talk about the prep work required to make sure you're ready to toe the rubber and gives you exclusive access into his game day routines from start to finish.",
    longDescription:
      "Game day is the culmination of all your hard work and preparation, but how do you ensure that you enter it in peak condition? Adam will share with us his exclusive approach to game days - from when he wakes up, until the final pitch. He'll show us why proper mental and physical prep are fundamental for success on gameday!",
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-14',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'What Matters Most',
    sessionNumber: 14,
    firebaseId: 'qEX4nP213WDUwvYey3qi',
    videoId: 'p0EKaj7DhTdVCatyVNvh',
    episode: 14,
    thumbnail:
      'https://videogif.bfldr.com/azKsld66C5zm00KVceAvzoHP3omblmESV/animated.gif',
    m3u8Link: 'https://stream.mux.com/4SClWIS2a5AfCNyZQs01rB2sD43i3LqXT.m3u8',
    duration: '9:43',
    durationInSeconds: 583,
    durationInMiliSeconds: 583000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/gc2jsbtrmcnfczcvtvp52vpw/14_Adam_Wainwright_What_Matters_Most_FINAL_H264_20Mbps_Apr21_9pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/f479n5x7qb3cf74jfvrx6m/AdamWainwrightthumbnails2_0013_Session_14?auto=webp&format=png',
    shortDescription:
      'Adam mentors you about his life lessons. Get actionable advice on what he knows to be true that will turn you into a valuable player, teammate, friend, husband, father and so much more.',
    longDescription:
      'Adam shares his experiences with you and can help guide your path to success. Learn from him the essential steps that will lead to becoming an invaluable person in every area of life, such as being a hardworking player on any team, forming lasting relationships based off of friendship & trustworthiness, taking pride in becoming a great husband or father & so much more!',
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'adam-wainwright-15',
    mavenSlug: 'adam-wainwright',
    maven: 'Adam Wainwright',
    title: 'Leadership',
    sessionNumber: 15,
    firebaseId: 'DFyXEDtDOPWzwMasuntd',
    videoId: '4awVwpO28l5UVKzwFp3d',
    episode: 15,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/kkfhkxvzcwkkfsc8vh7b3wbz/AdamWainwrightthumbnails2_0014_Session_15.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/yAPz00aNQTAmgD5xTMqwIA1ObTD5VKMSU.m3u8',
    duration: '7:21',
    durationInSeconds: 441,
    durationInMiliSeconds: 441000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/46546qtx7sq5z3xjwwr5b5c/15_Adam_Wainwright_Leadership_FINAL_H264_20Mbps_Apr21_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bnct74qj9f7r5pm5x6fxbzjg/AdamWainwrightthumbnails2_0014_Session_15?auto=webp&format=png',
    shortDescription:
      'What makes a great leader? Adam talks about what makes a great leader and how to develop your leadership skills to better your organization.',
    longDescription:
      'Leaders are the backbone of any successful organization. It is their vision and drive that creates growth, develops values and demonstrates impactful decision making abilities. With these attributes in mind, Adam can guide you on your journey to becoming a great leader by providing advice on how to cultivate effective leadership skills for positive organizational development',
    firebaseMavenId: '97Sb4vjsc7fSbYAeWliG',
  },
  {
    slug: 'albert-pujols-1',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Growing Up In the Dominican Republic',
    sessionNumber: 1,
    firebaseId: 'XcJoGit5sgi7SNYsbEqh',
    videoId: 'NjdnNlYxcS00fkbj4RKJ',
    episode: 1,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/9439n68rbqhmkqr67s5rbf7/AdamWainwrightthumbnails2_0000_Session_1.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/rSBC77OjiCft01zqYc2HUROjaoE9jL48G.m3u8',
    duration: '7:29',
    durationInSeconds: 449,
    durationInMiliSeconds: 449000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/fnmwbrktv9pb67wtw7gbpj/01_Albert_Pujols_Growing_Up_In_The_Dominican_Republic_FINAL_H264_20bps_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/5jj53fh647qsmc5gjfxz54t/Albert_PujolsThumbnails_0000_Session_1?auto=webp&format=png',
    shortDescription:
      'Without a doubt, Albert Pujols is one of the greatest baseball players to ever play the game. 10x All-Star Albert will teach you the practical and mental skills for improving your game.',
    longDescription:
      'Albert Pujols is the epitome of excellence in baseball. With 10x All-Star status, Albert has mastered practical and mental skills to reach such heights - learn them today so you can take your game to the next level!',
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-2',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Moving to US and Starting Pro Career',
    sessionNumber: 2,
    firebaseId: 'waBlxQh74E1YkMLROf9g',
    videoId: 'PmoqLGMHb5lzmqwjYmYi',
    episode: 2,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/j66t8bjkkpprbk5f5ctz7g/Albert_PujolsThumbnails_0001_Session_2.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/IN4StJHFwmBHwN602TAR9UQaftd1NGVqX.m3u8',
    duration: '7:19',
    durationInSeconds: 439,
    durationInMiliSeconds: 439000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/c49gp6bf2hn3343wxkb648b/02_Albert_Pujols_Moving_To_US_and_Starting_Pro_Career_FINAL_H264_20Mbps_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/2s3c6jt9sn24kfw6kxjhnmpz/Albert_PujolsThumbnails_0001_Session_2?auto=webp&format=png',
    shortDescription:
      'Albert talks about the obstacles he had to overcome moving to a new country and learning a new language to adapt to his new environment.',
    longDescription:
      'Albert faced a challenging journey when he moved to an unfamiliar country; however, rather than retreat into his comfort zone and give up, he chose the brave path of embracing change. By taking on this foreign language as well as other aspects of adjusting to life in another culture, Albert persevered against all odds—proving that through determination we can overcome any obstacle!',
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-3',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Making MLB Debut',
    sessionNumber: 3,
    firebaseId: 'ysiVMK6femGI861Wu0S7',
    videoId: 'qcIkSvtZRHjEoGwa4KEI',
    episode: 3,
    thumbnail:
      'https://videogif.bfldr.com/K9PdhBDdocExxytGs500HB6B91Wyr74du/animated.gif',
    m3u8Link: 'https://stream.mux.com/hlXjl02edMwvDulR4iRqMoK5OzbaCv01ZE.m3u8',
    duration: '5:53',
    durationInSeconds: 353,
    durationInMiliSeconds: 353000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/pxp6mtkcmw94cc39j8ntm2r/03_Albert_Pujols_Making_ML_Debut_and_Career_FINAL_H264_20Mbps_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/q69t35mx9gqffktrgkxsnskf/Albert_PujolsThumbnails_0002_Session_3?auto=webp&format=png',
    shortDescription:
      'Making your MLB debut is what every player dreams of. Albert talks about how he handled the pressure of being in the show and the mindset it took to stay there.',
    longDescription:
      "Albert achieved a momentous milestone - making his Major League Baseball debut. Hear him share the mentality and strategies it took to remain in that space of success, despite any pressures he faced along the way. Making your MLB dreams into reality is within reach – be inspired by Albert's story!",
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-4',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Overcoming Adversity',
    sessionNumber: 4,
    firebaseId: 'xq6BGKoaSkjPo4cH3zss',
    videoId: '2nDtwOnxopPOy6sIva0F',
    episode: 4,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/9xg5sq8vfz2h538kckgtc7/Albert_PujolsThumbnails_0003_Session_4.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/8ON3FfQ51DpQUk502SojoR8ZPBmIFnZXo.m3u8',
    duration: '3:43',
    durationInSeconds: 223,
    durationInMiliSeconds: 223000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/9p3mnqvs8snfnrxfq4x3xv5/04_Albert_Pujols_Overcoming_Adversity_FINAL_H264_20Mbps_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/p7q66hrf32twkk59jrkrntn/Albert_PujolsThumbnails_0003_Session_4?auto=webp&format=png',
    shortDescription:
      'Adversity is part of life and sports. How are you going to handle it? Learn the mindset it takes to overcome adversity from Albert.',
    longDescription:
      "Albert knows it takes a certain type of mindset to move through adversity in life and sports. He'll show you how to use those skills, so that when faced with obstacles you can take them head on and make the most out of every situation!",
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-5',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Mindset Is Everything',
    sessionNumber: 5,
    firebaseId: 'FP5L6iLcwaUWJcW7xwQx',
    videoId: 'u3MMkHx6wYwH04YPqvXs',
    episode: 5,
    thumbnail:
      'https://videogif.bfldr.com/jp401GVKyPdgQkgd501YCvJMWCqBCe8fjd/animated.gif',
    m3u8Link: 'https://stream.mux.com/OBotv9Gl3G43yQqvEX4MEmS6lldzLFpu.m3u8',
    duration: '3:58',
    durationInSeconds: 238,
    durationInMiliSeconds: 238000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/st8vwmw728rrfzrf8njfsn3f/05_Albert_Pujols_Mindset_Is_Everything_FINAL_H264_20Mpbs_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/7qnxz5rqx9qtjmswzcz6xh7/Albert_PujolsThumbnails_0004_Session_5?auto=webp&format=png',
    shortDescription:
      "Don't throw in the towel when you run into obstacles. Albert teaches you his techniques in using his experiences to become more mentally tough.",
    longDescription:
      "Albert knows that obstacles are inevitable in life. But with his techniques, he can help you develop the mental fortitude to face these challenges and overcome them head-on! Don't give up - Albert will show you how to be tougher than any obstacle thrown your way.",
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-6',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Being a Good Teammate',
    sessionNumber: 6,
    firebaseId: 'vZ9olU2VyMPdpH0VHZeW',
    videoId: 'UiCoIDRjLxyHMN2VG1Ge',
    episode: 6,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/2sx2jr5spcw7kfs25n928kg/Albert_PujolsThumbnails_0005_Session_6.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/MVL9txas00AsdMALsbrDcI3tzDjmGIfk01.m3u8',
    duration: '3:40',
    durationInSeconds: 220,
    durationInMiliSeconds: 220000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/4btckmvvxttzxpbvbtqwqwwt/06_Albert_Pujols_Being_A_Good_Teammate_FINAL_H264_20Mbps_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/vp6f2w39w95mrw6rqp88zqp/Albert_PujolsThumbnails_0005_Session_6?auto=webp&format=png',
    shortDescription:
      'Albert is known for being an incredible teammate. Supportive. Challenging. Inspiring. Learn how to be the kind of teammate that drives everyone on the team towards being as good as they can be.',
    longDescription:
      "Albert is an incredible example of how powerful it can be to truly invest in those around you. Whether through encouragement, providing guidance or simply pushing others out of their comfort zone - Albert demonstrates that being a great teammate involves more than just cheering from the sidelines! With insight into his methods and techniques, find out what makes him such an inspiration on any team he's part of – learn how to become the best possible version of yourself as a teammate today!",
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-7',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Routines',
    sessionNumber: 7,
    firebaseId: 'zOGiOmxZWOkEddkOaamN',
    videoId: '31qrKMNh744gAzKELCMr',
    episode: 7,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/639f8f3qcqk93587tc349b3/Albert_PujolsThumbnails_0006_Session_7.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/gLudGTzW9RjR018o8TENmeBm6Bv5S4AmW.m3u8',
    duration: '2:42',
    durationInSeconds: 162,
    durationInMiliSeconds: 162000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/4ssx6jn86xrwv9f48pbt6939/07_Albert_Pujols_Routine_FINAL_H264_20Mbps_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/fq4m48tf8gtfvh4qszqbrkcx/Albert_PujolsThumbnails_0006_Session_7?auto=webp&format=png',
    shortDescription:
      'What do you do in the off-season to get ready? What about in-season? Learn the routine that made Albert one of the most consistent players for 20+ years in baseball.',
    longDescription:
      "If you want to be great like Albert, it isn't just about the game itself- there's a lot of hard work and dedication that happens in between playing seasons. It'll take some extra effort during both off and on season preparation - so if you're truly committed to success at your sport, get serious about routines! A consistent routine will help carry successes from one year into another as strong foundations are built up over time.",
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-8',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Preperation and Focus',
    sessionNumber: 8,
    firebaseId: '3pkHdTMG63DyW2lbtYT9',
    videoId: '8NePqAQs8N976RewCt9i',
    episode: 8,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/9fs3jn56ptw3v5tmrh6r2r73/Albert_PujolsThumbnails_0007_Session_8.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/ADzuWmys4A500uy3DUuTgvjqWS6SWO6Eg.m3u8',
    duration: '4:42',
    durationInSeconds: 282,
    durationInMiliSeconds: 282000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/vs9c59xf328grjf6rrchfc/08_Albert_Pujols_Preparation_and_Focus_FINAL_H264_20Mbps_Apr_15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/ssr5hp6xx6sn599ztxstx44v/Albert_PujolsThumbnails_0007_Session_8?auto=webp&format=png',
    shortDescription:
      'There’s a method to the madness behind Albert’s approach to every at-bat. Learn the ins and outs of what’s going through Albert’s mind as he steps up to the plate for each pitch in an at-bat.',
    longDescription:
      "Every at-bat is an opportunity for Albert to show his expertise in strategizing and playing the game of baseball. His methodical approach gives him a competitive edge, allowing us all to marvel at his skill on the field with each pitch. Let's learn from Albert’s mastery of what it takes mentally and emotionallyto be successful!",
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-9',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Hitting Mechanics',
    sessionNumber: 9,
    firebaseId: '1qHkbQycsrViaZ4Qq2cZ',
    videoId: 'esaTF75O6euAt7VEzwEw',
    episode: 9,
    thumbnail:
      'https://videogif.bfldr.com/aIH34R3giwkQzmTSj8mFBcDSq7KZpbh7/animated.gif',
    m3u8Link: 'https://stream.mux.com/XYE6mfDJUwECwY00KCtnoBhC3rdyUAgAo.m3u8',
    duration: '5:19',
    durationInSeconds: 319,
    durationInMiliSeconds: 319000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/8k663rtqgpsc6fc4vv365v4x/09_Albert_Pujols_Hitting_Mechanics_FINAL_H264_20Mbps_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/6t4p997zmf24g88vcqkbkj2z/Albert_PujolsThumbnails_0008_Session_9?auto=webp&format=png',
    shortDescription:
      'Build a strong foundation and be in position to attack the baseball. Albert teaches you the swing from the ground up.',
    longDescription:
      "Albert wants you to build a rock-solid foundation, so that when the ball comes your way there's nothing holding you back from attacking it with confidence. Learn how to swing like a pro - start at the bottom and work up!",
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-10',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Tee Work',
    sessionNumber: 10,
    firebaseId: 'gcTHsfDJaUCbtLunKNSk',
    videoId: 'cD43NoSMZedjGEUh49mI',
    episode: 10,
    thumbnail:
      'https://videogif.bfldr.com/9UquPwaHkgcb9LTg37VeME02flxPArF82/animated.gif',
    m3u8Link: 'https://stream.mux.com/AcyQMxjX9006Nt9MzcwWdXX01zz2crQKyj.m3u8',
    duration: '3:45',
    durationInSeconds: 225,
    durationInMiliSeconds: 225000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/7cj79rb5bc5fg36jj6s8pc7/10_Albert_Pujols_Tee_Work_FINAL_H264_20Mbps_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/vm6cb4w9h9cn4hxrpnbfp46z/Albert_PujolsThumbnails_0009_Session_10?auto=webp&format=png',
    shortDescription:
      "How do you get ready to hit? Learn Albert's tee routine and the games he plays to challenge himself to barrel the baseball when the lights come on.",
    longDescription:
      'Albert has a winning strategy to be prepared and ready for the big game: his tee routine. With this combination of structured practice and imaginative play, Albert is challenging himself each time he steps up to bat so that when the lights come on - he knows how to barrel that baseball like no other.',
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-11',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'Soft Toss',
    sessionNumber: 11,
    firebaseId: 'ofrLpEQit0lkcOnZgoTx',
    videoId: 'nccTWUh2NS4UyeN3f0kj',
    episode: 11,
    thumbnail:
      'https://videogif.bfldr.com/xXC38oijvB4bfGzw99zUazzrK02pUXUI9/animated.gif',
    m3u8Link: 'https://stream.mux.com/6MHPUR2R8B1In1OnLtWCxAPpTQIqauj4.m3u8',
    duration: '1:58',
    durationInSeconds: 118,
    durationInMiliSeconds: 118000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hpwb3qcbf82x9tr3rc7j89f8/11_Albert_Pujols_Soft_Toss_FINAL_H264_20_Mbps_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/fc95nzkz6w7zrwwv8qs9tfxp/Albert_PujolsThumbnails_0010_Session_11?auto=webp&format=png',
    shortDescription:
      "Every drill needs purpose to know if you're getting better. Albert teaches you what to focus on when you're preparing to hit any pitch in any situation and have your A swing for the game.",
    longDescription:
      'Nothing breeds success like purpose and focus. Albert teaches you what it takes to unlock your A swing game after game - giving you the confidence to hit any pitch in every situation with power, poise and precision!',
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'albert-pujols-12',
    mavenSlug: 'albert-pujols',
    maven: 'Albert Pujols',
    title: 'The Pujols Foundation',
    sessionNumber: 12,
    firebaseId: '7exZ9tiN8H1iXUR7Uvjd',
    videoId: 'dVRUKEK5AIzA5yBFTAgh',
    episode: 12,
    thumbnail:
      'https://videogif.bfldr.com/tZVSxlW8TJlgslpYAmYnYqZM7ZTwIMjU/animated.gif',
    m3u8Link: 'https://stream.mux.com/CxWCSw453hP9xm54Dqm02e02Z9bP1GSTGQ.m3u8',
    duration: '3:22',
    durationInSeconds: 202,
    durationInMiliSeconds: 202000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/shmjwhn639qw7k9b3b7hmp/12_Albert_Pujols_Pujols_Foundation_FINAL_H264_20Mbps_Apr15_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/jzrmxspr4v7r7qkv8p3svsfv/Albert_PujolsThumbnails_0011_Session_12?auto=webp&format=png',
    shortDescription:
      'Giving back to your community matters. The Pujols Foundation started in 2005 and has been giving back to the Dominican Republic, St Louis, and Aneheim areas since. Listen to Albert share about how giving back has changed his life.',
    longDescription:
      'Giving back to your community is an incredibly fulfilling and life-changing endeavor. The Pujols Foundation, started in 2005 by Albert Pujols himself, has positively impacted the Dominican Republic, St Louis, and Aneheim communities for over a decade now! Hear how giving back was so powerful that it transformed his own life - join us today as we listen to Albert share about this remarkable journey of generosity.',
    firebaseMavenId: 'FmiERyZtewyc2bCcRHvy',
  },
  {
    slug: 'ali-krieger-1',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'Introduction',
    sessionNumber: 1,
    firebaseId: 'AXQfxN9XcZjBvgJLEenm',
    videoId: 'PzMsq5qigV2nj6LOKkFf',
    episode: 1,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/mprcxvx9qcvsqwbz6r7h7p/AliKriegerThumbnails_Session_001.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/Z4l1ttScs01Q500LEmYem8DUr9psExnPX5.m3u8',
    duration: '5:11',
    durationInSeconds: 311,
    durationInMiliSeconds: 311000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/46p9hsnt6f2vgqvrpqgrpcgh/S01_Introduction_FFA',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/knqbcnrpt9vxp9phg3txk4g8/AliKriegerThumbnails_Session_001?auto=webp&format=png',
    shortDescription:
      'World Cup Champion and renowned international player Ali Krieger grew up with a love for the sport of soccer from a young age. Learning the ropes from her dad, Ali entered the professional realm and made a name for herself. You will get to know who Ali is, where she gets her inspiration, and the accomplishments she is most proud of professionally. Ali will also lay out what she hopes you will take-away and learn from watching her course.',
    longDescription:
      "Ali Krieger is a World Cup Champion and globally recognized soccer player whose love for the sport was instilled in her by her father from an early age. Through gaining professional experience, Ali has crafted a name for herself as one of the most talented players on today's international stage! Her incredible story will show you where she finds motivation and pride - plus give you valuable insight into what it takes to reach elite levels of success. Let Ali be your source of inspiration as she shares all that makes her proud; get ready to empower yourself with knowledge straight from this top-level athlete!",
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-2',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'Bounce Back',
    sessionNumber: 2,
    firebaseId: 'oWCWuTkExJGiniLMDa0h',
    videoId: 'FmDIbz87yeRsvp8T7IvY',
    episode: 2,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/bcsbkxnmmvftbjjtxbkp9pn/AliKriegerThumbnails_Session_002.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/9K8z2942cdG8prIy002ApoomO9YUy01vKa.m3u8',
    duration: '6:29',
    durationInSeconds: 389,
    durationInMiliSeconds: 389000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bxgwnbwqhc6n6hkfvg2mqmhh/S02_Bounce_Back_FFA',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hm7kx8b736ks8thq4qxc8zr9/AliKriegerThumbnails_Session_002?auto=webp&format=png',
    shortDescription:
      'Most athletes experience an injury and must work their way back. Injuries unfortunately happen but it is your attitude and hard work that determines if you can make it back. In this session, hear how Ali overcame two ACL surgeries and still plays at an elite level.',
    longDescription:
      "Injuries can be scary and discouraging, but Ali shows us that with the right attitude we can make it through anything! Join this session to hear her inspiring tale of how she overcame two ACL surgeries without missing a beat - still playing at an elite level even stronger than before. Anything is possible if you work hard for your dreams - don't let setbacks bring you down, use them as motivation instead!",
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-3',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'Getting There and Staying There',
    sessionNumber: 3,
    firebaseId: '',
    videoId: 'QH159R6NIXU8cERy7lbW',
    episode: 3,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/nw43p5js8wwqh87fr2hpf74/AliKriegerThumbnails_Session_003.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/wBaFbvrzQxTGCPbRYlQKj3CXWaJb5LW7.m3u8',
    duration: '3:52',
    durationInSeconds: 232,
    durationInMiliSeconds: 232000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/h6ccn3xzfc6swhssnzgfszfp/S03_Getting_There_and_Staying_There_FFA',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/qg74g394v6s95xwvn4g59sts/AliKriegerThumbnails_Session_003?auto=webp&format=png',
    shortDescription:
      'For nearly 15 years, Ali Krieger has had an illustrious career in the USWNT, despite overcoming numerous setbacks. Making it to the elite level is one thing. Staying and thriving in the elite level is a whole different ball game. Learn from Ali what it takes to get there and stay there.',
    longDescription:
      "Ali Krieger's journey to the USWNT is one of grit and determination. She has been an inspiring beacon in soccer for 15 years, resilience personified despite all the challenges she faced along the way. Her success shows that it isn't enough just to make it - you have stay there too! So let us learn from her what we need to do conquer even our biggest hurdles and persevere at any level of greatness.",
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-4',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'Footwork 101',
    sessionNumber: 4,
    firebaseId: 'ZH4mniRngRrccDmU7FgL',
    videoId: '0io62xzlaxGQzX1JceV4',
    episode: 4,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/t62sq3vm2v4xtcr5t5twrb5/AliKriegerThumbnails_Session_004.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/F9aRZnVbSiWxH0045ODfojGoFuehHwN9H.m3u8',
    duration: '5:43',
    durationInSeconds: 343,
    durationInMiliSeconds: 343000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/9zcr4596867wrvgt5zj6mh/S04_Footwork_101_FFA_v2',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/g66w9bx6j5bjbg445c8k4426/AliKriegerThumbnails_Session_004?auto=webp&format=png',
    shortDescription:
      'Being calm, cool, and collected are all words that describe Ali Krieger’s game. Learn how having fundamental footwork and proper mechanics enhances your confidence and gives you what you need to make the next big play.',
    longDescription:
      'Ali Krieger is the embodiment of confidence and composure. She demonstrates that fundamental footwork, proper mechanics, and a calm state-of-mind are essential ingredients for success on any playing field. Take Ali’s approach to help you reach your own personal goals - practice these techniques to take strides towards greatness!',
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-5',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'Range of Distribution',
    sessionNumber: 5,
    firebaseId: 'PLWQIbyGyXHwBKLCC8uX',
    videoId: 'TjZbHLLyEy9GY6waDIbL',
    episode: 5,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/wvr4q8cn39bsvr7vscjk64r/AliKriegerThumbnails_Session_005.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/CPiqiaGO3SH01t71NgU3H00UF02jl9gruLF.m3u8',
    duration: '6:03',
    durationInSeconds: 363,
    durationInMiliSeconds: 363000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/jrjzwnbvg54hqkpfmzkqs3/S05_Range_of_Distribution_FFA_v2',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/8wgr9trhpcp534ct9gkqq4v3/AliKriegerThumbnails_Session_005?auto=webp&format=png',
    shortDescription:
      'Ali Krieger has perfected the ability to place the ball exactly where she wants it. Distribution and knowing where and how to place the ball is a vital part of the game of soccer. Ali walks you through the different types of distribution and gives practical ways to help improve your ball movement.',
    longDescription:
      'Ali Krieger has mastered the art of precision ball placement. She understands that pinpoint accuracy is a necessary requirement in order to excel at soccer - and now you can too! Ali gives invaluable insights into how perfecting your distribution skills will maximize your potential on the pitch, as well as practical advice for making it happen. Level up with her expert guidance; unleash peak performance today!',
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-6',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'Defensive Position: Right Back',
    sessionNumber: 6,
    firebaseId: 'heUMWs0w2GyPKLM8LeT2',
    videoId: 'NCfg1MWBLRhgilGftdMK',
    episode: 6,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/7z2h5jccr5r3s6gtg8mvv5bp/AliKriegerThumbnails_Session_006.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/ltPZUlZM8tdXXciV01O4i3ivRBXfX9aHs.m3u8',
    duration: '5:42',
    durationInSeconds: 342,
    durationInMiliSeconds: 342000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/8pvvc6637q9mvxbn7gfsmc/S06_Defensive_Position_Right_Back_FFA',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/h3mgpzcvv5zmvs75b63fhts/AliKriegerThumbnails_Session_006?auto=webp&format=png',
    shortDescription:
      'Ali Krieger has mastered both the positions of the Right Back and Center Back defensive positions. In this session, learn the ins-and-outs of what it takes to be an incredible Right Back Defensive player.',
    longDescription:
      "Ali Krieger is an inspiration for all aspiring defenders! In this session, discover what it takes to be a world-class Right Back and Center Back and master the defensive techniques needed to excel. Unlock your potential as a defender and become unstoppable on the pitch - with Ali's help, you can make that dream come true!",
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-7',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'Defensive Position: Center Back',
    sessionNumber: 7,
    firebaseId: 'w2rGxMftIGxD124113mN',
    videoId: 'M8GgAFKL6mATYbb4ds8N',
    episode: 7,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/8m3r73t394kj2xhwk9sr7p3/AliKriegerThumbnails_Session_007.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/mIRkc5iTqkoqMKYXYdpRxVm4xzLUDTwG.m3u8',
    duration: '5:36',
    durationInSeconds: 336,
    durationInMiliSeconds: 336000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bjtcv3k6nmx274j947gc6ft/S07_Defensive_Position_Center_Back_FFA_v2',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/x6bqnqbz7pwf56rr4jmz8sg8/AliKriegerThumbnails_Session_007?auto=webp&format=png',
    shortDescription:
      'In this second part of the Defensive Positioning set, Ali teaches the skills and importance of being the last line of defense as the Center Back.',
    longDescription:
      "Becoming a reliable Center Back is an integral part of having a successful defense. Ali shows us the value and power that comes with mastering these skills - allowing you to become closer in your team's journey towards victory!",
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-8',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'Aerial Control',
    sessionNumber: 8,
    firebaseId: 'V9P0UrrGlxrs0FGUbZXZ',
    videoId: 'AuC5vVvpzMbJway1m7Xy',
    episode: 8,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/qkp7s9hrmf9xtvm5hckr8bk/AliKriegerThumbnails_Session_008.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/9GiGnTMdOTY5L7E14uFiLbixcztkWAus.m3u8',
    duration: '3:50',
    durationInSeconds: 230,
    durationInMiliSeconds: 230000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/q4qv6p65v4q3rm7fq9h8s9jc/S08_Aerial_Control_FFA_v2',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/rzwjp859f7fg5rcjxjbpsc9/AliKriegerThumbnails_Session_008?auto=webp&format=png',
    shortDescription:
      'Ali Krieger has developed a keen sense of spacial awareness that has helped propel her to elite status in the realm of women’s professional soccer. Learn from Ali the tips and tricks she uses to master the field and the confidence it takes to gain aerial control of the ball.',
    longDescription:
      "Ali Krieger has achieved a level of mastery on the pitch that is virtually unmatched. She's developed her spacial awareness to pinpoint where players are at all times, allowing for precision passing and aerial control over the ball most can only dream about! Learn from Ali how she does it - gain insight into how you too can stand out in your own game with sharpened focus & unshakable confidence.",
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-9',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'Preparation and Making Adjustments',
    sessionNumber: 9,
    firebaseId: 'hqwjiG6nD7PQ2nkihmRT',
    videoId: 'pMJzZygo5sEnsDqzPErX',
    episode: 9,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/TXtM02ya462gJQJHNfBqwILXMEvLuU8M7.m3u8',
    duration: '5:13',
    durationInSeconds: 313,
    durationInMiliSeconds: 313000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/tk69phvm8cbzx8n87rk6bq5n/S09_Preparation__Making_Adjustments_FFA',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/vw5g56k3rjrbmm6v7f6678n4/AliKriegerThumbnails_Session_009?auto=webp&format=png',
    shortDescription:
      'Soccer is like a game of chess. Those that can read their opponents with an advantage. Ali has learned to read her opponents, the pitch, and each situation with accuracy, confidence and poise. In this session, Ali teaches you her tips and tricks in getting inside the head of her opponents to put herself on top.',
    longDescription:
      'Ali knows that soccer can be a game of strategy and success. She has learned the importance of reading her opponents, understanding what is happening on the field at any given moment, and using her skill to outwit them with accuracy and confidence. In this session she will share how you too can gain an advantage over your opposition through mastering these tactics - helping achieve winning results!',
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-10',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'The Mental Game',
    sessionNumber: 10,
    firebaseId: 'WgvOX0sDUCPssU7c23UW',
    videoId: 'KaIGncqeyu7L5FqL48bp',
    episode: 10,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/4jk7tv5wp4s4hh4cx3tvwjp/AliKriegerThumbnails_Session_010.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/tWOvhi3kEcP008iLTo4nhOrCJhqoRlCjW.m3u8',
    duration: '5:00',
    durationInSeconds: 300,
    durationInMiliSeconds: 300000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hxxgttfxxhcznnjz8b6whmt9/S10_The_Mental_Game_FFA_1',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/wcqz5p6tpgcts5jz9gqt4ns7/AliKriegerThumbnails_Session_010?auto=webp&format=png',
    shortDescription:
      'Despite losing her position on the USWNT, Ali picked herself up and worked twice as hard to make it back on the team (something that rarely, if ever happens). Learn how you too can be a warrior and the mentality it takes to make it and stay at the elite level.',
    longDescription:
      "Ali was dealt a difficult blow when she lost her spot on the USWNT, but instead of giving up and settling for defeat, she reached deep down inside to find an unyielding warrior spirit. Despite daunting odds stacked against her, Ali made it back onto the team - something rarely seen in professional sports! Let's take a lesson from this incredible athlete and draw upon our inner warriors to reach new heights no matter what challenges come our way.",
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-11',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'Breaking Boundaries',
    sessionNumber: 11,
    firebaseId: 'XE3JGf6bqcJFGXGv35oM',
    videoId: 'M76PQoOOp0FlVbHUMmU6',
    episode: 11,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/5wrr567gvm36hmp5bf69gbrj/Session_7?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/j01GeyoOyraxwR1dPEmtBhnkAuoDUc2zf.m3u8',
    duration: '4:47',
    durationInSeconds: 287,
    durationInMiliSeconds: 287000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/99g3sg9n9cxn49977c3fhrfj/S11_Breaking_Boundaries_FFA_v2',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/86h9k3q6rjzwc9vsr38qv34/AliKriegerThumbnails_Session_011?auto=webp&format=png',
    shortDescription:
      'As one of the most famous advocates for female sports, Ali Krieger has fought alongside her wife, Ashlyn Harris, and countless other professional athletes to create space and equality for women. Ali recounts how they began to pave the way for the women behind them by bringing awareness to the inequality women go through. She also shares with us her hope for the future of women’s soccer and other women’s professional sports.',
    longDescription:
      "Ali Krieger has both made an incredible impact in the world of women's sports and given us a vision for what it can become. She stands strong with her wife Ashlyn Harris, as well other professional athletes leading the charge towards creating equal space within their fields. With Ali at its forefront, we have already seen progress being made to bring awareness to female inequality in sport – but she still envisions greater heights ahead! Let's all be inspired by this champion duo who are fearlessly striving forward together into new levels of success and equity on behalf of today’s generations off athletic heroines everywhere.",
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'ali-krieger-12',
    mavenSlug: 'ali-krieger',
    maven: 'Ali Krieger',
    title: 'What Matters Most',
    sessionNumber: 12,
    firebaseId: 'JVnhywFie5P2JOUkUhhz',
    videoId: 'ZaNABiQtb6NrXeqLoKR3',
    episode: 12,
    thumbnail:
      'https://videogif.bfldr.com/JQdxpV01BR9ZmmhWn02jVxFvKaiYV13pUM/animated.gif',
    m3u8Link: 'https://stream.mux.com/abaeNovEd72krLO0000Z9rRyDQMi4O7jDf.m3u8',
    duration: '5:05',
    durationInSeconds: 305,
    durationInMiliSeconds: 305000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/rrwnvgq4w5xmvnpxvtwmks/S12_What_Matters_Most_FFA_v2',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/c62rt374cmxkzbvtm5825nh/AliKriegerThumbnails_Session_012?auto=webp&format=png',
    shortDescription:
      'Ali walks through how it is possible to be a great mom and a professional athlete and what it takes to do both at the highest level. Also, learn how Ali’s service to others has enhanced her game and how you should get involved in something you are passionate about and make a difference in the life of someone else.',
    longDescription:
      "Drawing from her own experience, Ali will show you how to become a powerhouse mom and professional athlete! She'll let you into the world of high performance athletics – exploring what it takes for success. Plus glean wisdom about why service is invaluable in achieving excellence: by finding something that matters deeply to your heart, then acting on it others can be inspired and lives changed - including yours!",
    firebaseMavenId: '2G8TgOtUQvGNaGjixMhx',
  },
  {
    slug: 'amanda-lorenz-1',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Who is Amanda Lorenz?',
    sessionNumber: 1,
    firebaseId: '6bTgBgXDlAEwi1j1e09q',
    videoId: 'omeNXevOsKrdIb1RyjXn',
    episode: 1,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/N6sixd2BsNgfO01JFQz8021mXWe022P6ETm.m3u8',
    duration: '4:34',
    durationInSeconds: 274,
    durationInMiliSeconds: 274000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/gq538m4cbj5pjk87xhz54t3/01_Amanda_Lorenz_Who_Is_Amanda_Lorenz_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/3frk26bbchtbf9bpbkr9qwx/AmandaLorenz_0000_Session_1?auto=webp&format=png',
    shortDescription:
      'Get to know Amanda Lorenz. 2x All-American. 4x National Fastpitch Coaches Association All-American. Amanda will teach you practical & mental skills for improving your game.',
    longDescription:
      "Amanda Lorenz is a woman of extraordinary talent - an All-American champion two times over and a four-time National Fastpitch Coaches Association All-Star. She's here to help you reach your full potential, providing practical advice and mental skill sets so that you can take charge on the field! Unleash your inner athlete with Amanda at the helm & unleash greatness in life too.",
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-2',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Finding Your Routines',
    sessionNumber: 2,
    firebaseId: 'xWdQXivGxzGNg3cklWgS',
    videoId: '2Wxh2SQv5BcTvfFpRoH0',
    episode: 2,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/9p6rxngnmhsrvqc5nq23m4/AmandaLorenz_0001_Session_2.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/MzsaR3jDnC47LOS7iZCN00zGwjEEeL3f02.m3u8',
    duration: '3:14',
    durationInSeconds: 194,
    durationInMiliSeconds: 194000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hq4jgqz59nmsck36zwp56q/02_Amanda_Lorenz_Finding_Your_Routines_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/6vm58x8t8gftxqfh5wgbqhf4/AmandaLorenz_0001_Session_2?auto=webp&format=png',
    shortDescription:
      'To be the best batter, you must have a plan. Learn the importance of having a routine in place and how it has helps you stay focused in clutch moments.',
    longDescription:
      'Being the best batter requires more than just raw skill and natural talent. Having a plan which you stick to is of paramount importance: it provides focus in those moments that really matter! Establishing an effective routine will help ensure success at the plate.',
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-3',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Hitting Off the Tee',
    sessionNumber: 3,
    firebaseId: '3mgcnbRlvir1bHsk0u0K',
    videoId: 'Xvfvx5g3YsqOFILfyJLu',
    episode: 3,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/3xpnkfvt38fsr9qrspxf2q/AmandaLorenz_0002_Session_3.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/i9Qs01bWkpvPfiDnlnx5wwBffny84BeIH.m3u8',
    duration: '5:52',
    durationInSeconds: 352,
    durationInMiliSeconds: 352000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/ghtm2854vqpk3rg8mmkz6/03_Amanda_Lorenz_Hitting_Off_The_Tee_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/tkkqt95fq98rbsfq8ngzhk/AmandaLorenz_0002_Session_3?auto=webp&format=png',
    shortDescription:
      'Learn to love the tee to improve your swing. Amanda teaches you how to attack the ball with a tee routine that builds your swing to hit any pitch.',
    longDescription:
      "Challenge yourself to master the tee, and in time you'll be able to attack any pitch with confidence. With Amanda Lorenz as your coach - it's possible! Develop a consistent routine when practicing off of the tee for an unbeatable swing that will lead you straight into victory!",
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-4',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Hitting Fundamentals: Timing',
    sessionNumber: 4,
    firebaseId: '29AvJ8tvCbKRTBDyJbHD',
    videoId: 'EL6DgM8nTJbRTrHZXxHR',
    episode: 4,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/wj87vtt8q6jmxqnsqm494bg/AmandaLorenz_0003_Session_4.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/O8oqFEguOBETZowrgT3vZjmi00NuUsF57.m3u8',
    duration: '7:54',
    durationInSeconds: 474,
    durationInMiliSeconds: 474000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/cs2skkpqsf9n7ppp6js8hx/04_Amanda_Lorenz_Hitting_Fundamentals_Timing_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/f4px5xmf3f6pb37ss5gwrb3/AmandaLorenz_0003_Session_4?auto=webp&format=png',
    shortDescription:
      'Timing is everything. Learn the fundamental skills of timing from one of the hardest outs of all time, Amanda Lorenz.',
    longDescription:
      'Achieving greatness requires mastering the art of timing and Amanda Lorenz, one of the most successful hitters in history, is here to show you how. Take advantage of this incredible opportunity now - unlock your potential with her guidance!',
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-5',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Hitting Fundamentals: Lower Half',
    sessionNumber: 5,
    firebaseId: 'XjWZmj4FeuFzUpj9OVaT',
    videoId: 'LujnN1fOXUqeoPsc07q1',
    episode: 5,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/s897wpvpmp7ph5bs77zvqn/AmandaLorenz_0004_Session_5.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/reBkanzZTbKJFuV5bXtloF9PSVCIkSyZ.m3u8',
    duration: '7:02',
    durationInSeconds: 422,
    durationInMiliSeconds: 422000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/q6533w5tmqh3sgghrfbnmcmz/05_Amanda_Lorenz_Hitting_Fundamentals_Lower_Half_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bv2rbkn4tcqrkn9gt6s77hmg/AmandaLorenz_0004_Session_5?auto=webp&format=png',
    shortDescription:
      'It’s all about that base. Learn the fundamentals of how to utilize your lower half to your advantage and get the power out of your swing that you are looking for.',
    longDescription:
      "Unlock the secrets of an explosive lower body and watch your performance take flight! By harnessing the power in your lower half, you can drive optimal results out of every swing. It's time to discover what hidden potential lies dormant in each shot.",
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-6',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Hitting Fundamentals: Upper Half',
    sessionNumber: 6,
    firebaseId: 'B9yrdyoXOBWRcknhIZMh',
    videoId: 'XIwFVoYenA7C1oiKO72t',
    episode: 6,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/6c5k23hj4w646pt4wjscvmx4/AmandaLorenz_0005_Session_6.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/e91pwmWjC2avkJ3WhJ01TTi8eMtBEA8bS.m3u8',
    duration: '6:59',
    durationInSeconds: 419,
    durationInMiliSeconds: 419000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/nqrmwhjv7nkpvnv8gf3zwxbb/06_Amanda_Lorenz_Hitting_Fundamentals_Upper_Half_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/tngcn4gbgqws4ck4gqz2sj/AmandaLorenz_0005_Session_6?auto=webp&format=png',
    shortDescription:
      'Amanda will teach you about how to best use your upper body to place the ball precisely where you want it to go and drills to get you there.',
    longDescription:
      'Amanda is here to show you the way! Learn how your upper body can become a powerful tool, sending that ball exactly where it needs to go. Through drills and practice, discover what lies within - unleash the power of precision play today!',
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-7',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Game Strategy',
    sessionNumber: 7,
    firebaseId: 'FAsXDuh4rbBeG66srMvU',
    videoId: 'iobHTbVCSQgHHgbeLvfs',
    episode: 7,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/Hd7ALfre5jy4hEDQ00BNdGE02oCTEjPasD.m3u8',
    duration: '7:30',
    durationInSeconds: 450,
    durationInMiliSeconds: 450000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/qvc2h45t6cxhk8fmm6nrzqk/07_Amanda_Lorenz_Game_Strategy_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/xsk7p9cbjn7jj34p4ppzbf5j/AmandaLorenz_0006_Session_7?auto=webp&format=png',
    shortDescription:
      'The game isn’t just won in 7 innings. Learn how to approach each game and the strategies you can implement in your own game to help take your team to the next level.',
    longDescription: 'l',
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-8',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Training the Body',
    sessionNumber: 8,
    firebaseId: 'fX8MLtbcMQcUmF7HDXOw',
    videoId: 'DFrwIAyEuUc5JUSn8jMf',
    episode: 8,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/4x8xt6j84qkmnjhthvnb542p/AmandaLorenz_0007_Session_8.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/GFEIjUTf59XMPOqYJRQgoK9pdcHNVYcF.m3u8',
    duration: '6:15',
    durationInSeconds: 375,
    durationInMiliSeconds: 375000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/xghqhscrjg9fvfr5cp37fj/08_Amanda_Lorenz_Training_The_Body_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/htrxpr8kff6vjr8w8j48q54r/AmandaLorenz_0007_Session_8?auto=webp&format=png',
    shortDescription:
      'If you demand your body to perform at an elite level, you must learn to take care of it. Amanda will walk you through how she keeps her body in peak condition.',
    longDescription: '',
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-9',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Training the Mind',
    sessionNumber: 9,
    firebaseId: 'XqtpMYnRbPmyiHR86vbb',
    videoId: 'mBYRIFc5OBbtVSxEUjGi',
    episode: 9,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/tiaswr8RYDPOHXHtV43nmhzu8IssQwOE.m3u8',
    duration: '5:31',
    durationInSeconds: 331,
    durationInMiliSeconds: 331000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/ph5r9q4schjxsgsshtktvmz/09_Amanda_Lorenz_Training_The_Mind_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/smwbpn3h3qc76r49ttc953r/AmandaLorenz_0008_Session_9?auto=webp&format=png',
    shortDescription:
      '“Confidence is crucial.” Amanda knows that to succeed against every pitcher, you have to be confident and focused on each pitch. Learn how to be the hardest out a pitcher will face.',
    longDescription: '',
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-10',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Pro Athlete Female',
    sessionNumber: 10,
    firebaseId: 'hEElCrtZWGqQAXpkwUG1',
    videoId: 'p3MaDFfIxoQdr8PHA4WK',
    episode: 10,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/xGLsg5GXD6uVYnPixzebHHw1ZblgN02DU.m3u8',
    duration: '4:06',
    durationInSeconds: 246,
    durationInMiliSeconds: 246000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/psj5xm8tqm5fkvptx59wtj/10_Amanda_Lorenz_Pro_Athlete_Female_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/rcrrgpxfj3t65hbwxbmm84g/AmandaLorenz_0009_Session_10?auto=webp&format=png',
    shortDescription:
      'Amanda will teach you what it is truly like to be a professional player and the lessons she has learned becoming an elite athlete.',
    longDescription: '',
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-11',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Learning to Be a Great Coach',
    sessionNumber: 11,
    firebaseId: '37QaK4CmRESZ9hYKySZk',
    videoId: 'QcBlWfIq6pKhIq0IM5kU',
    episode: 11,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/v4xzqrp4gg5zw6cx7p4qmkm/AmandaLorenz_0010_Session_11.jpg?width=600&height=310',
    m3u8Link:
      'https://stream.mux.com/2280200Pj004klVDxrzWa1eq0000RkJc7c6WD.m3u8',
    duration: '3:40',
    durationInSeconds: 220,
    durationInMiliSeconds: 220000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/t9qpppf9t89bwphjj8q9s4/11_Amanda_Lorenz_Learning_To_Be_A_Great_Coach_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/f84kjnqztmpfmnm6mqp7768x/AmandaLorenz_0010_Session_11?auto=webp&format=png',
    shortDescription:
      'Learn how you can transition from being a player to a coach and how to implement the insights Amanda gained as a player under some great tutelage.',
    longDescription: '',
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'amanda-lorenz-12',
    mavenSlug: 'amanda-lorenz',
    maven: 'Amanda Lorenz',
    title: 'Playing for a Cause',
    sessionNumber: 12,
    firebaseId: 'vghspICxiW9FHUxsCEoj',
    videoId: 'e9uj7q62SnqL4bHWPbJt',
    episode: 12,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/A8hh5Xxo02u1sS4ZF64900SlBJ83fEkzDd.m3u8',
    duration: '4:20',
    durationInSeconds: 260,
    durationInMiliSeconds: 260000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/q9vr5vvcf7xn92f7s7shf9t/12_Amanda_Lorenz_Playing_For_A_Cause_FINAL_H264_20Mbps_Apr19_10am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/wwczxm3prcm39jvrj7k59f/AmandaLorenz_0011_Session_12?auto=webp&format=png',
    shortDescription:
      'Although softball is her first love, Amanda Lorenz also loves and looks for ways to give back. You will learn from Amanda what success really looks like on and off the field.',
    longDescription: '',
    firebaseMavenId: 'ehXs1r1xFMABmh8PNcLX',
  },
  {
    slug: 'ashlyn-harris-1',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Introduction',
    sessionNumber: 1,
    firebaseId: 'OPZXKA29I27qxCNgzZ0i',
    videoId: 'kpRFvy0Ho1VnpL6YUOQz',
    episode: 1,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/m5vIXox1Kxa02TGBE9u00QIGl7nafeEcz1.m3u8',
    duration: '5:11',
    durationInSeconds: 311,
    durationInMiliSeconds: 311000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bp2sgf556vsg8rjf6bgv3m85/S01_Ashlyn_Harris_Introduction_FINAL_091422',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/fmbpkrsrr4k57brtj2nj48p/Ashlyn_Harris_Thumbnails_Session_1?auto=webp&format=png',
    shortDescription:
      'From a difficult home life to winning the World Cup twice, Ashlyn Harris has overcome great adversity and hardship to make it to where she is today. You will get a good understanding of what makes Ashlyn tick; where she gets her inspiration, and the accomplishments she is most proud of professionally. Ashlyn will also lay out what she hopes you will take-away and learn from taking her course.',
    longDescription:
      "Ashlyn Harris has persevered through the toughest of obstacles to become an inspirational two-time World Cup Champion! Get in on the opportunity to learn what drives and motivates her, as well as how she achieved success during some of life's most trying moments. Through Ashlyn’s personal anecdotes and experiences you will gain insight into strength that it takes endure adversity, while gaining knowledge from one of today’s greatest female athletes who refuses to give up - no matter what odds are stacked against her.",
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-2',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Soccer Saved My Life',
    sessionNumber: 2,
    firebaseId: 'mBfWBGw6gtivSciVYCCd',
    videoId: 'O4UeWEqng2FJWxOyfH64',
    episode: 2,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/jpbhxqk5sbc4nnqxzghns/Ashlyn_Harris_Thumbnails_Session_2.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/eGRuchHJPA5uDvIlsxcpevZmKvyeM495.m3u8',
    duration: '5:55',
    durationInSeconds: 355,
    durationInMiliSeconds: 355000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/sbkfc6tvmw9rvvr9htft944/S02_Ashlyn_Harris_Soccer_Saved_My_Life_FINAL_091422',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/kt7mwcbqg3mt9b769gb/Ashlyn_Harris_Thumbnails_Session_2?auto=webp&format=png',
    shortDescription:
      'Alone. Unwanted. Trapped. Unsure. All these feelings can seem overwhelming and frankly, daunting. As a thrill-seeking teenager, Ashlyn Harris found herself immersed in the same feelings. Learn how the game of soccer became a means of grace, belonging, and confidence in the most unsettling time in her life.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-3',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Mindset of Greatness',
    sessionNumber: 3,
    firebaseId: 'MmJQ0p6j5HwX9DuYZdHi',
    videoId: 'AS74yNCpIod9G1H8dKNK',
    episode: 3,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/jzxkbm5nxcrmn3s2bmjc8g7/Ashlyn_Harris_Thumbnails_Session_3.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/MLKoGJYdhFi02HBvknJBwXTLfNlJrTVLC.m3u8',
    duration: '6:05',
    durationInSeconds: 365,
    durationInMiliSeconds: 365000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/b78zbtt5mq3jsrrv9krv7qf/S03_Ashlyn_Harris_Mindset_of_Greatness_FINAL_091522',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/7tpnj9tnns7w3m36p9kt8nr/Ashlyn_Harris_Thumbnails_Session_3?auto=webp&format=png',
    shortDescription:
      '“How you do anything is how you do everything.” ~Ashlyn Harris. Soccer, as well as most things in life, are tackled through a right mindset as well as a great work ethic. Ashlyn gives a sneak peak into how she prepares mentally and how you can increase your game by harnessing your mental ability.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-4',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Handling Technique',
    sessionNumber: 4,
    firebaseId: 'PEfvTutPnSXLsTZCqpQR',
    videoId: '9f0T8fL56f89GJn1gWNX',
    episode: 4,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/3s3vrjbk425rxn6nvkf5v26/Ashlyn_Harris_Thumbnails_Session_4.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/2maVjrMxEIolMSCUS00VONIQXM4dj5Tt4.m3u8',
    duration: '6:28',
    durationInSeconds: 388,
    durationInMiliSeconds: 388000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/cvmq8hnwwfgp999k3kbv3fb8/S04_Ashlyn_Harris_Handling_Technique_FINAL_091522',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/gk65qp2jg4jhnr3c4tjqfg/Ashlyn_Harris_Thumbnails_Session_4?auto=webp&format=png',
    shortDescription:
      'When the game is on the line, proper mechanics and handling are key to blocking the game winning shot. In this session, Ashlyn will walk through the technique she uses to make the play at an elite level and how you can improve your handling skills.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-5',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Quick Reactions and Fast Reflexes',
    sessionNumber: 5,
    firebaseId: 'z4zNVMA9yIOpUl6zHJAR',
    videoId: 'tdGtvhgQfR7kN0Gz0sE1',
    episode: 5,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/vvjh8qjnj7bpjn88cvrpp/Ashlyn_Harris_Thumbnails_Session_5.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/iYB6Q9402RRFKod1I6XDNhkLUC5L02i009O.m3u8',
    duration: '4:28',
    durationInSeconds: 268,
    durationInMiliSeconds: 268000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/c8chwt3m6fmj56trnrc7ttt/S05_Ashlyn_Harris_Quick_Reactions_and_Fast_Reflexes_FINAL_091422',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/8np4fq8jxqsppbtv5nq5hxb/Ashlyn_Harris_Thumbnails_Session_5?auto=webp&format=png',
    shortDescription:
      'Nothing is more important to the position of goalkeeper than to have quick reaction and reflexes when the ball is headed your way. It can quite literally be the difference between winning or losing a game. In this session, Ashlyn will teach you how to increase your reaction time and speed.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-6',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Diving',
    sessionNumber: 6,
    firebaseId: 'FcarQ5oFtZiyRTluWZtl',
    videoId: 'JL7qhJZCIcl9HRQR5hQN',
    episode: 6,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/hkc4tk3h64g8g6ng24qr49/Ashlyn_Harris_Thumbnails_Session_6.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/yCUnKlNeJTW02Tk91ihqjz3AWIhcU3LnG.m3u8',
    duration: '8:36',
    durationInSeconds: 516,
    durationInMiliSeconds: 516000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/4p8xff3jckc9fcv5hs4v73rw/S06_Ashlyn_Harris_Diving_FINAL_091422',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hzgv9qvjbsf9xkjv5v8mrtz/Ashlyn_Harris_Thumbnails_Session_6?auto=webp&format=png',
    shortDescription:
      'Diving isn’t just something that happens in the pool. One of the most strategic skills you can hone as a goalkeeper is learning how and when to dive. In this session, Ashlyn walks step-by-step through how to properly dive and gives you tips and tricks to make the play every time.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-7',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Reading the Game',
    sessionNumber: 7,
    firebaseId: 'Lw5XxKfoBCeKJJS5G3Xx',
    videoId: 'qvkeARHuTjcw5T0HZff9',
    episode: 7,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/pcnxhrbcthc78zxjg8sgp/Ashlyn_Harris_Thumbnails_Session_7.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/IH00Tj01vapM02y5R6qTwGENTUs947UEx1n.m3u8',
    duration: '5:22',
    durationInSeconds: 322,
    durationInMiliSeconds: 322000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/4cnx56mxrbcqsb2jrq9x3n/S07_Ashlyn_Harris_Reading_The_Game_FINAL_091222',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/h9mqt5f9x9w8b988f5hfv/Ashlyn_Harris_Thumbnails_Session_7?auto=webp&format=png',
    shortDescription:
      'You can’t make it to the professional level without knowing how to read your opponent’s every move. It takes skill. Accuracy. Determination. Mental prowess. Learn how Ashlyn reads her opponents like an open book as well as glean helpful insights into how you can develop the mental component of your game.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-8',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Leading From the Back',
    sessionNumber: 8,
    firebaseId: 'RVq6qB7G0oYlM3htdsA9',
    videoId: 'RNuDnBXT66GqwRXPL3bq',
    episode: 8,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/4zpqwqg4jwss3fg7b9pc7n4/Ashlyn_Harris_Thumbnails_Session_8.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/jiC29U8sV69pPbFN027BA0101WdJCkX9TSU.m3u8',
    duration: '6:58',
    durationInSeconds: 418,
    durationInMiliSeconds: 418000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bc9hr6697fp6b893jc9pfc/S08_Ashlyn_Harris_Leading_From_The_Back_FINAL_091522',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/45v8n883rmmsc7bfrg85x/Ashlyn_Harris_Thumbnails_Session_8?auto=webp&format=png',
    shortDescription:
      'If a team wants to excel, they must have a good defensive spine and one of the key roles for a goalkeeper in that spine is to lead. Ashlyn exudes what a good leader is and does. In this session, Ashlyn teaches the importance of developing your team’s trust in you.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-9',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Great Feet',
    sessionNumber: 9,
    firebaseId: 'ZyGH50kk6ho0vHuSed0J',
    videoId: 'fkwutuZoPVvIMZ9WM335',
    episode: 9,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/xnmvxbktm6693x862zqbqtg/Ashlyn_Harris_Thumbnails_Session_9.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/HuZXaWpYZGYOXJRQi71fn2i6xeAALCMt.m3u8',
    duration: '4:51',
    durationInSeconds: 291,
    durationInMiliSeconds: 291000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/s9mhkhmxpv7ng8chtbtgc8/S09_Ashlyn_Harris_Great_Feet_FINAL_091422',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bsbvkqc4cqkkkpvfrnrkbjs4/Ashlyn_Harris_Thumbnails_Session_9?auto=webp&format=png',
    shortDescription:
      'No matter the position you play, footwork is an essential part of the game of soccer and to become the best, you must put in the work. This is true even for great players like Ashlyn Harris. In this session, Ashlyn walks you through how to improve your footwork as a goalkeeper.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-10',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Distribution',
    sessionNumber: 10,
    firebaseId: 'qFYoAL4yIzao7A0a0s7B',
    videoId: 'lmApV2649cqa8LDrZr1l',
    episode: 10,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/31tLEJTANlDAEJcnVbqltbNsPjKxyKB6.m3u8',
    duration: '5:16',
    durationInSeconds: 316,
    durationInMiliSeconds: 316000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/f4fjpgq7bw5fm3vg3qzc7cs/S10_Ashlyn_Harris_Distribution_FINAL_091322',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/m366wbtz2hkzpvgbp594hqk3/Ashlyn_Harris_Thumbnails_Session_10?auto=webp&format=png',
    shortDescription:
      'Versatility is the name of the game for a goalkeeper. This is particularly true in regard to distributing the ball. Ashlyn has honed the craft of distributing the ball with both her hands and feet. In this session, you will learn the fundamentals of distributing the ball.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-11',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'Staying Motivated',
    sessionNumber: 11,
    firebaseId: 'dXbEZEC5UqOLvYEFFEEt',
    videoId: 'dAj4zES4RXy7kntt0fDY',
    episode: 11,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/hJSeIuB2E29xvBhBeqCn49N59rgExdu3.m3u8',
    duration: '3:54',
    durationInSeconds: 234,
    durationInMiliSeconds: 234000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/pc778kctc56hc899rzhsrckr/S11_Ashlyn_Harris_Staying_Motivated_FINAL_091222',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/mnk62q2q26r52m2h96t4h8v/Ashlyn_Harris_Thumbnails_Session_11?auto=webp&format=png',
    shortDescription:
      'As one of the longest running players of the US Women’s National Team, Ashlyn knows the hard work and dedication it takes to make it. How do you stay motivated and overcome obstacles? In this session, learn how to “just stay” and fight for your position despite what hardship might come your way.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'ashlyn-harris-12',
    mavenSlug: 'ashlyn-harris',
    maven: 'Ashlyn Harris',
    title: 'I Am Woman Hear Me Roar',
    sessionNumber: 12,
    firebaseId: 'XTVAGrhKq7EynbhKlz1O',
    videoId: 'zt3WGSAiThmkmhnjTRAe',
    episode: 12,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/JQdxpV01BR9ZmmhWn02jVxFvKaiYV13pUM.m3u8',
    duration: '4:32',
    durationInSeconds: 272,
    durationInMiliSeconds: 272000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/thb75r9c3nbhmvgnb2gsg4c/S12_Ashlyn_Harris__I_Am_Woman_Hear_Me_Roar_FINAL_091122',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/q4r88h68b7rq39p483f8gqh/Ashlyn_Harris_Thumbnails_Session_12?auto=webp&format=png',
    shortDescription:
      'Women’s right have come a long way in the past 50 years yet there is still so much that has been left untouched. With the rise in women’s professional sports, there are still several hurdles that women are trying to overcome. Ashlyn recounts how she and her teammates began to pave the way for current and future women’s sports and her hope for the future.',
    longDescription: '',
    firebaseMavenId: 'Iico0Cu7fABCZYST0iZe',
  },
  {
    slug: 'david-copeland-smith-1',
    mavenSlug: 'david-copeland-smith',
    maven: 'David Copeland-Smith',
    title: 'Beast Mode Soccer',
    sessionNumber: 1,
    firebaseId: 'ogqgjGP6q3RDsw48I1S6',
    videoId: 'wAQMfn1AQhXULQzls6GK',
    episode: 1,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/4D4fuztyYOyY00qZHHwD2501Yq2KYF9M02Z.m3u8',
    duration: '6:29',
    durationInSeconds: 389,
    durationInMiliSeconds: 389000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/p886vxhb8x4pxf5kxbcrpxg/S01_David_Copeland-Smith_Beast_Mode_Soccer_FINAL_H264_30Mbps_083022',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/v3phs3rb75vsbqcgqc5vxgmq/DavidCopelandSmith_thumbnails_Session_1?auto=webp&format=png',
    shortDescription:
      'As one of the premier trainers in soccer and founder of Beast Mode Soccer, David Copeland-Smith has coached some of the greatest players in soccer in leagues such as the US Women’s National Team, Major League Soccer, English Premier league, and the list goes on. Get to know David, and he will let help you understand what all you are going to be learning in his course.',
    longDescription: '',
    firebaseMavenId: 'amDKxcGwPlmrYCUfeBwU',
  },
  {
    slug: 'david-copeland-smith-2',
    mavenSlug: 'david-copeland-smith',
    maven: 'David Copeland-Smith',
    title: 'Mental Toughness',
    sessionNumber: 2,
    firebaseId: 'xlfjHqFyflEyHugOx8qS',
    videoId: 'oyVtpGnmv6cHiextdFwF',
    episode: 2,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/wvtt876c7sczhgrr65c5p3kq/DavidCopelandSmith_thumbnails_Session_2?auto=webp&format=png',
    m3u8Link: 'https://stream.mux.com/tU4trIwnYycuvAfdxEU3XUYf6pb1h3Je.m3u8',
    duration: '7:48',
    durationInSeconds: 468,
    durationInMiliSeconds: '',
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/4scrgcxzf5xfwh4h249jptvr/S02_David_Copeland-Smith_Mental_Toughness_FINAL_H264_30Mbps_083122',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/wvtt876c7sczhgrr65c5p3kq/DavidCopelandSmith_thumbnails_Session_2?auto=webp&format=png',
    shortDescription: '',
    longDescription: '',
    firebaseMavenId: 'amDKxcGwPlmrYCUfeBwU',
  },
  {
    slug: 'david-copeland-smith-3',
    mavenSlug: 'david-copeland-smith',
    maven: 'David Copeland-Smith',
    title: 'Developing an Elite Mindset',
    sessionNumber: 3,
    firebaseId: 'E6h0I5E5nLwty2hHD6xo',
    videoId: 'SmVG5FZxcXZ1nHtVoaI7',
    episode: 3,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/pfmnmm3s3cvv477vqj8pw6/DavidCopelandSmith_thumbnails_Session_3.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/TJfz1xUGx4apt00f7I74zNoOp3xr7XChe.m3u8',
    duration: '7:06',
    durationInSeconds: 426,
    durationInMiliSeconds: 426000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/xt5v8htfmpzhfbn55jwm9fp/S03_David_Copeland-Smith_Developing_an_Elite_Mindset_FINAL_H264_30Mbps_083022',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/8nrgcg7p434mjtbfwgjzpv/DavidCopelandSmith_thumbnails_Session_3?auto=webp&format=png',
    shortDescription:
      'Develop an elite mindset. David Copeland-Smith has coached some of the greatest soccer players like Alex Morgan and Rachel Daly, he knows that it takes to make it to the elite level. You will learn the type of mindset it takes to make it to the top of their game.',
    longDescription: '',
    firebaseMavenId: 'amDKxcGwPlmrYCUfeBwU',
  },
  {
    slug: 'david-copeland-smith-4',
    mavenSlug: 'david-copeland-smith',
    maven: 'David Copeland-Smith',
    title: "Don't Discount Juggling",
    sessionNumber: 4,
    firebaseId: 'dNcYIe3U6G7J3DUVYs0M',
    videoId: 'IWtXcVY5gWfiKud4s4oy',
    episode: 4,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/25s755s925g9gjgthbjqjm/DavidCopelandSmith_thumbnails_Session_4.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/7uCuxp9fv02LnKJ8qceRENfgzLpGU5fUT.m3u8',
    duration: '9:05',
    durationInSeconds: 545,
    durationInMiliSeconds: 545000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/fk6tpnkngkcmkbq25qnhzmc/S04_David_Copeland-Smith_Dont_Discount_Juggling_FINAL_H264_30Mbps_083022',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/gxv3swb73qfs4mqpnjmr4jnb/DavidCopelandSmith_thumbnails_Session_4?auto=webp&format=png',
    shortDescription:
      'David Copeland-Smith believes that commitment coupled with discipline will create success. Part of being disciplined is learning the basic skills and fundamentals of the game. David will teach you the proper mechanics and fundamentals of juggling.',
    longDescription: '',
    firebaseMavenId: 'amDKxcGwPlmrYCUfeBwU',
  },
  {
    slug: 'david-copeland-smith-5',
    mavenSlug: 'david-copeland-smith',
    maven: 'David Copeland-Smith',
    title: 'Ball Mastery',
    sessionNumber: 5,
    firebaseId: 'TaCryzf1eyPIw15wlNvm',
    videoId: 'K1q6LMuIQUobZekkMjg5',
    episode: 5,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/54hc6r44csw2xsn8cg4kpcb/DavidCopelandSmith_thumbnails_Session_5.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/GSEHYxMwd2x2tarR2L02rcqHf9MKHOK00H.m3u8',
    duration: '4:59',
    durationInSeconds: 299,
    durationInMiliSeconds: 299000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/b8ffccw3z9qn3kvw986hgbt/S05_David_Copeland-Smith_Ball_Mastery_FINAL_H264_30Mbps_083022',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/tp4cxtr73s8psrfx4mjp64/DavidCopelandSmith_thumbnails_Session_5?auto=webp&format=png',
    shortDescription:
      'Ball handling is crucial to outmaneuver your defense and make them guess where you are about to go. One key element of ball handling is learning the art of dribbling. David will teach you how to advance your skills in dribbling and keep your defender on their toes.',
    longDescription: '',
    firebaseMavenId: 'amDKxcGwPlmrYCUfeBwU',
  },
  {
    slug: 'david-copeland-smith-6',
    mavenSlug: 'david-copeland-smith',
    maven: 'David Copeland-Smith',
    title: 'Mastering Touch Part 1',
    sessionNumber: 6,
    firebaseId: 'iHAVTqOvSmKVAQHZupKX',
    videoId: 'uzeZkeK8HlPI7w1N2AEB',
    episode: 6,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/D1i2NWkBe01xrAuBEdOtNHu5J4i01SI11D.m3u8',
    duration: '12:11',
    durationInSeconds: 731,
    durationInMiliSeconds: 731000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/srcv4jt5pp2ptjppjsrw2s35/S06_David_Copeland-Smith_Mastering_Touch_Pt1_FINAL_H264_30Mbps_090222',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/3g4f3g44h82sgcxk4pqxj8n/DavidCopelandSmith_thumbnails_Session_6?auto=webp&format=png',
    shortDescription:
      'Soccer is a game of finesse, and any player will tell you that learning the art of touch is pivotal to be at the top of your game. David teaches the delicate balance of touch and some drills to help you master the ball.',
    longDescription: '',
    firebaseMavenId: 'amDKxcGwPlmrYCUfeBwU',
  },
  {
    slug: 'david-copeland-smith-7',
    mavenSlug: 'david-copeland-smith',
    maven: 'David Copeland-Smith',
    title: 'Mastering Touch Part 2',
    sessionNumber: 7,
    firebaseId: 'QgXOF0Ksb7RxKRXFZNtO',
    videoId: 'WUwwEEmu7d4aQUG6iLju',
    episode: 7,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/rbtc4frkj7g9kw49w2fkg5g/DavidCopelandSmith_thumbnails_Session_7.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/MFQpdfg17ojkq7vbPjHTwDEUCrwSoAWc.m3u8',
    duration: '15:39',
    durationInSeconds: 939,
    durationInMiliSeconds: 939000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bpxztp7nqmft3xbr9fj2h2q/S07_Mastering_Touch_Pt_2_FFA_v2',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/44s5gq26jmbhbm56cngk6s/DavidCopelandSmith_thumbnails_Session_7?auto=webp&format=png',
    shortDescription:
      'This is a continuation session from Mastering Touch Part 1. David dives deeper into developing touch and finesse through proven drills that every soccer player and coach should know.',
    longDescription: '',
    firebaseMavenId: 'amDKxcGwPlmrYCUfeBwU',
  },
  {
    slug: 'david-copeland-smith-8',
    mavenSlug: 'david-copeland-smith',
    maven: 'David Copeland-Smith',
    title: 'Diamond Drill',
    sessionNumber: 8,
    firebaseId: 'xieUyp8VFocuwzGJizGL',
    videoId: 'mHwBKdBDEnWPVhHf5Kiw',
    episode: 8,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/aAHD2WVFqvhwmbMNxULM87imwLJl01018A.m3u8',
    duration: '6:44',
    durationInSeconds: 403,
    durationInMiliSeconds: 403000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/3v7ztx2cxnczmbrjxqknfvw/S08_David_Copeland-Smith_1v1_Diamond_Drill_FINAL_H264_30Mbps_083022',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/8k7hpmqvhcks9rt9tmgpcxq5/DavidCopelandSmith_thumbnails_Session_8?auto=webp&format=png',
    shortDescription:
      'As an internationally recognized leading figure in individual technical development, David Copeland-Smith has coached some of the best athletes in soccer today. Learn how to improve your offensive skills by developing 1-on-1 techniques with the diamond drill.',
    longDescription: '',
    firebaseMavenId: 'amDKxcGwPlmrYCUfeBwU',
  },
  {
    slug: 'david-copeland-smith-9',
    mavenSlug: 'david-copeland-smith',
    maven: 'David Copeland-Smith',
    title: 'Finishing',
    sessionNumber: 9,
    firebaseId: '0mX7wOCPev5nvfiONO7x',
    videoId: 'd34okN76E60L6vzgjxJ4',
    episode: 9,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/gQwDFmY6ctLLDK3StNaJpa2daIyJe9Gf.m3u8',
    duration: '13:56',
    durationInSeconds: 836,
    durationInMiliSeconds: 836000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/gqbjv4vc9vwb96fqxvqg8nc/S09_David_Copeland-Smith_Finishing_FINAL_H264_30Mbps_083022',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/n5vkbbvj9nw5hns36n6pw29/DavidCopelandSmith_thumbnails_Session_9?auto=webp&format=png',
    shortDescription:
      'Every player dreams of scoring a game winning goal, however very few athletes feel confident if given the opportunity. Learn from David in this session the keys to building confidence in your ability to finish and some drills to make you.',
    longDescription: '',
    firebaseMavenId: 'amDKxcGwPlmrYCUfeBwU',
  },
  {
    slug: 'jennie-finch-1',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Who Is Jennie Finch?',
    sessionNumber: 1,
    firebaseId: 'ROfgGNfSn2GHn07Zcpgf',
    videoId: 'icXzmGjpOcIiUIMc1M6T',
    episode: 1,
    thumbnail:
      'https://videogif.bfldr.com/Mco1nxvD86WMzrp1SUsSl00WtFCFKTXTf/animated.gif',
    m3u8Link: 'https://stream.mux.com/OJdazfopxHc97r4rOJCOFgIcBdI00702Q7.m3u8',
    duration: '3:57',
    durationInSeconds: 237,
    durationInMiliSeconds: 237000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/632wrwjbmn8zj487tfck93h/01_Jennie_Finch_Who_Is_Jennie_Finch_FINAL_H264_20Mbps_Apr14_9am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hgw4gkhr4ffv2v6vhw8sx6h7/JennyThumbnail_0000_Session_1?auto=webp&format=png',
    shortDescription:
      'The most dominant pitcher to ever step foot on the mound and dubbed “the most famous softball player in history of the sport”. Embark on a journey through Jennie’s drive to the top of her game and learn from her practical skills that she learned along the way.',
    longDescription:
      'Jennie Finch is one of the most iconic athletes to ever play softball. She made history by conquering pitching like no other before her, becoming "the most famous player in the sport". Follow Jennie\'s inspiring journey towards success and discover how she reached heights nobody had previously achieved! Learn from her wisdom as you witness firsthand the practical skills that helped elevate this superstar athlete to great fame.',
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-2',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Consistency Is Queen',
    sessionNumber: 2,
    firebaseId: 'QMA3k1R1EBHkWgy4PblD',
    videoId: 'ZXKryz9EQj8QM4psouQZ',
    episode: 2,
    thumbnail:
      'https://videogif.bfldr.com/j9TReuEBPhjyQ01dAlcI43mlCq2PRGH9z/animated.gif',
    m3u8Link: 'https://stream.mux.com/Hhj2l8Rnq83Xzgh5r3ts9xJBRV01HbxvG.m3u8',
    duration: '5:34',
    durationInSeconds: 334,
    durationInMiliSeconds: 334000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/76mzhs79xbfpmw7pn8fhcqcw/02_Jennie_Finch_Consistency_Is_Queen_FINAL_H264_20Mbps_Apr14_11pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/97rbg7q5nrt44bg3nfn2z5n/JennyThumbnail_0001_Session_2?auto=webp&format=png',
    shortDescription:
      'Want to know one of the essential keys to success for all elite athletes? Consistency. Learn from Jennie how she applies consistency in her workouts and how to develop a routine that fit your needs.',
    longDescription:
      'Consistency is the driving force behind all elite athletes! Jennie can help you learn how to adopt this mindset and create a workout plan that will bring out your biggest successes. With her guidance, consistency could be the key ingredient in unlocking an unbeatable version of yourself!',
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-3',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Proper Pitching Mechanics',
    sessionNumber: 3,
    firebaseId: 'opYbtpsSzsjysmKKMrkP',
    videoId: 'jvWiVRTmjS4b2lPKghkD',
    episode: 3,
    thumbnail:
      'https://videogif.bfldr.com/l96p4021mTKm01RmUCWkSqaPkpkHloghgR/animated.gif',
    m3u8Link: 'https://stream.mux.com/2GHFIBo3vlUMmvDrSAb8p4Bi39rIBbRY.m3u8',
    duration: '6:17',
    durationInSeconds: 377,
    durationInMiliSeconds: 377000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/h6nbsht7m3htk78bx9s2pvn/03_Jennie_Finch_Session_Proper_Pitching_Mechanics_FINAL_H264_20Mbps_Apr14_2022_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/9x8z8m7g2ff6q53x4shtp6/JennyThumbnail_0002_Session_3?auto=webp&format=png',
    shortDescription:
      'Jennie teaches you the basic mechanics and fundamentals of pitching. Understand the importance of proper mechanics that help you generate spin and accuracy.',
    longDescription:
      'Jennie can provide you the foundation to unlock your pitching potential! Through her lessons on mechanics and fundamentals, she will help guide you in learning how to generate spin and precision with each pitch. Unleash what is within; become a master of the mound today!',
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-4',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Generating Speed',
    sessionNumber: 4,
    firebaseId: '9zcQ3WIH0cuqbCwzwceM',
    videoId: 'SrGv1oCVaEgH3B1DebcK',
    episode: 4,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/spnw45pmxnjvw7hjk8mwpz59/JennyThumbnail_0001_Session_2.jpg?auto=webp&format=jpg&width=670&height=346',
    m3u8Link: 'https://stream.mux.com/tFCVMoM02FMsnQKlhc81T7kGzim9TDA4P.m3u8',
    duration: '4:54',
    durationInSeconds: 294,
    durationInMiliSeconds: 294000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/vwj8hmnbpk84q54mc6p9hwj/04_Jennie_Finch_Session_Generating_Speed_FINAL_H264_20Mbps_Apr14_2022_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/gvrvt97ggw3h974xc95zxss8/JennyThumbnail_0003_Session_4?auto=webp&format=png',
    shortDescription:
      'Jennie is known worldwide for her ability to generate incredible speed with her pitches. You will learn in-depth tips and drills from Jennie on how to generate as much speed as you can with your pitches.',
    longDescription:
      "Jennie is an international sensation for her powerful hurling ability. Not only does she have a knack for velocity, but Jennie will also provide you with the tools and tricks to replicate - or even surpass! - those same speeds in your own pitches. Unlock potential you didn't know was possible by learning from this esteemed professional today!",
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-5',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Art of the Rise Ball',
    sessionNumber: 5,
    firebaseId: 'mG4DmiIl55kYMcc6zmSF',
    videoId: 'qJUxW3GT8MQqZKIdmDQr',
    episode: 5,
    thumbnail:
      'https://videogif.bfldr.com/BkRSieOF2ByaA8YiZPEPy37Z8rohRtwN/animated.gif',
    m3u8Link: 'https://stream.mux.com/tfzHSl3N02tjGmkkytEEcZS2iBsZ6xdT4.m3u8',
    duration: '4:43',
    durationInSeconds: 283,
    durationInMiliSeconds: 283000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/v6ncv66g3n644h3s46rtvp9/05_Jennie_Finch_Session_Art_Of_The_Rise_Ball_FINAL_H264_20Mbps_Apr14_2022_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/8s4vb79jgsmk4wqz7bpwjqjk/JennyThumbnail_0004_Session_5?auto=webp&format=png',
    shortDescription:
      'Learn how to grip, release, and the proper body movement for Jennie’s rise ball. She’ll also give you a few practical drills for improving your rise ball.',
    longDescription:
      "Jennie’s rise ball can get you the edge in competition. Mastering the proper grip, release, and body movement will have your opponents outmatched - plus she has all those practical drills to give you a further advantage! Nobody said it was gonna be easy…but with Jenny's instruction take that next step towards greatness!",
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-6',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Adding to the Arsenal: Curveball & Screwball',
    sessionNumber: 6,
    firebaseId: 'CsxehqCudkVVyCKTzbVx',
    videoId: '9eMwZ9VtcLM7pN4Yq1rF',
    episode: 6,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/g7bs66rhgqm6fcrq44rk8bn/JennyThumbnail_0005_Session_6.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/i9Ngb3I003CiCWrZISDlpebA49QtcRn1M.m3u8',
    duration: '6:54',
    durationInSeconds: 414,
    durationInMiliSeconds: 414000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/cthf9z6fhg7pfbf4hxgsh8/06_Jennie_Finch_Session_Adding_To_The_Arsenal_1_Curveball_Screwball_FINAL_H264_20Mbps_Apr15_2022_2am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/5rn8fh9z9jws2r6tbnqm7hm9/JennyThumbnail_0005_Session_6?auto=webp&format=png',
    shortDescription:
      'Practice with purpose and learn Jennie’s pitch grips and her release techniques for her curveball and screwball.',
    longDescription:
      "If you want to maximize your potential and become a powerhouse on the mound, strive to master Jennie's pitch grips and release techniques for her curveball and screwball. Focusing with intent will help take your pitching game to new heights!",
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-7',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Adding to the Arsenal: Dropball & Change-Up',
    sessionNumber: 7,
    firebaseId: '8lJZ3C9m64GbuKdNBvdk',
    videoId: 'xGnA0TOZnPRpml70IoGj',
    episode: 7,
    thumbnail:
      'https://videogif.bfldr.com/3xrGTgbtDz02N5Al00hIhbIkiiYTvOVrnJ/animated.gif',
    m3u8Link: 'https://stream.mux.com/bc5QYo336XkbeHwFqrcv3S3Hy8Zudu84.m3u8',
    duration: '7:17',
    durationInSeconds: 437,
    durationInMiliSeconds: 437000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/7fpvn3hkhgp8p78ms8m8mwjb/07_Jennie_Finch_Session_Adding_To_The_Arsenal_2_Dropball_Changeup_FINAL_H264_20Mbps_Apr14_2022_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/wvkzcvm5frjtczs8j6p47cr6/JennyThumbnail_0006_Session_7?auto=webp&format=png',
    shortDescription:
      'This session is a continuation on from the previous lesson. In this session viewers will learn how Jennie throws her drop-ball, and her changeup.',
    longDescription:
      'In this incredible follow-up session, Jennie will take us on a journey of skill and expertise as she shows how to master the drop-ball and changeup - two powerful pitching techniques.',
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-8',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Battling Fear & Anxiety',
    sessionNumber: 8,
    firebaseId: 'YN8Zg4O6fhvdIhpRZqFs',
    videoId: 'rVQSaFAZQFN7dKj1pBMh',
    episode: 8,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/Lxt01S1e1Yedd025gSz36Gb9J6T202vzrgG.m3u8',
    duration: '6:37',
    durationInSeconds: 397,
    durationInMiliSeconds: 397000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/6nh3cvxjw3qgtbq8h2phsx/08_Jennie_Finch_Session_Battling_Fear_Anxiety_FINAL_H264_20Mbps_Apr14_2022_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/5fn4mg3jfchwjchw57vx9cn/JennyThumbnail_0007_Session_8?auto=webp&format=png',
    shortDescription:
      'Jennie, venerably opens up about some of the fears and anxiety she has fought throughout her career, and how she learned to overcome them.',
    longDescription:
      'Jennie has faced fear and anxiety on the path to achieve her dreams, but she did not let that stop her. She found strength within herself to conquer each challenge with courage and fought against all odds - an inspiring example of determination!',
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-9',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Setting Goals',
    sessionNumber: 9,
    firebaseId: 'vf5vxEdzrZk3FqNyIkfq',
    videoId: 'JUagP6sAqwyeM5fFBE4W',
    episode: 9,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/nvw4hfm8n8hmkp7s8425w2t/JennyThumbnail_0008_Session_9.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/jtQO01dKf6iREVlzFqTbdHMuBAUg1JkHs.m3u8',
    duration: '3:25',
    durationInSeconds: 205,
    durationInMiliSeconds: 205000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/xn9c99s9jb97kkqvpnk5zk5/09_Jennie_Finch_Session_Setting_Goals_FINAL_H264_20Mbps_Apr14_2022_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/mpbs9jvcr8x2v3ssbfcncbt/JennyThumbnail_0008_Session_9?auto=webp&format=png',
    shortDescription:
      'Build skills in how to make an accurate assessment of your current abilities, and how to set realistic achievable goals for the present.',
    longDescription:
      'With self-assessment, you have the power of vision and determination - two tools essential to setting realistic goals that will lead to your success! Assessing where you are today allows for a strong foundation from which to plan out what tomorrow can bring.',
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-10',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Training the Next Generation',
    sessionNumber: 10,
    firebaseId: 'hVPfNN4PfrMWljyj1MfY',
    videoId: 'EZXW9e23SCdahCnaaQst',
    episode: 10,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/2n3j98vrxfnjjmnpjmmvgvb/JennyThumbnail_0000_Session_1.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/OSeT11pUkWlHTmQrZgThkc7l231eTO6Z.m3u8',
    duration: '5:02',
    durationInSeconds: 302,
    durationInMiliSeconds: 302000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/gs2qk4ggpb484n7x2hrwrpn/10_Jennie_Finch_Session_Training_The_Next_Generation_FINAL_H264_20Mbps_Apr14_2022_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/t94k2747h5gqm36pqfbjkp/JennyThumbnail_0009_Session_10?auto=webp&format=png',
    shortDescription:
      'Hear some stories of how her coaches, mentors, and parents made her the woman she is today. Viewers will also learn some practical tips bringing up the next generation of athletes.',
    longDescription:
      "Get inspired by Jennie's journey! Hear remarkable stories of how different influences in her life formed the amazing athlete and leader she is today. Plus, gain valuable tools to support our next generation of athletes on their own extraordinary paths.",
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-11',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'The Power of Your Personal Brand',
    sessionNumber: 11,
    firebaseId: 'BHsu7WINTwtGVROb9pbS',
    videoId: 'SKnrRCrsywSHi3LW9if9',
    episode: 11,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/6wcwbhxr7nhcgs597jgghx/JennyThumbnail_0010_Session_11.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/00ZrBxS01C9m42TV00wzAJw1TfiiOdxjBWZ.m3u8',
    duration: '5:45',
    durationInSeconds: 345,
    durationInMiliSeconds: 345000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/93sk9s774zwgtqhnh34fbf4/11_Jennie_Finch_Session_The_Power_Of_Your_Personal_Brand_FINAL_H264_20Mbps_Apr14_2022_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/pc3kbz6p2kvnhnhmmz5z2t3p/JennyThumbnail_0010_Session_11?auto=webp&format=png',
    shortDescription:
      'Learn how to create a strong brand, how to navigate having a business in the digital age, and how to recognize and seize opportunities when they present themselves.',
    longDescription:
      "You have the power to build an engaging brand and make your mark in today's digital world. With knowledge and dedication, you can identify opportunities that will take your business goals farther than ever before!",
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jennie-finch-12',
    mavenSlug: 'jennie-finch',
    maven: 'Jennie Finch',
    title: 'Making An Impact',
    sessionNumber: 12,
    firebaseId: 'DAzjVlok0aUPP009N3yn',
    videoId: 'kyidNizj5vvWrtVVcliB',
    episode: 12,
    thumbnail:
      'https://videogif.bfldr.com/IFgXFoXaW1avFUkxnfMFK4y01JFPRae01T/animated.gif',
    m3u8Link: 'https://stream.mux.com/GtUfoNj77HD023eDbeiOxcrlRSfw3j007c.m3u8',
    duration: '4:37',
    durationInSeconds: 277,
    durationInMiliSeconds: 277000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/fj4b6wrwjzm9kgwwjmktcvvs/12_Jennie_Finch_Session_Making_An_Impact_FINAL_H264_20Mbps_Apr14_2022_8am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/542gkrn984knh4n7rh8srp/JennyThumbnail_0011_Session_12?auto=webp&format=png',
    shortDescription:
      'Learn the principals that guide Jennie’s philanthropic side, and how to develop those same traits in yourself.',
    longDescription:
      "Take control of your own journey to giving back and create a unique path that reflects who you are! Learn how Jennie's principles nurture her philanthropic spirit, so you can manifest positivity in the world around us.",
    firebaseMavenId: '02RRcJV8yUtDHkbqZpAN',
  },
  {
    slug: 'jessica-mendoza-1',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Who Is Jessica Mendoza',
    sessionNumber: 1,
    firebaseId: 'MendozaSession1',
    videoId: 'MendozaSession1',
    episode: 1,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/cfnxpts474jpp6wfrx68np6h/Session_1?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/f00gf00sOevzBug2kI4PoBWfj02fgfzm1MN.m3u8',
    duration: '25:11:00',
    durationInSeconds: 1511,
    durationInMiliSeconds: 1511000,
    video: 'https://stream.mux.com/f00gf00sOevzBug2kI4PoBWfj02fgfzm1MN.m3u8',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/cfnxpts474jpp6wfrx68np6h/Session_1?auto=webp&format=png',
    shortDescription:
      'California native. Stanford graduate. Olympic Gold Medalist. Mother of 2. Boundary-shredding broadcaster. So, who is Jessica Mendoza? Learn about her early life and the importance of how a strong, supportive family shaped her mindset. She came from a family with strong female role models. This support and encouragement led to the confident, strong woman we see on ESPN today. Jessica will also tell you what she will be teaching you throughout the course.',
    longDescription:
      "Jessica Mendoza is a woman of many accomplishments; from her Olympic Gold Medal to being the first female analyst in Major League Baseball, she has achieved it all. But what's even more inspiring than these remarkable achievements and titles is how Jessica was able to achieve them - through hard work, determination, and an unwavering support system found within her family. Learn about how this California native used every ounce of optimism given by those closest to create a legacy that goes far beyond any medal or title!",
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-2',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Framing Your Mindest',
    sessionNumber: 2,
    firebaseId: 'MendozaSession2',
    videoId: 'MendozaSession2',
    episode: 2,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/k7tx8m7vkbrc8wsq8t3fc4v6/Session_2?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/01ObXCBTo00FYY6I5CS7QxE5bXUyboueqw.m3u8',
    duration: '16:56',
    durationInSeconds: 1016,
    durationInMiliSeconds: 1016000,
    video: 'https://stream.mux.com/01ObXCBTo00FYY6I5CS7QxE5bXUyboueqw.m3u88',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/k7tx8m7vkbrc8wsq8t3fc4v6/Session_2?auto=webp&format=png',
    shortDescription:
      'Being one of the most celebrated softball players in the world and having a groundbreaking career in broadcasting has taught Jessica that all success begins within a person’s mindset. By walking through some of her favorite stories, you will learn the necessity of mental toughness.',
    longDescription:
      "Jessica's incredible accomplishments in the world of softball and television broadcasting are a testament to her unwavering mental strength. By sharing some of her hard-won insights, you too will discover how that same toughness can bring tremendous success into your own life!",
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-3',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Time Management',
    sessionNumber: 3,
    firebaseId: 'MendozaSession3',
    videoId: 'MendozaSession3',
    episode: 3,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/ps6m8h9tgfjz2ft59phckrs8/Session_3?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/KYCz5o872u01EWv1qyJHux9NZARKq0095G.m3u8',
    duration: '17:51',
    durationInSeconds: 1071,
    durationInMiliSeconds: 1071000,
    video: 'https://stream.mux.com/KYCz5o872u01EWv1qyJHux9NZARKq0095G.m3u8',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/ps6m8h9tgfjz2ft59phckrs8/Session_3?auto=webp&format=png',
    shortDescription:
      'As the mother of two, a wife, a television broadcaster, and gold medal Olympian Jessica understands the extreme importance of managing her time well and keeping up good routines. You will hear her stories and learn practical tools from Jessica on how to manage your time well and create healthy routines.',
    longDescription:
      "Jessica knows what it takes to succeed and live a fulfilling life. Drawing on her experiences as both mother and wife, Olympic athlete, and television broadcaster, she'll share inspiring stories on how you too can make the most of your time by keeping up good routines. Join Jessica for tangible tips that help structure your schedule so you reap rewards in all aspects!",
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-4',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Work Ethic',
    sessionNumber: 4,
    firebaseId: 'MendozaSession4',
    videoId: 'MendozaSession4',
    episode: 4,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/k7vk6b55nvwt5smv9c264c/Session_4?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/z2dXHHiLakBdkWON00NP8lK3mXbmL5w2L.m3u8',
    duration: '11:15',
    durationInSeconds: 675,
    durationInMiliSeconds: 675000,
    video: 'https://stream.mux.com/z2dXHHiLakBdkWON00NP8lK3mXbmL5w2L.m3u88',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/k7vk6b55nvwt5smv9c264c/Session_4?auto=webp&format=pngg',
    shortDescription:
      'Jessica has learned from experience that hard work has been the cornerstone of her many successes. You will hear some of those stories and learn tools for developing a better work ethic of your own.',
    longDescription:
      "Being successful doesn't come easy - Jessica learned that the hard way! But she used her experiences to develop an incredible work ethic, which has been key in helping her reach every goal. Join us as we learn from Jessica's story and discover how you can cultivate a winning attitude of your own!",
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-5',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Be Adaptable and Find Your Place',
    sessionNumber: 5,
    firebaseId: 'MendozaSession5',
    videoId: 'MendozaSession5',
    episode: 5,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/h75ngr7xp8z9rc549v6677x/Session_5?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/MWID4ax89yKED6uzYkaZ93X61GLDnQRm.m3u8',
    duration: '11:41',
    durationInSeconds: 701,
    durationInMiliSeconds: 701000,
    video: 'https://stream.mux.com/MWID4ax89yKED6uzYkaZ93X61GLDnQRm.m3u8',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/h75ngr7xp8z9rc549v6677x/Session_5?auto=webp&format=png',
    shortDescription:
      'New opportunities can be scary for anyone, and life always throws curveballs. Jessica has enjoyed two successful careers in two completely different professions. You will learn how to approach any and every opportunity that life might throw at you.',
    longDescription:
      'Jessica is proof of how rewarding it can be to take a chance on something new! By following her example, you too can learn how to embrace and make the most out of every opportunity that comes your way - despite any curveballs life might throw at you.',
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-6',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Leadership and Mentorship',
    sessionNumber: 6,
    firebaseId: 'MendozaSession6',
    videoId: 'MendozaSession6',
    episode: 6,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/tq3t7g4qpp6bv6c7wc66vxrr/Session_6?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/Oy8VZkAnrHJSPfyRWlcIlUaUapBpBmX7.m3u8',
    duration: '11:50',
    durationInSeconds: 710,
    durationInMiliSeconds: 710000,
    video: 'https://stream.mux.com/Oy8VZkAnrHJSPfyRWlcIlUaUapBpBmX7.m3u88',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/tq3t7g4qpp6bv6c7wc66vxrr/Session_6?auto=webp&format=pngg',
    shortDescription:
      'Being a good leader starts with having been led well. There is no substitute to having someone who truly believes in you pushing you forward. Jessica’s life has been packed with incredible leaders, coaches, and mentors, and now she is paying it all forward by mentoring and inspiring young women all over the world to chase their dreams. Learn how to follow great leaders and eventually end up as one.',
    longDescription:
      'Jessica has been fortunate to have amazing leaders, coaches and mentors pushing her forward. Now she is taking all of that knowledge, turning it into fuel for action, and paying it forward by inspiring young women everywhere to realize their dreams! We can learn from great leaders in order to become incredible ones ourselves - so start your journey today and strive towards reaching the title of "leader".',
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-7',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Breaking Boundaries',
    sessionNumber: 7,
    firebaseId: 'MendozaSession7',
    videoId: 'MendozaSession7',
    episode: 7,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/3zf8whvzj3bsvp3g6s7shvsc/AliKriegerThumbnails_Session_011.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/004JCwwxjXVxrU7w1YG02xCX11ujtwQfop.m3u8',
    duration: '21:51',
    durationInSeconds: 1311,
    durationInMiliSeconds: 1311000,
    video: 'https://stream.mux.com/004JCwwxjXVxrU7w1YG02xCX11ujtwQfop.m3u8',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/5wrr567gvm36hmp5bf69gbrj/Session_7?auto=webp&format=pngg',
    shortDescription:
      'Jessica’s is no doubt a trailblazer. By breaking into the male-dominated world of sports broadcasting she has paved the way for generations of women to come. Learn how to set goals and accomplish them no matter how crazy they might seem.',
    longDescription:
      "Jessica's story is one of triumph and inspiration. She fearlessly embarked on a journey to conquer the male-dominated world of sports broadcasting, blazing her own trail for generations after her! Let Jessica be an example that no goal is too ambitious when it comes to pursuing your dreams; with hard work, dedication, and perseverance you can make them happen.",
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-8',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Is the Sacrifice Worth It?',
    sessionNumber: 8,
    firebaseId: 'MendozaSession8',
    videoId: 'MendozaSession8',
    episode: 8,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/nknznhnb79pc5k56kmps8mr/Session_8?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/GiGqlax2biRO2Fv01ZquSSD12oQfEn5Ih.m3u8',
    duration: '18:28',
    durationInSeconds: 1108,
    durationInMiliSeconds: 1108000,
    video: 'https://stream.mux.com/GiGqlax2biRO2Fv01ZquSSD12oQfEn5Ih.m3u88',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/nknznhnb79pc5k56kmps8mr/Session_8?auto=webp&format=pngg',
    shortDescription:
      'Olympic medals, national broadcasts, and motherhood don’t come cheap. In her pursuit of greatness, Jessica has learned if you want to be great at anything, the journey will undoubtedly require sacrifices. Learn how to think with the future in mind, how to embrace sacrifice, and how to determine if a sacrifice is worth it.',
    longDescription: '',
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-9',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Battling Self Doubt and Anxiety',
    sessionNumber: 9,
    firebaseId: 'MendozaSession9',
    videoId: 'MendozaSession9',
    episode: 9,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/4bf8hgftgw29tk6z3xkj3x/Session_9?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/PPn7zpmhQUH5iOTvP68826uOHgaLnH8u.m3u8',
    duration: '14:44',
    durationInSeconds: 884,
    durationInMiliSeconds: 884000,
    video: 'https://stream.mux.com/PPn7zpmhQUH5iOTvP68826uOHgaLnH8u.m3u88',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/4bf8hgftgw29tk6z3xkj3x/Session_9?auto=webp&format=png',
    shortDescription:
      'Like anyone who attempts greatness, Jessica has always been her greatest critic, and along the way she has learn how to conquer herself doubt and anxiety. Learn how to distinguish between the good and bad voices in your head, and how to move into the future with the confidence of a champion.',
    longDescription: '',
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-10',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'The Business of Broadcasting',
    sessionNumber: 10,
    firebaseId: 'MendozaSession10',
    videoId: 'MendozaSession10',
    episode: 10,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hs4mfgk9psmvbh34rmm4r27/Session_10?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/OO9alSbaMRyk4r5Cta7FcNx00HCkRIMXu.m3u8',
    duration: '15:29',
    durationInSeconds: 929,
    durationInMiliSeconds: 929000,
    video: 'https://stream.mux.com/OO9alSbaMRyk4r5Cta7FcNx00HCkRIMXu.m3u8',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hs4mfgk9psmvbh34rmm4r27/Session_10?auto=webp&format=pngg',
    shortDescription:
      'With no degree or background in television Jessica didn’t think that she would be good on camera. But taking the chance to push herself into something new led her to new heights in the broadcasting booth. Learn how Jessica prepares for a broadcast and how to take chances that can push you to develop new skills in your career and life.',
    longDescription: '',
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-11',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Analyzing Both Games',
    sessionNumber: 11,
    firebaseId: 'MendozaSession11',
    videoId: 'MendozaSession11',
    episode: 11,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/94x6ttnjf9mcgv7b4xhvwkc/Session_11?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/7Wnq6DBIOj1rj1oW7MPky48CdP1G3Vjw.m3u8',
    duration: '10:03',
    durationInSeconds: 603,
    durationInMiliSeconds: 603000,
    video: 'https://stream.mux.com/7Wnq6DBIOj1rj1oW7MPky48CdP1G3Vjw.m3u88',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/94x6ttnjf9mcgv7b4xhvwkc/Session_11?auto=webp&format=png',
    shortDescription:
      'Jessica grew up playing baseball and learning the game from her dad. Jessica will teach you the similarities and differences between softball and baseball and how learning both can benefit your coaching approach in either sport.',
    longDescription: '',
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-12',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Philanthropy',
    sessionNumber: 12,
    firebaseId: 'MendozaSession12',
    videoId: 'MendozaSession12',
    episode: 12,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/56t7fsfsnxj5nqhr6n5mc8mh/Session_12?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/ME1QbUdGODKQ2of74YJwq6XnjD45Rb9X.m3u8',
    duration: '7:17',
    durationInSeconds: 437,
    durationInMiliSeconds: 437000,
    video: 'https://stream.mux.com/ME1QbUdGODKQ2of74YJwq6XnjD45Rb9X.m3u88',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/56t7fsfsnxj5nqhr6n5mc8mh/Session_12?auto=webp&format=png',
    shortDescription:
      'Jessica Mendoza is not only known as one of the greatest softball players of all time. In fact, she is better known for her love of others and breaking boundaries of inequality. Learn from Jessica how she prioritizes helping others and ways you can begin to as well.',
    longDescription: '',
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'jessica-mendoza-13',
    mavenSlug: 'jessica-mendoza',
    maven: 'Jessica Mendoza',
    title: 'Female Empowerment',
    sessionNumber: 13,
    firebaseId: 'MendozaSession13',
    videoId: 'MendozaSession13',
    episode: 13,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/as/j7xbt3w98npbbg9cjmh3xws8/Session_13?auto=webp&format=jpg',
    m3u8Link: 'https://stream.mux.com/4XfdbZfKrSbSVRKNdcYkbG86OveE2v009.m3u8',
    duration: '5:27',
    durationInSeconds: 327,
    durationInMiliSeconds: '',
    video: 'https://stream.mux.com/4XfdbZfKrSbSVRKNdcYkbG86OveE2v009.m3u8',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/j7xbt3w98npbbg9cjmh3xws8/Session_13?auto=webp&format=png',
    shortDescription:
      'Jessica feels her calling in life is to make an impact. Hear about who has empowered her in her life and how that has led her down the road of becoming one of the first female broadcasters in Major League Baseball. Learn how to empower the next generation of female leaders in this session.',
    longDescription: '',
    firebaseMavenId: 'ZImfTcYCBWmg9CMAq0ra',
  },
  {
    slug: 'kelley-o-hara-1',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: "Who Is Kelley O'Hara",
    sessionNumber: 1,
    firebaseId: 'wujs2wwS3qwKsCqDzKOi',
    videoId: 'x5TVpBAtH127xNrBFDvV',
    episode: 1,
    thumbnail: 'https://stream.mux.com/vMqtye6Y6ufNA6KtXzBGY6svfhZuhrGF.m3u8',
    m3u8Link: 'https://stream.mux.com/vMqtye6Y6ufNA6KtXzBGY6svfhZuhrGF.m3u8',
    duration: '4:45',
    durationInSeconds: 285,
    durationInMiliSeconds: 285000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/7xb5fs9xj3ck7fp7sg753b/01_Kelley_OHara_Who_Is_Kelley_OHara_FINAL_080822',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/92r5vwprz5tgwwchsq2r7s/KelleyOHaraThumbnails_0000_Session_1?auto=webp&format=png',
    shortDescription:
      'From a shy little girl to the most versatile women’s soccer player of all time, Kelley O’Hara has made her mark on the world. Learn what makes Kelley tick; where she gets her inspiration, and the accomplishments she is most proud of professionally.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-2',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Put In the Work',
    sessionNumber: 2,
    firebaseId: 'GscDJEYXSUZEUUlYG7F7',
    videoId: 'eLR2z6XwXbAWkXMfHhnj',
    episode: 2,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/Zuio5yZnNcJT02pyCauM5O7KAUS00PZ4YP.m3u8',
    duration: '4:42',
    durationInSeconds: 282,
    durationInMiliSeconds: 282000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/xrbt3zbmcr4gxqxsjfv9r38g/02_Kelley_OHara_Put_In_The_Work_FINAL_080822',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/84k3bxgk7s8brw7sqtrrbf3/KelleyOHaraThumbnails_0001_Session_2?auto=webp&format=png',
    shortDescription:
      'To become a professional athlete, you have to earn your spot. It takes time. Effort. Grit. Determination. Kelley will regale her stories of making it to the top and what it took to get there',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-3',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'How to Train to Be Adaptable',
    sessionNumber: 3,
    firebaseId: 'aAk1LSszTxVgDXXscmSZ',
    videoId: '8TTxvkRlewtvYashruiH',
    episode: 3,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/275s3smbthpm7sq6mskcrcm/KelleyOHaraThumbnails_0002_Session_3.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/5V8tyVxRVu016B3Wz8kk4015e7fy2hjSKZ.m3u8',
    duration: '5:27',
    durationInSeconds: 327,
    durationInMiliSeconds: 327000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/pfgj6fnf44gq2964r65qjrf/03_Kelley_OHara_How_To_Train_To_Be_Adaptable_FINAL_H264_20Mbps_080722',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/svhstwkm6rmgcb3st2xfx8m/KelleyOHaraThumbnails_0002_Session_3?auto=webp&format=png',
    shortDescription:
      'As one of the best all-time scorers, Kelley O’Hara had to learn a completely different position in order to make the 2012 Olympic Team. She learned to adapt. Kelley will teach why it is important to be adaptable as an athlete and how to use change for your advantage.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-4',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Juggling Fundamentals',
    sessionNumber: 4,
    firebaseId: 'vwFypFNHQA3OyZtTG522',
    videoId: 'j8W4O6hRdl2E2pmiYEV5',
    episode: 4,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/OC7O901y02VHpjuu4Wov8J7Z9oZVUILsY3.m3u8',
    duration: '6:46',
    durationInSeconds: 406,
    durationInMiliSeconds: 406000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/g5vtm84jk38bm5nvwnc55f/04_Kelley_OHara_Juggling_Fundamentals_And_Progression_080822',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/r88hfk9sr7jg83brz7sw4g4/KelleyOHaraThumbnails_0003_Session_4?auto=webp&format=png',
    shortDescription:
      'Footwork is crucial in learning how to control the ball. You will learn the fundamentals and progression of juggling from one of the best offensive players, Kelley O’Hara.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-5',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Passing and Receiveing',
    sessionNumber: 5,
    firebaseId: '1QYqvEftnmhIjyBSi16H',
    videoId: 'XWP7Pg8HffXFaaqOhzKB',
    episode: 5,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/sb6fj4w3rvvkz4n7gbhjc7/KelleyOHaraThumbnails_0004_Session_5.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/AL02VYug1YpXVeUhQnxhspf00uRU44okrM.m3u8',
    duration: '10:28',
    durationInSeconds: 628,
    durationInMiliSeconds: 628000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/7fnnrzgpqm8kh4k6733npz46/05_Kelley_OHara_Passing_And_Receiving_FINAL_082922',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/9kwq3rmjxk7tfnhn95pvj/KelleyOHaraThumbnails_0004_Session_5?auto=webp&format=png',
    shortDescription:
      'Another fundamental that often gets overlooked is passing. The ability to pass the ball with precision, placement, and speed only comes through disciplined practice. Kelley will walk you through the fundamentals of passing and how you can hit their mark every time.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-6',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Distribution',
    sessionNumber: 6,
    firebaseId: 'fTzOHtCJhBzrQMUKTap2',
    videoId: 'xzcgeztVAQ81TAGjZ4Wr',
    episode: 6,
    thumbnail: '',
    m3u8Link:
      'https://stream.mux.com/ck98hW800sjJu01lP76N00fhH00LIe400sS0001.m3u8',
    duration: '4:26',
    durationInSeconds: 266,
    durationInMiliSeconds: 266000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/99x92fmq3qw75pm6q64sjz/06_Kelley_OHara_Distribution_FINAL_081022',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/pncsbk4kr2jzqbfpfvx6k8/KelleyOHaraThumbnails_0005_Session_6?auto=webp&format=png',
    shortDescription:
      "How do you get the ball from point A to point B? Learn how to take the fundamentals you've learned from passing and recieving by adding a tactical layer into your ball distribution. Kelley teaches you the drills to distribute the ball anywhere on the field.",
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-7',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Beat Your Defender',
    sessionNumber: 7,
    firebaseId: '2XsKrKq46wlX74Mlailm',
    videoId: 'ciWZwTtwuwtjeeBj2KdX',
    episode: 7,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/Ih7PIaqmErC1sBpSB01FqspCUNpOQG02vn.m3u8',
    duration: '10:07',
    durationInSeconds: 607,
    durationInMiliSeconds: 607000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/sw65fsnn8q47bm9vfhskm6j/07_Kelley_OHara_Beat_Your_Defender_FINAL_801122',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/qrnq9vw79hfcjrh93v4zjp/KelleyOHaraThumbnails_0006_Session-7?auto=webp&format=png',
    shortDescription:
      'As one of the most prolific goal scorers of all time, she learned to read her competitors and exploited their tendencies to her advantage. Learn from Kelley the things she looks for when reading her defender and how you can move yourself into a better position.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-8',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Defensive Strategy',
    sessionNumber: 8,
    firebaseId: 'sJ5ZOghOb5ejgAVJc9rz',
    videoId: 'fwLdzDlcC6TvxGp99npr',
    episode: 8,
    thumbnail:
      'https://videogif.bfldr.com/5uE1vN7nhkNpr4z83e9c01NmxieU01O6j02/animated.gif',
    m3u8Link: 'https://stream.mux.com/y5Mp3vQg01oAG00k8va6XmfD1rTayEEwD00.m3u8',
    duration: '7:22',
    durationInSeconds: 442,
    durationInMiliSeconds: 442000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/987pxkb6qfqqq9q9qp6bgswr/08_Kelley_OHara_Defensive_Strategy_FINAL_081022',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/nkn7r72xvx55k86c4fjz8/KelleyOHaraThumbnails_0007_Session_8?auto=webp&format=png',
    shortDescription:
      'The importance of taking care of the ball cannot be overstated. One of the best ways to shield the ball is through positioning your body. Learn from her different techniques she utilizes to protect the ball.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-9',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Defensive Footwork Drills',
    sessionNumber: 9,
    firebaseId: 'rSWrwmapjpxsKvtC2UeQ',
    videoId: 'TegfOtzU96zXMlgprcmT',
    episode: 9,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/5RlvI7C7VP7mfTN8a6aP5va6Of6MId00L.m3u8',
    duration: '3:11',
    durationInSeconds: 191,
    durationInMiliSeconds: 191000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/gtpk97cvqwpjrj4sqxbnjqt8/09_Kelley_OHara_Defensive_Footwork_Drills_FINAL_080922',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/2t63r7sjz9r46rntgbzrvg5/KelleyOHaraThumbnails_0008_Session_9?auto=webp&format=png',
    shortDescription:
      'After stepping into a defending role later in her career, Kelley applied the skills she learned over the years of play as an offender to up her game as a defender. Learn from Kelley how to be a great defensive player.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-10',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Speed and Agility Training',
    sessionNumber: 10,
    firebaseId: 'WtSYmz1DweolJ4xbzv1B',
    videoId: 'NgqLFr3pZoVAUE1Jzr75',
    episode: 10,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/p9x5qhtnjnj8ch6t99nwkc/KelleyOHaraThumbnails_0009_Session_10.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/3q54f502OvcI54f798Mhn1HTAI13j7HQ1.m3u8',
    duration: '2:16',
    durationInSeconds: 136,
    durationInMiliSeconds: 136000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bb4pzww5pxs24r8xfq96j29/10_Kelley_OHara_Speed_And_Agility_Training_FINAL_081022',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/fqmxg3pg4w23gsc7cs9p64g/KelleyOHaraThumbnails_0009_Session_10?auto=webp&format=png',
    shortDescription:
      'So much of what it takes to become a professional athlete happens off the field. Kelley teaches you about how to increase your speed and agility and walks you through how to train like a true professional with tips on how to increase quickness.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-11',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Be a Great Teammate',
    sessionNumber: 11,
    firebaseId: 'D7QQp1NTzmlEu2Y2seFx',
    videoId: 'YqNcz3RSuNPPpMcowXHL',
    episode: 11,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/35r3wn9b33fkrks2scgwkqh/KelleyOHaraThumbnails_0010_Session_11.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/jnzmzoS9ghGi45qyX9BObW1jgDb100wrx.m3u8',
    duration: '7:41',
    durationInSeconds: 461,
    durationInMiliSeconds: 461000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bnhsxmqbpswqfxvv6739kwk/11_Kelley_OHara_Be_A_Great_Teammate_FINAL_H264_20Mbps_080722',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hcfvrb96pg6rgbwn4fp85qz/KelleyOHaraThumbnails_0010_Session_11?auto=webp&format=png',
    shortDescription:
      'Teams are comprised of individuals. But individuals only succeed when the team comes together. Kelley has learned what it takes to be a great teammate. Learn how to be the kind of teammate that drives everyone on the team towards being as good as they can be.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-12',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Adversity',
    sessionNumber: 12,
    firebaseId: 'BwwXW4kKrl1t0omd0BG2',
    videoId: 'oShqSazXfLSfVmfX0EH1',
    episode: 12,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/6qDLubeII3BsbR4weDjPNCvv3QfSR02L3.m3u8',
    duration: '7:42',
    durationInSeconds: 462,
    durationInMiliSeconds: 462000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/jjxmwz2zncpvb8rntt7967/12_Kelley_OHara_Adversity_080822',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/gjkv65psqmx45qvmjjs46qcg/KelleyOHaraThumbnails_0011_Session_12?auto=webp&format=png',
    shortDescription:
      'Adversity happens in life and sports. When it rears it’s ugly head, you have a choice: let it to overtake you or use it to propel you forward. Kelley will teach you her toughest moments on and off the field, and give some steps to take when you face adversity in order to launch you into greatness.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-13',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Mindset Matters',
    sessionNumber: 13,
    firebaseId: 'TOfrWfY6QE77DNTw2hdo',
    videoId: '1cM0cqVF5JSZdPv9kBi6',
    episode: 13,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/rbw7rqp75k4vg9n3wb72kw6/KelleyOHaraThumbnails_0012_Session_13.jpg?width=600&height=310',
    m3u8Link:
      'https://stream.mux.com/SJgV3n02q801p02DJKWKKGNclBXRef02ldSo.m3u8',
    duration: '7:35',
    durationInSeconds: 455,
    durationInMiliSeconds: 455000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/mp3rqgbm7n8ttrjr6pw3qg/13_Kelley_OHara_Mindset_Matters_FINAL_080822',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/gbt4bfgmtwbb4ms3zngt58z/KelleyOHaraThumbnails_0012_Session_13?auto=webp&format=png',
    shortDescription:
      'How do you create purpose in your training? Kelley teaches you how to elevate your training that transfers into your game on the pitch. Learn how to control what you can control and take your game to the next level.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-14',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Take Care of Your Body',
    sessionNumber: 14,
    firebaseId: 'CbUgvSLjYP1lKlgfTkkh',
    videoId: 'fLMcMOifmGo9LbEjt58x',
    episode: 14,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/kxyQ9uL4jc9J8eyANFXezdzsE8VvgKwx.m3u8',
    duration: '5:30',
    durationInSeconds: 330,
    durationInMiliSeconds: 330000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/4wj9tr4qr88jgg2c3r44mxmw/14_Kelley_OHara_Take_Care_Of_Your_Body_FINAL_080822',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/9x46273r6whj649f6gsmks9n/KelleyOHaraThumbnails_0013_Session_14?auto=webp&format=png',
    shortDescription:
      'How do you take care of your body? Kelley teaches you about nutrition, recovery, fitness and conditioning to help you become the best player you can be. Learn what she has learned throughout her career that helped her sustain longevity.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'kelley-o-hara-15',
    mavenSlug: 'kelley-o-hara',
    maven: "Kelley O'Hara",
    title: 'Rest, Recovery and Prehab',
    sessionNumber: 15,
    firebaseId: 'mRl53JvLDzzCT5Ciy99m',
    videoId: 'Py25j9bUOsmRgszEpgiv',
    episode: 15,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/2nmwfntb5n835kjxmrws6xmq/KelleyOHaraThumbnails_0014_Session_15.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/pzR02jaeKLW4SoODjVGZFD7ro02VA3tkVA.m3u8',
    duration: '7:26',
    durationInSeconds: 445,
    durationInMiliSeconds: 445000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/cwnkr837886h89gjrfngsmh/15_Kelley_OHara_Rest_Recovery_And_Prehab_FINAL_801122',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/wh3jz9cr32p9cs75m8zcgwp/KelleyOHaraThumbnails_0014_Session_15?auto=webp&format=png',
    shortDescription:
      'How often do you think about recovery? Kelley teaches you about how to properly recovery off the field so you can show up everyday feeling refreshed to perform your best in training and on the pitch during games.',
    longDescription: '',
    firebaseMavenId: 'gZrEf3fdkf3OgURkK0Jl',
  },
  {
    slug: 'tim-walton-1',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'Who Is Tim Walton?',
    sessionNumber: 1,
    firebaseId: '0revdJCCAj1ogGgA0s9q',
    videoId: 'ZMyjA34CVd2dje7MU9hW',
    episode: 1,
    thumbnail:
      'https://videogif.bfldr.com/FKQFY9pDVlmmKZNxktGbXQGVrnLpdBLP/animated.gif',
    m3u8Link:
      'https://stream.mux.com/4tfaIX1PLceIj501PaI100mB00bItif301Iz.m3u8',
    duration: '6:07',
    durationInSeconds: 367,
    durationInMiliSeconds: 367000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/ffvp2s8m3fsb7pmjkwpcnmxt/01_Tim_Walton_Who_Is_Tim_Walton_FINAL_H264_20Mbps_Apr17_5pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/r64wnqt527n5f9q3g64rhz/TIMWaltonthumbnails_0000_Session_1?auto=webp&format=png',
    shortDescription:
      'As one of the greatest coaches to ever coach the game, Tim Walton has made an undeniable imprint on the game of softball.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-2',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'Developing a Practice Plan',
    sessionNumber: 2,
    firebaseId: 'EDyZZFZXHarMQ0KMsX3m',
    videoId: 'E1PH61XAZvAqy5aH5nT4',
    episode: 2,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/nrs8hmqpq3p9mmmv86pgpks4/TIMWaltonthumbnails_0001_Session_2.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/wj7EoxZmf8Gm2fdBCnHBlAXH7100yqv53.m3u8',
    duration: '8:21',
    durationInSeconds: 501,
    durationInMiliSeconds: 501000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/jp394gh3sshbshtcv8pct9b/02_Tim_Walton_Developing_A_Practice_Plan_FINAL_H264_20Mbps_Apr17_5pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/kk8wkgtwbbnj4whcsck8c977/TIMWaltonthumbnails_0001_Session_2?auto=webp&format=png',
    shortDescription:
      'Tim Walton knows the importance of planning and preparation. Learn about his systematic approach to practice how having a plan in place for practice helps build a robust powerhouse dynasty.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-3',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'Fundamentals of Hitting',
    sessionNumber: 3,
    firebaseId: 'z0yioyno4n0IaNeXZ9e2',
    videoId: 'tkXEf66YU9RLQR13ykUl',
    episode: 3,
    thumbnail:
      'https://videogif.bfldr.com/YPITAoaAMtVv2hCmZon3OgR4ak5382FS/animated.gif',
    m3u8Link: 'https://stream.mux.com/zuCmRy9Jj00DPpwHBClJAxDIf2buYh2vu.m3u8',
    duration: '10:53',
    durationInSeconds: 653,
    durationInMiliSeconds: 653000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/shtt83795rsxkcr6ktpf4r23/03_Tim_Walton_Fundamentals_Of_Hitting_FINAL_H264_20Mbps_Apr18_11am',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/3q2m9gr4wrxvgjsh69mzxz/TIMWaltonthumbnails_0002_Session_3?auto=webp&format=png',
    shortDescription:
      'Tim will walk you through the basic mechanics of hitting as well as ways to diagnosis when something is off. Who better to learn the basics with than one of the all-time best instructors.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-4',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'Developing Into a Power Hitter',
    sessionNumber: 4,
    firebaseId: 'auqsvb8VZnYCbmGaXeyl',
    videoId: 'drrQFJFzJwoxfwD2RauX',
    episode: 4,
    thumbnail:
      'https://videogif.bfldr.com/5uE1vN7nhkNpr4z83e9c01NmxieU01O6j02/animated.gif',
    m3u8Link:
      'https://stream.mux.com/qh01mbGScfXuEPr7W902KH4SxrrUjY00LC02.m3u8',
    duration: '9:18',
    durationInSeconds: 558,
    durationInMiliSeconds: 558000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/3srk9j68xmtp7hj7x743/04_Tim_Walton_Developing_Into_A_Power_Hitter_FINAL_H264_20Mbps_Apr18_3pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/s94f5vh6cz87579vvw3hsjw8/TIMWaltonthumbnails_0003_Session_4?auto=webp&format=png',
    shortDescription:
      'Tim Walton has a keen eye for helping a hitter get to the next level. In this session, Tim will walk you through the mechanics of taking your swing to the next level.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-5',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'Defensive Strategy',
    sessionNumber: 5,
    firebaseId: 'VDNHVjpvq7cZvD9r10sQ',
    videoId: 'hqROapMiN8TLcpKbYXOz',
    episode: 5,
    thumbnail: '',
    m3u8Link: 'https://stream.mux.com/f5ZGcZ02CVabWnUmHaeDPn3z02OGtlgyas.m3u8',
    duration: '6:45',
    durationInSeconds: 405,
    durationInMiliSeconds: 405000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/n6xvz2qfrvcwfjk5jq66bgj/05_Tim_Walton_Defensive_Strategy_FINAL_H264_20Mbps_Apr17_6pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/fz2tkw7qw74fcww6jf78m8j/TIMWaltonthumbnails_0004_Session_5?auto=webp&format=png',
    shortDescription:
      'Tim clearly knows how to work the field as well. From bunts to slaps, learn how the five-time SEC Coach of the Year arranges the field to create an unstoppable defense.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-6',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'In-Game Management',
    sessionNumber: 6,
    firebaseId: 'h5EJs3UWJp5uV5riBLgZ',
    videoId: 'Co2w2zciN1n4fm4kAveU',
    episode: 6,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/4bjkkxtwqsb8cm6mcjp8n54/TIMWaltonthumbnails_0005_Session_6.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/vO8Z014O8kg002wnrqjWvS6BYmk7Pc0291g.m3u8',
    duration: '7:41',
    durationInSeconds: 461,
    durationInMiliSeconds: 461000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/w7wtsrqp8kwqg95txm4r6xjb/06_Tim_Walton_In_Game_Management_Best_Practice_FINAL_H264_20Mbps_Apr17_6pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/cp5vxtcbgbfnpzx8jwv7r95p/TIMWaltonthumbnails_0005_Session_6?auto=webp&format=png',
    shortDescription:
      'High pressure situation calls can make or break the success of your team. In this session, Tim will walk through the key elements to be aware of during the game and how to manage them so that you come out with a W.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-7',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'How to Coach 3rd Base',
    sessionNumber: 7,
    firebaseId: 'cYBjT9L3Ao6WA40Eev2L',
    videoId: 'iG8Kycz9hCfnT6FqmbJl',
    episode: 7,
    thumbnail:
      'https://videogif.bfldr.com/I7CUjCDYptNz8RZu20002I00lUIF5C901UJq/animated.gif',
    m3u8Link:
      'https://stream.mux.com/zY01901C024QPxrK01Dhaca3yfd1N3OkwRiz.m3u8',
    duration: '4:50',
    durationInSeconds: 290,
    durationInMiliSeconds: 290000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/nkw4b698vfp4mshwk9g8b5s/07_Tim_Walton_How_To_Coach_3rd_Base_FINAL_H264_20Mbps_Apr18_10pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/6gt4r69hgvv4k4fnhsnvmbnz/TIMWaltonthumbnails_0006_Session_7?auto=webp&format=png',
    shortDescription:
      'Coaching 3rd base is an art. Knowing how to coach 3rd base could be the differnce of winning and losing. In this session, Tim walks you though how to be prepared when stepping into the coaching box.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-8',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'Know Your Players',
    sessionNumber: 8,
    firebaseId: '0YdsKU3pUOcjBkFl3AB7',
    videoId: 'e0euHuRdRrEoQCmShxhP',
    episode: 8,
    thumbnail:
      'https://videogif.bfldr.com/8sp5WLpJoCs5PlpqZARZnhAOQwFnJaZj/animated.gif',
    m3u8Link: 'https://stream.mux.com/5fLpTHZdpXrSYBQxMVGHfJd00LYUu2fUZ.m3u8',
    duration: '7:36',
    durationInSeconds: 456,
    durationInMiliSeconds: 456000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/3nhj5bj63cc3rqgm3srn/08_Tim_Walton_Know_Your_Players_FINAL_H264_20Mbps_Apr17_7pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/x6gfgk35j9bfn4vzsk377cxx/TIMWaltonthumbnails_0007_Session_8?auto=webp&format=png',
    shortDescription:
      'Learn how Tim gets to know his players’ strengths, style, and personality, as well as the strategies he implements to harness each of his athlete’s greatest potential.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-9',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'Managing Conflict',
    sessionNumber: 9,
    firebaseId: 'vJph0iC8f2mDLW2uuTQr',
    videoId: 'l7r0ix89lt8danAT1S5n',
    episode: 9,
    thumbnail:
      'https://videogif.bfldr.com/oNuCGJgS7819oQdcRwXwrG2ulj02jTSPK/animated.gif',
    m3u8Link: 'https://stream.mux.com/VqxBOy00Ecs8nfEjwn6UliHyJLYrJYlg9.m3u8',
    duration: '7:32',
    durationInSeconds: 452,
    durationInMiliSeconds: 452000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/grktmt7pc6k23sc93nqcg3/09_Tim_Walton_Managing_Conflict_FINAL_H264_20Mbps_Apr18_1pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/mht6gs3f96k793rzbp389tkx/TIMWaltonthumbnails_0008_Session_9?auto=webp&format=png',
    shortDescription:
      'Through his years of experience, Tim Walton has had to have several hard conversations that in the end, made his team stronger. In this session, viewers will learn how to tackle conflict head on.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-10',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'Leading Through Change',
    sessionNumber: 10,
    firebaseId: 'mS3TRIlFyT4RfZN76Z4T',
    videoId: 'AXmUDO2pxtKxadaUBYSP',
    episode: 10,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/nvs8cg2xc7849n7r5t2rbmx/TIMWaltonthumbnails_0009_Session_10.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/NZD02bQLXDToGWFp01EOk76fVH6BW6OEO2.m3u8',
    duration: '7:19',
    durationInSeconds: 439,
    durationInMiliSeconds: 439000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/fqq6ckp59r8rm98mg9th4v/10_Tim_Walton_Leading_Through_Change_FINAL_H264_20Mbps_Apr18_10pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/vjgmnb8mgkcswvgscswphm/TIMWaltonthumbnails_0009_Session_10?auto=webp&format=png',
    shortDescription:
      'College sports are a revolving door. New athletes come and go every 4 years. Learn in this session how Tim Walton embraces the change and the strategies he utilizes to help his team move forward towards success.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-11',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'How to Build a Winning Culture',
    sessionNumber: 11,
    firebaseId: 'SumajaGpyTg4SHtv0j9S',
    videoId: 'TuJjlM8TNFdihZOVlRlZ',
    episode: 11,
    thumbnail:
      'https://cdn.bfldr.com/B1ZZKPB4/at/mkppr543qr9b65vjmpsj4c2j/TIMWaltonthumbnails_0010_Session_11.jpg?width=600&height=310',
    m3u8Link: 'https://stream.mux.com/HOGbeNVkEolKJrsmxAq02Vv302G01fsHGDN.m3u8',
    duration: '3:43',
    durationInSeconds: 223,
    durationInMiliSeconds: 223000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/3zt9n93tks58x9zm7nnwsfg/11_Tim_Walton_How_To_Build_A_Winning_Culture_FINAL_H264_20Mbps_Apr18_6pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hqrprqmk2nx57b75cx3kvph9/TIMWaltonthumbnails_0010_Session_11?auto=webp&format=png',
    shortDescription:
      'Tim Walton knows what it takes to make every moment count both on and off the field. Learn the tools effectively build a winning culture that will last the test of time.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-12',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'Building a Staff Around You',
    sessionNumber: 12,
    firebaseId: 'WI8bbsMPAQRErGonh1m1',
    videoId: 'fc4dwLUQ2MXI8hBe0wi1',
    episode: 12,
    thumbnail:
      'https://videogif.bfldr.com/DfiIFoBNsL601Dg6vECERgUqZNKdtrpE5/animated.gif',
    m3u8Link: 'https://stream.mux.com/GfwusgFsgZ8mVZ9qQ9psYbyZgDiAiOKE.m3u8',
    duration: '4:38',
    durationInSeconds: 278,
    durationInMiliSeconds: 278000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/6hssbjhx5x3ch9897kz9crn/12_Tim_Walton_Building_A_Staff_Around_You_FINAL_H264_20Mbps_Apr18_6pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hv8647xjkftnv38sk35ch/TIMWaltonthumbnails_0011_Session_12?auto=webp&format=png',
    shortDescription:
      'Cultivate and hone your ability to bring together coaches and support systems for your team. Learn how Tim puts together a solid foundation of support from which he can build a winning empire.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: 'tim-walton-13',
    mavenSlug: 'tim-walton',
    maven: 'Tim Walton',
    title: 'Recruiting',
    sessionNumber: 13,
    firebaseId: '1CqAxEKnyJXCpgWOEOr5',
    videoId: 'uGiele4FAkSoRM6cMDBN',
    episode: 13,
    thumbnail:
      'https://videogif.bfldr.com/a3sigMGeWNX14hXID00HUkgCR02wrg6VxT/animated.gif',
    m3u8Link: 'https://stream.mux.com/02HDMBO9wnendBXz737Gr016EkpQQBZqgJ.m3u8',
    duration: '6:18',
    durationInSeconds: 378,
    durationInMiliSeconds: 378000,
    video:
      'https://cdn.bfldr.com/B1ZZKPB4/as/bpsbfmk4jngv8fkb8cjfws/13_Tim_Walton_Recruiting_FINAL_H264_20Mbps_Apr19_1pm',
    m3u8Link__1: '',
    image:
      'https://cdn.bfldr.com/B1ZZKPB4/as/hc27nb8v576xptqgvzxrc8/TIMWaltonthumbnails_0012_Session_13?auto=webp&format=png',
    shortDescription:
      'Playing collegiate ball may feel like a pipe dream. Learn from one of the best coaches of all time, Tim Walton, on what it takes to make it to the next level and how you can help your athlete get there.',
    longDescription: '',
    firebaseMavenId: 'KFWblG8xBnqbHe5gjdLN',
  },
  {
    slug: '',
    mavenSlug: '',
    maven: '',
    title: '',
    sessionNumber: '',
    firebaseId: '',
    videoId: '',
    episode: '',
    thumbnail: '',
    m3u8Link: '',
    duration: '',
    durationInSeconds: 54374,
    durationInMiliSeconds: '',
    video: '',
    m3u8Link__1: '',
    image: '',
    shortDescription: '',
    longDescription: '',
    firebaseMavenId: '',
  },
];
