import React, { InputHTMLAttributes } from "react";
import { CheckboxStyles } from "./Checkbox.style";

export type Props = {} & InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = (props: Props) => {
  const { className = "" } = props;

  return <CheckboxStyles {...props} className={className} type="checkbox" />;
};
