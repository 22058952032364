export const secondaryFilters = [
  { id: 0, title: 'Courses', value: 'course', icon: '/icons/Square-Play.svg' },
  {
    id: 1,
    title: 'Conversation Only',
    value: 'conversations',
    icon: '/icons/AI.svg',
  },
];

export const herosBadges = [
  { id: 0, title: 'Course', value: 'course', icon: '/icons/Square-Play.svg' },
  {
    id: 1,
    title: 'Conversation',
    value: 'conversations',
    icon: '/icons/AI.svg',
  },
];

export const herosBadgeInteractiveOnly = [
  {
    id: 1,
    title: 'Conversation',
    value: 'conversations',
    icon: '/icons/AI.svg',
  },
];
