import { useState, useContext, useEffect } from 'react';
import { AuthContext } from 'src/context/AuthContext';
import { checkout } from 'src/features/Stripe/checkout';
import { generalFaqs, pricingAndMembershipFaqs } from 'src/data/faqs';
import FAQ from '../LandingPage/FAQ';
import Button from '@components/Button/Button';
import PricingBanner from './PricingBanner';
import SEO from '@components/Seo/Seo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './PricingPageNew.scss';
import Loader from '@components/Loader/Loader';
import { getPrices } from 'src/features/db';
import { useSubscription } from 'src/context/SubscriptionContext';
import useHasMounted from '@hooks/useHasMounted';

const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

const availablePlans = [
  {
    title: 'Premium',
    list: [
      'Unlimited access to all Versus Courses and Conversations.',
      'Early sneak peaks to new content, plus access to newly released content within your subscription period.',
      'Members-only benefit of collaborating with the Versus team to request new experts and participate in the development of new content for your streaming feed.',
      'Streaming on the Versus Interactive mobile app.',
    ],
    price: 19900,
    discountedPrice: 0,
    body: '<div className="center">*Limited time offer. Sign up to unlock free access to all premium content and features.</div>',
    buttonText: 'Get FREE Access',
    link: '',
  },
  {
    title: 'Group Membership',
    list: [
      'Everything in the individual plan, provided to each member in your group.',
      'Volume-based discounts dependent on group size. ',
    ],

    body: 'Want to get your team or organization to become Versus members? Connect with one of our specialists today for more information on volume-based discounts.',
    subBody:
      'Call us now: <a href="tel:+18003600273" target="_blank">+1 (800) 360-0273</a>',
    buttonText: 'Contact Sales',
    link: '',
    list: [
      'Everything in the individual plan provided to each member in your group.',
      'Volume-based discounts.',
    ],
  },
];

const convert = (string) => {
  if (isHTML(string)) {
    return <div dangerouslySetInnerHTML={{ __html: string }} />;
  }
  return string;
};

function PricingPageNew({ setContactModal, currentUser, setToggledLoginType }) {
  const subscription = useSubscription();
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getPrices()
      ?.then((e) => {
        const available = availablePlans.map((a, index) => ({
          ...a,
          price: index === 0 ? e[index].price.unit_amount : a.price,
          stripe: e[index],
        }));
        setProducts(available);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setProducts(availablePlans);
      });
  }, []);

  const handleRedirectToStripeCheckout = async (item) => {
    setIsLoading(true);
    const coupon = searchParams.get('discount');
    checkout(item.priceId, user, coupon, null, setIsLoading);
  };

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="pricing-page-new">
          <SEO
            title="View Game Plans | Versus"
            description="All plans come with a 3-day free trial, plus 1-year of unlimited access to Versus."
            name="Pricing Page for VS Individual"
            type="article"
          />
          {/* <PricingPageHero /> */}
          {/* <HeroSimple
            title='
            Choose your plan'
            subtitle={
              <div>
                All plans include a <b>3-day free trial</b>, plus 1-year of
                unlimited access to Versus.
              </div>
            }
          /> */}

          <div className="content">
            <div className="card-container">
              {products?.map((a, index) => (
                <div key={`pricing-${index}`} className="card">
                  <div className="price-header">
                    <img src="/pages/vs-card.png" alt="pricing-logo" />
                    {a.title}{' '}
                    {index === 0 && (
                      <span className="badge">Limited Time Only!</span>
                    )}
                  </div>
                  {(a.price || a.discountedPrice) && (
                    <div className="price">
                      <h4>${a.price / 100}</h4>

                      {a.discountedPrice === 0 ? (
                        <>
                          <h3>Free/</h3>
                          <b>
                            Limited
                            <br />
                            Time
                          </b>
                        </>
                      ) : (
                        <h3>
                          ${a.discountedPrice / 100} <small>/yr</small>
                        </h3>
                      )}
                    </div>
                  )}
                  <div className="paragraph">{convert(a.body)}</div>
                  {a.subBody && (
                    <div className="paragraph sub-body">
                      {convert(a.subBody)}
                    </div>
                  )}

                  {a.title === 'Premium' && (
                    <>
                      {!currentUser && (
                        <Button
                          className="primary"
                          onClick={() => navigate('/pass/?id=vsunlimited')}
                          //onClick={() => setToggledLoginType('signup')}
                        >
                          {a.buttonText}
                        </Button>
                      )}
                      {currentUser && (
                        <>
                          {subscription.planName === 'Free' && (
                            <Button
                              className="primary"
                              onClick={() => navigate('/pass/?id=vsunlimited')}
                              /* onClick={() =>
                                handleRedirectToStripeCheckout(a.stripe)
                              } */
                            >
                              {a.buttonText}
                            </Button>
                          )}
                          {subscription.planName === 'Premium' && (
                            <Button className="secondary disabled">
                              Your plan renews on {subscription.setExpiredAt}.
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {a.title === 'Group Membership' && (
                    <Button
                      onClick={() =>
                        setContactModal({
                          type: 'Group Membership',
                          title: 'Group Membership Inquiry',
                        })
                      }
                      className="primary"
                    >
                      {a.buttonText}
                    </Button>
                  )}

                  <ul>
                    {a.list.map((aa, index) => (
                      <li key={`list-${index}`}>
                        <img src="/icons/checkmark.svg" alt="Checked" /> {aa}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <PricingBanner setContactModal={setContactModal} />
            <div className="faq-landing">
              <h1>Frequently Asked Questions</h1>
              <FAQ data={generalFaqs} title="General" />
              <FAQ
                data={pricingAndMembershipFaqs}
                title="Pricing & Membership"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PricingPageNew;
