import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import { useSubscription } from 'src/context/SubscriptionContext';
import { goToPayment } from 'src/features/Stripe/stripeHelpers';
import './Subscription.scss';

const Subscription = ({ setContactModal, currentUser, setIsLoading }) => {
  const subscription = useSubscription();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <div className="subscription">
      <div className="settingsRow">
        <h4>Subscription</h4>
        <div className="subscriptionBox">
          <div className="subscriptionInfoWrapper">
            <div className="subscriptionInfo">
              <h4>
                {subscription.planName === 'Premium' ? 'Individual' : 'Free'}
              </h4>
              {subscription.planName !== 'Free' ? (
                <p>
                  {subscription.period} Subscription / ${subscription.price}
                </p>
              ) : (
                <p>Limited access</p>
              )}
            </div>
            {subscription.planName !== 'Free' && (
              <div className="subscriptionRenewal">
                <span>Renews {subscription.expiredAtFormated}</span>
              </div>
            )}
          </div>

          {subscription.planName !== 'Premium' ? (
            <>
              <Button
                className="primary"
                style={{ width: '200px' }}
                title="Change Plan"
                action="Upgrade Subscription"
                onClick={() => {
                  goToPayment(
                    searchParams,
                    currentUser,
                    navigate,
                    setIsLoading
                  );
                }}
              >
                Upgrade Now
              </Button>
            </>
          ) : (
            <Button
              className="changePlanBtn"
              title="Change Plan"
              action="Cancel Subscription"
              onClick={() =>
                setContactModal({
                  type: 'Cancel Subscription',
                  title: 'Cancel Subscription',
                })
              }
            >
              Cancel Subscription
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
