export const partnersData = [
  {
    slug: 'usssa-coach-softball',
    partner: 'usssa',
    type: 'Coach',
    sport: 'Softball',
    heroVideoBackground:
      'https://stream.mux.com/QMW00Ij4qmaQuoFPFIFr6I02P1qBAHuicl.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for softball coaches who want to be the best softball coaches they can be.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus connects people who want to achieve something great with the coaches and players who already have. We bring new and refreshing ways to help you ignite your players’ to being their best.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what is like overcoming injury.',
    rosterSelectedCategory: 'softball',
    CTAHeader: 'A membership for softball coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>USSSASC</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MXSqvGZM0o83MkVpF4U9chQ&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'USSSA Softball Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=USSSA&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dusssa%26utm_medium%3Demail%26utm_term%3Dapp-download',
  },
  {
    slug: 'usssa-coach-baseball',
    partner: 'usssa',
    type: 'Coach',
    sport: 'Baseball',
    heroVideoBackground:
      'https://stream.mux.com/1QUgnJcmBd01hM9VeVosn00f02bZoIcoGGd.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for coaches to help their players compete at their next level.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Featuring some of baseball’s top players and coaches, Versus helps you choose how to better teach the mindset, habits and leadership qualities that lead to winning - on and off the field.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'baseball',
    CTAHeader: 'A membership for baseball coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>USSSABC</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'versus.co/pass/?discount=promo_1MXSphGZM0o83MkV38OkiuhU&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'USSSA Baseball Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=USSSA&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dusssa%26utm_medium%3Demail%26utm_term%3Dapp-download',
  },
  {
    slug: 'usssa-player-softball',
    partner: 'usssa',
    type: 'Player',
    sport: 'Softball',
    heroVideoBackground:
      'https://stream.mux.com/QMW00Ij4qmaQuoFPFIFr6I02P1qBAHuicl.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'How softball players become standout softball players.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you how to be their best. We’ll help you become the competitor you were meant to be by learning the skills, winning mindset and character traits from those who’ve done it at the highest levels of the game.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'softball',
    CTAHeader: 'A membership for softball players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>USSSASP</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MXSrcGZM0o83MkVTd25Qj90&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'USSSA Softball Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=USSSA&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dusssa%26utm_medium%3Demail%26utm_term%3Dapp-download',
  },
  {
    slug: 'usssa-player-baseball',
    partner: 'usssa',
    type: 'Player',
    sport: 'Baseball',
    heroVideoBackground:
      'https://stream.mux.com/1QUgnJcmBd01hM9VeVosn00f02bZoIcoGGd.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Created for the baseball player who dares to be better and never stop learning.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you how to advance to your next level, by some of the best baseball players and coaches in the game.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'baseball',
    CTAHeader: 'A membership for baseball players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>USSSABP</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MXSrEGZM0o83MkVi9k4RqaJ&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'USSSA Baseball Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=USSSA&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dusssa%26utm_medium%3Demail%26utm_term%3Dapp-download',
  },
  {
    slug: 'alliance-coach',
    partner: 'alliance',
    type: 'Coach',
    sport: 'Softball',
    heroVideoBackground:
      'https://stream.mux.com/QMW00Ij4qmaQuoFPFIFr6I02P1qBAHuicl.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for softball coaches who want to be the best softball coaches they can be.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus connects people who want to achieve something great with the coaches and players who already have. We bring new and refreshing ways to help you ignite your players’ to being their best.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'softball',
    CTAHeader: 'A membership for softball coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>ALLIANCEFPC</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MXXgMGZM0o83MkVBjIH1iDB&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'Alliance FP Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Alliance%20Fastpitch&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dalliance-fastpitch%26utm_medium%3Demail%26utm_term%3Dapp-download',
  },
  {
    slug: 'alliance-player',
    partner: 'alliance',
    type: 'Player',
    sport: 'Softball',
    heroVideoBackground:
      'https://stream.mux.com/QMW00Ij4qmaQuoFPFIFr6I02P1qBAHuicl.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'How softball players become standout softball players.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you how to be your best. We’ll help you become the competitor you were meant to be by learning the skills, winning mindset and character traits from those who’ve done it at the highest levels of the game.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'softball',
    CTAHeader: 'A membership for softball players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>ALLIANCEFP</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MXXh0GZM0o83MkVbEbT18Dl&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'Alliance FP Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Alliance%20Fastpitch&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dalliance-fastpitch%26utm_medium%3Demail%26utm_term%3Dapp-download',
  },
  {
    slug: 'perfectgame-coach-baseball',
    partner: 'perfect game',
    type: 'Coach',
    sport: 'Baseball',
    heroVideoBackground:
      'https://stream.mux.com/1QUgnJcmBd01hM9VeVosn00f02bZoIcoGGd.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for coaches to help their players compete at their next level.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Featuring some of baseball’s top players and coaches, Versus helps you choose how to better teach the mindset, habits and leadership qualities that lead to winning - on and off the field.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'baseball',
    CTAHeader: 'A membership for baseball coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>PGCOACH</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MXXoYGZM0o83MkVMAMtCs8s&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'PG Baseball Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Perfect%20Game&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dperfectgame%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'perfectgame-coach-softball',
    partner: 'perfect game',
    type: 'Coach',
    sport: 'Softball',
    heroVideoBackground:
      'https://stream.mux.com/QMW00Ij4qmaQuoFPFIFr6I02P1qBAHuicl.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for softball coaches who want to be the best softball coaches they can be.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus connects people who want to achieve something great with the coaches and players who already have. We bring new and refreshing ways to help you ignite your players’ to being their best.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'softball',
    CTAHeader: 'A membership for softball coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>PGCOACH23</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MXXoYGZM0o83MkVITAbD87y&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'PG Softball Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Perfect%20Game&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dperfectgame%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'perfectgame-player-baseball',
    partner: 'perfect game',
    type: 'Player',
    sport: 'Baseball',
    heroVideoBackground:
      'https://stream.mux.com/1QUgnJcmBd01hM9VeVosn00f02bZoIcoGGd.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Created for the baseball player who dares to be better and never stop learning.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you how to advance to your next level, by some of the best baseball players and coaches in the game.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'baseball',
    CTAHeader: 'A membership for baseball players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>PGPLAYER</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MXXoYGZM0o83MkVVmYvD8d5&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'PG Baseball Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Perfect%20Game&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dperfectgame%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'perfectgame-player-softball',
    partner: 'perfect game',
    type: 'Player',
    sport: 'Softball',
    heroVideoBackground:
      'https://stream.mux.com/QMW00Ij4qmaQuoFPFIFr6I02P1qBAHuicl.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'How softball players become standout softball players.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you how to be your best. We’ll help you become the competitor you were meant to be by learning the skills, winning mindset and character traits from those who’ve done it at the highest levels of the game.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'softball',
    CTAHeader: 'A membership for softball players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>PGPLAYER23</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MXXoYGZM0o83MkVG93ERGKd&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'PG Softball Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Perfect%20Game&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dperfectgame%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'teamsnap-coach',
    partner: 'teamsnap',
    type: 'Coach',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for coaches to help their players compete at their next level.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      "Featuring some of sport's top players and coaches, Versus helps you better teach the mindset, habits, and leadership qualities that lead to winning - on and off the field.",
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'soccer',
    CTAHeader: 'A membership for coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>TSCOACH</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MZFcxGZM0o83MkVEPAvBsPJ&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'TeamSnap Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=TeamSnap&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dteamsnap%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'teamsnap-player',
    partner: 'teamsnap',
    type: 'Player',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Created for the player who dares to be better and never stop learning.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      "Featuring some of sport's top players and coaches, Versus helps you learn the mindset, habits, and leadership qualities that lead to winning - on and off the field.",
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'soccer',
    CTAHeader: 'A membership for players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>TSPLAYER</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MZFcxGZM0o83MkVN70rLkK8&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'TeamSnap Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=TeamSnap&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dteamsnap%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'teamsnap-parent',
    partner: 'teamsnap',
    type: 'Parent',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'Built to help your players compete at their next level.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      "Featuring some of sport's top players and coaches, Versus helps you better teach the mindset, habits, and leadership qualities that lead to winning - on and off the field.",
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'soccer',
    CTAHeader: 'A membership for parents who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>TSPARENT</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MZFcxGZM0o83MkVtyj8p2VQ&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'TeamSnap Parent',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=TeamSnap&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dteamsnap%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports',
    partner: 'stack sports',
    type: 'Other',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'Learn to compete at the highest level.',
    heroBody:
      'Unlock <b>3 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader: 'An experience like no other. Only at Versus.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: "Let's get started.",
    CTABody:
      'A Versus membership gets you unlimited access to a variety of soft and techinical based video content, plus a library of over 4,700 real-time interactions with top athletes and coaches.',
    CTAButton: 'Join Now',
    CTALink: '',
    'Mailchimp Tag': 'Stack Sports',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-coach',
    partner: 'stack sports',
    type: 'Coach',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for coaches to help their players compete at their next level.',
    heroBody:
      'Unlock <b>30 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you the mindset, habits, and leadership qualities of some of the best players and coaches in sports.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: 'A membership for coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>SSCOACH</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MmltYGZM0o83MkV8tqhXOaW&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'Stack Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=9',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-player',
    partner: 'stack sports',
    type: 'Player',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Created for the player who dares to be better and never stop learning.',
    heroBody:
      'Unlock <b>7 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you the mindset, habits, and leadership qualities of some of the best players and coaches in sports.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: 'A membership for players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>SSATHLETE</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MmltPGZM0o83MkV11KIDoY9&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'Stack Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=10',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-parent',
    partner: 'stack sports',
    type: 'Parent',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'Built to help your players compete at their next level.',
    heroBody:
      'Unlock <b>7 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you the mindset, habits, and leadership qualities of some of the best players and coaches in sports.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: 'A membership for parents who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>SSPARENT</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MmlteGZM0o83MkVuBuMjoP7&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'Stack Parent',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=11',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-coach-baseball',
    partner: 'stack sports',
    type: 'Coach',
    sport: 'Baseball',
    heroVideoBackground:
      'https://stream.mux.com/1QUgnJcmBd01hM9VeVosn00f02bZoIcoGGd.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for coaches to help their players compete at their next level.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Featuring some of baseball’s top players and coaches, Versus helps you choose how to better teach the mindset, habits and leadership qualities that lead to winning - on and off the field.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'baseball',
    CTAHeader: 'A membership for baseball coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>SSBASBEALL</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MnwqQGZM0o83MkV6mTiXU8L&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'Stack BSB Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-coach-softball',
    partner: 'stack sports',
    type: 'Coach',
    sport: 'Softball',
    heroVideoBackground:
      'https://stream.mux.com/QMW00Ij4qmaQuoFPFIFr6I02P1qBAHuicl.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for softball coaches who want to be the best softball coaches they can be.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus connects people who want to achieve something great with the coaches and players who already have. We bring new and refreshing ways to help you ignite your players’ to being their best.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what is like overcoming injury.',
    rosterSelectedCategory: 'softball',
    CTAHeader: 'A membership for softball coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>SSSOFTBALL</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MnwqdGZM0o83MkVJsqG2UdB&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'Stack SFT Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=9',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-coach-soccer',
    partner: 'stack sports',
    type: 'Coach',
    sport: 'Soccer',
    heroVideoBackground:
      'https://stream.mux.com/AKnKxaNiIOVfV5TcH1djGdJNcmdkqlIp.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for coaches to help their players compete at their next level.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus connects people who want to achieve something great with the coaches and players who already have. We bring new and refreshing ways to help you ignite your players’ to being their best.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'soccer',
    CTAHeader: 'A membership for coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>SSSOCCER</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MnwqXGZM0o83MkVEGopQ38j&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'Stack SOC Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=10',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-player-baseball',
    partner: 'stack sports',
    type: 'Player',
    sport: 'Baseball',
    heroVideoBackground:
      'https://stream.mux.com/1QUgnJcmBd01hM9VeVosn00f02bZoIcoGGd.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Created for the baseball player who dares to be better and never stop learning.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you the mindset, habits, and leadership qualities of some of the best baseball players and coaches in the game.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'baseball',
    CTAHeader: 'A membership for baseball players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>SSBASEBALL23</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1Mnws1GZM0o83MkVFmYRyPSW&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'Stack BSB Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=11',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-player-softball',
    partner: 'stack sports',
    type: 'Player',
    sport: 'Softball',
    heroVideoBackground:
      'https://stream.mux.com/QMW00Ij4qmaQuoFPFIFr6I02P1qBAHuicl.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'How softball players become standout softball players.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you the mindset, habits, and leadership qualities of some of the best softball players and coaches in the game.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'softball',
    CTAHeader: 'A membership for softball players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>SSSOFTBALL23</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1Mnws8GZM0o83MkVZBHTmmio&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'Stack SFT Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=12',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-player-soccer',
    partner: 'stack sports',
    type: 'Player',
    sport: 'Soccer',
    heroVideoBackground:
      'https://stream.mux.com/AKnKxaNiIOVfV5TcH1djGdJNcmdkqlIp.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Created for the soccer player who dares to be better and never stop learning.',
    heroBody: 'Get exclusive <b>unlimited access</b>. Sign up to learn more.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you the mindset, habits, and leadership qualities of some of the best soccer players and coaches in the game.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'soccer',
    CTAHeader: 'A membership for players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>SSSOCCER23</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MnwsFGZM0o83MkVEmT4cYOc&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'Stack SOC Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=13',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-parent-baseball',
    partner: 'stack sports',
    type: 'Parent',
    sport: 'Baseball',
    heroVideoBackground:
      'https://stream.mux.com/1QUgnJcmBd01hM9VeVosn00f02bZoIcoGGd.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'Built to help your players compete at their next level.',
    heroBody:
      'Unlock <b>7 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      "Featuring some of baseball's top players and coaches, Versus helps you better teach the mindset, habits, and leadership qualities that lead to winning - on and off the field.",
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: 'A membership for parents who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>STACKBASEBALL</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MnwudGZM0o83MkVbJE4ypYW&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'Stack BSB Parent',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=13',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-parent-softball',
    partner: 'stack sports',
    type: 'Parent',
    sport: 'Softball',
    heroVideoBackground:
      'https://stream.mux.com/QMW00Ij4qmaQuoFPFIFr6I02P1qBAHuicl.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'Built to help your players compete at their next level.',
    heroBody:
      'Unlock <b>7 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      "Featuring some of softball's top players and coaches, Versus helps you better teach the mindset, habits, and leadership qualities that lead to winning - on and off the field.",
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: 'A membership for parents who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>STACKSOFTBALL</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MnwusGZM0o83MkVeICKBIul&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'Stack SFT Parent',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=13',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'stacksports-parent-soccer',
    partner: 'stack sports',
    type: 'Parent',
    sport: 'Soccer',
    heroVideoBackground:
      'https://stream.mux.com/AKnKxaNiIOVfV5TcH1djGdJNcmdkqlIp.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'Built to help your players compete at their next level.',
    heroBody:
      'Unlock <b>7 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      "Featuring some of soccer's top players and coaches, Versus helps you better teach the mindset, habits, and leadership qualities that lead to winning - on and off the field.",
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: 'A membership for parents who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>STACKSOCCER</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MnwukGZM0o83MkVv72Hcv1X&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'Stack SOC Parent',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=Stack%20Sports&mt=13',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dstacksports%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'gamechanger',
    partner: 'gamechanger',
    type: 'Other',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'Learn to compete at the highest level.',
    heroBody:
      'Unlock <b>7 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader: 'An experience like no other. Only at Versus.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: 'A membership for the one who never stops learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>GC2023</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MgXqRGZM0o83MkVdKfuS2iN&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'GameChanger',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=GameChanger&mt=8',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dgamechanger%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'gamechanger-coach',
    partner: 'gamechanger',
    type: 'Coach',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Built for coaches to help their players compete at their next level.',
    heroBody:
      'Unlock <b>30 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      "Featuring some of sport's top players and coaches, Versus helps you better teach the mindset, habits, and leadership qualities that lead to winning - on and off the field.",
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: 'A membership for coaches who never stop learning.',
    CTABody:
      '<b>Unlock 30 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>GCCOACH</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MnwnEGZM0o83MkVSQBnCEZF&p=price_1MHYN3GZM0o83MkVhLjAehip',
    'Mailchimp Tag': 'GC Coach',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=GameChanger&mt=9',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dgamechanger%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'gamechanger-player',
    partner: 'gamechanger',
    type: 'Player',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader:
      'Created for the player who dares to be better and never stop learning.',
    heroBody:
      'Unlock <b>7 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      'Versus is the exclusive, AI-powered app that teaches you the mindset, habits, and leadership qualities of some of the best players and coaches in the game.',
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: 'A membership for players who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>GCPLAYER</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MnwnfGZM0o83MkVItx7Ul59&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'GC Player',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=GameChanger&mt=10',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dgamechanger%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
  {
    slug: 'gamechanger-parent',
    partner: 'gamechanger',
    type: 'Parent',
    sport: 'Other',
    heroVideoBackground:
      'https://stream.mux.com/4XYAOK00jUzQZ00S7wJTh4IwQnxNlp6PO7.m3u8',
    heroBackgroundImage: '',
    heroHeader: 'Built to help your players compete at their next level.',
    heroBody:
      'Unlock <b>7 days</b> of free, unlimited access when you create an account today.',
    heroEmailFormField: 'Email address',
    heroEmailButton: 'Sign Up',
    experienceHeader:
      "Featuring some of sport's top players and coaches, Versus helps you better teach the mindset, habits, and leadership qualities that lead to winning - on and off the field.",
    experienceP1Header: '100+',
    experienceP1SubHeader: 'available sessions',
    experienceP1Body:
      "Exclusive video access to learn from the career altering lessons that took sport's best to the top.",
    experienceP2Header: '100+',
    experienceP2SubHeader: 'available topics',
    experienceP2Body:
      'Learn from a variety of topics to up every aspect of your game – both on the field and off of it.',
    experienceP3Header: '4700+',
    experienceP3SubHeader: 'expert-driven responses',
    experienceP3Body:
      'Got questions? We have answers. Ask anything from memories playing ball as a kid to what it is like overcoming injury.',
    rosterSelectedCategory: 'all',
    CTAHeader: 'A membership for parents who never stop learning.',
    CTABody:
      '<b>Unlock 7 days of free, unlimited access when you create an account today.</b> \nAnd as a bonus, receive a discount on annual membership,\nenter <b>GCPARENT</b> at checkout to receive an additional $100 off.',
    CTAButton: 'Join Now',
    CTALink:
      'https://versus.co/pass/?discount=promo_1MnwnmGZM0o83MkVGduyBYsD&p=price_1MHBVoGZM0o83MkV3pjIhrN6',
    'Mailchimp Tag': 'GC Parent',
    appStoreLink:
      'https://apps.apple.com/app/apple-store/id1612364880?pt=124180028&ct=GameChanger&mt=11',
    playStoreLink:
      'https://play.google.com/store/apps/details?id=com.mavenarena.versus&referrer=utm_source%3Dgamechanger%26utm_medium%3Demail%26utm_term%3Dapp%252Bdownload',
  },
];
