import styled from "styled-components";

export const CheckboxStyles = styled.input`
  width: 16px;
  height: 16px;
  background: #fff;
  color: #000;
  border-radius: 2px;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 4px #00000040;
  &:checked,
  &:focus,
  &:checked:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &:checked,
  &:focus,
  &:checked:focus {
    color: blue;
  }
`;
