import { checkout } from 'src/features/Stripe/checkout';

export const goToPayment = (
  searchParamsObj,
  currentUser,
  navigate,
  setIsLoading
) => {
  //If search params include prepaid skip checkout and push to root
  if (searchParamsObj?.get('prepaid') == 'true') {
    //remove prepaid param
    setTimeout(() => {
      navigate('/');
      window.location.reload();
    }, 3000);
    return;
  } else {
    const price = searchParamsObj?.get('p') || 'price_1MTuPOGZM0o83MkVALLWwoJi';
    const coupon = searchParamsObj?.get('discount') || '';

    checkout(
      price,
      currentUser,
      coupon,
      searchParamsObj?.get('id'),
      setIsLoading
    );
  }
};
