import useHasMounted from '@hooks/useHasMounted';
import React from 'react';
import './Teamsnap.scss';
import TeamsnapForm from './TeamsnapForm';

function Teamsnap() {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }
  return (
    <div className="teamsnap fade-in">
      <div className="container">
        <h1>Get exclusive, unlimited access.</h1>
        <p>
          Versus offers an all-access pass to learn from some of the best soccer
          players in the world. Develop your mindset, technical skills and
          leadership — it’s everything you need to be great on and off the
          field.
        </p>
        <TeamsnapForm />
      </div>
      <iframe
        width="495"
        height="309"
        src="https://www.youtube.com/embed/zyR4FSyQBW8"
        title="VS Soccer Training with Kelley O'Hara, Ali Krieger, Tyler Adams and more..."
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default Teamsnap;
