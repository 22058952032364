import { useContext, useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
  useSearchParams,
  useParams,
} from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import Navbar from './components/Navbar/Navbar';
import HomePage from './pages/HomePage/HomePage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import PortalPage from './pages/PortalPage/PortalPage';
import LandingPage from './pages/LandingPage/LandingPage';
import LoginPage from './pages/LoginPage/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import Loader from './components/Loader/Loader';
import usePageTracking from '@hooks/usePageTracking';
import Logout from './pages/Logout/Logout';
import Survey from './pages/Survey/Survey';
import NavbarUnAuthed from '@components/NavbarUnAuthed/NavbarUnAuthed';
import Footer from './layout/Footer/Footer';
import LoginModal from '@components/Modals/LoginModal/LoginModal';
import VideoModal from '@components/Modals/VideoModal/VideoModal';
import DownloadApp from '@components/Modals/DownloadApp/DownloadApp';
import SubscribeModal from '@components/Modals/SubscribeModal/SubscribeModal';
import ArticlePage from './pages/ArticlePage/ArticlePage';
import CourseLanding from './pages/CourseLanding/CourseLanding';
import BioModal from '@components/Modals/BioModal/BioModal';
import ArticleLanding from './pages/ArticleLanding/ArticleLanding';
import InteractivePage from './pages/InteractivePage/InteractivePage';
import './styles.scss';
import Collection from './pages/Collection/Collection';
import InteractiveLanding from './pages/InteractiveLanding/InteractiveLanding';
import CollectionLanding from './pages/CollectionLanding/CollectionLanding';
import { useSubscription } from 'src/context/SubscriptionContext';
import Privacy from './pages/Policies/Privacy';
import Terms from './pages/Policies/Terms';
import Alert from '@components/Alert/Alert';
import ContactModal from '@components/Modals/ContactModal/ContactModal';
import Teamsnap from './pages/Teamsnap/Teamsnap';
import SimpleHeaderLayout from './layout/SimpleHeaderLayout/SimpleHeaderLayout';
import Course from './pages/Course/Course';
import SegmentPage from './pages/Segment/Segment';
import LandingPartner from './pages/LandingPartner/LandingPartner';
import { mavenData } from './data/mavenData';
import LandingHome from './pages/LandingHome/LandingHome';
import { Capacitor } from '@capacitor/core';
import TabbedNav from './layout/TabbedNav/TabbedNav';
import { isMobile } from 'react-device-detect';
import Button from '@components/Button/Button';

const isNative = Capacitor.getPlatform() === 'ios';

function App() {
  usePageTracking();
  const navigate = useNavigate();
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');
  const { search, pathname } = useLocation();
  const [searchParamsObj] = useSearchParams();
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [toggledLoginType, setToggledLoginType] = useState(null);
  const [videoModal, setVideoModal] = useState(null);
  const [bioModal, setBioModal] = useState(null);
  const [contactModal, setContactModal] = useState(null);
  const [toggledDownloadModal, setToggledDownloadModal] = useState(false);
  const [toggledSubscribeModal, setToggledSubscribeModal] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [alert, setAlert] = useState(null);
  const [showBanner, setShowBanner] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const subscription = useSubscription();
  let location = useLocation();

  const handleSwitchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  /* useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []); */

  // const getLocation = (pathname) => {
  //   if (pathname.includes('/roster/')) {
  //     const stripped = pathname.split('/roster/')[1];
  //     return `${stripped}`;
  //   }
  // };

  //Segment Page Tracking
  let prevPath = null;
  let prevUser = null;

  useEffect(() => {
    if (pathname.includes('/interactive/')) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }

    //Segment Page Tracking
    if (window.analytics) {
      if (pathname !== prevPath) {
        prevPath = pathname;
        window.analytics.page();
      }
      if (user) {
        if (user !== prevUser) {
          prevUser = user;
          window.analytics.identify(user.id, {
            name: user.displayName,
            email: user.email,
            uid: user.uid,
          });
        }
      }
    }
  }, [pathname, user]);

  //disable body scroll if modal is open
  useEffect(() => {
    if (bioModal) {
      setIsFixed(true);
      return;
    }
    if (videoModal) {
      setIsFixed(true);
      return;
    }
    if (toggledLoginType) {
      setIsFixed(true);
      return;
    }
    if (toggledDownloadModal) {
      setIsFixed(true);
      return;
    }
    setIsFixed(false);
  }, [bioModal, videoModal, toggledLoginType, toggledDownloadModal]);

  const handleLSBanner = () => {
    localStorage.setItem('showBannerLS', 'false');
    setShowBanner(false);
  };

  const handleDownloadModal = () => {
    localStorage.setItem('downloadModal', 'seen');
    setShowDownloadModal(false);
  };

  const allRoutes = (
    <>
      {alert && (
        <Alert
          type={alert.type}
          title={alert.title}
          message={alert.message}
          icon={alert.icon}
        />
      )}
      {videoModal && (
        <VideoModal
          video={videoModal.video}
          title={videoModal.title}
          subtitle={videoModal.subtitle}
          actions={videoModal.actions}
          setVideoModal={setVideoModal}
        />
      )}
      {bioModal && (
        <BioModal
          title={bioModal.title}
          body={bioModal.body}
          image={bioModal.image}
          setBioModal={setBioModal}
        />
      )}
      {contactModal && (
        <ContactModal
          type={contactModal.type}
          title={contactModal.title}
          setContactModal={setContactModal}
          user={user}
        />
      )}
      {toggledDownloadModal && (
        <DownloadApp setToggledDownloadModal={setToggledDownloadModal} />
      )}
      {showDownloadModal && (
        <DownloadApp
          setToggledDownloadModal={setToggledDownloadModal}
          handleDownloadModal={handleDownloadModal}
          currentUser={user}
        />
      )}
      {toggledSubscribeModal && (
        <SubscribeModal
          setToggledSubscribeModal={setToggledSubscribeModal}
          setToggledLoginType={setToggledLoginType}
          searchParamsObj={searchParamsObj}
          currentUser={user}
          navigate={navigate}
          setIsLoading={setIsLoading}
        />
      )}

      {user && (
        <>
          <Routes>
            <Route
              path="/contact/"
              exact
              element={
                <SimpleHeaderLayout
                  type="ContactPage"
                  searchParams={search}
                  title="Contact Us"
                  subtitle={<div>Contact and support.</div>}
                />
              }
            />
            {/*  <Route
          path="/portal"
          element={<PortalPage searchParams={search} />}
        /> */}
            {subscription?.planName !== 'Premium' && (
              <Route
                path="/pricing"
                exact
                element={
                  <SimpleHeaderLayout
                    type="Pricing"
                    searchParams={search}
                    title="Choose your plan"
                    setContactModal={setContactModal}
                    currentUser={user}
                    setToggledLoginType={setToggledLoginType}
                    subtitle={
                      <>
                        <div>
                          Get Versus <b>FREE</b>, when you sign up today! No
                          Credit Card required.
                        </div>
                        {/* <div>
                        All plans include a <b>3-day free trial</b>, plus 1-year
                        of unlimited access to Versus.
                      </div> */}
                      </>
                    }
                  />
                }
              />
            )}

            <Route
              path="/roster"
              exact
              element={
                <SimpleHeaderLayout
                  type="Roster"
                  searchParams={search}
                  title="Roster"
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  subtitle={
                    <div>
                      Discover hours of developmental content and thousands of
                      interactions inside the Versus content library.
                    </div>
                  }
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/interactive/:slug"
              element={
                <InteractivePage
                  searchParams={search}
                  setToggledDownloadModal={setToggledDownloadModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  currentUser={user}
                />
              }
            />
            <Route
              path="/course/:slug"
              element={
                <CourseLanding
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setBioModal={setBioModal}
                  setToggledDownloadModal={setToggledDownloadModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                  currentUser={user}
                />
              }
            />

            <Route
              path="/course/:mavenSlug/video/:videoSlug"
              element={
                <Course
                  setVideoModal={setVideoModal}
                  setBioModal={setBioModal}
                  setToggledDownloadModal={setToggledDownloadModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                  searchParams={search}
                />
              }
            />
            {/* <Route
          path="/segment/:subcategorySlug/video/:videoSlug"
          element={
            <SegmentPage
              setVideoModal={setVideoModal}
              setBioModal={setBioModal}
              setToggledDownloadModal={setToggledDownloadModal}
              setAlert={setAlert}
              searchParams={search}
              setToggledSubscribeModal={setToggledSubscribeModal}
            />
          }
        /> */}
            {/* <Route
          path="/collection/:slug"
          element={
            <Collection
              searchParams={search}
              setVideoModal={setVideoModal}
              setBioModal={setBioModal}
              setToggledSubscribeModal={setToggledSubscribeModal}
              setToggledDownloadModal={setToggledDownloadModal}
            />
          }
        />
        <Route
          path="/collections"
          element={
            <CollectionLanding
              searchParams={search}
              setVideoModal={setVideoModal}
              setBioModal={setBioModal}
              setToggledSubscribeModal={setToggledSubscribeModal}
              setToggledDownloadModal={setToggledDownloadModal}
            />
          }
        /> */}

            <Route
              path="/conversation/:slug"
              element={
                <InteractiveLanding
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setBioModal={setBioModal}
                  setToggledDownloadModal={setToggledDownloadModal}
                  setAlert={setAlert}
                />
              }
            />

            <Route
              path="/browse-content"
              element={
                <SimpleHeaderLayout
                  type="Browse"
                  searchParams={search}
                  title="Browse Content"
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  subtitle={
                    <div>
                      Discover hours of developmental content and thousands of
                      interactions inside the Versus content library.
                    </div>
                  }
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/learn"
              element={
                <ArticleLanding
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/learn/category/:slug"
              element={
                <ArticleLanding
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/learn/:slug"
              element={
                <ArticlePage
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                  setToggledLoginType={setToggledLoginType}
                  setContactModal={setContactModal}
                  currentUser={user}
                />
              }
            />
            <Route
              path="/sport/:slug"
              exact
              element={
                <SimpleHeaderLayout
                  type="Sport"
                  searchParams={search}
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/courses"
              exact
              element={
                <SimpleHeaderLayout
                  type="Courses"
                  searchParams={search}
                  title="Courses"
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  subtitle={
                    <div>
                      Discover hours of developmental content and thousands of
                      interactions inside the Versus content library.
                    </div>
                  }
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/conversations"
              element={
                <SimpleHeaderLayout
                  type="Conversation"
                  searchParams={search}
                  title="Conversations"
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  subtitle={
                    <div>
                      Discover hours of developmental content and thousands of
                      interactions inside the Versus content library.
                    </div>
                  }
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/logout" element={<Logout searchParams={search} />} />

            <Route path="/portal" element={<PortalPage />} />
            <Route
              path="/partners-teamsnap"
              element={<Teamsnap searchParams={search} />}
            />
            <Route
              path="/settings"
              exact
              element={
                <SettingsPage
                  setContactModal={setContactModal}
                  setIsLoading={setIsLoading}
                  currentUser={user}
                />
              }
            />
            <Route path="/logout" exact element={<Logout />} />
            <Route path="/upgrade" exact element={<Survey />} />
            <Route
              path="/partner/:slug"
              exact
              element={
                <LandingPartner
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setToggledLoginType={setToggledLoginType}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/privacy"
              element={<Privacy searchParams={search} />}
            />
            <Route path="/terms" element={<Terms searchParams={search} />} />
            <Route
              path="/partners-teamsnap"
              element={<Teamsnap searchParams={search} />}
            />

            {/* <Route
              path="/home"
              exact
              element={<HomePage currentUser={user} navigate={navigate} />}
            />
            <Route
              path="/"
              exact
              element={<HomePage currentUser={user} navigate={navigate} />}
            /> */}

            <Route
              path="/"
              exact
              element={
                <LandingHome
                  currentUser={user}
                  navigate={navigate}
                  setContactModal={setContactModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                />
              }
            />

            <Route
              path="/home"
              exact
              element={
                <LandingHome
                  currentUser={user}
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setAlert={setAlert}
                  navigate={navigate}
                  setToggledDownloadModal={setToggledDownloadModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setContactModal={setContactModal}
                  isNative={isNative}
                />
              }
            />

            {/* <Route path="/" element={<Navigate to="/home" replace />} /> */}
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
        </>
      )}
      {!user && (
        <>
          {toggledLoginType === 'signin' && (
            <LoginModal
              setToggledLoginType={setToggledLoginType}
              setIsFixed={setIsFixed}
            />
          )}
          {toggledLoginType === 'signup' && (
            <LoginModal
              setToggledLoginType={setToggledLoginType}
              isSignup={true}
              setIsFixed={setIsFixed}
            />
          )}

          <Routes>
            <Route
              path="/contact/"
              exact
              element={
                <SimpleHeaderLayout
                  type="ContactPage"
                  searchParams={search}
                  title="Contact Us"
                  subtitle={<div>Contact and support.</div>}
                />
              }
            />
            <Route
              path="/pricing"
              exact
              element={
                <SimpleHeaderLayout
                  type="Pricing"
                  searchParams={search}
                  title="Choose your plan"
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  subtitle={
                    <>
                      <div>
                        Get Versus <b>FREE</b>, when you sign up today! No
                        Credit Card required.
                      </div>
                      {/* <div>
                      All plans include a <b>3-day free trial</b>, plus 1-year
                      of unlimited access to Versus.
                    </div> */}
                    </>
                  }
                />
              }
            />
            <Route
              path="/roster"
              exact
              element={
                <SimpleHeaderLayout
                  type="Roster"
                  searchParams={search}
                  title="Roster"
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  subtitle={
                    <div>
                      Discover hours of developmental content and thousands of
                      interactions inside the Versus content library.
                    </div>
                  }
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                />
              }
            />
            {mavenData.map((m, index) => (
              <>
                <Route
                  key={`${m.slug}-${index}-1`}
                  path={`/roster/${m.slug}`}
                  element={<Navigate replace to={`/course/${m.slug}`} />}
                />
                <Route
                  key={`${m.slug}-${index}-2`}
                  path={`/${m.slug}`}
                  element={<Navigate replace to={`/course/${m.slug}`} />}
                />
                <Route
                  key={`${m.slug}-${index}-3`}
                  path={`/${m.slug}`}
                  element={
                    <CourseLanding
                      slug={m.slug}
                      searchParams={search}
                      setVideoModal={setVideoModal}
                      setBioModal={setBioModal}
                      setToggledDownloadModal={setToggledDownloadModal}
                      setToggledSubscribeModal={setToggledSubscribeModal}
                      setAlert={setAlert}
                      currentUser={user}
                    />
                  }
                />
              </>
            ))}

            <Route
              path="/course/:mavenSlug/video/:videoSlug"
              element={
                <Course
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setBioModal={setBioModal}
                  setToggledDownloadModal={setToggledDownloadModal}
                  setAlert={setAlert}
                  searchParams={search}
                />
              }
            />
            {/* <Route
          path="/segment/:subcategorySlug/video/:videoSlug"
          element={
            <SegmentPage
              setVideoModal={setVideoModal}
              setBioModal={setBioModal}
              setToggledDownloadModal={setToggledDownloadModal}
              setAlert={setAlert}
              searchParams={search}
              setToggledSubscribeModal={setToggledSubscribeModal}
            />
          }
        />
*/}
            <Route
              path="/roster/:slug"
              element={
                <Navigate
                  replace
                  to={`/course/${location?.pathname.split('/roster/')[1]}`}
                />
              }
            />
            <Route
              path="/courses"
              exact
              element={
                <SimpleHeaderLayout
                  type="Courses"
                  searchParams={search}
                  title="Courses"
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  subtitle={
                    <div>
                      Discover hours of developmental content and thousands of
                      interactions inside the Versus content library.
                    </div>
                  }
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/conversations"
              element={
                <SimpleHeaderLayout
                  type="Conversation"
                  searchParams={search}
                  title="Conversations"
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  subtitle={
                    <div>
                      Discover hours of developmental content and thousands of
                      interactions inside the Versus content library.
                    </div>
                  }
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/interactive/:slug"
              element={
                <InteractivePage
                  searchParams={search}
                  setToggledDownloadModal={setToggledDownloadModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  currentUser={user}
                />
              }
            />
            <Route
              path="/course/:slug"
              element={
                <CourseLanding
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setBioModal={setBioModal}
                  setToggledDownloadModal={setToggledDownloadModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                  currentUser={user}
                />
              }
            />
            <Route
              path="/conversation/:slug"
              element={
                <InteractiveLanding
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setBioModal={setBioModal}
                  setToggledDownloadModal={setToggledDownloadModal}
                  setAlert={setAlert}
                />
              }
            />
            {/* <Route
          path="/collection/:slug"
          element={
            <Collection
              searchParams={search}
              setVideoModal={setVideoModal}
              setBioModal={setBioModal}
              setToggledSubscribeModal={setToggledSubscribeModal}
              setToggledDownloadModal={setToggledDownloadModal}
            />
          }
        />
        <Route
          path="/collections"
          element={
            <CollectionLanding
              searchParams={search}
              setVideoModal={setVideoModal}
              setBioModal={setBioModal}
              setToggledSubscribeModal={setToggledSubscribeModal}
              setToggledDownloadModal={setToggledDownloadModal}
            />
          }
        /> */}

            <Route
              path="/browse-content"
              element={
                <SimpleHeaderLayout
                  type="Browse"
                  searchParams={search}
                  title="Browse Content"
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  subtitle={
                    <div>
                      Discover hours of developmental content and thousands of
                      interactions inside the Versus content library.
                    </div>
                  }
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/learn"
              element={
                <ArticleLanding
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/learn/category/:slug"
              element={
                <ArticleLanding
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/learn/:slug"
              element={
                <ArticlePage
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                  setToggledLoginType={setToggledLoginType}
                  setContactModal={setContactModal}
                />
              }
            />
            <Route
              path="/sport/:slug"
              exact
              element={
                <SimpleHeaderLayout
                  type="Sport"
                  searchParams={search}
                  setContactModal={setContactModal}
                  currentUser={user}
                  setToggledLoginType={setToggledLoginType}
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setAlert={setAlert}
                />
              }
            />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/login"
              element={
                <LoginPage searchParams={search} setIsFixed={setIsFixed} />
              }
            />
            <Route
              path="/signup"
              element={
                <LoginPage
                  searchParams={search}
                  isSignup={true}
                  setIsFixed={setIsFixed}
                />
              }
            />
            <Route
              path="/logout"
              element={<Logout searchParams={search} setIsFixed={setIsFixed} />}
            />
            <Route
              path="/privacy"
              element={<Privacy searchParams={search} />}
            />
            <Route path="/terms" element={<Terms searchParams={search} />} />
            <Route
              path="/partners-teamsnap"
              element={<Teamsnap searchParams={search} />}
            />
            <Route
              path="/pass"
              element={
                <LoginPage searchParams={search} setIsFixed={setIsFixed} />
              }
            />
            <Route
              path="/partner/:slug"
              exact
              element={
                <LandingPartner
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setToggledLoginType={setToggledLoginType}
                  setAlert={setAlert}
                />
              }
            />
            <Route
              path="/"
              exact
              element={
                <LandingPage
                  searchParams={search}
                  setVideoModal={setVideoModal}
                  setToggledSubscribeModal={setToggledSubscribeModal}
                  setToggledLoginType={setToggledLoginType}
                  setAlert={setAlert}
                />
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </>
      )}
    </>
  );

  useEffect(() => {
    if (isNative) return;
    if (isMobile) {
      const lsBanner = localStorage.getItem('showBannerLS');
      if (!lsBanner) {
        setShowBanner(true);
      }
      if (lsBanner === 'false') {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
    } else {
      return setShowBanner(false);
    }
  }, []);

  useEffect(() => {
    if (isNative) return;
    if (pathname === '/home' || pathname === '/') {
      if (user) {
        const downloadModal = localStorage.getItem('downloadModal');
        if (downloadModal === null) {
          setShowDownloadModal(true);
          return;
        }
        if (downloadModal === 'seen') {
          setShowDownloadModal(false);
          return;
        }
      } else {
        setShowDownloadModal(false);
      }
    }
  }, [user, pathname]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <HelmetProvider>
      <div
        className={showBanner ? 'with-banner main-container' : 'main-container'}
        data-theme={theme}
      >
        <div className="download-cta">
          <img
            className="close"
            onClick={() => handleLSBanner()}
            src="/icons/close.svg"
            alt="Close Banner"
          />
          <img src="/VS-Banner.png" alt="Download from Appstore" />
          <Button className="view" external="https://onelink.to/gskzwd">
            View
          </Button>
        </div>

        <div
          id="App"
          className={`min-h-screen min-w-screen ${isFixed ? 'fixed' : ''}`}
        >
          {user ? (
            <Navbar
              currentUser={user}
              isNative={isNative}
              pathname={pathname}
              handleSwitchTheme={handleSwitchTheme}
              theme={theme}
            />
          ) : (
            <NavbarUnAuthed
              isNative={isNative}
              pathname={pathname}
              setToggledLoginType={setToggledLoginType}
              searchParams={search}
              handleSwitchTheme={handleSwitchTheme}
              theme={theme}
            />
          )}
          {allRoutes}
          {!isNative &&
            !pathname.includes('/interactive/') &&
            !pathname.includes('/partner/') && <Footer searchParams={search} />}
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
