import React from 'react';
import LoginPage from 'src/pages/LoginPage/LoginPage';
import '../Modals.scss';
import './LoginModal.scss';

function LoginModal({ setToggledLoginType, isSignup, setIsFixed }) {
  return (
    <div className="login-modal modals">
      <div className="content">
        <LoginPage
          loginModal={true}
          setToggledLoginType={setToggledLoginType}
          isSignup={isSignup}
          setIsFixed={setIsFixed}
        />
      </div>
    </div>
  );
}

export default LoginModal;
