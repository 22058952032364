import React, { useState, useEffect, createContext, useContext } from 'react';
import { db } from '../config/firebase';
import { query, collection, where, onSnapshot } from 'firebase/firestore';
import { AuthContext } from './AuthContext';
import { REVENUE_CAT_API_KEY, REVENUECAT_API_URL } from '@config/index';
import moment, { Moment } from 'moment';
export interface SubscriptionContextI {
  planName: string | null;
  price: number;
  period: string | null;
  expiredAt: Moment | undefined;
  expiredAtFormated: string | undefined;

  productId: string | undefined;
  isLoading: boolean;
}

export const SubscriptionContext = createContext<SubscriptionContextI>(
  {} as SubscriptionContextI
);

export function useSubscription() {
  return useContext(SubscriptionContext);
}
export const SubscriptionProvider = ({ children }: any) => {
  // User
  const [planName, setPlanName] = useState<string | null>('Free');
  const [price, setPrice] = useState<number>(0);
  const [period, setPeriod] = useState<string>('Annual');
  const [expiredAt, setExpiredAt] = useState<Moment>();
  const [expiredAtFormated, setExpiredAtFormated] = useState<string>();
  const [productId, setProductId] = useState<string>();
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(AuthContext);

  // Listen for user changes
  const getSubscription = () => {
    if (!user) return;
    //Check revenuecat for entitlements/subscription
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Platform': 'stripe',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + REVENUE_CAT_API_KEY,
      },
    };

    fetch(REVENUECAT_API_URL + '/subscribers/' + user?.uid, options)
      .then((response) => response.json())
      .then((response) => {
        //console.log('revenuecat re2s', response);
        //TODO: Paul Daniel, use revenuecat as source of truth, revenuecat returns an empty entitlement object.
        setPlanName(
          Object.keys(response?.subscriber?.entitlements)[0] || 'Free'
        );
      })
      .catch((err) => console.error(err));

    const q = query(
      collection(db, `customers/${user?.uid}/subscriptions`),
      where('status', 'in', ['trialing', 'active'])
    );
    onSnapshot(q, (snapshot) => {
      if (snapshot.docs.length) {
        const subscriptionDoc = snapshot.docs[0].data();
        // console.log("subscriptionDoc", subscriptionDoc);
        const date = moment(
          new Date(1970, 0, 1).setSeconds(
            subscriptionDoc.current_period_end.seconds
          )
        );
        setExpiredAt(date);
        setExpiredAtFormated(date.format('MMMM, Do YYYY'));
        setPrice(subscriptionDoc.items[0].plan.amount / 100);
        setPeriod(
          subscriptionDoc.items[0].plan.interval === 'year'
            ? 'Annual'
            : 'Monthly'
        );
        setProductId(subscriptionDoc.product.id);
        /*  onSnapshot(subscriptionDoc.product, (productSnapshot: any) => {
          const productDoc = productSnapshot.data();

          //using revenuecat as source of truth
          //setPlanName(productDoc.name);
        }); */
      }
    });
  };

  const values: SubscriptionContextI = {
    isLoading,
    planName,
    price,
    period,
    expiredAtFormated,
    expiredAt,
    productId,
  };
  useEffect(() => {
    getSubscription();
  }, [user]);

  return (
    <SubscriptionContext.Provider value={values}>
      {children}
    </SubscriptionContext.Provider>
  );
};
