import React, { ReactNode } from 'react';
import { ConfirmationModalOverlay } from './ConfirmationModal.style';

const ConfirmationModal = ({ modalText, buttonText = 'Done', onClose }) => {
  return (
    <ConfirmationModalOverlay>
      <div className="confirmModal">
        {modalText}
        <button
          title="Confirmation Modal"
          description={modalText}
          action="Cancel"
          onClick={onClose}
        >
          {buttonText}
        </button>
      </div>
    </ConfirmationModalOverlay>
  );
};

export default ConfirmationModal;
