import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({
  title,
  description,
  name,
  type,
  keywords,
  image,
  ogTitle,
}) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta content={description} name="description" data-react-helmet="true" />
      {keywords && <meta name="keywords" content={keywords} />}
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:image"
        content={image || `${window.location.origin}/VS_Website.jpg`}
      />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
    </Helmet>
  );
}
