import Button from '@components/Button/Button';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

function ConversationsBanner({
  athlete,
  topicsCount,
  responsesCount,
  image,
  setBioModal,
  slug,
  bio,
  bioImage,
  restingVideo,
  restingVideoLandscape,
  restingImageLandscape,
}) {
  const handleShowBio = () => {
    const bioForModal = {
      body: bio,
      title: athlete,
      image: bioImage,
    };

    setBioModal(bioForModal);
  };

  return (
    <div className="conversations-banner">
      <div className="overlay" />
      <div className="left">
        <div className="badge">
          <img src="/icons/AI.svg" alt="Conversation" /> Conversation
        </div>
        <h3>Conversations with {athlete?.split(' ')[0]}</h3>
        <div className="meta">
          <div>
            <h4>{topicsCount}</h4>
            <p className="faded">Topics</p>
          </div>
          <div>
            <h4>{responsesCount}</h4>
            <p className="faded">Interactions</p>
          </div>
        </div>
        <div className="actions">
          <Link className="primary button" to={`/interactive/${slug}`}>
            <img src="/icons/microphone.svg" alt="Watch Video" />
            Ask Now
          </Link>
          <Button
            height="52px"
            className="secondary"
            onClick={() => handleShowBio()}
          >
            <img src="/icons/Info.svg" alt="Watch Video" />
            About {athlete.split(' ')[0]}
          </Button>
        </div>
      </div>

      <div className="right">
        {/* <div className="overlay" /> */}
        <video
          playsInline
          autoPlay
          muted
          loop
          preload="yes"
          width="100%"
          height="auto"
          poster={isMobile ? image : '/Placeholder.jpg'}
          src={restingVideoLandscape}
        />
      </div>
    </div>
  );
}

export default ConversationsBanner;
