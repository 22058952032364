import { useEffect, useState } from 'react';
import { articlesList } from '../../data/articles';
import { useNavigate, Link } from 'react-router-dom';

const uniqueTopics = [
  'all',
  ...new Set([...articlesList.map((a) => a.topic)]),
].filter((aa) => aa.length > 0);

function LatestPublications({
  title,
  length,
  showFilters,
  selectedFilter,
  searchParams,
}) {
  const navigate = useNavigate();
  const [articles, setArticles] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(
    selectedFilter || uniqueTopics[0]
  );

  useEffect(() => {
    const reversedOrder = [...articlesList].reverse();
    const filteredArticles = length
      ? reversedOrder.slice(0, length)
      : reversedOrder;

    let preparedFilteredArticles;

    if (selectedTopic === 'all') {
      preparedFilteredArticles = filteredArticles;
    } else {
      preparedFilteredArticles = filteredArticles.filter(
        (f) => f.topic === selectedTopic
      );
    }

    setArticles(
      preparedFilteredArticles
        .map((r) => ({
          ...r,
          'Published On': new Date(r['Published On']).toLocaleDateString(
            'en-us',
            { year: 'numeric', month: 'short', day: 'numeric' }
          ),
        }))
        .sort(
          (a, b) => new Date(b['Published On']) - new Date(a['Published On'])
        )
    );
    handleScrollTo(selectedFilter);
  }, [selectedFilter]);

  const handleScrollTo = (u) => {
    const el = document.querySelector(`#${u}`);
    if (el) el.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  return (
    <div className="latest-publications">
      {showFilters && (
        <div className="filters">
          {uniqueTopics.map((u) => (
            <div
              onClick={() => {
                setSelectedTopic(u);
                handleScrollTo(u);
                navigate(`/learn/category/${u}${searchParams}`);
              }}
              className={u === selectedTopic ? 'active' : ''}
              id={u}
            >
              {u}
            </div>
          ))}
        </div>
      )}
      <div className="publication-list">
        <h4>
          {title}{' '}
          {selectedFilter &&
          selectedFilter !== 'all' &&
          selectedFilter !== 'general'
            ? `on ${selectedFilter}`
            : ''}
        </h4>
        <div className="collection">
          {articles &&
            articles.map((d) => (
              <div
                key={d.id}
                className="card header"
                style={{ backgroundImage: `url(${d['Thumbnail image']})` }}
              >
                <div className="label">{d.topic}</div>
                <div className="body">
                  <h5>{d.Name}</h5>
                  <div className="faded">{d['Post Summary']}</div>
                  <div className="footer">{d['Published On']}</div>
                  <Link
                    to={`/learn/${d.Slug}${searchParams}`}
                    className="button primary"
                  >
                    Read Article
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default LatestPublications;
