export const generalFaqs = [
  {
    title: 'What is Versus?',
    body: 'Versus is a digital educational platform that helps aspiring leaders and athletes pursue competitive excellence and achieve their full potential.. Our members can enjoy exclusive content from top leaders, athletes, and coaches, but they can also ask our experts thousands of questions about their journey in life and their career. To keep learning, all you have to do is keep working hard and keep asking.',
  },
  {
    title: 'What is Conversational AI? ',
    body: 'Conversational AI is a technology that combines natural language processing with traditional software, like chatbots or voice assistants. This technology is what allows our members to interact with VS experts through spoken or typed dialogue.',
  },
  {
    title: 'How does Conversational AI work?',
    body: 'Conversational AI technology uses natural language processing as well as other complementary tools to understand the context of a conversation. The more VS members ask questions to our experts, the better they can understand patterns and provide you with the best answers possible. Conversational AI engages VS members through 2 main ways: (1) Understanding our members needs, and (2) Offering help and support through expert advice',
  },
  {
    title: 'Who is Versus for?',
    body: 'Versus is a community for learners. Anyone who wants to acquire knowledge and learn from the challenges and perseverance from top leaders, athletes, and coaches is a prime Versus member candidate. Additionally, Versus is a tool that offers parents expert advice about training, skill development, mindset, practice plans, college recruiting, emotionally competency, and much more.',
  },
  {
    title: 'What types of content are available to me?',
    body: 'Versus has free content in our Expert Advice section that includes articles around leadership, mental game/training, practice plans, drills, teamwork, culture, and more. For paid members, you get access to every available course offered by our experts, plus any new content released within your subscription period. Additionally, paid members get unlimited access to Conversations, providing them the opportunity to ask thousands of questions across hundreds of topics to our experts.',
  },
  {
    title: 'How do I use the Conversation feature?',
    body: 'Versus Conversations are interactive and simple. Before you interact with one of our experts, you must enable microphone access on your device if you would like to use the ‘Talk’ feature. If not, you can type your question or select a question inside the topics menu.  Getting started: When first attempting to interact, your device will prompt you to allow access to your microphone. If you choose to not allow access, you will not be able to use the ‘Talk’ feature. You can change these settings in your Privacy & security settings on your phone. How to interact: You can toggle between 3 features to ask a question and receive a response from our experts. 1. Talk (press and hold to talk). 2. Type (type your questions and press enter to submit). 3. Topics (explore the topics library, select the desired topic, explore questions within that topic, click on a question to hear a response)',
  },
];
export const pricingAndMembershipFaqs = [
  {
    title: 'What is included in a Versus membership?',
    body: "When you become a member of Versus, you’ll receive a 3-day free trial to get started. If you decide to continue with your membership, you'll instantly get access to hours of exclusive video content from our experts, plus unlimited access to Conversations, providing you the opportunity to ask thousands of questions across hundreds of topics to our experts. All new content released within your subscription period will be immediately available for your viewing upon release. Be sure to sign up for our newsletter to stay up-to-date on new content releases. Want to be a part of building the future of Versus? As a paid member, you get exclusive sneak peaks at new experts coming to the Versus platform, plus the unique opportunity to work with the Versus team to provide your input on what you want to hear from our new experts.",
  },
  {
    title: 'How much does Versus cost?',
    body: 'A membership to Versus is valued at $199, but as an early adopter, you can get it for $129/annually.',
  },
  {
    title: 'Are taxes included in the cost?',
    body: 'Depending on where you live, you may be charged taxes in addition to your annual membership price. If tax applies, you will see the tax amount upon checkout, as well as stated separately on your emailed invoice. Tax rates are calculated based on your location or the billing information you provided at the time of purchase. Should you have any additional questions about the taxes you were charged, please  don’t hesitate to contact us by visiting versus.co/contact, emailing us at support@versus.co, or calling us at +1 (800) 360-0273.',
  },
  {
    title: 'When will my payment method be charged?',
    body: 'A Versus membership currently runs on an annual schedule and your plan will automatically renew exactly one year from when you originally subscribed, unless you cancel your subscription. But don’t worry! We’ll be sure to let you know that your subscription will renew a before you get charged.',
  },
  {
    title: 'How do I cancel my subscription?',
    body: "If you subscribed to Versus through the web, you’ll want to login to your account on versus.co. Once logged in, navigate to the profile icon in the top right corner of the main navigation bar. Select “Manage Account”, scroll down to “Subscription”, then select the button for “Cancel Subscription”. If you subscribed through Apple’s in-app-purchases, follow the instructions on Apple's website: https://support.apple.com/en-us/HT202039. If you’d like, please provide us feedback as to why you’re canceling your subscription. We’d like to know how we could’ve been a better resource for you.",
  },
];
