import { useRef, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useSubscription } from 'src/context/SubscriptionContext';
import { UserCircleIcon, XIcon, MenuIcon } from '@heroicons/react/outline';
import Button from '../Button/Button';
import Logo from '../../assets/images/logo.svg';
import { auth } from '../../config/firebase';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import AvatarImg from '../../assets/images/avatar.png';
import './Navbar.scss';
import NavbarDrawer from './NavbarDrawer';
import { isDesktop, isMobile } from 'react-device-detect';

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

const Navbar = ({
  currentUser,
  isNative,
  pathname,
  handleSwitchTheme,
  theme,
}) => {
  const subscription = useSubscription();
  const { search } = useLocation();

  const navigate = useNavigate();

  const navbarLinks = [
    {
      name: 'Home',
      href: `/home${search}`,
      mobileOnly: true,
    },
    {
      name: 'Roster',
      href: `/roster${search}`,
    },
    {
      name: 'Browse Content',
      icon: '/icons/browse-content-icon.svg',
      href: `/browse-content${search}`,
    },
    {
      name: 'Courses',
      href: `/courses${search}`,
      mobileOnly: true,
    },
    {
      name: 'Conversations',
      href: `/conversations${search}`,
      mobileOnly: true,
    },
    {
      name: 'Expert Advice',
      href: `/learn${search}`,
      mobileOnly: true,
    },
    {
      name: 'Contact',
      href: `/contact${search}`,
      type: 'small',
    },
    {
      name: 'Terms and Conditions',
      href: `/terms${search}`,
      mobileOnly: true,
      type: 'small',
    },
    {
      name: 'Privacy Policy',
      href: `/privacy${search}`,
      mobileOnly: true,
      type: 'small',
    },
  ];

  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [toggledDropdown, setToggledDropdown] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpened((prevOpened) => !prevOpened);
    if (isMobileMenuOpened) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  const handleLogout = () => {
    auth.signOut();
    navigate(`/${search}`);
  };

  const dropdownRef = useRef();
  useOutsideAlerter(dropdownRef, (isOutSide) => {
    if (isOutSide) {
      setToggledDropdown(false);
    }
  });

  return (
    <nav>
      <div className="navMenuWrapper">
        <div className="navbarLeftMenu">
          {isNative && (pathname !== '/home' || pathname === '/') ? (
            <Button
              className="logo dflex"
              to={`/${search}`}
              onClick={() => {
                setToggledDropdown(false);
                navigate(-1);
              }}
            >
              <img src="/icons/chevron-down.svg" alt="Back" className="back" />
              <img src={Logo} className="logo" alt="VS" />
            </Button>
          ) : (
            <Link
              className="logo"
              to={`/${search}`}
              onClick={() => setToggledDropdown(false)}
            >
              <img src={Logo} alt="VS" />
            </Link>
          )}

          {navbarLinks.map((item, index) => {
            if (!item.mobileOnly) {
              return (
                <Link
                  to={`${item.href}${search}`}
                  key={index}
                  className="navLink"
                >
                  {item.name}{' '}
                  {item.icon && (
                    <img src={item.icon} alt="icon" className="icon" />
                  )}
                </Link>
              );
            }
          })}
          {/* {subscription.planName !== 'Premium' && (
            <Link to={`/pricing/${search}`} className="navLink">
              Pricing
            </Link>
          )} */}
          {isMobile && (
            <div className="scroll" onClick={() => scrollToTop()}></div>
          )}
        </div>

        <div className="navbarRightMenu">
          {/* {subscription?.planName !== 'Premium' && (
            <div className='memberStatusWrapper'>
              <Link
                onClick={() => setToggledDropdown(false)}
                to={`/pricing${search}`}
              >
                <Button
                  className='primary rounded'
                  title='Navbar'
                  action='Upgrade'
                >
                  Upgrade
                </Button>
              </Link>
            </div>
          )} */}
          {pathname.includes('/interactive/') && (
            <div className="button secondary rounded">Beta</div>
          )}
          {isDesktop && (
            <div
              onClick={() => {
                setToggledDropdown(!toggledDropdown);
                setIsMobileMenuOpened(false);
              }}
              className="userIconWrapper"
              ref={dropdownRef}
            >
              <UserCircleIcon />
              {toggledDropdown && (
                <div className="userMenuDropdown">
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="userMenuRow userMenuHeading"
                  >
                    <img src={AvatarImg} alt="avatar" />

                    {currentUser?.displayName ? (
                      <h4>
                        {currentUser.displayName?.length > 15
                          ? currentUser.displayName?.slice(0, 15) + '...'
                          : currentUser.displayName}
                      </h4>
                    ) : (
                      <h4>Versus Customer</h4>
                    )}
                  </div>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="userMenuRow userMenuBody"
                  >
                    {/* <Link
                      onClick={() => setToggledDropdown(false)}
                      to={`/pricing${search}`}
                    >
                      Manage Subscription
                    </Link> */}
                    <Link
                      onClick={() => setToggledDropdown(false)}
                      to={`/settings${search}`}
                    >
                      Account Settings
                    </Link>
                    {/* <Button
                      title={`Switch to ${
                        theme === 'dark' ? 'Light' : 'Dark'
                      } Theme`}
                      action="Button/Link Clicked"
                      className="link"
                      onClick={() => handleSwitchTheme()}
                    >
                      {theme === 'dark'
                        ? 'Switch to Light Theme'
                        : 'Switch to Dark Theme'}
                    </Button> */}
                  </div>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="userMenuRow userMenuFooter"
                  >
                    <Button
                      onClick={handleLogout}
                      title="Navbar"
                      action="Sign Out"
                    >
                      Sign out
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}

          <img
            src={'/icons/burger-menu-right.svg'}
            alt="menu"
            className="openMenuIconBurger openMenuIcon"
            onClick={() => {
              toggleMobileMenu();
              setToggledDropdown(false);
            }}
          />
          {/* <MenuIcon
            onClick={() => {
              toggleMobileMenu();
              setToggledDropdown(false);
            }}
            className="openMenuIcon"
          /> */}
        </div>
      </div>

      {/* Mobile Layout */}
      <NavbarDrawer
        isMobileMenuOpened={isMobileMenuOpened}
        toggleMobileMenu={toggleMobileMenu}
        search={search}
        currentUser={currentUser}
      />
    </nav>
  );
};

export default Navbar;
