import Button from '@components/Button/Button';
import { useSubscription } from 'src/context/SubscriptionContext';
import { goToPayment } from 'src/features/Stripe/stripeHelpers';
import Logo from '../../../assets/images/logo.svg';
import './SubscribeModal.scss';

function SubscribeModal({
  setToggledSubscribeModal,
  setToggledLoginType,
  searchParamsObj,
  currentUser,
  navigate,
  setIsLoading,
}) {
  const subscription = useSubscription();
  const handleSignUpRoute = () => {
    setToggledSubscribeModal(false);
    navigate('/pass/?id=vsunlimited');
    //setToggledLoginType('signup');
  };

  return (
    <div className="modals subscribe">
      <img
        className="close"
        src="/icons/close.svg"
        alt="Close Modal"
        onClick={() => setToggledSubscribeModal(false)}
      />
      <div className="content">
        <img src={Logo} className="logo" alt="VS" />
        {/* <img src="/icons/lock.svg" alt="Locked Content" /> */}
        <h3>Limited time offering.</h3>
        <h1>
          Get FREE access to Versus.<br></br>No Credit Card needed!
        </h1>
        {/* <p>
          A Versus membership get’s you unlimited access to a variety of soft
          and technical skill based video content, plus a library of over 4,700
          real-time interactions with top athletes and coaches.
        </p> */}
        <p>
          Create your free account and unlock hours of content and thousands of
          interactions with world class athletes and coaches.
        </p>

        {subscription?.planName !== 'Premium' && currentUser ? (
          <Button
            className="primary button"
            onClick={() =>
              goToPayment(searchParamsObj, currentUser, navigate, setIsLoading)
            }
            title="Subscribe Modal"
            action="Upgrade"
          >
            Upgrade Now
          </Button>
        ) : (
          <Button
            className="primary button"
            onClick={() => handleSignUpRoute()}
            title="Subscribe Modal"
            action="Sign Up"
          >
            Get FREE Access
          </Button>
        )}
      </div>
    </div>
  );
}

export default SubscribeModal;
