import { useEffect, useState } from 'react';
import { tabs } from './TabbedNavData';
import './TabbedNav.scss';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import NavbarDrawer from '@components/Navbar/NavbarDrawer';

function TabbedNav({ pathname, search }) {
  const navigate = useNavigate();
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpened((prevOpened) => !prevOpened);
  };

  const handleOnClick = (t) => {
    if (t.label === 'Menu') {
      setIsMobileMenuOpened(true);
    } else {
      navigate(t.pathname);
    }
  };
  return (
    <>
      {isMobileMenuOpened && (
        <nav>
          <NavbarDrawer
            isMobileMenuOpened={isMobileMenuOpened}
            toggleMobileMenu={toggleMobileMenu}
            search={search}
          />
        </nav>
      )}
      <div className="tabbed-nav">
        {tabs.map((t) => (
          <div
            onClick={() => handleOnClick(t)}
            className={`tab ${pathname === t.pathname ? 'active' : ''} ${
              t.label
            }`}
          >
            <img src={t.icon} alt={t.label} />
            {t.label}
          </div>
        ))}
      </div>
    </>
  );
}

export default TabbedNav;
