import React from 'react';
import Logo from '../../assets/images/logo.svg';
import './Loader.scss';

interface Props {
  message?: string;
  naked?: boolean;
}

const Loader = (props: Props) => {
  const { message = '', naked = false } = props;

  return (
    <div className={`loader-container ${naked ? 'naked' : ''}`}>
      {!naked && (
        <div className="loaderMessage">
          <img src={Logo} alt="Logo" />
          <p>{message ? message : 'Please wait, excellence takes time.'}</p>
        </div>
      )}
      <div className="loader"></div>
      {/* <div className="loader">
        <div className="loaderOuter" />
        <div className="loaderInner" />
      </div> */}
    </div>
  );
};

export default Loader;
