const features = [
  {
    label: 'Forbes',
    image: '/landing/Forbes-logo.svg',
    link: 'https://www.forbes.com/sites/johnperrotto/2022/08/16/st-louis-cardinals-manager-oliver-marmol-launches-instructional-app-for-good-causes/?sh=37c4b0e567ef',
  },
  {
    label: 'LA Times',
    image: '/landing/LA-Times-logo.svg',
    link: 'https://www.latimes.com/sports/newsletter/2022-10-25/galaxy-lafc-cristiano-ronaldo-soccer',
  },
  {
    label: 'Inc. Magazine',
    image: '/landing/Inc-logo.svg',
    link: 'https://www.inc.com/jeff-haden/side-hustle-business-st-louis-cardinals.html',
  },
  {
    label: 'USA Today',
    image: '/landing/USA-Today-logo.svg',
    link: 'https://www.usatoday.com/story/sports/mlb/columnist/bob-nightengale/2022/09/25/mlb-pl[…]e-trade-deadline-disappointments-shine/8109823001/?gnt-cfr=1',
  },
  {
    label: 'Entrepreneur',
    image: '/landing/Entrepreneur-logo.svg',
    link: 'https://www.entrepreneur.com/living/inside-st-louis-cardinals-manager-oliver-marmol-and-amber/431024',
  },
  {
    label: 'Fast Company',
    image: '/landing/FC_Logo_2018_amgwhf.png',
    link: 'https://www.fastcompany.com/90849121/most-innovative-companies-sports-2023',
  },
];

function FeaturedOn() {
  return (
    <div className="featured-on hero">
      <h1>Featured On</h1>
      <div className="features">
        {features.map((f) => (
          <a key={f.label} href={f.link} target="_blank" rel="noreferrer">
            <img src={f.image} alt={f.label} />
          </a>
        ))}
      </div>
    </div>
  );
}

export default FeaturedOn;
