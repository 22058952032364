import Button from '@components/Button/Button';
import React from 'react';

function PricingBanner({ setContactModal }) {
  return (
    <div className="pricing-banner">
      <img src="/pages/PricingBannerImage.png" alt="Pricing Banner" />
      <div>
        <h3>Want to become a partner?</h3>
        <p>
          Get in touch with our Partnerships team to see if you meet the
          crieteria of our Partnership Program to enjoy exclusive partnership
          benefits.
        </p>

        <Button
          onClick={() =>
            setContactModal({
              type: 'Partnership',
              title: 'Partnership Inquiry',
            })
          }
          className="primary rounded"
        >
          Contact Us
        </Button>
      </div>
    </div>
  );
}

export default PricingBanner;
