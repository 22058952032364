import SEO from '@components/Seo/Seo';
import useHasMounted from '@hooks/useHasMounted';
import './Policies.scss';

function Privacy() {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className='policies'>
      <SEO
        title='Privacy Policies | Versus'
        description='‍We respect your privacy and are committed to protecting it. Our Privacy Policy governs the processing of all personal data collected from you in connection with your use of the Sites.'
        name='Privacy Policies for Versus'
        type='article'
      />
      <div class='page'>
        <h1>Privacy Policy</h1>
        <div class='div-block-579'>
          <div class='tabs-content w-tab-content'>
            <div
              data-w-tab='Tab 1'
              class='tab-pane-tab-1 w-tab-pane w--tab-active'
            >
              <div class='div-block-87'>
                <p class='paragraph-28'>
                  <span class='text-span-2'>
                    <strong class='bold-text'>‍</strong>
                  </span>
                  <br />
                  <strong>Effective Date:</strong> April 14, 2022
                  <br />
                  <br />
                  This Privacy Policy describes the data protection practices of
                  Versus. This Privacy Policy applies to information that we
                  collect and use about you when you access or use the Versus
                  website, mobile application, or other online or mobile sites
                  (“Sites”) that link to Versus services (“Services”).
                  <br />
                  <br />
                  <strong>
                    PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW
                    WE HANDLE YOUR INFORMATION. IF YOU DO NOT AGREE TO THIS
                    PRIVACY POLICY, DO NOT USE THE SERVICES.
                    <br />
                    <br />
                    Information We Collect <br />
                    How We Use Personal Information
                    <br />
                    How We Share and Disclose Personal Information
                    <br />
                    Your Rights and Choices
                    <br />
                    Non-Discrimination
                    <br />
                    California Privacy Rights
                    <br />
                    Contact Information
                  </strong>
                  <br />
                  <br />‍<br />
                  <span class='text-span-3'>
                    <strong>Information We Collect</strong>
                    <br />
                  </span>
                  <br />
                  <br />
                  We collect information that identifies, relates to, describes,
                  references, is reasonably capable of being associated with, or
                  could reasonably be linked, directly or indirectly, with a
                  particular user (&quot;
                  <strong>Personal Information</strong>&quot;). In particular,
                  we may collect the following categories of Personal
                  Information: <br />‍<br />
                </p>
                <ul role='list' class='list-2'>
                  <li class='list-item-6'>Email address</li>
                  <li>Name</li>
                  <li>Telephone number</li>
                  <li class='list-item-6'>
                    Billing information, such as billing address
                  </li>
                  <li class='list-item-6'>Your purchases and orders</li>
                  <li class='list-item-6'>
                    Internet or other network activity, such as Internet
                    Protocol address, browsing history, search history, and
                    information about your interaction with the Sites.
                  </li>
                  <li class='list-item-6'>Audio information</li>
                  <li class='list-item-6'>
                    Your preferences and behavior from observing your
                    interaction with our Sites
                  </li>
                  <li class='list-item-6'>Your user name and your password.</li>
                </ul>
                <p class='paragraph-42'>
                  <br />
                  We collect the categories of Personal Information listed above
                  from the following categories of sources:
                </p>
                <ul role='list' class='list-2'>
                  <li class='list-item-6'>
                    Directly from you. For example, from Services you obtain or
                    surveys/questionnaires you complete.
                    <br />‍
                  </li>
                  <li>
                    Indirectly from you. For example, from observing your
                    actions on our Sites.
                    <br />‍
                  </li>
                  <li>
                    Third-party Automated Collection: We and our service
                    providers use anonymous identifiers such as cookies and
                    other technologies to collect and store certain types of
                    information (e.g., click stream information, browser type,
                    time and date, subject of advertisements clicked or scrolled
                    over, hardware/software information, cookie and session ID)
                    whenever you interact with us or third-parties that use our
                    services. We also allow third-party companies (e.g., Google)
                    to place tags on our digital properties once approved
                    through our tagging process. The tags may collect
                    information from your interactions on Target.com. Our
                    Privacy Policy does not cover these third-party companies.
                    Please contact these third-party companies (e.g., Google)
                    directly for more information about their privacy policy and
                    your choices regarding the tags and the information
                    collected by the tags.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    Social Media Widgets. Our Sites include social media
                    features, such as the Facebook, Pinterest, and Twitter
                    widgets. These features may collect information about you
                    such as your IP address and which page you are visiting on
                    our site. They may set a cookie or employ other tracking
                    technologies to accomplish this. Social media features and
                    widgets may be hosted by a third-party. Your interactions
                    with those features are governed by the privacy policies of
                    the companies that provide them.
                    <br />
                    <br />‍
                  </li>
                </ul>
                <p class='paragraph-42'>
                  <span class='text-span-3'>
                    <strong>
                      How We Use Personal Information
                      <br />‍<br />
                    </strong>
                  </span>
                  We may use or disclose the Personal Information we collect for
                  one or more of the following purposes:{' '}
                </p>
                <ul role='list' class='list-2'>
                  <li class='list-item-6'>
                    To fulfill or meet the reason you provided the information.
                    For example, if you share your name and contact information
                    to request a price quote or ask a question about our
                    Services, we will use that Personal Information to respond
                    to your inquiry.
                    <br />‍
                  </li>
                  <li>
                    To process your requests, purchases, transactions, and
                    payments and prevent transactional fraud. We may also save
                    your information to facilitate new orders or process
                    returns.
                    <br />‍
                  </li>
                  <li>
                    To provide you with updates and information about Services
                    in which you have enrolled, including surveys,
                    questionnaires, research studies, promotions, special events
                    and other subjects that we think may be of interest to you.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    To provide, support, personalize, and develop our Sites and
                    Services.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    To create, maintain, customize, and secure your account with
                    us.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    To provide you with support and to respond to your
                    inquiries, including to investigate and address your
                    concerns and monitor and improve our responses.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    To personalize your Sites experience and to deliver content
                    and product and service offerings relevant to your
                    interests, including targeted offers and ads through our
                    Sites, third-party sites, and via email or text message
                    (with your consent, where required by law).
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    To help maintain the safety, security, and integrity of our
                    Sites and Services, databases and other technology assets,
                    and business.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    For testing, research, analysis, and product development,
                    including to develop and improve our Sites and Services.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    To respond to law enforcement requests and as required by
                    applicable law, court order, or governmental regulations.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    To evaluate or conduct a merger, divestiture, restructuring,
                    reorganization, dissolution, or other sale or transfer of
                    some or all of our assets, whether as a going concern or as
                    part of bankruptcy, liquidation, or similar proceeding, in
                    which Personal Information held by us about our [Sites
                    users/consumers] is among the assets transferred.
                  </li>
                </ul>
                <p class='paragraph-42'>
                  ​​
                  <br />
                  Additionally, we use Statcounter. Statcounter is an online
                  service which helps us to understand our visitors; for
                  example, how visitors find our website, how long they spend on
                  our site, which web pages they are most interested in etc. We
                  use Statcounter because better understanding how visitors are
                  interacting with our website helps us to improve the content,
                  design and functionality of our site. This allows us to offer
                  a better online experience to our visitors.
                  <br />
                  <br />
                  Statcounter uses cookies and other technologies to collect
                  data on visitors and visitor activity on our website. This
                  data includes:
                </p>
                <ul role='list' class='list-2'>
                  <li class='list-item-6'>
                    Time and date of visit (this can help us to identify and
                    plan for busy periods on our website)
                    <br />‍
                  </li>
                  <li>
                    IP address (this is a numerical label assigned to a device
                    by an Internet Service Provider to enable the device to
                    access the internet)
                    <br />‍
                  </li>
                  <li>
                    Browser and Operating System (this can help us to make sure
                    that our website functions correctly in the
                    browsers/operating systems used to access our site)
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    Device Information e.g. device type and screen size (this
                    can help us to make sure that our website functions
                    correctly in the devices used to access our site)
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    Referring Data e.g. a search engine link (this can help us
                    to understand which search engines are helping visitors to
                    find our website)
                    <br />‍
                  </li>
                </ul>
                <p class='paragraph-42'>
                  Furthermore, when you visit our website a Statcounter cookie
                  (called &quot;is_unique&quot;) may be placed in your browser.
                  This cookie is used only to determine whether you are a
                  first-time or returning visitor and to estimate unique visits
                  to the site.
                  <br />
                  <br />
                  You can learn more about cookies from Statcounter and set your
                  browser to refuse same here:
                  <br />‍
                  <a href='http://statcounter.com/about/set-refusal-cookie/'>
                    http://statcounter.com/about/set-refusal-cookie/
                    <br />
                    <br />‍
                  </a>
                  For further details, please see the Statcounter privacy policy
                  the:
                  <br />‍
                  <a href='https://statcounter.com/about/legal/#privacy'>
                    https://statcounter.com/about/legal/#privacy
                    <br />‍
                  </a>
                  ‍<br />‍
                  <span>
                    <strong class='bold-text'>
                      How We Share and Disclose Personal Information
                    </strong>
                  </span>
                  <strong>
                    <br />‍<br />‍
                  </strong>
                  We may share your Personal Information by disclosing it to a
                  third party for a business purpose. We may share Personal
                  Information for a business purpose to the categories of third
                  parties in the following ways:
                </p>
                <ul role='list' class='list-2'>
                  <li class='list-item-6'>
                    Service Providers: We provide access to or share your
                    Personal Information with third party service providers who
                    perform services on our behalf, such as billing, marketing,
                    products content and features, analytics, research, customer
                    service, data storage, security, payment processing, and
                    legal services.
                    <br />‍
                  </li>
                  <li>
                    Subsidiaries and Affiliates: We may share information we
                    collect with our affiliates and subsidiaries.
                  </li>
                </ul>
                <p class='paragraph-42'>
                  We do not sell Personal Information.
                  <a href='https://statcounter.com/about/legal/#privacy'>
                    <br />‍
                  </a>
                  ‍<br />‍
                  <span>
                    <strong class='bold-text'>Your Rights and Choices </strong>
                  </span>
                  <strong>
                    <br />‍<br />‍
                  </strong>
                  This section describes your rights and explains how to
                  exercise those rights.
                  <br />
                  <br />‍
                  <strong>
                    <em class='italic-text-2'>
                      Right to Know and Data Portability
                      <br />‍
                    </em>
                  </strong>
                  You have the right to request that we disclose certain
                  information to you about our collection and use of your
                  Personal Information over the past 12 months (the &quot;Right
                  to Know&quot;). Once we receive your request and confirm your
                  identity, we will disclose to you:
                </p>
                <ul role='list' class='list-2'>
                  <li class='list-item-6'>
                    The categories of Personal Information we collected about
                    you.
                    <br />‍
                  </li>
                  <li>
                    The categories of sources for the Personal Information we
                    collected about you.
                    <br />‍
                  </li>
                  <li>
                    Our business or commercial purpose for collecting or selling
                    that Personal Information.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    The categories of third parties with whom we share that
                    Personal Information.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    If we disclosed your Personal Information for a business
                    purpose, identifying the Personal Information categories
                    that each category of recipient obtained.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    The specific pieces of Personal Information we collected
                    about you (also called a data portability request).
                    <br />‍
                  </li>
                </ul>
                <p class='paragraph-42'>
                  We do not provide a right to know or data portability
                  disclosure for B2B personal information.
                  <a href='https://statcounter.com/about/legal/#privacy'>‍</a>‍
                  <strong>
                    <br />‍<br />‍
                  </strong>
                  <strong>
                    <em class='italic-text-3'>
                      Right to Delete <br />‍
                    </em>
                  </strong>
                  You have the right to request that we delete any of your
                  Personal Information that we collected from you and retained
                  (the &quot;Right to Delete&quot;), subject to certain
                  exceptions. Once we receive your request and confirm your
                  identity, we will review your request to see if an exception
                  allowing us to retain the information applies. We may deny
                  your deletion request if retaining the information is
                  necessary for us or our service provider(s) to:{' '}
                </p>
                <ul role='list' class='list-2 orderedlist'>
                  <li class='list-item-6'>
                    Complete the transaction for which we collected the Personal
                    Information, provide a service that you requested, take
                    actions reasonably anticipated within the context of our
                    ongoing business relationship with you, or otherwise perform
                    our contract with you.
                    <br />‍
                  </li>
                  <li>
                    Detect security incidents, protect against malicious,
                    deceptive, fraudulent, or illegal activity, or prosecute
                    those responsible for such activities.
                    <br />‍
                  </li>
                  <li>
                    Debug products to identify and repair errors that impair
                    existing intended functionality.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    Exercise free speech, ensure the right of another consumer
                    to exercise their free speech rights, or exercise another
                    right provided for by law.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    Engage in public or peer-reviewed statistical research in
                    the public interest that adheres to all other applicable
                    ethics and privacy laws, when the information&#x27;s
                    deletion may likely render impossible or seriously impair
                    the research&#x27;s achievement, if you previously provided
                    informed consent.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    Enable solely internal uses that are reasonably aligned with
                    consumer expectations based on your relationship with us.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    Comply with a legal obligation.
                    <br />‍
                  </li>
                  <li class='list-item-6'>
                    Make other internal and lawful uses of that information that
                    are compatible with the context in which you provided it.
                  </li>
                </ul>
                <p class='paragraph-42'>
                  We will delete or deidentify Personal Information not subject
                  to one of these exceptions from our records and will direct
                  our service providers to take similar action. We do not
                  provide these deletion rights for B2B personal information.{' '}
                  <br />
                  <br />‍
                  <strong>
                    <em class='italic-text-4'>
                      Exercising Your Rights to Know or Delete
                      <br />‍
                    </em>
                  </strong>
                  To exercise your rights to know or delete described above,
                  please submit a request by either:{' '}
                </p>
                <ul role='list' class='list-2'>
                  <li class='list-item-6'>
                    Calling us at <a href='#'>+1 (800) 360-0273</a>
                  </li>
                  <li>Emailing us at support@versus.co</li>
                  <li>Visiting https://versus.co</li>
                </ul>
                <p class='paragraph-42'>
                  Only you, or someone legally authorized to act on your behalf,
                  may make a request to know or delete related to your personal
                  information. <br />
                  <br />
                  You may only submit a request to know twice within a 12-month
                  period. Your request to know or delete must:
                </p>
                <ul role='list' class='list-2'>
                  <li class='list-item-6'>
                    Provide sufficient information that allows us to reasonably
                    verify you are the person about whom we collected Personal
                    Information or an authorized representative.
                  </li>
                  <li>
                    Describe your request with sufficient detail that allows us
                    to properly understand, evaluate, and respond to it.
                  </li>
                </ul>
                <p class='paragraph-42'>
                  We cannot respond to your request or provide you with Personal
                  Information if we cannot verify your identity or authority to
                  make the request and confirm the Personal Information relates
                  to you.
                  <br />
                  <br />
                  You do not need to create an account with us to submit a
                  request to know or delete. However, we do consider requests
                  made through your password protected account sufficiently
                  verified when the request relates to Personal Information
                  associated with that specific account.
                  <br />
                  <br />
                  We will only use Personal Information provided in the request
                  to verify the requestor&#x27;s identity or authority to make
                  it. <br />
                  <br />‍
                  <strong>
                    <em class='italic-text-5'>
                      Response Timing and Format
                      <br />‍
                    </em>
                  </strong>
                  We will confirm receipt of your request within ten (10)
                  business days. <br />
                  <br />
                  We endeavor to substantively respond to a verifiable consumer
                  request within forty-five (45) days of its receipt. If we
                  require more time (up to another 45 days), we will inform you
                  of the reason and extension period in writing.
                  <br />
                  <br />
                  If you have an account with us, we will deliver our written
                  response to that account. If you do not have an account with
                  us, we will deliver our written response by mail or
                  electronically, at your option. <br />
                  <br />
                  Any disclosures we provide will only cover the 12-month period
                  preceding our receipt of your request. The response we provide
                  will also explain the reasons we cannot comply with a request,
                  if applicable. For data portability requests, we will select a
                  format to provide your Personal Information that is readily
                  useable and should allow you to transmit the information from
                  one entity to another entity without hindrance.
                  <br />
                  <br />
                  We do not charge a fee to process or respond to your
                  verifiable consumer request unless it is excessive,
                  repetitive, or manifestly unfounded. If we determine that the
                  request warrants a fee, we will tell you why we made that
                  decision and provide you with a cost estimate before
                  completing your request. <br />
                  <br />‍
                  <strong>
                    <em class='italic-text-6'>
                      Cookies
                      <br />‍
                    </em>
                  </strong>
                  The help function of your browser should contain instructions
                  on how to set your device to accept all cookies, to notify you
                  when a cookie is issued, or to not receive cookies at any
                  time. If you set your device to not receive cookies at any
                  time, certain personalized services cannot be provided to you,
                  and accordingly, you may not be able to take full advantage of
                  all of our features.
                  <br />
                  <br />‍
                  <strong>
                    <em class='italic-text-7'>
                      Do Not Track
                      <br />‍
                    </em>
                  </strong>
                  Some browsers have a “do not track” feature that lets you tell
                  websites that you do not want to have your online activities
                  tracked. At this time, we do not respond to browser “do not
                  track” signals.
                  <br />
                  <br />‍
                  <strong>
                    <em class='italic-text-8'>
                      Other Web Site Analytics Services
                      <br />‍
                    </em>
                  </strong>
                  We may use analytics services such as Google Analytics to
                  analyze information regarding visits to our Sites. It uses
                  cookies, web beacons, recorded interactions and other tracking
                  mechanisms to collect this information. To learn more about
                  Google’s privacy practices, click here. To access and use the
                  Google Analytics Opt-out Browser Add-on, click here.
                  <br />
                  <br />‍
                  <span>
                    <strong class='bold-text-5'>Non-Discrimination</strong>
                  </span>
                  <strong>
                    <br />‍<br />‍
                  </strong>
                  We will not discriminate against you for exercising any of
                  your rights. We will not:
                </p>
                <ul role='list' class='list-2'>
                  <li class='list-item-6'>Deny you Services.</li>
                  <li>
                    Charge you different prices or rates for Services, including
                    through granting discounts or other benefits, or imposing
                    penalties.
                  </li>
                  <li>Provide you a different level or quality of Services.</li>
                  <li>
                    Suggest that you may receive a different price or rate for
                    Services or a different level or quality of Services.
                  </li>
                </ul>
                <p class='paragraph-42'>
                  However, we may offer you certain financial incentives
                  permitted by law that can result<strong> </strong>in different
                  prices, rates, or quality levels. Any permitted financial
                  incentive we offer will reasonably relate to your personal
                  information&#x27;s value and contain written terms that
                  describe the program&#x27;s material aspects. Participation in
                  a financial incentive program requires your prior opt-in
                  consent, which you may revoke at any time. <br />
                  <br />‍
                  <strong class='bold-text-6'>
                    California Privacy Rights
                    <br />
                    <br />‍
                  </strong>
                  California&#x27;s &quot;Shine the Light&quot; law (Civil Code
                  Section § 1798.83) permits users of our Sites who are
                  California residents to request certain information regarding
                  our disclosure of Personal Information to third parties for
                  their direct marketing purposes. To make such a request,
                  please send an email to support@versus.co or write us at:{' '}
                  <br /> Versus
                  <br /> Attn: Customer Support
                  <br /> 2183 Parkway Lake Drive Birmingham AL, 35244 <br />
                  <br />‍
                  <strong class='bold-text-7'>
                    Changes to Our Privacy Policy
                    <br />
                    <br />‍
                  </strong>
                  We reserve the right to amend this privacy policy at our
                  discretion and at any time. When we make changes to this
                  privacy policy, we will post the updated notice on the Sites
                  and update the notice&#x27;s effective date. Your continued
                  use of our Sites following the posting of changes constitutes
                  your acceptance of such changes.
                  <br />
                  <br />‍
                  <strong class='bold-text-8'>
                    Contact Information
                    <br />
                    <br />‍
                  </strong>
                  If you have any questions or comments about this notice, the
                  ways in which we collect and use your Personal Information
                  described here, your choices and rights regarding such use, or
                  wish to exercise your rights, please do not hesitate to
                  contact us at:
                  <br />
                  <br />‍<strong>Phone</strong>:{' '}
                  <a target='_blank' href='tel:+18003600273'>
                    +1 (800) 360-0273
                  </a>
                  <br />‍<strong>Website</strong>:{' '}
                  <a href='https://versus.co'>https://versus.co</a>
                  <br />‍<strong>Email</strong>:{' '}
                  <a href='mailto:support@versus.co'>support@versus.co</a>
                  <br />‍<strong>Postal Address</strong>: <br />
                  Versus
                  <br />
                  Attn: Customer Support
                  <br />
                  2183 Parkway Lake Drive Birmingham AL, 35244 <br />
                  <br />
                  If you need to access this Policy in an alternative format due
                  to having a disability, please contact{' '}
                  <a href='mailto:support@versus.co'>
                    support@versus.co
                  </a> and{' '}
                  <a target='_blank' href='tel:+18003600273'>
                    +1 (800) 360-0273
                  </a>
                  .
                </p>
              </div>
            </div>
            <div data-w-tab='Tab 2' class='w-tab-pane'>
              <div class='div-block-87'></div>
            </div>
            <div data-w-tab='Tab 3' class='w-tab-pane'>
              <div class='div-block-87'></div>
            </div>
            <div data-w-tab='Tab 4' class='w-tab-pane'></div>
            <div data-w-tab='Tab 5' class='w-tab-pane'></div>
            <div data-w-tab='Tab 6' class='w-tab-pane'></div>
            <div data-w-tab='Tab 7' class='w-tab-pane'></div>
            <div data-w-tab='Tab 8' class='w-tab-pane'></div>
            <div data-w-tab='Tab 9' class='w-tab-pane'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
