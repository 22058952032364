import React, { useState } from 'react';
import Button from '@components/Button/Button';
import Input from '@components/Input/Input';

function EmailHeroForm({ emailPlaceholder, emailButton }) {
  const [inputEmail, setInputEmail] = useState('');
  //const [checked, setChecked] = useState(false);
  const [buttonState, setButtonState] = useState(
    emailButton || 'Join Email List'
  );
  const [buttonClass, setButtonClass] = useState('primary');

  const handleSubmitForm = () => {
    setButtonState('Sending ...');
    /* handleToast({
      icon: 'success',
      text: "You've been added to our mailing list.",
    }); */
    setTimeout(() => {
      window.document.querySelector('#submitEmailForm').click();
    }, 500);

    setTimeout(() => {
      setButtonState('Successfully Joined');
      setButtonClass('success');
      setInputEmail('');

      setTimeout(() => {
        setButtonState(emailButton || 'Join EmailList');
        setButtonClass('primary');
      }, 2000);
    }, 1500);
  };

  return (
    <div className="email-hero-form">
      <div className="call-to-action">
        <Input
          placeholder={emailPlaceholder ? emailPlaceholder : 'Enter your email'}
          value={inputEmail}
          onChange={(e) => setInputEmail(e.target.value)}
        />
        <Button
          className={`primary ${buttonClass} ${
            inputEmail.length === 0 ? 'disabled' : ''
          }`}
          disabled={inputEmail.length === 0}
          onClick={() => inputEmail.length > 0 && handleSubmitForm()}
          title="News Letter Form"
          action="Sign Up"
        >
          {buttonState}
        </Button>
      </div>
      <div className="additionalInfo">
        By signing up, you are agreeing to our Terms of Service and Privacy
        Policy, and to receiving email from Versus.
      </div>
      {/* <div className="additionalInfo">
        <img
          onClick={() => setChecked(!checked)}
          src={checked ? '/icons/checked.svg' : '/icons/checkbox-empty.svg'}
          alt="Agree to email updates"
        />
        I agree to receive email updates.
      </div> */}

      <iframe
        title="secret-frame-four"
        name="secret-frame-four"
        width="0"
        height="0"
        border="0"
        style={{ display: 'none' }}
      ></iframe>
      <form
        className="form"
        action="https://docs.google.com/forms/d/e/1FAIpQLSdqBOwDE6DxM8eQ-Ri5Ek933QjukAQTh60en9_4HSg0ktqQLw/formResponse"
        id="ss-form4"
        method="POST"
        target="secret-frame-four"
        style={{ display: 'none' }}
      >
        <div className="form-group">
          <label htmlFor="subscriptionEmail">Email</label>
          <input
            name="entry.1635714210"
            readOnly
            type="email"
            value={inputEmail}
          />
        </div>

        <input id="submitEmailForm" type="submit" value="Submit Subscribe" />
      </form>
    </div>
  );
}

export default EmailHeroForm;
