import React from 'react';

function TextHero({ data, heading, hideAppstore }) {
  if (data)
    return (
      <div className="text-hero">
        <h1>
          {heading ? heading : 'An experience like no other. Only at Versus.'}
        </h1>
        {!hideAppstore && (
          <div className="call-to-action">
            <a
              href="https://apps.apple.com/us/app/versus-interactive/id1612364880"
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: '15px' }}
            >
              <img src="/landing/appstore.png" alt="Download on the AppStore" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mavenarena.versus"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/landing/googleplay.png"
                alt="Download on the AppStore"
              />
            </a>
          </div>
        )}

        <div className="container">
          {data.map((t, index) => (
            <div key={`${t.title}-${t.id}-${index}`}>
              <h6>{t.title}</h6>
              <p>{t.subtitle}</p>
              <hr />
              {t.body}
            </div>
          ))}
        </div>
      </div>
    );
}

export default TextHero;
