import { useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { setDoc, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { auth, db } from '../../config/firebase';

import Button from '../../components/Button/Button';
import LoginBg from '../../assets/images/General_BG.jpg';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import ResetPasswordModal from '../../components/Modals/ResetPasswordModal/ResetPasswordModal';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import Logo from '../../assets/images/logo.svg';
import { usePartner } from 'src/context/PartnerContext';
import {
  REVENUE_CAT_API_KEY,
  REVENUECAT_API_URL,
  REVENUECAT_SECRET_KEY,
} from '@config/index';

import './LoginPage.scss';
import SEO from '@components/Seo/Seo';
import useHasMounted from '@hooks/useHasMounted';

const LoginPage = ({
  loginModal,
  setToggledLoginType,
  isSignup,
  setIsFixed,
}) => {
  const { search } = useLocation();

  const partnerData = usePartner();
  const [showBackground, setShowBackground] = useState(false);
  const [background, setBackground] = useState('');
  const [backgroundVideo, setBackgroundVideo] = useState('');
  const [partner, setPartner] = useState('');
  const [partnerLogo, setPartnerLogo] = useState('');
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [body, setBody] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [hasAccount, setHasAccount] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedError, setCheckedError] = useState('');
  const [isResetPasswordModalOpened, setIsResetPasswordModalOpened] =
    useState(false);
  const [isConfirmResetModalOpened, setIsConfirmResetModalOpened] =
    useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const openChangeResetPasswordModal = () => {
    setIsResetPasswordModalOpened(true);
  };

  const closeChangeResetPasswordModal = () => {
    setIsResetPasswordModalOpened(false);
  };

  const openConfirmResetModal = () => {
    setIsConfirmResetModalOpened(true);
  };

  const closeConfirmResetModal = () => {
    setIsConfirmResetModalOpened(false);
  };

  // Handle has account
  const handleHasAccount = () => {
    setHasAccount((prev) => !prev);
  };

  // Clear errors
  const clearErrors = () => {
    setFullNameError('');
    setEmailError('');
    setPasswordError('');
    setCheckedError('');
  };

  // Handle login
  const handleLogin = () => {
    setIsFixed(false);
    clearErrors();
    if (email.trim() === '') {
      setEmailError("Email can't be empty");
      return;
    }
    if (password.trim() === '') {
      setPasswordError("Password can't be empty");
      return;
    }
    signInWithEmailAndPassword(auth, email, password).catch((err) => {
      switch (err.code) {
        case 'auth/invalid-email':
          setEmailError('Invalid email address');
          break;
        case 'auth/user-disabled':
          setEmailError('User is disabled');
          break;
        case 'auth/user-not-found':
          setEmailError('User not found');
          break;
        case 'auth/wrong-password':
          setPasswordError('Wrong password');
          break;
      }
    });
  };

  useEffect(() => {
    if (searchParams.get('discount') || searchParams.get('p')) {
      setHasAccount(false);
    }

    const sParams = Object.fromEntries([...searchParams]);
    if (sParams) {
      //?email=user@email.com&code=12345678abc&id=P4Iaz6XOl2ouyx2qgyBi
      setEmail(sParams.email || '');
      setBackground(partnerData?.background || '');
      setBackgroundVideo(partnerData?.backgroundVideo || '');
      setPartner(partnerData?.partner || '');
      setPartnerLogo(partnerData?.partnerLogo || '');
      setHeading(partnerData?.heading || '');
      setSubheading(partnerData?.subheading || '');
      setBody(partnerData?.body || '');
      //setHasAccount(sParams.hasAccount === 'true' ? true : false);
    }
    setTimeout(() => {
      setShowBackground(true);
    }, 1000);
  }, [searchParams, partnerData]);

  useEffect(() => {
    if (loginModal) {
      setHasAccount(true);
    }
    if (isSignup) setHasAccount(false);
  }, []);

  const createProfile = async () => {
    await setDoc(doc(db, 'profiles', auth.currentUser.uid), {
      credits: 10,
      unlocked: true,
      osPlatform: 'web',
    });
  };

  // Handle signup
  const handleSignup = () => {
    setIsFixed(false);
    clearErrors();
    if (fullName.trim() === '') {
      setFullNameError("Name can't be empty");
      return;
    }
    if (email.trim() === '') {
      setEmailError("Email can't be empty");
      return;
    }
    if (password.trim() === '') {
      setPasswordError("Password can't be empty");
      return;
    }
    if (!checked) {
      setCheckedError(
        'Please accept the terms and conditions before proceeding'
      );
      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        const options = {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'X-Platform': 'stripe',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + REVENUE_CAT_API_KEY,
          },
        };

        fetch(
          REVENUECAT_API_URL + '/subscribers/' + auth.currentUser.uid,
          options
        )
          .then((response) => response.json())
          .then((response) => console.log('revenuecat res 1', response))
          .catch((err) => console.error(err));

        updateProfile(auth.currentUser, {
          displayName: fullName,
        });

        createProfile();
      })
      .then(async (user) => {
        //For partners that prepaid: check if id has slots
        if (partnerData?.passCount > partnerData?.userCount) {
          //Call Firebase and save user email to emails array field so we have a record of which emails have used the free premium from partner.
          const newPartnerData = await updateDoc(
            doc(db, 'partners', partnerData?.id),
            {
              emails: arrayUnion(email),
              userIds: arrayUnion(auth.currentUser.uid),
            }
          );

          const userId = auth.currentUser.uid;
          console.log(userId, 'USER ID');

          const options = {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${REVENUECAT_SECRET_KEY}`,
            },
            body: JSON.stringify({ duration: 'yearly' }),
          };

          fetch(
            `https://api.revenuecat.com/v1/subscribers/${userId}/entitlements/Premium/promotional`,
            options
          )
            .then((response) => response.json())
            .then((response) => console.log(response, 'REVCAT'))
            .catch((err) => console.error(err));

          //Send user to non-cc path, check for prepaid true in upgrade screen
          //navigate(`/upgrade/${search}&prepaid=true`, { replace: true });
          navigate(`/${search}&prepaid=true`, { replace: true });
          return;
        }

        //Navigate user to onboarding Survey.js
        //navigate(`/upgrade/${search}`, { replace: true });
        navigate(`/${search}&prepaid=true`, { replace: true });
      })
      .catch((err) => {
        switch (err.code) {
          case 'auth/email-already-in-use':
            setEmailError('Email is already in use');
            break;
          case 'auth/invalid-email':
            setEmailError('Invalid email address');
            break;
          case 'auth/weak-password':
            setPasswordError('Password must have at least 6 characters');
            break;
        }
      });
  };

  // Login info data
  const loginInfoData = [
    {
      name: 'Email',
      type: 'text',
      value: email,
      handleChange: (e) => setEmail(e.target.value),
      focused: true,
      placeholder: 'Email',
      errorMsg: emailError,
      autoComplete: 'email',
    },
    {
      name: 'Password',
      type: 'password',
      value: password,
      handleChange: (e) => setPassword(e.target.value),
      placeholder: 'Password ',
      errorMsg: passwordError,
      autoComplete: 'current-password',
    },
  ];

  const registerInfoData = [
    {
      name: 'Full name',
      type: 'text',
      value: fullName,
      handleChange: (e) => setFullName(e.target.value),
      placeholder: 'Full name ',
      errorMsg: fullNameError,
      autoComplete: 'name',
    },
    {
      name: 'Email',
      type: 'text',
      value: email,
      handleChange: (e) => setEmail(e.target.value),
      focused: true,
      placeholder: 'Email',
      errorMsg: emailError,
      autoComplete: 'email',
    },
    {
      name: 'Password',
      type: 'password',
      value: password,
      handleChange: (e) => setPassword(e.target.value),
      placeholder: 'Password ',
      errorMsg: passwordError,
      autoComplete: 'new-password',
    },
  ];

  /* const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      console.log('hit enter', hasAccount);
      if (hasAccount) {
        if (email.length > 0 && password.length > 0) handleLogin();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', (e) => handleEnterKey(e));
    return () => {
      window.removeEventListener('keyup', (e) => handleEnterKey(e));
    };
  }, []); */

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div
      className={loginModal ? 'login-page login-modal-override' : 'login-page'}
      style={{
        background: showBackground
          ? background?.length > 0
            ? `url(${background}) no-repeat center/cover`
            : `url(${LoginBg}) no-repeat center/cover`
          : '',
      }}
    >
      <SEO
        title="Login to your Membership | Versus"
        description="‍Versus gives you access to the best athletes and coaches through online courses and interactive questions."
        name="Login Page for Versus"
        type="article"
      />
      {isResetPasswordModalOpened && (
        <ResetPasswordModal
          openConfirmResetModal={openConfirmResetModal}
          closeChangeResetPasswordModal={closeChangeResetPasswordModal}
        />
      )}
      {isConfirmResetModalOpened && (
        <ConfirmationModal
          modalText={
            <p>
              Reset password information has been <br /> sent to your email.
            </p>
          }
          onClose={closeConfirmResetModal}
        />
      )}
      {!loginModal && (
        <div className="loginBg">
          <div className="loginBgOverlay" />

          <video
            playsInline
            autoPlay
            muted
            loop
            preload="metadata"
            width="100%"
            height="auto"
            // poster={background}
            id="bgvid"
            src={
              backgroundVideo?.length > 0
                ? backgroundVideo
                : 'https://cdn.bfldr.com/B1ZZKPB4/as/hvhh7hh4s35rnw8vmhwbtccw/Website_Intro'
            }
          />

          <div className="left">
            <div className="collab-container">
              <img src={Logo} alt="VS" className="logo" />
              {partnerLogo && (
                <>
                  <span className="collab">+</span>
                  <img src={partnerLogo} alt={partner} />
                </>
              )}
            </div>
            <div className="loginBgInfo">
              <p>{subheading ? subheading : 'Are you ready to get better?'}</p>
              <h3>
                {heading
                  ? heading
                  : 'Interact with and learn from top athletes & coaches.'}
              </h3>
              <p>
                {body ? (
                  body
                ) : (
                  <span>Create an account to start your free trial.</span>
                )}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="loginInfoWrapper">
        <div className="loginInfo">
          {loginModal && (
            <img
              onClick={() => setToggledLoginType(null)}
              src="/icons/close.svg"
              className="close-icon"
              alt="Close Modal"
            />
          )}
          <div className="show-mobile">
            {/* <img src={Logo} alt="VS" className="logo" />

            {partnerLogo && (
              <>
                <span className="collab">+</span>
                <img src={partnerLogo} alt={partner} />
              </>
            )} */}
            <br />
          </div>

          <h2>{hasAccount ? 'Sign In' : 'Create Account'} </h2>
          <div className="loginData">
            {hasAccount
              ? loginInfoData.map((item, index) => (
                  <div key={index} className="loginRow">
                    <input
                      type={item.type}
                      placeholder={item.placeholder}
                      autoFocus={index === 0}
                      value={item.value}
                      onChange={item.handleChange}
                      autoComplete={item.autoComplete}
                    />

                    <p>{item.errorMsg}</p>
                  </div>
                ))
              : registerInfoData.map((item, index) => (
                  <div key={index} className="loginRow">
                    <input
                      type={item.type}
                      placeholder={item.placeholder}
                      autoFocus={index === 0}
                      value={item.value}
                      onChange={item.handleChange}
                      autoComplete={item.autoComplete}
                    />
                    <p>{item.errorMsg}</p>
                  </div>
                ))}
          </div>
          {!hasAccount && (
            <div className="signupWrapper">
              <div className="checkboxWrapper">
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <p>
                  By creating an account you agree to our{' '}
                  <a
                    href="https://versus.co/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                  , Conditions, and{' '}
                  <a
                    href="https://versus.co/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy.
                  </a>
                </p>
              </div>
              {checkedError && (
                <div className="errorMsg">
                  <p>{checkedError}</p>
                </div>
              )}
            </div>
          )}
          <div className="btnWrapper">
            {hasAccount ? (
              <Button className="primary" onClick={handleLogin}>
                Sign in
              </Button>
            ) : (
              <Button className="primary" onClick={handleSignup}>
                Continue
              </Button>
            )}
          </div>
          <div className="additionalInfo">
            {hasAccount ? (
              <>
                <p>Don't have an account?</p>
                <span>
                  Join thousands of others on the Versus platform.{' '}
                  <span className="signUpText" onClick={handleHasAccount}>
                    Sign Up Now
                  </span>
                </span>
                <span>
                  <br />
                  Forgot your password?{' '}
                  <span
                    className="resetText"
                    onClick={openChangeResetPasswordModal}
                  >
                    Reset password
                  </span>
                </span>
              </>
            ) : (
              <>
                <p>Already have an account?</p>
                <span>
                  What are you waiting for?{' '}
                  <span className="signInText" onClick={handleHasAccount}>
                    Sign In Now
                  </span>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
