import { useEffect, useState } from 'react';
import { useSubscription } from 'src/context/SubscriptionContext';
import HeroSimple from '@components/HeroSimple/HeroSimple';
import { mavenData } from 'src/data/mavenData';
import { getUserVideos, getAskedQuestions } from '@hooks/useWatchingVideo';
import { landing } from '../LandingPage/data.js';
import '../CourseLanding/CourseLanding.scss';
import './LandingHome.scss';
import LandingHomeHero from './LandingHomeHero';
import { sessionVideos } from '../../data/sessionVideos';
import { Link } from 'react-router-dom';
import Courses from '../LandingPage/Courses';
import CollectionsSection from '../BrowseContent/CollectionsSection.js';
import { collectionData } from 'src/data/dataCollections';
import CourseContent from '../CourseLanding/CourseContent.js';
import useHasMounted from '@hooks/useHasMounted.js';
import KeepAsking from './KeepAsking.js';
import Button from '@components/Button/Button';
import { useSwipeable } from 'react-swipeable';
import { isMobile } from 'react-device-detect';

const { tabData } = landing;

const config = {
  delta: 10, // min distance(px) before a swipe starts. *See Notes*
  preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
  trackTouch: true, // track touch input
  trackMouse: false, // track mouse input
  rotationAngle: 0, // set a rotation angle
  swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
  touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
};

function LandingHome({
  currentUser,
  searchParams,
  setVideoModal,
  setAlert,
  setToggledDownloadModal,
  setToggledSubscribeModal,
  setContactModal,
  isNative,
}) {
  const subscription = useSubscription();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [videosWatched, setVideosWatched] = useState(null);
  const [videosWatchedIds, setVideosWatchedIds] = useState([]);
  const [videosWatchedWithData, setVideosWatchedWithData] = useState(null);
  const [askedQuestions, setAskedQuestions] = useState(null);
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      handleSetSelectedMaven('right');
    },
    onSwipedRight: (eventData) => {
      handleSetSelectedMaven('left');
    },
    ...config,
  });

  useEffect(() => {
    getUserVideos(setVideosWatched, setVideosWatchedIds);
    getAskedQuestions(setAskedQuestions);
  }, []);

  useEffect(() => {
    const vWatched = sessionVideos.filter((s) =>
      videosWatchedIds.includes(s.videoId)
    );
    const dataForVideosWatched = vWatched
      .map((m) => {
        const video = videosWatched.find((v) => v.video === m.videoId);
        return { ...m, ...video };
      })
      .sort(
        (a, b) =>
          (b.updatedTime?.seconds || new Date(b.updatedTime)) -
          (a.updatedTime?.seconds || new Date(a.updatedTime))
      );
    setVideosWatchedWithData(dataForVideosWatched);
  }, [videosWatchedIds, videosWatched]);

  const handleSetSelectedMaven = (direction) => {
    if (direction === 'left') {
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      } else {
        setSelectedIndex(mavenData.length - 1);
      }
    }
    if (!direction || direction === 'right') {
      if (selectedIndex < mavenData.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      } else {
        setSelectedIndex(0);
      }
    }
  };

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <div className="course-landing landing-home">
      <div className="hero-slider" {...handlers}>
        <div className="hero-slider-actions">
          <img
            className="left"
            src="/icons/chevron-down.svg"
            alt="Slide Left"
            onClick={() => handleSetSelectedMaven('left')}
          />
          <img
            className="right"
            src="/icons/chevron-down.svg"
            alt="Slide Right"
            onClick={() => handleSetSelectedMaven('right')}
          />
        </div>
        <div className="dots">
          {mavenData.map((m, index) => (
            <div
              key={`${m.slug}-${index}`}
              onClick={() => setSelectedIndex(index)}
              className={`dot ${
                mavenData[selectedIndex].slug === m.slug ? 'active' : ''
              }`}
            ></div>
          ))}
        </div>
        <div className="hero-slide">
          <LandingHomeHero
            key={mavenData[selectedIndex].slug}
            athlete={mavenData[selectedIndex].title}
            subscription={subscription}
            shortBio={mavenData[selectedIndex].shortBio}
            poster={
              mavenData[selectedIndex].courseHeroImage ||
              mavenData[selectedIndex].restingImageLandscape
            }
            mobilePoster={
              mavenData[selectedIndex].courseHeroImageMobile?.length > 0
                ? mavenData[selectedIndex].courseHeroImageMobile
                : mavenData[selectedIndex].restingImage
            }
            mobileVideo={
              mavenData[selectedIndex].trailer?.length > 0
                ? mavenData[selectedIndex].trailer
                : mavenData[selectedIndex].restingVideo
            }
            video={
              mavenData[selectedIndex].trailer ||
              mavenData[selectedIndex].restingVideoLandscape
            }
            interactiveOnly={
              mavenData[selectedIndex].interactiveOnly === 'FALSE'
                ? false
                : true
            }
            slug={mavenData[selectedIndex].slug}
            callback={handleSetSelectedMaven}
          />
        </div>
      </div>
      {videosWatchedWithData?.length > 0 && (
        <div className="has-watched">
          <CourseContent
            data={videosWatchedWithData}
            setToggledDownloadModal={setToggledDownloadModal}
            setToggledSubscribeModal={setToggledSubscribeModal}
            header="Keep Watching"
            currentUser={currentUser}
          />
        </div>
      )}
      {(!videosWatchedWithData ||
        videosWatchedWithData?.length === 0 ||
        !askedQuestions ||
        askedQuestions?.length === 0) && (
        <div className="hero-content">
          <HeroSimple />
          <div className="body">
            <h2>Browse Content</h2>
            <p className="faded">
              Discover hours of content and thousands of interactions with our
              experts.
            </p>
            <Link to="/browse-content" className="button primary">
              Browse Content
            </Link>
          </div>
        </div>
      )}

      {askedQuestions?.length > 0 && <KeepAsking data={askedQuestions} />}
      <Courses
        title="Roster"
        type="Roster"
        tabData={tabData}
        setVideoModal={setVideoModal}
        setToggledSubscribeModal={null}
        searchParams={searchParams}
        setAlert={setAlert}
        exploreMore={{ pathname: '/roster' }}
      />
      {/* <div className="browse-content">
        <CollectionsSection data={collectionData} searchParams={searchParams} />
      </div> */}
      <div
        className="hero-content"
        style={{ height: '75vh', paddingBottom: '15vh' }}
      >
        <div className="body">
          <h2>Want to see more Versus?</h2>
          <p className="faded">
            Our experts want to make sure you’re supported on your pursuit of
            competitive excellence. Submit your feedback and our team will work
            diligently to bring new and relevant content to your feed.
          </p>
          <Button
            className="button primary"
            onClick={() =>
              setContactModal({
                type: 'Feedback',
                title: 'Feedback',
              })
            }
          >
            Give Feedback
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LandingHome;
