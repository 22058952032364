import { useState, useRef } from 'react';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import './Dropdown.scss';

function Dropdown({ items, selected, callback, placeholder }) {
  const dropdownRef = useRef();
  const [selectedOption, setSelectedOption] = useState(selected);
  const [toggledDropdown, setToggledDropdown] = useState(false);
  useOutsideAlerter(dropdownRef, (isOutSide) => {
    if (isOutSide) {
      setToggledDropdown(false);
    }
  });
  const handleSelectedOption = (d) => {
    setSelectedOption(d);
    callback(d);
    setToggledDropdown(false);
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div
        className="dropdown-selected"
        onClick={() => setToggledDropdown(!toggledDropdown)}
      >
        {selectedOption ? (
          selectedOption.label
        ) : (
          <div className="faded">{placeholder}*</div>
        )}
        <img src="/icons/chevron-down.svg" alt="dropdown arrow" />
      </div>
      {toggledDropdown && (
        <div className="dropdown-options">
          {items
            ?.filter((i) => i.id !== selectedOption?.id)
            .map((d) => (
              <div
                key={`${d.label}-${d.id}`}
                className={`dropdown-option ${
                  selectedOption?.id === d.id ? 'active' : ''
                }`}
                onClick={() => handleSelectedOption(d)}
              >
                {d.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
