import { addDoc, collection, doc, onSnapshot } from 'firebase/firestore';
import { getStripe } from '../../config';
import { db } from '../../config/firebase';

export async function checkout(
  price: any,
  user: any,
  coupon: string | null,
  id: string | null,
  setIsLoading: any | null
) {
  setIsLoading(true);
  const stripe = await getStripe();

  const session_doc: any = {
    client: 'web',
    payment_method_types: ['card'],
    success_url: `${window.location.origin}/?session_id={CHECKOUT_SESSION_ID}${
      id ? `&id=${id}` : ''
    }`,
    cancel_url: `${window.location.origin}/?${id ? `&id=${id}` : ''}`,
    client_reference_id: user?.uid,
    customer_email: user?.email || '',
    allow_promotion_codes: true,
    price,
  };
  if (coupon) {
    session_doc.promotion_code = coupon;
  }

  const checkoutSessionsRef = await addDoc(
    collection(
      doc(collection(db, 'customers'), user?.uid || ''),
      'checkout_sessions'
    ),
    session_doc
  );
  onSnapshot(checkoutSessionsRef, async (ref) => {
    const checkout = ref.data() || {};

    if (stripe && checkout.sessionId) {
      const params = {
        sessionId: checkout.sessionId,
      };
      const { error } = await stripe.redirectToCheckout(params);
      if (error) {
        // return toast.error(error.message);
        console.log(error);
      }
    } else if (checkout.error) {
      console.error('fb checkout error', checkout.error.message);
    }
  });
}
