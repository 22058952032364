import styled from "styled-components";

export const ChangeNameModalOverlay = styled.div`
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  .changeNameModal {
    padding: 80px 0 0 0;
    width: 100%;
    max-width: 640px;
    min-height: 360px;
    background: #f5f5f5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    &.hasError {
      min-height: 400px;
    }
    .closeModalBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      cursor: pointer;
    }
    h3 {
      color: #000;
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
    }
    .nameInputWrapper {
      margin: 40px 0 0 0;
      width: 336px;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      .nameLabel {
        background: #fff;
        display: block;
        padding: 6px 16px 0 16px;
        color: #888;
        font-size: 12px;
        font-weight: 500;
      }
      input {
        width: 100%;
        height: 44px;
        border-radius: 0 0 8px 8px;
      }
      .errorMsg {
        margin: 10px 0 0 0;
        color: red;
        font-size: 14px;
      }
      .errorMsg {
        span {
          color: lightblue;
          cursor: pointer;
        }
      }
    }
    .nameBtnWrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 20px;
      background: #fff;
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        width: 122px;
        height: 52px;
        font-size: 16px;
        &.cancelBtn {
          margin-right: 12px;
          background: transparent;
          color: #000;
        }
        &.doneBtn {
          background: #ededed;
          color: #888;
          &.validatedBtn {
            background: #fff200;
            color: #000;
          }
        }
      }
    }
  }
  @media (max-width: 694px) {
    .changeNameModal {
      padding: 70px 0 0 0;
      max-width: 94%;
      min-height: 340px;
      &.hasError {
        min-height: 360px;
      }
      .closeModalBtn {
        top: 17px;
        right: 17px;
        width: 24px;
      }
      h3 {
        font-size: 22px;
      }
      .nameInputWrapper {
        width: 100%;
        padding: 0 17px;
        .nameLabel {
          border-radius: 8px 8px 0 0;
        }
        .errorMsg {
          margin: 10px 0 0 0;
          color: red;
          font-size: 14px;
        }
      }
    }
  }
`;
