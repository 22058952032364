import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function Topics({ data, setToggledTopics, handleModal }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedData, setSelectedData] = useState(data);

  useEffect(() => {
    const sTopic = data.find((d) => d.title === searchParams.get('topic'));
    if (sTopic) {
      setSelectedTopic(sTopic);
      setSelectedData(sTopic.data);
    }
  }, []);

  const handleBodyClick = (m) => {
    handleRemoveParams();
    if (selectedTopic) {
      handleModal(m);
    } else {
      setSelectedTopic(m);
      setSelectedData(m.data);
    }
  };

  const handleRemoveParams = () => {
    if (searchParams.has('topic')) {
      searchParams.delete('topic');
      setSearchParams(searchParams);
    }
  };

  return (
    <div className={`topics ${selectedTopic ? 'selected-topic' : ''}`}>
      <div className="header">
        {selectedTopic && (
          <img
            className="back icon"
            src="/icons/chevron-down.svg"
            alt="Back"
            onClick={() => {
              setSelectedTopic(null);
              setSelectedData(data);
              handleRemoveParams();
            }}
          />
        )}

        {selectedTopic ? (
          <span>
            {selectedTopic.title} ({selectedTopic.data.length})
          </span>
        ) : (
          <span>Topics{/*  ({data?.length}) */}</span>
        )}

        <img
          className="close icon"
          src="/icons/close.svg"
          alt="Close Topics"
          onClick={() => {
            setToggledTopics(false);
            handleRemoveParams();
          }}
        />
      </div>
      <div className="body">
        {selectedData?.map((m, index) => (
          <div
            key={`${m.title}-${index}`}
            className="item"
            onClick={() => handleBodyClick(m)}
          >
            <h6>{m.title || m.name}</h6>
            {selectedTopic ? (
              <span>{m.text}</span>
            ) : (
              <>
                <span className="faded">{m.questionCount} Questions</span>
                <img
                  src="/icons/chevron-down.svg"
                  alt="arrow"
                  className="icon"
                />
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Topics;
