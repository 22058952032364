import { useEffect, useState } from 'react';
import Button from '@components/Button/Button';
import Input from '@components/Input/Input';
import Dropdown from '@components/Dropdown/Dropdown';
import { useLocation } from 'react-router-dom';

const types = [
  { id: 0, label: 'Player' },
  { id: 1, label: 'Coach' },
  { id: 2, label: 'Parent' },
];

function TeamsnapForm({
  emailPlaceholder,
  emailButton,
  emailSport,
  emailPartner,
  emailType,
  emailSlug,
  mailchimpTag,
}) {
  const [inputEmail, setInputEmail] = useState('');
  const [selectedType, setSelectedType] = useState(null);
  const [checked, setChecked] = useState(false);
  const [tags, setTags] = useState('');
  const [buttonState, setButtonState] = useState(
    emailButton || 'Submit Request'
  );
  const [buttonClass, setButtonClass] = useState('primary');
  const { pathname } = useLocation();
  const f = pathname?.replace('partners-', '');
  const formattedPathname = f?.slice(1, f.length);

  const handleSubmitForm = () => {
    if (inputEmail.length === 0) return;
    setTags(emailSlug || `${formattedPathname}`);
    setButtonState('Sending ...');
    setTimeout(() => {
      window.document.querySelector('#submitTSForm').click();
    }, 500);

    setTimeout(() => {
      setButtonState('Sent Successfully');
      setButtonClass('success');
      setInputEmail('');

      setTimeout(() => {
        setButtonState(emailButton || 'Submit Request');
        setButtonClass('primary');
      }, 2000);
    }, 1500);
  };

  useEffect(() => {
    if (emailPartner) setTags(emailPartner);
    if (emailType) {
      const sType = types.find((t) => emailType === t.label);
      setSelectedType(sType);
    }
    if (emailButton) setButtonState(emailButton);
  }, [emailPartner, emailType, emailButton, selectedType]);

  return (
    <div className="email-hero-form">
      <div className="call-to-action">
        {!emailType && (
          <Dropdown
            type="Teamsnap"
            placeholder="Select Type"
            items={types}
            selected={selectedType}
            callback={setSelectedType}
          />
        )}

        <Input
          placeholder={
            emailPlaceholder ? `${emailPlaceholder}*` : 'Enter your email*'
          }
          value={inputEmail}
          onChange={(e) => setInputEmail(e.target.value)}
        />
        <Button
          className={`primary ${buttonClass} ${
            inputEmail.length === 0 ? 'disabled' : ''
          }`}
          title="Teamsnap Form"
          action="Submit"
          disabled={!checked}
          onClick={() => handleSubmitForm()}
        >
          {buttonState}
        </Button>
      </div>

      <iframe
        title="secret-frame-two"
        name="secret-frame-two"
        width="0"
        height="0"
        frameBorder="0"
        style={{ display: 'none' }}
      ></iframe>
      <form
        className="form"
        action="https://docs.google.com/forms/d/e/1FAIpQLSfCICrxvCZWgr9SbVpw19l7Mntyx62rneYM5ZCpNbnSvj8hxQ/formResponse"
        id="ss-form4"
        method="POST"
        target="secret-frame-two"
        style={{ display: 'none' }}
      >
        <div className="form-group">
          <label htmlFor="subscriptionEmail">Email</label>
          <input
            name="entry.384784946"
            readOnly
            type="text"
            value={inputEmail}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subscriptionType">Type</label>
          <input
            name="entry.600604502"
            readOnly
            type="text"
            value={selectedType?.label || 'Other'}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subscriptionTags">Tags</label>
          <input
            name="entry.1676142734"
            readOnly
            type="text"
            value={mailchimpTag || tags}
          />
        </div>
        <div className="form-group">
          <label htmlFor="sport">Sport</label>
          <input
            name="entry.74738073"
            readOnly
            type="text"
            value={emailSport || 'Other'}
          />
        </div>

        <div className="form-group">
          <label htmlFor="sport">Partner</label>
          <input
            name="entry.1513840895"
            readOnly
            type="text"
            value={emailPartner}
          />
        </div>

        <input id="submitTSForm" type="submit" value="Submit Teamsnap Form" />
      </form>
    </div>
  );
}

export default TeamsnapForm;
