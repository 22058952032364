import React from 'react';
import './HeroSimple.scss';

function HeroSimple({ title, subtitle, poster, video, meta, type }) {
  const getVideo = () => {
    if (type === 'Conversation')
      return 'https://cdn.bfldr.com/B1ZZKPB4/as/5sm9ntbpxsr8jhxnvh7hgp/Video_BG_Interactive';
    return (
      video ||
      'https://cdn.bfldr.com/B1ZZKPB4/as/hvhh7hh4s35rnw8vmhwbtccw/Website_Intro'
    );
  };
  return (
    <div className="hero-simple">
      <div className="landingBg">
        <video
          loop
          playsInline
          autoPlay
          muted
          preload="metadata"
          width="100%"
          height="auto"
          id="bgvid"
          poster={poster || '/HomeHero.jpg'}
          src={getVideo()}
        />
        <div className="overlay" />
        {title && (
          <div className="landingBgInfo">
            <h1>{title}</h1>
            <hr />
            {meta && (
              <div className="meta">
                {meta.map((m) => (
                  <div className="item" key={m.title}>
                    <h3>{m.value}</h3>
                    {m.title}
                  </div>
                ))}
              </div>
            )}
            <p>{!meta && subtitle}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeroSimple;
