import { useState } from 'react';
import Input from '@components/Input/Input';
import Button from '@components/Button/Button';
import { Link } from 'react-router-dom';
import Dropdown from '@components/Dropdown/Dropdown';

const types = [
  { id: 0, label: 'Account' },
  { id: 1, label: 'Cancel Membership' },
  { id: 2, label: 'Group Membership' },
  { id: 3, label: 'Partnership Inquiry' },
  { id: 4, label: 'Press Related Inquiry' },
  { id: 5, label: 'Technical Issues (iOS)' },
  { id: 6, label: 'Technical Issues (Android)' },
  { id: 7, label: 'Personal Data & Privacy' },
  { id: 9, label: 'Feedback' },
  { id: 8, label: 'Other' },
];

function ContactForm({ type, user, setContactModal }) {
  const getType = () => {
    if (type === 'Teamsnap') return null;
    if (type === 'Cancel Membership') return types[1];
    if (type === 'Group Membership') return types[2];
    if (type === 'Partnership') return types[3];
    if (type === 'Feedback') return types[9];
    return null;
  };
  const [firstName, setFirstName] = useState(
    user?.displayName?.split(' ')?.[0] || ''
  ); //1431231543
  const [lastName, setLastName] = useState(
    user?.displayName?.split(' ')?.[1] || ''
  ); //1450919874
  const [email, setEmail] = useState(user?.email || ''); //159131749
  const [organization, setOrganization] = useState(''); //1415466881
  const [groupSize, setGroupSize] = useState(''); //673651094
  const [role, setRole] = useState(''); //1490587691
  const [description, setDescription] = useState(
    type === 'Cancel Subscription' ? 'Please cancel my subscription' : ''
  ); //888919343
  const [buttonState, setButtonState] = useState('Submit');
  const [buttonClass, setButtonClass] = useState('primary');
  const [cancelBody, setCancelBody] = useState(
    'Are you sure you want to cancel your subscription?'
  );
  const [showActions, setShowActions] = useState(true);
  const [selectedType, setSelectedType] = useState(getType());
  const [tag, setTag] = useState('');

  const getTag = () => {
    if (selectedType?.id === 3) {
      setTag('Partnership Inquiry');
      return;
    }
    if (selectedType?.id === 2) {
      setTag('Group Membership Inquiry');
      return;
    }
    if (selectedType?.id === 4) {
      setTag('Press Inquiry');
      return;
    }
    if (selectedType?.id === 9) {
      setTag('Feedback');
      return;
    }
    setTag('Customer Support Inquiry');
    setTimeout(() => {
      window.document.querySelector('#submitContactForm').click();
    }, 1000);
  };

  const handleSubmitForm = (indefinite) => {
    setButtonState('Sending ...');

    getTag();

    setTimeout(() => {
      setButtonState('Sent Successfully');
      setButtonClass('success');

      if (indefinite) {
        setCancelBody(
          <>
            <p>
              Request to cancel your subscription submitted. Thank you for
              supporting VS!
            </p>
            <p>
              If you have any questions, please{' '}
              <Link to="/contact" onClick={() => setContactModal(null)}>
                contact us here
              </Link>
              .
            </p>
          </>
        );
        setShowActions(false);
        return;
      }
      setFirstName('');
      setLastName('');
      setEmail('');
      setOrganization('');
      setRole('');
      setDescription('');
      setGroupSize('');
      setTag('');

      setTimeout(() => {
        setButtonState('Submit');
        setButtonClass('primary');
      }, 1500);
    }, 1500);
  };

  return (
    <div className="contact-form">
      {type === 'Cancel Subscription' && (
        <div className="cancel-content">
          <p>{cancelBody}</p>
          {showActions && (
            <div className="actions">
              <Button
                className="secondary"
                onClick={() => setContactModal(null)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  email.length > 0 && handleSubmitForm(true);
                }}
                className="danger"
              >
                {buttonState}
              </Button>
            </div>
          )}
        </div>
      )}
      <div className={type === 'Cancel Subscription' ? 'hide' : ''}>
        <div className="double">
          <div>
            <label className={firstName.length > 0 ? 'filled' : ''}>
              First Name*
            </label>
            <Input
              placeholder="First Name*"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <label className={lastName.length > 0 ? 'filled' : ''}>
              Last Name*
            </label>
            <Input
              placeholder="Last Name*"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>

        <div className="single">
          <label className={email.length > 0 ? 'filled' : ''}>Email*</label>
          <Input
            placeholder="Email*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={`single ${type ? 'hidden' : ''}`}>
          <Dropdown
            placeholder="Select a category"
            items={types}
            selected={selectedType}
            callback={setSelectedType}
          />
        </div>

        <div
          className={
            selectedType?.label === 'Group Membership' ||
            selectedType?.label === 'Partnership Inquiry' ||
            selectedType?.label === 'Press Related Inquiry'
              ? ''
              : 'hide'
          }
        >
          <div className="single">
            <label className={organization.length > 0 ? 'filled' : ''}>
              Organization Name*
            </label>
            <Input
              placeholder="Organization Name*"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
            />
          </div>
          <div className="single">
            <label className={role.length > 0 ? 'filled' : ''}>
              Role or Title*
            </label>
            <Input
              placeholder="Role or Title*"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </div>
          <div className="single">
            <label className={groupSize.length > 0 ? 'filled' : ''}>
              Group Size (# of People)*
            </label>
            <Input
              placeholder="Group Size (# of People)*"
              value={groupSize}
              onChange={(e) => setGroupSize(e.target.value)}
            />
          </div>
        </div>

        <div className="single">
          <label className={description.length > 0 ? 'filled' : ''}>
            Description
          </label>
          <Input
            placeholder="Please tell us what you are inquiring about..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <Button
          className={`${buttonClass} ${email.length === 0 ? 'disabled' : ''}`}
          onClick={() => {
            email.length > 0 && handleSubmitForm();
          }}
        >
          {buttonState}
        </Button>
      </div>

      <iframe
        title="secret-frame-one"
        name="secret-frame-one"
        width="0"
        height="0"
        border="0"
        style={{ display: 'none' }}
      ></iframe>
      <form
        className="form"
        action="https://docs.google.com/forms/d/e/1FAIpQLSfqIbpbCNNQSUalgVgwvOIkzpCaAWUlhW66Tr7ofgunLYqFxg/formResponse"
        id="ss-form1"
        method="POST"
        target="secret-frame-one"
        style={{ display: 'none' }}
      >
        <div className="form-group">
          <input name="entry.1431231543" readOnly value={firstName} />
          <input name="entry.74337840" readOnly value={selectedType?.label} />
          <input name="entry.1450919874" readOnly value={lastName} />
          <input name="entry.159131749" readOnly type="email" value={email} />
          <input name="entry.1415466881" readOnly value={organization} />
          <input name="entry.1490587691" readOnly value={role} />
          <input name="entry.888919343" readOnly value={description} />
          <input name="entry.673651094" readOnly value={groupSize} />
          <input name="entry.793398080" readOnly value={tag} />
        </div>

        <input id="submitContactForm" type="submit" value="Submit Subscribe" />
      </form>
    </div>
  );
}

export default ContactForm;
