import EmailHeroForm from './EmailHeroForm';

function EmailHero({ title, body, mobileTitle }) {
  return (
    <div className="email-hero">
      <div className="left">
        <h1 className="hide-mobile">{title}</h1>
        <h1 className="hide-desktop">{mobileTitle}</h1>
        <p>{body}</p>
      </div>
      <EmailHeroForm />
    </div>
  );
}

export default EmailHero;
