import Button from '@components/Button/Button';
import { current } from '@reduxjs/toolkit';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import './DownloadApp.scss';

function DownloadApp({
  setToggledDownloadModal,
  handleDownloadModal,
  currentUser,
}) {
  return (
    <div className="modals download-app">
      <img
        className="close"
        src="/icons/close.svg"
        alt="Close Modal"
        onClick={() => {
          if (currentUser) {
            handleDownloadModal();
          }
          setToggledDownloadModal(false);
        }}
      />
      <div className="content">
        <img src="/logo.svg" className="logo" alt="Versus Logo" />
        <BrowserView>
          <h1>The Versus desktop experience is on its way!</h1>
          <p style={{ marginBottom: '20px' }}>
            For now, please download the Versus Interactive app to access your
            content.
          </p>
        </BrowserView>
        <MobileView>
          <h1>Download the Versus app</h1>
          <p style={{ marginBottom: '20px' }}>
            Ready for an experience like no other? Download the Versus
            Interactive app to access your content.
          </p>
        </MobileView>

        <p>Select your app store.</p>
        <div className="button-group">
          <a
            href="https://apps.apple.com/us/app/versus-interactive/id1612364880"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/landing/appstore.png" alt="app-store" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.mavenarena.versus"
          >
            <img src="/landing/googleplay.png" alt="play-store" />
          </a>
        </div>

        <div className="footer-text">
          {handleDownloadModal ? (
            <Button
              to="/contact"
              className="primary"
              title="Button/Link Clicked"
              action="Continue to Website"
              onClick={() => handleDownloadModal()}
            >
              Or continue using the website
            </Button>
          ) : (
            <>
              <p>Need help managing your account?</p>
              <Link to="/contact">Contact Us</Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DownloadApp;
