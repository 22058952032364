import React, { useState } from 'react';
import { auth } from '../../../config/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import { XIcon } from '@heroicons/react/outline';
import { ResetPasswordModalOverlay } from './ResetPasswordModal.style';

interface Props {
  openConfirmResetModal: () => void;
  closeChangeResetPasswordModal: () => void;
}

const ResetPasswordModal = (props: Props) => {
  const { openConfirmResetModal, closeChangeResetPasswordModal } = props;
  const [emailAddress, setEmailAddress] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (value: string) => {
    const regex = new RegExp(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    );
    return regex.test(value);
  };

  const resetPassword = () => {
    setEmailError('');
    if (emailAddress.trim() === '') {
      setEmailError("Email can't be empty");
      return;
    }

    sendPasswordResetEmail(auth, emailAddress)
      .then(() => {
        closeChangeResetPasswordModal();
        openConfirmResetModal();
      })
      .catch((err) => {
        switch (err.code) {
          case 'auth/invalid-email':
            setEmailError('Invalid email address');
            break;
          case 'auth/user-not-found':
            setEmailError('User with this email not found');
            break;
        }
      });
  };

  return (
    <ResetPasswordModalOverlay>
      <div className={`resetPasswordModal ${!!emailError ? 'hasError' : ''}`}>
        <XIcon
          onClick={closeChangeResetPasswordModal}
          className="closeModalBtn"
        />
        <h3>Reset password</h3>
        <p>
          Enter the email associated to your Versus account <br /> to receive a
          link to reset your password.
        </p>
        <div className="emailInputWrapper">
          <span className="emailLabel">Email</span>
          <Input
            type="name"
            autoFocus
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          {!!emailError && <p className="errorMsg">{emailError}</p>}
        </div>
        <div className="emailBtnWrapper">
          <Button
            onClick={closeChangeResetPasswordModal}
            className="cancelBtn "
            title="Reset Password Modal"
            action="Cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={resetPassword}
            className={`sendLinkBtn primary ${
              validateEmail(emailAddress) ? 'validatedBtn' : ''
            }`}
            disabled={!validateEmail(emailAddress)}
            title="Reset Password Modal"
            action="Send Link"
          >
            Send link
          </Button>
        </div>
      </div>
    </ResetPasswordModalOverlay>
  );
};

export default ResetPasswordModal;
